import { isMobile } from '../utils/index'
export default {
  address: '接待中心:台北市大同區重慶北路三段68號2F',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.0657132506144!2d121.51123461500706!3d25.06576178395756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a938a4cb10dd%3A0xdb0031b0aad055ed!2zMTAz5Y-w5YyX5biC5aSn5ZCM5Y2A6YeN5oW25YyX6Lev5LiJ5q61NjjomZ8yRg!5e0!3m2!1szh-TW!2stw!4v1599015709736!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/k1vqYRxCmRP7vzzK8',
  phone: '02-2587-5257',
  fbLink: 'https://www.facebook.com/%E7%AF%89%E5%84%84%E4%B8%B0%E7%9B%9B-18-22%E5%9D%AA-%E5%BE%B7%E5%9C%8B%E7%B2%BE%E5%93%81%E5%AE%B6-106367061173988',
  fbMessage: 'https://m.me/106367061173988',
  caseName: '築億丰盛',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '築億建設 ',],
    ['廣告代銷', '海沃創意行銷',],
    ['基地面積', '168.19坪',],
    ['格局規劃', '18-22坪',],
  ],

  gtmCode: ['N29VKBT','5B7P2DG'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: isMobile ? '讀秒登入<br />双捷靜巷‧公園首席' : '讀秒登入 双捷靜巷‧公園首席',
    subTitle: ''
  }
}
