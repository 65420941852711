<template>
  <div>
    <div class="section4">
      <svg v-if="!isMobile" version="1.1" id="s4" class="img absolute" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 582 780" style="enable-background:new 0 0 582 780;" xml:space="preserve">
        <line class="st0" x1="76.6" y1="145.9" x2="76.6" y2="675.8"/>
        <circle class="st1" cx="76" cy="79" r="66.8"/>
        <g class="ant0">
          <path class="st2" d="M117.5,66.7v10.6h5.7v-5.6h4.9v10.5h-15.6V66.7H96.2v4.5h10.7v10.9h-4.9v-6h-5.8v6.1h-7.5v-4.9h2.6V61.7h21.3
            v-2.5h4.9v2.5h5.7v-2.5h4.9v7.4H117.5z M91.2,84.9h36.9v13.8H128v0.1H88.7v-4.9h2.6V84.9z M112.6,93.9v-4.1h-5.7v4.1H112.6z
            M123.2,89.9h-5.7v4.1h5.7V89.9z M96.2,89.9v4.1h5.8v-4.1H96.2z M66.2,59.2h-4.9v18H44.5v4.9h16.7v5.8H44.5V93h16.7v5.7h4.9V93
            h16.7v-4.9H66.2v-5.8h16.7v-4.9H66.2V59.2z M25.7,93.9c0,0.2-0.1,0.3-0.2,0.5c-0.2,0.1-0.4,0.2-0.8,0.2c-0.2,0-0.4,0-0.5,0v-2.3
            c0.1,0,0.3,0,0.5,0c0.3,0,0.5,0.1,0.6,0.2c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.1,0.4-0.4,0.5v0C25.4,93.4,25.7,93.6,25.7,93.9z
            M24.5,93.3h0.3c0.3,0,0.5-0.2,0.5-0.4c0-0.3-0.2-0.4-0.5-0.4c-0.1,0-0.2,0-0.3,0V93.3z M25.4,93.9c0-0.3-0.3-0.4-0.6-0.4h-0.2v0.9
            c0.1,0,0.1,0,0.2,0C25.1,94.4,25.4,94.3,25.4,93.9z M28,93.4c0,0.8-0.5,1.2-1.1,1.2c-0.6,0-1-0.5-1-1.2c0-0.7,0.5-1.2,1.1-1.2
            C27.6,92.3,28,92.8,28,93.4z M27.7,93.4c0-0.5-0.2-0.9-0.7-0.9c-0.5,0-0.7,0.5-0.7,1c0,0.5,0.3,0.9,0.7,0.9
            C27.5,94.4,27.7,94,27.7,93.4z M30.1,92.3h-0.3v1.4c0,0.5-0.2,0.7-0.6,0.7c-0.3,0-0.5-0.2-0.5-0.7v-1.4h-0.3v1.3c0,0.7,0.4,1,0.8,1
            c0.5,0,0.9-0.3,0.9-1V92.3z M31.4,94.6v-2h0.7v-0.3h-1.7v0.3h0.7v2H31.4z M32.6,92.3h-0.3v2.3h0.3V92.3z M34,94.6
            c-0.5,0-1-0.4-1-1.2c0-0.7,0.4-1.2,1.1-1.2c0.6,0,1,0.5,1,1.2c0,0.6-0.3,1-0.7,1.1v0c0.2,0.1,0.5,0.1,0.7,0.1L35,94.9
            c-0.3-0.1-0.6-0.2-0.9-0.3C34.1,94.7,34,94.6,34,94.6z M34,94.4c0.5,0,0.7-0.4,0.7-1c0-0.5-0.2-0.9-0.7-0.9c-0.5,0-0.7,0.5-0.7,1
            C33.3,94,33.6,94.4,34,94.4z M37.1,93.6v-1.3h-0.3v1.4c0,0.5-0.2,0.7-0.6,0.7c-0.3,0-0.5-0.2-0.5-0.7v-1.4h-0.3v1.3
            c0,0.7,0.4,1,0.8,1C36.8,94.6,37.1,94.3,37.1,93.6z M38.9,92.6v-0.2h-1.2v2.3h1.3v-0.2h-1v-0.8h0.9v-0.2H38v-0.7H38.9z M29.6,97.4
            c0.1,0.3,0.1,0.5,0.2,0.6h-0.3c0-0.1-0.1-0.3-0.2-0.5c-0.1-0.3-0.2-0.4-0.5-0.4h-0.3v1h-0.3v-2.3c0.1,0,0.4,0,0.6,0
            c0.3,0,0.5,0.1,0.7,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.2,0.5-0.4,0.6v0C29.4,97,29.5,97.1,29.6,97.4z M29.4,96.3
            c0-0.3-0.2-0.4-0.5-0.4c-0.1,0-0.2,0-0.3,0v0.8h0.3C29.2,96.8,29.4,96.6,29.4,96.3z M30.4,96.9h0.9v-0.2h-0.9v-0.7h0.9v-0.2h-1.2
            V98h1.3v-0.2h-1V96.9z M32.5,96.7c-0.3-0.1-0.5-0.2-0.5-0.4c0-0.2,0.1-0.3,0.4-0.3c0.2,0,0.4,0.1,0.4,0.1l0.1-0.2
            c-0.1-0.1-0.3-0.1-0.5-0.1c-0.4,0-0.7,0.3-0.7,0.6c0,0.3,0.2,0.5,0.6,0.6c0.3,0.1,0.4,0.2,0.4,0.5c0,0.2-0.2,0.4-0.5,0.4
            c-0.2,0-0.4-0.1-0.5-0.1l-0.1,0.2C31.8,98,32,98,32.2,98c0.5,0,0.8-0.3,0.8-0.7C33,97,32.8,96.8,32.5,96.7z M35.4,96.8
            c0,0.8-0.5,1.2-1.1,1.2c-0.6,0-1-0.5-1-1.2c0-0.7,0.5-1.2,1.1-1.2C35,95.7,35.4,96.1,35.4,96.8z M35.1,96.8c0-0.5-0.2-0.9-0.7-0.9
            c-0.5,0-0.7,0.5-0.7,1c0,0.5,0.3,0.9,0.7,0.9C34.8,97.8,35.1,97.4,35.1,96.8z M37.1,97.4c0.1,0.3,0.1,0.5,0.2,0.6H37
            c0-0.1-0.1-0.3-0.2-0.5c-0.1-0.3-0.2-0.4-0.5-0.4h-0.3v1h-0.3v-2.3c0.1,0,0.4,0,0.6,0c0.3,0,0.5,0.1,0.7,0.2
            c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.2,0.5-0.4,0.6v0C36.9,97,37,97.1,37.1,97.4z M36.9,96.3c0-0.3-0.2-0.4-0.5-0.4
            c-0.1,0-0.2,0-0.3,0v0.8h0.3C36.7,96.8,36.9,96.6,36.9,96.3z M37.4,95.9h0.7v2h0.3v-2h0.7v-0.3h-1.7V95.9z M39,88.4h0.2V61.1H39
            V88.4z M23.8,88.4H24V61.1h-0.2V88.4z M25.4,67.5c-0.1,0-0.2,0.1-0.2,0.2v0.8c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0l5.2-0.4
            c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.2v-0.7c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0L29,67.2v-1.7h1.5c0,0,0.1,0,0.1,0
            c0,0,0.1-0.1,0.1-0.2v-0.7c0-0.1-0.1-0.2-0.2-0.2h-4.8c-0.1,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1v0.8c0,0,0,0.1,0.1,0.1
            c0,0,0.1,0,0.1,0h1.5v1.9L25.4,67.5z M32.4,68.8c0,0-0.1-0.1-0.1-0.1h-1.5c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1v0.4h-5.2
            c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1v0.8c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0h5.2v3.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1h1.4
            c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1v-3.1h5.2c0.1,0,0.2-0.1,0.2-0.2v-0.8c0-0.1-0.1-0.2-0.2-0.2h-5.2v-0.4
            C32.4,68.8,32.4,68.8,32.4,68.8z M37.8,73.3L35.4,71c-0.1-0.1-0.2-0.2-0.4-0.2h-1.7c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0,0.1
            l2.4,2.3c0.1,0.1,0.2,0.1,0.3,0.1h1.9c0,0,0.1,0,0.1-0.1C37.9,73.3,37.9,73.3,37.8,73.3z M26.7,64.1c0,0,0.1,0,0.1,0
            c0,0,0.1-0.1,0.1-0.1l0.7-1.1h0.6l0.6,1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0h1.6c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1l-0.6-1h1.1
            c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.2v-0.7c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0h-2.9l0.2-0.3c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1
            H27c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1l-1.7,2.5c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1H26.7z M31.3,64C31.3,64,31.3,64,31.3,64
            l1.7,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1l0.6-1h0.8l0.6,1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0H37c0,0,0.1,0,0.1-0.1
            c0,0,0-0.1,0-0.1l-0.6-1h1.1c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.2v-0.7c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0h-3.1l0.2-0.3
            c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1h-1.6c-0.1,0-0.2,0.1-0.3,0.2l-1.5,2.4C31.3,63.9,31.3,63.9,31.3,64z M37.8,67.8
            C37.8,67.8,37.9,67.8,37.8,67.8c0.1,0.1,0.1,0.1,0.1,0.1l-0.4,0.8c0,0.1-0.1,0.1-0.1,0.1h-1.3c-0.2,0-0.4-0.1-0.5-0.2
            c-0.2-0.2-0.3-0.3-0.3-0.5h-1.3c-0.1,0-0.2,0-0.2-0.1l-0.3-0.2l-0.5,0.4c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0h-1.5
            c0,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.1l1.3-1.1l-1.7-1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0.1-0.1h1.4c0.1,0,0.1,0,0.2,0l0.6,0.3v-0.8
            h-1.3c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1v-0.8c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1h4.4c0.4,0,0.8,0.1,1,0.3
            c0.2,0.2,0.3,0.4,0.3,0.7v2.4H37.8z M35.2,65.5h-0.9v1.1c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3l1.1,0.7V65.5z M25.1,73.4
            c0,0.1,0.1,0.1,0.1,0.1h1.8c0.1,0,0.2,0,0.3-0.1l2.4-2.3c0,0,0.1-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.1H28c-0.1,0-0.3,0.1-0.4,0.2
            l-2.5,2.3C25.1,73.3,25.1,73.3,25.1,73.4z M36.4,84.8h-6.9c-0.2,0-0.3,0-0.4-0.1c-0.2-0.1-0.3-0.3-0.3-0.6v-2.8
            c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c0.2-0.2,0.4-0.3,0.6-0.3h6.8c0.2,0,0.4,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.6v2.7
            c0,0.2-0.1,0.4-0.2,0.6C36.8,84.8,36.6,84.8,36.4,84.8z M35.5,83.2h-4.9v0.8h4.9V83.2z M35.5,81.5h-4.9v0.8h4.9V81.5z M37.6,79.9
            h-9.3c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1V79c0-0.1,0.1-0.1,0.2-0.1h2.2L30,77.6l-1.1,0c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1v-0.7
            c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0h3.4l-0.4-0.7c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1h1.7c0.1,0,0.2,0,0.2,0.1l0.5,0.8h2.9
            c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1v0.6c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0l-1,0l-0.5,1.3h1.9c0.1,0,0.2,0.1,0.2,0.2v0.7
            c0,0,0,0.1,0,0.1C37.7,79.9,37.7,79.9,37.6,79.9z M34.4,77.6h-2.6l0.5,1.3H34L34.4,77.6z M35.3,87.3h-2.6v-1.8c0,0,0-0.1,0-0.1
            c0,0-0.1-0.1-0.1-0.1H31c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1v1.7c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.2,0.1,0.3,0.2
            c0.1,0.1,0.2,0.1,0.3,0.1h3.2c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1l0.4-0.7C35.4,87.4,35.4,87.3,35.3,87.3C35.4,87.3,35.4,87.3,35.3,87.3
            z M29.8,87.9l0.7-2.2c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1h-1.4c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0.1L28,87.9c0,0,0,0.1,0,0.1
            c0,0,0,0,0.1,0h1.5C29.7,88,29.8,88,29.8,87.9z M36.3,88h1.5c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1l-0.7-2.3c0,0,0-0.1-0.1-0.1
            c0,0-0.1,0-0.1,0h-1.4c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1l0.7,2.4C36.1,88,36.2,88,36.3,88z M34.5,85.2h-1.1c0,0-0.1,0-0.1,0.1
            c0,0,0,0.1,0,0.1l0.4,1.4c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0H35c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1l-0.4-1.4
            C34.7,85.3,34.6,85.2,34.5,85.2C34.6,85.2,34.5,85.2,34.5,85.2z M27.5,88.1c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1v-7.4
            c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0H27l1.5-4.4c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.1h-1.5c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1
            l-1.6,4.7c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1h0.7v7.3c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1H27.5z"/>
          <polygon class="st3" points="44.2,66.2 44.2,71 83.2,66.7 83.2,61.7 	"/>
        </g>
        <circle class="st4 an1" cx="76" cy="229.9" r="48"/>
        <g class="ant1">
          <path class="st5" d="M72.8,212.5c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.1-0.4,0.2c-1.6,1.3-2.8,2.2-3.5,2.6
            c-1.3,0.7-2.5,1.3-3.5,1.6c-1.4,0.4-3.1,0.8-5.2,0.9l-0.7-1.4c1.9-0.1,3.5-0.3,4.8-0.6c1.3-0.3,2.5-0.9,3.8-1.6
            c0.9-0.5,2-1.4,3.3-2.7L72.8,212.5z M67.8,204.2c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.4,0.4v1.1H72v1.3h-4.3v1.6h5.7v1.3h-5.7v3
            c0,0.7-0.2,1.1-0.5,1.4c-0.3,0.3-1,0.5-2.1,0.5l-0.6-1.4h1.1c0.4,0,0.6-0.3,0.6-0.8v-2.7h-6.3v-1.3h2.6v-3.3l1.3,0.1
            c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.2,0.4v2.7h2.4V204L67.8,204.2z M64.3,211.3c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
            c-0.8,1.1-1.6,2.1-2.4,2.9l-1.4-0.7c1.1-0.9,1.9-1.9,2.6-3.2L64.3,211.3z M81.1,207.6c0.2,0,0.2,0.1,0.2,0.2c0,0,0,0.1-0.1,0.2
            c-0.2,0.1-0.6,0.5-1.2,1.3c-0.3,0.3-0.5,0.7-0.8,1v7.7h-1.4v-6.2c-0.4,0.4-0.8,0.8-1.3,1.1l-1.3-0.7c2-1.4,3.5-3,4.8-4.9
            L81.1,207.6z M80.4,204.7c0.2,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.1-0.1,0.2c-0.2,0-0.5,0.3-0.9,0.8c-0.8,0.8-1.7,1.5-2.8,2.2l-1.3-0.7
            c1.5-0.9,2.8-1.9,3.8-3.1L80.4,204.7z M89.1,209.2v1.3h-2.6v5.8c0,0.6-0.2,1-0.6,1.3c-0.3,0.3-1.2,0.4-2.5,0.4l-0.6-1.5h1.4
            c0.5,0,0.8-0.1,0.8-0.4v-5.6h-4.2v-1.3H89.1z M88.5,205.1v1.3h-6.6v-1.3H88.5z M41.6,234.1c0.2,0.1,0.3,0.2,0.3,0.2
            c0,0.1-0.1,0.3-0.3,0.4l-2.3,2.9c0.5,0.3,1,0.7,1.4,1l1.8-2.4l1.2,0.8c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.4,0.2l-3.6,4.4
            c0.9-0.1,1.8-0.3,2.7-0.4c-0.2-0.5-0.4-0.9-0.6-1.4l1.4-0.3c0.7,1.4,1.2,2.8,1.5,4.1l-1.4,0.3c-0.1-0.5-0.3-1-0.5-1.5
            c-0.4,0.1-0.9,0.2-1.4,0.3v7.3h-1.6v-7c-0.9,0.2-1.8,0.3-2.9,0.5c-0.1,0.2-0.2,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.2-0.2l-0.3-1.5
            c0.6,0,1.2-0.1,1.8-0.2l1.7-2.3c-1-0.7-2.1-1.4-3.3-2.2l0.9-1.1c0.2,0.1,0.4,0.3,0.7,0.4l2.3-3.3L41.6,234.1z M39.3,244.9
            c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.3-0.3,0.4c-0.3,1.5-0.7,2.9-1.2,4l-1.5-0.5c0.3-0.5,0.5-1.1,0.7-1.8c0.3-0.8,0.5-1.8,0.6-2.8
            L39.3,244.9z M45.2,248.6l-1.4,0.3c-0.4-1.5-0.9-2.9-1.4-4.1l1.4-0.3C44.4,245.9,44.9,247.3,45.2,248.6z M48,234.1
            c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.3-0.4,0.5c-0.2,0.7-0.4,1.4-0.6,2.1h5.4c0,5.2-0.1,8.4-0.4,9.8c-0.2,1.3-0.5,2.2-1.1,2.7
            c-0.6,0.5-1.6,0.8-3.1,0.8l-0.8-1.6c1.6,0,2.5-0.1,2.8-0.5c0.3-0.2,0.5-0.8,0.7-1.7c0.2-1,0.3-3.6,0.4-7.9h-4.3
            c-0.3,0.9-0.7,1.7-1.1,2.4l-1.6-0.6c0.5-0.8,1-1.6,1.3-2.6c0.4-1.2,0.8-2.5,1.1-4L48,234.1z M49.7,244.1l-1.3,1.1
            c-0.8-1.2-1.6-2.1-2.4-2.8l1.2-1.1C48,242.1,48.8,243.1,49.7,244.1z M64.1,251.1c-5.1,0-7.6-4-7.6-11.9c0-8,2.5-11.9,7.6-11.9
            c5,0,7.6,4,7.6,11.9C71.6,247.2,69.1,251.1,64.1,251.1z M67.2,232.2c-0.7-1.2-1.7-1.8-3.1-1.8c-2.8,0-4.2,2.9-4.2,8.8
            c0,5.8,1.4,8.7,4.2,8.7c1.3,0,2.4-0.6,3.1-1.8c0.8-1.4,1.2-3.7,1.2-6.9C68.3,236,67.9,233.7,67.2,232.2z M75.9,251.1v-4.5h4.5v4.5
            H75.9z M97.2,248.9c-1.4,1.5-3.1,2.2-5.2,2.2c-3.3,0-5.5-1.7-6.8-5.2l2.8-1.2c0.8,2.2,2.2,3.3,4,3.3c2.7,0,4.1-1.8,4.1-5.3
            c0-3.3-1.1-5-3.4-5c-1,0-1.8,0.2-2.3,0.6c-0.5,0.4-1,1-1.4,1.9H86v-12.5h12.4v3h-9.2v4.9c0.8-0.7,2-1.1,3.4-1.1
            c2,0,3.7,0.7,4.9,2.2c1.2,1.5,1.8,3.5,1.8,5.9C99.4,245.2,98.6,247.4,97.2,248.9z M107.5,230.3c0.1,0,0.2,0.1,0.2,0.2
            c0,0-0.1,0.1-0.4,0.2c-0.4,0.9-0.8,1.8-1.3,2.5c-0.5,0.7-1,1.2-1.6,1.8l-1-0.7c0.7-0.6,1.3-1.2,1.7-1.8c0.4-0.6,0.9-1.4,1.2-2.4
            L107.5,230.3z M112,233.8c0,2.3-0.1,3.8-0.3,4.3c-0.1,0.6-0.4,1-0.7,1.2c-0.4,0.2-1,0.4-1.9,0.4l-0.5-1.1c1,0,1.5-0.1,1.7-0.2
            c0.2-0.1,0.3-0.3,0.4-0.7c0.1-0.4,0.2-1.4,0.2-3h-2.4c-0.1,0.9-0.2,1.7-0.5,2.2c-0.3,0.7-0.7,1.2-1.1,1.6c-0.4,0.4-1,0.8-1.7,1.2
            l-1-0.8c0.8-0.3,1.3-0.6,1.7-1c0.4-0.4,0.7-0.8,1-1.4c0.2-0.5,0.4-1.1,0.5-1.9h-1.7v-1H112z M111.4,229.6c0.1,1.2,0.4,2.1,1,2.8
            c0.4,0.5,1.1,1.1,2,1.8l-1,0.7c-1-0.8-1.8-1.6-2.3-2.3c-0.4-0.5-0.6-1.2-0.8-2h-2.3v-1H111.4z M106.7,241.5c0.1,0,0.2,0.1,0.2,0.1
            c0,0.1-0.1,0.2-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0.5,0.4,1.1,0.8,1.7,1.1l-0.7,0.7c-0.6-0.4-1.1-0.8-1.5-1.2
            c-0.2,0.3-0.5,0.5-0.7,0.7c-0.2,0.2-0.5,0.5-0.7,0.7h2.9v0.9h-1.1v1.2h1.5v0.9h-1.5v3.1c0.5-0.1,1-0.1,1.5-0.2v0.8
            c-0.8,0.1-2,0.3-3.7,0.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.2l-0.2-1c0.6,0,1.2-0.1,1.8-0.2v-3.2h-1.6v-0.9h1.6V245
            h-1.1v-0.7c-0.2,0.1-0.3,0.3-0.5,0.4l-0.7-0.7c0.6-0.4,1.1-0.9,1.5-1.4c0.4-0.4,0.8-0.9,1.2-1.6L106.7,241.5z M105.2,249.5
            l-0.7,0.4c-0.2-0.6-0.5-1.1-0.8-1.7l0.8-0.4C104.7,248.4,105,249,105.2,249.5z M108,248.1c0.1,0,0.2,0.1,0.2,0.1
            c0,0-0.1,0.1-0.2,0.2c-0.2,0.4-0.4,0.8-0.6,1.3l-0.6-0.2c0.1-0.3,0.2-0.5,0.3-0.7c0.1-0.3,0.2-0.7,0.3-1L108,248.1z M111.6,241.1
            c0.2,0,0.3,0.1,0.3,0.2c0,0-0.1,0.1-0.2,0.2v0.4h2.3v0.8h-0.8l-0.4,1h1.3v0.8H108v-0.8h1.6l-0.4-1h-0.9v-0.8h2.4V241L111.6,241.1z
            M113.7,245.1v3.6h-2.2v0.6h2.3v0.8h-2.3v0.7h2.6v0.8H108v-0.8h2.5V250h-2.2v-0.8h2.2v-0.6h-2.2v-3.6H113.7z M110.5,246.6v-0.7
            h-1.2v0.7H110.5z M110.5,247.3h-1.2v0.7h1.2V247.3z M111.8,243.6l0.4-1h-2.1l0.4,1H111.8z M111.5,246.6h1.2v-0.7h-1.2V246.6z
            M112.7,247.3h-1.2v0.7h1.2V247.3z"/>
          <path class="st6" d="M179.2,232.3c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7
            c1-1,2.2-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7C180.8,230.1,180.3,231.3,179.2,232.3z M189.7,220.2v-1l1.8,0.1
            c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.4,0.5v0.2h5.5v-1l1.8,0.1c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.4,0.5v0.2h5.4v1.6H199v1
            h-1.9v-1h-5.5v1h-1.9v-1h-5.5v-1.6H189.7z M193.6,223v5.4l1.4,0.1c0.1,0,0.2,0,0.2,0V223h8.5v14.7c0,0.9-0.4,1.4-1.1,1.7
            c-0.6,0.2-1.2,0.3-1.8,0.3l-0.7-1.8l-1.4,1.3c-1-0.4-1.8-0.8-2.4-1.2c-0.4-0.2-0.8-0.5-1.2-0.8v2.4h-1.8V237
            c-0.4,0.3-0.8,0.6-1.2,0.9c-0.8,0.5-1.8,1.1-3.2,1.8l-1.8-1.3c1.4-0.5,2.6-0.9,3.5-1.3c0.5-0.2,0.9-0.4,1.4-0.7h-3.4v-4.1h4.7v-0.9
            h-5.5v-1.3h5.5v-1h-6.5v10.7H185V223H193.6z M191.7,225.3v-0.7h-4.9v0.7H191.7z M191.7,227.3v-0.7h-4.9v0.7H191.7z M190.9,233.8
            l1-0.5h-1.5v1.7h1.4C191.6,234.6,191.3,234.2,190.9,233.8z M193.3,233.3h-1.2c0.3,0.4,0.7,0.8,0.9,1.2l-0.8,0.5h1V233.3z
            M197.7,237c0.7,0.3,1.6,0.5,2.7,0.7l-0.2,0.2l1.1,0c0.3,0,0.5-0.2,0.5-0.7v-8.3h-6.5c-0.1,0.1-0.2,0.2-0.3,0.3v0.7h5.6v1.3h-5.6
            v0.9h4.8v4.1h-4.1C196.6,236.6,197.2,236.9,197.7,237z M195.7,234.6c0.4-0.5,0.7-0.9,0.9-1.3h-1.6v1.7h1.2L195.7,234.6z
            M198.2,233.3h-1.1l0.6,0.3c0.1,0.1,0.2,0.2,0.2,0.2c0,0.1-0.1,0.2-0.3,0.4c-0.2,0.3-0.4,0.6-0.6,0.8h1.2V233.3z M201.9,225.3v-0.7
            H197v0.7H201.9z M201.9,226.6H197v0.7h4.8V226.6z M211.1,224.5c-0.4,2.5-0.9,4.8-1.5,6.9l-1.8-0.5c0.7-2.4,1.2-4.7,1.5-7
            L211.1,224.5z M213.8,219.7c0.3,0,0.5,0.1,0.5,0.2c0,0.2-0.2,0.4-0.5,0.6v6.3c0,2.3-0.2,4.3-0.6,6c-0.3,1.4-0.9,2.6-1.6,3.9
            c-0.6,0.9-1.3,1.8-2,2.6l-1.6-1.4c0.8-0.7,1.4-1.4,1.8-2.1c0.7-1,1.2-2.1,1.5-3.1c0.4-1.6,0.7-3.5,0.7-5.8v-5.8
            c0-0.4,0-0.9-0.1-1.5L213.8,219.7z M217.6,231.6l-1.8,0.4c-0.4-2.7-0.9-5.3-1.4-7.6l1.8-0.4C216.8,226.7,217.3,229.2,217.6,231.6z
            M219.9,220.2c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.3-0.3,0.5v17.9h-2V220L219.9,220.2z M224.1,231.6l-1.8,0.4
            c-0.5-2.7-1.1-5.3-1.8-7.6l1.8-0.4C223.1,226.7,223.7,229.2,224.1,231.6z M226.5,219.6c0.3,0,0.5,0.1,0.5,0.3
            c0,0.1-0.1,0.3-0.3,0.5v19h-2v-20L226.5,219.6z M238.5,220.6c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.2,0.2-0.7,0.3
            c-0.3,1-0.6,1.9-0.9,2.7h4.8v-4.8l1.8,0.2c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.3-0.4,0.5v3.8h6.8v1.8H244v4.4h5.9v1.8H244v4.6h7.9
            v1.8h-19.3v-1.8h9.3v-4.6h-6.2v-1.8h6.2v-4.4h-5.6c0,0.1-0.1,0.2-0.1,0.3c-0.7,1.3-1.4,2.5-2.3,3.5l-2-1.3c1.1-1.2,1.9-2.4,2.6-3.6
            c0.7-1.2,1.3-2.8,1.8-4.8L238.5,220.6z M259.6,235.2c0.2,0.1,0.4,0.2,0.4,0.3c0,0.2-0.1,0.3-0.4,0.5c-0.7,1.5-1.5,2.7-2.3,3.7
            l-1.7-1.1c0.9-1.1,1.8-2.5,2.5-4.1L259.6,235.2z M261.7,219.7c0.2,0.1,0.4,0.2,0.4,0.3c0,0.1-0.2,0.2-0.5,0.4
            c-0.5,0.7-1.1,1.5-1.7,2.3c1.3-0.1,2.6-0.2,3.9-0.3c-0.2-0.4-0.4-0.7-0.6-1l1.1-1c0.9,1.1,1.8,2.6,2.6,4.3l-1.3,0.9v6.4
            c0,0.9-0.4,1.5-1.2,1.7c-0.6,0.2-1.2,0.3-1.9,0.3l-0.7-1.8l1.4,0c0.4,0,0.6-0.2,0.6-0.7v-0.5h-4.8v2.7h-1.8V225h8.1
            c-0.2-0.4-0.4-0.8-0.6-1.2c-1.8,0.2-4.4,0.5-7.8,0.9c-0.2,0.2-0.3,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.3l-0.4-1.7
            c0.7,0,1.4-0.1,2.1-0.1c0.3-0.5,0.6-0.9,0.9-1.3c0.5-0.9,1-1.7,1.4-2.5L261.7,219.7z M263.8,227.4v-0.9h-4.8v0.9H263.8z
            M263.8,229.6v-0.9h-4.8v0.9H263.8z M263.3,234.5c0.3,0,0.4,0.2,0.4,0.3c0,0.2-0.1,0.4-0.4,0.6c-0.2,0.1-0.3,0.4-0.3,0.7v0.4
            c0,0.6,0.1,1,0.2,1.3c0.2,0.2,0.5,0.4,1.1,0.4h3.9c0.7,0,1.3-0.1,1.7-0.4c0.4-0.2,0.7-0.9,1-2l1.8,0.8c-0.4,1.4-0.9,2.3-1.4,2.7
            c-0.5,0.4-1.1,0.6-1.8,0.6h-5.8c-0.8,0-1.5-0.3-1.9-0.8c-0.4-0.5-0.6-1.2-0.6-2.1v-2.7L263.3,234.5z M269.2,235.9l-1.6,1.1
            c-0.7-1.1-1.4-2-2.2-2.7l1.2-1C267.5,233.9,268.3,234.8,269.2,235.9z M269.3,219.5c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.4-0.4,0.6
            c-0.2,0.1-0.3,0.4-0.3,0.7v0.7c0.6-0.1,1.1-0.3,1.6-0.4c0.9-0.2,2-0.7,3.2-1.2l1.1,1.1c0.1,0.1,0.1,0.2,0.1,0.2
            c0,0.1-0.1,0.1-0.1,0.1h-0.4c-0.1,0-0.2,0-0.4,0.1c-1.7,0.7-3.4,1.2-5.1,1.6c0,0.3,0.1,0.6,0.2,0.8c0.2,0.3,0.5,0.4,1.1,0.4h1.8
            c0.6,0,1.1-0.1,1.4-0.2c0.3-0.1,0.6-0.5,0.8-1.2l1.7,0.8c-0.4,0.9-0.8,1.6-1.3,1.8c-0.4,0.2-0.9,0.4-1.6,0.4h-3.7
            c-0.8,0-1.4-0.2-1.8-0.7c-0.4-0.5-0.6-1.1-0.6-2v-4L269.3,219.5z M269.4,226.5c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.4-0.4,0.6
            c-0.2,0.1-0.3,0.4-0.3,0.7v0.9c0.7-0.1,1.4-0.3,1.9-0.5c0.9-0.3,2.1-0.7,3.4-1.4l1.1,1.1c0.1,0.1,0.1,0.2,0.1,0.2
            c0,0.1-0.1,0.2-0.1,0.2h-0.3c-0.1,0-0.3,0-0.5,0.1c-1.8,0.8-3.7,1.4-5.6,1.8c0,0.5,0.1,0.8,0.2,1c0.2,0.3,0.5,0.4,1.1,0.4h1.7
            c0.7,0,1.3-0.1,1.7-0.3c0.4-0.2,0.7-0.7,1-1.6l1.7,0.8c-0.4,1.2-0.9,1.9-1.4,2.3c-0.5,0.3-1.1,0.5-1.8,0.5h-3.5
            c-0.8,0-1.4-0.2-1.8-0.7c-0.4-0.5-0.5-1.1-0.5-2v-4.4L269.4,226.5z M276.3,237.4l-1.8,1.1c-0.8-1.4-1.7-2.5-2.6-3.5l1.4-1.1
            C274.3,234.9,275.3,236,276.3,237.4z M286.7,222.8c0.2,0.1,0.4,0.2,0.4,0.3c0,0.2-0.2,0.4-0.6,0.5c-0.6,1.7-1.2,3-1.8,3.9
            c-0.7,1.1-1.9,2.3-3.4,3.6l-1.7-1.4c1.5-1.1,2.6-2.2,3.3-3.4c0.6-0.8,1.1-2.1,1.6-3.9L286.7,222.8z M289.9,227.8
            c0.3,0.1,0.5,0.2,0.5,0.3c0,0.1-0.2,0.3-0.5,0.5c-0.9,2.4-2.1,5-3.6,7.8c2.8-0.2,5.5-0.4,8-0.8c-0.8-1.2-1.7-2.3-2.5-3.3l1.5-1.2
            c1.8,1.9,3.5,4.3,5.3,7.2l-1.9,1.3c-0.4-0.8-0.8-1.6-1.2-2.2l-12.8,1.5c-0.3,0.4-0.5,0.5-0.7,0.5c-0.3,0-0.4-0.1-0.4-0.4l-0.8-2.5
            c1.2,0,2.3,0,3.4-0.1c0.7-1.3,1.3-2.5,1.7-3.7c0.9-2,1.6-4,2.1-5.9L289.9,227.8z M294.1,220.2c0.2,2.4,0.9,4.4,2,5.9
            c0.8,1.1,2.1,2.3,4.1,3.8l-2,1.4c-2.1-1.7-3.6-3.3-4.6-4.8c-0.8-1.1-1.3-2.6-1.6-4.5h-5.4v-1.8H294.1z M323.2,220v19.5h-1.8v-0.6
            h-15.2v0.6h-1.8V220H323.2z M321.4,237.2v-15.4h-15.2v15.4H321.4z M318.8,227.6v3.7h-3.7c0.4,0.7,0.9,1.3,1.4,1.7
            c0.9-0.5,1.7-1,2.3-1.5l1.1,1.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.2,0.2-0.5,0.2c-0.6,0.3-1.1,0.5-1.7,0.8c0.8,0.5,1.8,0.9,3.1,1.4
            l-1.8,1.4c-1.8-0.9-3.1-1.8-3.9-2.7c-0.7-0.7-1.2-1.6-1.7-2.8h-0.4c-0.4,0.4-0.7,0.7-1.1,1v2.5c1.1-0.2,2.1-0.4,2.9-0.6l0.2,1.4
            c-1.9,0.5-3.6,0.9-5.1,1.1c-0.1,0.2-0.2,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.3l-0.9-1.5c0.6-0.1,1.2-0.1,1.8-0.2v-1.6
            c-0.8,0.5-1.6,0.8-2.6,1.1l-1.4-1.4c1.4-0.3,2.5-0.7,3.5-1.2c0.4-0.2,0.8-0.4,1.2-0.6h-2.5v-3.7H318.8z M312.8,223.2v-1.1l1.7,0.1
            c0.3,0,0.5,0.1,0.5,0.2c0,0.1-0.1,0.3-0.3,0.5v0.2h4.7v1.4h-4.7v0.8h5.7v1.5H307v-1.5h5.7v-0.8h-4.6v-1.4H312.8z M317.1,229h-6.6
            v0.9h6.6V229z"/>
        </g>
        <circle class="st4 an2" cx="76" cy="352.9" r="48"/>
        <g class="ant2">
          <path class="st5" d="M73.8,334.7c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.1-0.4,0.2c-1.6,1.3-2.8,2.2-3.5,2.6
            c-1.3,0.7-2.5,1.3-3.5,1.6c-1.4,0.4-3.1,0.8-5.2,0.9l-0.7-1.4c1.9-0.1,3.5-0.3,4.8-0.6c1.3-0.3,2.5-0.9,3.8-1.6
            c0.9-0.5,2-1.4,3.3-2.7L73.8,334.7z M68.8,326.3c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.4,0.4v1.1H73v1.3h-4.3v1.6h5.7v1.3h-5.7v3
            c0,0.7-0.2,1.1-0.5,1.4c-0.3,0.3-1,0.5-2.1,0.5l-0.6-1.4h1.1c0.4,0,0.6-0.3,0.6-0.8v-2.7H61v-1.3h2.6v-3.3l1.3,0.1
            c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.2,0.4v2.7h2.4v-4.8L68.8,326.3z M65.3,333.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
            c-0.8,1.1-1.6,2.1-2.4,2.9l-1.4-0.7c1.1-0.9,1.9-1.9,2.6-3.2L65.3,333.4z M82.2,329.7c0.2,0,0.2,0.1,0.2,0.2c0,0,0,0.1-0.1,0.2
            c-0.2,0.1-0.6,0.5-1.2,1.3c-0.3,0.3-0.5,0.7-0.8,1v7.7h-1.4v-6.2c-0.4,0.4-0.8,0.8-1.3,1.1l-1.3-0.7c2-1.4,3.5-3,4.8-4.9
            L82.2,329.7z M81.4,326.8c0.2,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.1-0.1,0.2c-0.2,0-0.5,0.3-0.9,0.8c-0.8,0.8-1.7,1.5-2.8,2.2l-1.3-0.7
            c1.5-0.9,2.8-1.9,3.8-3.1L81.4,326.8z M90.1,331.4v1.3h-2.6v5.8c0,0.6-0.2,1-0.6,1.3c-0.3,0.3-1.2,0.4-2.5,0.4l-0.6-1.5h1.4
            c0.5,0,0.8-0.1,0.8-0.4v-5.6h-4.2v-1.3H90.1z M89.5,327.3v1.3h-6.6v-1.3H89.5z M48.1,352.8c0.2,0.1,0.3,0.2,0.3,0.3
            c0,0.2-0.1,0.4-0.4,0.5l-2.9,3.7c0.7,0.4,1.3,0.9,1.8,1.3l2.4-3.1l1.5,1c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.2,0.2-0.5,0.3l-4.7,5.7
            c1.2-0.1,2.3-0.3,3.4-0.6c-0.3-0.6-0.5-1.2-0.8-1.8l1.8-0.4c0.9,1.8,1.6,3.6,1.9,5.3l-1.8,0.4c-0.2-0.7-0.4-1.3-0.6-1.9
            c-0.5,0.1-1.1,0.2-1.8,0.4v9.4h-2v-9.1c-1.1,0.2-2.3,0.4-3.7,0.6c-0.1,0.3-0.3,0.4-0.5,0.4c-0.1,0-0.2-0.1-0.2-0.3l-0.4-1.9
            c0.8,0,1.5-0.1,2.3-0.2l2.2-3c-1.3-0.9-2.7-1.9-4.3-2.8l1.2-1.5c0.3,0.2,0.6,0.4,0.9,0.5l2.9-4.3L48.1,352.8z M45.1,366.8
            c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.4-0.4,0.6c-0.4,2-0.9,3.7-1.5,5.2l-1.9-0.6c0.4-0.7,0.7-1.5,0.9-2.3c0.3-1.1,0.6-2.3,0.8-3.6
            L45.1,366.8z M52.6,371.6l-1.8,0.4c-0.5-1.9-1.1-3.7-1.8-5.3l1.8-0.4C51.7,368.1,52.3,369.9,52.6,371.6z M56.3,352.8
            c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.4-0.5,0.7c-0.2,0.9-0.5,1.8-0.8,2.7h7c0,6.7-0.2,10.9-0.5,12.6c-0.2,1.7-0.7,2.9-1.4,3.5
            c-0.8,0.7-2.1,1-4,1l-1-2.1c2,0,3.2-0.2,3.6-0.6c0.4-0.3,0.7-1,0.9-2.2c0.3-1.3,0.4-4.7,0.5-10.2h-5.6c-0.5,1.1-0.9,2.2-1.4,3.1
            l-2-0.7c0.7-1,1.2-2.1,1.7-3.3c0.6-1.5,1-3.3,1.4-5.2L56.3,352.8z M58.5,365.8l-1.7,1.5c-1.1-1.5-2.1-2.7-3.1-3.6l1.5-1.4
            C56.3,363.2,57.4,364.4,58.5,365.8z M84.3,357.7c-1,1.4-2.5,3.1-4.6,5.1c-3.6,3.4-5.7,6-6.5,7.8h13v3.9H67.8c0.7-3,1.8-5.6,3.4-8
            c1.3-2,3.2-4.1,5.7-6.4c1.9-1.8,3.3-3.3,4-4.5c0.7-1.1,1.1-2.4,1.1-3.7c0-2.5-1.7-3.7-5-3.7c-3.2,0-5,2.3-5.2,6.8l-3.9-1.5
            c0.3-2.9,1.2-5.2,2.8-6.9c1.6-1.7,3.7-2.6,6.3-2.6c2.7,0,5,0.7,6.7,2.1c1.7,1.4,2.6,3.3,2.6,5.7C86.3,353.8,85.6,355.7,84.3,357.7z
            M97.3,353.1c0.1,0,0.2,0.1,0.2,0.2c0,0-0.1,0.1-0.4,0.2c-0.4,0.9-0.8,1.8-1.3,2.5c-0.5,0.7-1,1.2-1.6,1.8l-1-0.7
            c0.7-0.6,1.3-1.2,1.7-1.8c0.4-0.6,0.9-1.4,1.2-2.4L97.3,353.1z M101.7,356.6c0,2.3-0.1,3.8-0.3,4.3c-0.1,0.6-0.4,1-0.7,1.2
            c-0.4,0.2-1,0.4-1.9,0.4l-0.5-1.1c1,0,1.5-0.1,1.7-0.2c0.2-0.1,0.3-0.3,0.4-0.7c0.1-0.4,0.2-1.4,0.2-3h-2.4
            c-0.1,0.9-0.2,1.7-0.5,2.2c-0.3,0.7-0.7,1.2-1.1,1.6c-0.4,0.4-1,0.8-1.7,1.2l-1-0.8c0.8-0.3,1.3-0.6,1.7-1c0.4-0.4,0.7-0.8,1-1.4
            c0.2-0.5,0.4-1.1,0.5-1.9h-1.7v-1H101.7z M101.2,352.4c0.1,1.2,0.4,2.1,1,2.8c0.4,0.5,1.1,1.1,2,1.8l-1,0.7c-1-0.8-1.8-1.6-2.3-2.3
            c-0.4-0.5-0.6-1.2-0.8-2h-2.3v-1H101.2z M96.5,364.3c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
            c0.5,0.4,1.1,0.8,1.7,1.1l-0.7,0.7c-0.6-0.4-1.1-0.8-1.5-1.2c-0.2,0.3-0.5,0.5-0.7,0.7c-0.2,0.2-0.5,0.5-0.7,0.7h2.9v0.9h-1.1v1.2
            h1.5v0.9h-1.5v3.1c0.5-0.1,1-0.1,1.5-0.2v0.8c-0.8,0.1-2,0.3-3.7,0.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.2l-0.2-1
            c0.6,0,1.2-0.1,1.8-0.2V370h-1.6v-0.9h1.6v-1.2h-1.1v-0.7c-0.2,0.1-0.3,0.3-0.5,0.4l-0.7-0.7c0.6-0.4,1.1-0.9,1.5-1.4
            c0.4-0.4,0.8-0.9,1.2-1.6L96.5,364.3z M95,372.3l-0.7,0.4c-0.2-0.6-0.5-1.1-0.8-1.7l0.8-0.4C94.5,371.3,94.8,371.8,95,372.3z
            M97.7,371c0.1,0,0.2,0.1,0.2,0.1c0,0-0.1,0.1-0.2,0.2c-0.2,0.4-0.4,0.8-0.6,1.3l-0.6-0.2c0.1-0.3,0.2-0.5,0.3-0.7
            c0.1-0.3,0.2-0.7,0.3-1L97.7,371z M101.3,364c0.2,0,0.3,0.1,0.3,0.2c0,0-0.1,0.1-0.2,0.2v0.4h2.3v0.8h-0.8l-0.4,1h1.3v0.8h-6.1
            v-0.8h1.6l-0.4-1H98v-0.8h2.4v-0.9L101.3,364z M103.4,368v3.6h-2.2v0.6h2.3v0.8h-2.3v0.7h2.6v0.8h-6.1v-0.8h2.5v-0.7h-2.2v-0.8h2.2
            v-0.6h-2.2V368H103.4z M100.3,369.4v-0.7h-1.2v0.7H100.3z M100.3,370.1h-1.2v0.7h1.2V370.1z M101.6,366.5l0.4-1h-2.1l0.4,1H101.6z
            M101.2,369.4h1.2v-0.7h-1.2V369.4z M102.5,370.1h-1.2v0.7h1.2V370.1z"/>
          <path class="st6" d="M179.2,317.8c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7
            c1-1,2.2-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7C180.8,315.5,180.3,316.8,179.2,317.8z M192.4,310.7
            c0-0.2,0-0.3,0-0.5v-3.7c0-0.5-0.1-1-0.2-1.7l2.2,0.2c0.4,0,0.6,0.1,0.6,0.3c0,0.2-0.2,0.5-0.5,0.7v4.2c0,0.2,0,0.4,0,0.5h8.5v1.9
            h-8.4c0.8,2.6,1.9,4.6,3.3,6.2c1.3,1.6,3.2,3,5.9,4.1L202,325c-2.3-1.2-4.2-2.8-5.7-4.9c-0.9-1.2-1.7-2.7-2.4-4.4
            c-0.2,0.6-0.4,1.1-0.6,1.6c-0.7,1.6-2,3.2-3.8,4.6c-1.3,1.1-2.9,2.1-4.7,3.1l-1.8-1.7c1.9-0.9,3.3-1.7,4.4-2.5
            c1.7-1.2,2.9-2.5,3.5-3.7c0.7-1.3,1.2-2.8,1.4-4.5h-8.5v-1.9H192.4z M226.8,305.8v17.1c0,1-0.6,1.6-1.6,1.9
            c-0.9,0.2-1.7,0.4-2.6,0.4l-0.8-2l2.1,0c0.6,0,0.9-0.3,0.9-0.8v-14.5h-14v17.4h-2v-19.3h1H226.8z M223,309.6v1.8h-10.5v-1.8H223z
            M222.4,313.6v7h-7.3v1.2h-1.9v-8.2H222.4z M220.6,318.7v-3.3h-5.5v3.3H220.6z M240.4,309.9c0.2,0.1,0.4,0.2,0.4,0.3
            c0,0.1-0.1,0.2-0.2,0.2c-0.3,0.1-0.9,0.7-1.8,1.9c-0.4,0.5-0.8,1-1.2,1.4v11.4h-2.1v-9.2c-0.6,0.6-1.2,1.1-1.9,1.7l-1.9-1.1
            c2.9-2,5.2-4.4,7-7.3L240.4,309.9z M239.3,305.6c0.2,0.1,0.4,0.2,0.4,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.2,0-0.7,0.5-1.4,1.2
            c-1.1,1.2-2.5,2.3-4.1,3.3l-1.9-1.1c2.3-1.3,4.1-2.8,5.5-4.6L239.3,305.6z M252.2,312.3v1.8h-3.8v8.6c0,0.8-0.3,1.4-0.9,1.8
            c-0.5,0.4-1.8,0.6-3.8,0.6l-0.9-2.2h2.1c0.7,0,1.1-0.2,1.2-0.6v-8.3h-6.2v-1.8H252.2z M251.3,306.3v1.9h-9.8v-1.9H251.3z
            M265.7,306.9v1.8h-3.2v4.3h3v1.8h-3v5.5c1.2-0.3,2.4-0.7,3.6-1.1v1.8c-1.8,0.7-4.8,1.6-8.7,2.8c-0.2,0.3-0.3,0.5-0.5,0.5
            c-0.2,0-0.2-0.1-0.3-0.4l-0.5-2.1c0.3,0,0.5-0.1,0.8-0.1v-9.8l1.7,0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.1-0.1,0.3-0.4,0.5v8.6
            c0.6-0.1,1.2-0.3,1.8-0.4v-12.2h-4v-1.8H265.7z M270.4,305.4c0.2,0.1,0.4,0.2,0.4,0.3c0,0.2-0.2,0.3-0.6,0.5
            c-0.2,0.8-0.4,1.5-0.6,2.1h7v1.8h-1.3c-0.2,2.3-0.5,4.1-1,5.4c-0.4,1.3-0.9,2.5-1.5,3.6c-0.2,0.3-0.3,0.6-0.5,0.8
            c1.1,1.3,2.5,2.5,4.4,3.7l-1.6,1.7c-1.7-1.2-3-2.5-4.1-3.8c-0.3,0.3-0.6,0.6-0.9,0.9c-1.1,0.9-2.5,1.8-4.1,2.8l-1.7-1.8
            c1.6-0.7,2.8-1.4,3.7-2.1c0.7-0.5,1.3-1,1.9-1.7c-0.1-0.1-0.1-0.1-0.1-0.2c-1-1.6-1.7-4-2.1-7.1l1.8-0.2c0.3,2.4,0.8,4.4,1.6,5.8
            c0.6-0.9,1-2,1.4-3.2c0.4-1.2,0.7-2.8,0.9-4.8h-4.5c-0.5,1.1-1.3,2.2-2.3,3.3l-1.6-1.3c1-1.1,1.7-2.2,2.3-3.3
            c0.4-0.8,0.7-2.1,1.1-3.8L270.4,305.4z M289.6,309.2v-4.4l2,0.2c0.4,0,0.6,0.1,0.6,0.3c0,0.1-0.2,0.3-0.5,0.5v3.4h7v10h-2.1v-1.3
            h-4.8v7.2h-2.1v-7.2h-4.8v1.3h-2.1v-10H289.6z M289.6,315.9v-4.8h-4.8v4.8H289.6z M291.7,315.9h4.8v-4.8h-4.8V315.9z M309.3,312.6
            c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.2,0.2-0.7,0.3c-0.3,2-0.6,3.7-0.9,5.2c-0.4,1.4-0.7,2.6-1.2,3.8l-2-1.3c0.6-1.3,1-2.6,1.4-3.9
            c0.4-1.3,0.7-3,1-5.2L309.3,312.6z M312.9,310.6c0.3,0,0.5,0.2,0.5,0.4c0,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.4-0.4,0.8v8.4
            c0,0.7,0.1,1.2,0.3,1.5c0.2,0.3,0.7,0.4,1.4,0.4h3.6c1,0,1.7-0.2,2.2-0.7c0.5-0.4,1-1.6,1.3-3.6l2,0.9c-0.5,2.3-1.1,3.7-1.8,4.4
            c-0.6,0.6-1.4,0.9-2.3,0.9h-5.9c-1,0-1.7-0.3-2.2-0.9c-0.5-0.6-0.7-1.4-0.7-2.4v-11L312.9,310.6z M318.5,311l-1.3,1.4
            c-1.8-2-3.9-3.8-6.1-5.5l1.4-1.4C314.8,307.3,316.9,309.1,318.5,311z M325.4,317.9l-1.4,1.5c-1.5-2.4-3.2-4.6-4.9-6.6l1.5-1.4
            C322.5,313.4,324.1,315.6,325.4,317.9z M179.2,354c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7
            c0-1.4,0.5-2.7,1.5-3.7c1-1,2.2-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7C180.8,351.8,180.3,353,179.2,354z
            M188.8,341.9v-1l1.8,0.1c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.4,0.5v0.2h5.5v-1l1.8,0.1c0.3,0,0.5,0.1,0.5,0.3
            c0,0.1-0.1,0.2-0.4,0.5v0.2h5.4v1.6h-5.4v1h-1.9v-1h-5.5v1h-1.9v-1h-5.5v-1.6H188.8z M192.7,344.7v5.4l1.4,0.1c0.1,0,0.2,0,0.2,0
            v-5.5h8.5v14.7c0,0.9-0.4,1.4-1.1,1.7c-0.6,0.2-1.2,0.3-1.8,0.3l-0.7-1.8l-1.4,1.3c-1-0.4-1.8-0.8-2.4-1.2
            c-0.4-0.2-0.8-0.5-1.2-0.8v2.4h-1.8v-2.7c-0.4,0.3-0.8,0.6-1.2,0.9c-0.8,0.5-1.8,1.1-3.2,1.8l-1.8-1.3c1.4-0.5,2.6-0.9,3.5-1.3
            c0.5-0.2,0.9-0.4,1.4-0.7h-3.4v-4.1h4.7v-0.9h-5.5v-1.3h5.5v-1h-6.5v10.7H184v-16.6H192.7z M190.8,347v-0.7h-4.9v0.7H190.8z
            M190.8,349v-0.7h-4.9v0.7H190.8z M190,355.5l1-0.5h-1.5v1.7h1.4C190.6,356.3,190.3,355.9,190,355.5z M192.4,355h-1.2
            c0.3,0.4,0.7,0.8,0.9,1.2l-0.8,0.5h1V355z M196.7,358.7c0.7,0.3,1.6,0.5,2.7,0.7l-0.2,0.2l1.1,0c0.3,0,0.5-0.2,0.5-0.7v-8.3h-6.5
            c-0.1,0.1-0.2,0.2-0.3,0.3v0.7h5.6v1.3h-5.6v0.9h4.8v4.1h-4.1C195.6,358.3,196.2,358.6,196.7,358.7z M194.8,356.3
            c0.4-0.5,0.7-0.9,0.9-1.3h-1.6v1.7h1.2L194.8,356.3z M197.2,355h-1.1l0.6,0.3c0.1,0.1,0.2,0.2,0.2,0.2c0,0.1-0.1,0.2-0.3,0.4
            c-0.2,0.3-0.4,0.6-0.6,0.8h1.2V355z M200.9,347v-0.7h-4.8v0.7H200.9z M200.9,348.3h-4.8v0.7h4.8V348.3z M210.7,346.2
            c-0.4,2.5-0.9,4.8-1.5,6.9l-1.8-0.5c0.7-2.4,1.2-4.7,1.5-7L210.7,346.2z M213.3,341.4c0.3,0,0.5,0.1,0.5,0.2c0,0.2-0.2,0.4-0.5,0.6
            v6.3c0,2.3-0.2,4.3-0.6,6c-0.3,1.4-0.9,2.6-1.6,3.9c-0.6,0.9-1.3,1.8-2,2.6l-1.6-1.4c0.8-0.7,1.4-1.4,1.8-2.1
            c0.7-1,1.2-2.1,1.5-3.1c0.4-1.6,0.7-3.5,0.7-5.8v-5.8c0-0.4,0-0.9-0.1-1.5L213.3,341.4z M217.1,353.3l-1.8,0.4
            c-0.4-2.7-0.9-5.3-1.4-7.6l1.8-0.4C216.3,348.4,216.8,350.9,217.1,353.3z M219.4,341.9c0.3,0,0.5,0.1,0.5,0.3
            c0,0.1-0.1,0.3-0.3,0.5v17.9h-2v-18.8L219.4,341.9z M223.6,353.3l-1.8,0.4c-0.5-2.7-1.1-5.3-1.8-7.6l1.8-0.4
            C222.6,348.4,223.2,350.9,223.6,353.3z M226,341.4c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.3-0.3,0.5v19h-2v-20L226,341.4z M242.9,341.1
            c0.4,0,0.6,0.1,0.6,0.3c0,0.1-0.2,0.3-0.5,0.5v2.2h9.1v1.9h-20.2v-1.9h9v-3.2L242.9,341.1z M240.9,348.9v-2.4l2,0.2
            c0.4,0,0.6,0.1,0.6,0.3c0,0.1-0.2,0.3-0.5,0.5v1.4h7.4v8.5c0,1-0.6,1.7-1.9,2c-1,0.2-1.9,0.4-2.9,0.4l-0.8-2.1l2.4,0
            c0.7,0,1-0.3,1-0.8v-5.9H243v10.5h-2.1v-10.5H236v9.2h-2.1v-11.2h1.1H240.9z M258.9,346.2V341l1.9,0.2c0.3,0,0.5,0.1,0.5,0.3
            c0,0.1-0.1,0.3-0.4,0.5v4.2h2.6v1.8h-2.6v5.2c0.8-0.2,1.7-0.5,2.5-0.7v1.8c-1.3,0.5-3.3,1.2-6,2c-0.2,0.3-0.3,0.5-0.4,0.5
            c-0.2,0-0.3-0.1-0.3-0.4l-0.5-2.2c0.9-0.1,1.8-0.3,2.7-0.5v-5.7h-2.5v-1.8H258.9z M276.6,350.1v1.8h-8.8c-0.2,0.5-0.4,1-0.6,1.4
            h9.1c0,3.2-0.1,5.2-0.4,6c-0.2,0.8-0.5,1.4-1,1.7c-0.6,0.3-1.5,0.5-2.8,0.5l-1-2c1.3,0,2.1-0.1,2.4-0.2c0.2-0.1,0.4-0.4,0.5-0.9
            c0.2-0.5,0.3-1.6,0.3-3.3h-1.4c-0.5,1.5-1,2.7-1.6,3.4c-0.9,1.1-1.9,2.2-3.2,3.1l-1.8-1.2c1.4-0.9,2.5-1.7,3.2-2.6
            c0.6-0.7,1-1.6,1.4-2.6h-1.6c-0.5,1.1-1.1,2-1.7,2.6c-0.9,0.9-2.1,1.7-3.5,2.4l-1.8-1.2c1.6-0.7,2.7-1.3,3.4-2
            c0.5-0.5,1-1.1,1.5-1.8H266c-0.7,0.8-1.6,1.6-2.6,2.2l-1.8-1.2c1.3-0.7,2.2-1.4,2.8-2.1c0.5-0.5,0.9-1.2,1.2-1.9h-2.5v-1.8H276.6z
            M274.8,341.6v7.4H265v-7.4H274.8z M272.8,344.6v-1.2h-5.7v1.2H272.8z M272.8,346h-5.7v1.3h5.7V346z M179.2,390.3
            c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7c1-1,2.2-1.5,3.7-1.5
            c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7C180.8,388.1,180.3,389.3,179.2,390.3z M192.4,383.2c0-0.2,0-0.3,0-0.5V379
            c0-0.5-0.1-1-0.2-1.7l2.2,0.2c0.4,0,0.6,0.1,0.6,0.3c0,0.2-0.2,0.5-0.5,0.7v4.2c0,0.2,0,0.4,0,0.5h8.5v1.9h-8.4
            c0.8,2.6,1.9,4.6,3.3,6.2c1.3,1.6,3.2,3,5.9,4.1l-1.8,2.1c-2.3-1.2-4.2-2.8-5.7-4.9c-0.9-1.2-1.7-2.7-2.4-4.4
            c-0.2,0.6-0.4,1.1-0.6,1.6c-0.7,1.6-2,3.2-3.8,4.6c-1.3,1.1-2.9,2.1-4.7,3.1l-1.8-1.7c1.9-0.9,3.3-1.7,4.4-2.5
            c1.7-1.2,2.9-2.5,3.5-3.7c0.7-1.3,1.2-2.8,1.4-4.5h-8.5v-1.9H192.4z M226.8,378.3v17.1c0,1-0.6,1.6-1.6,1.9
            c-0.9,0.2-1.7,0.4-2.6,0.4l-0.8-2l2.1,0c0.6,0,0.9-0.3,0.9-0.8v-14.5h-14v17.4h-2v-19.3h1H226.8z M223,382.1v1.8h-10.5v-1.8H223z
            M222.4,386.1v7h-7.3v1.2h-1.9v-8.2H222.4z M220.6,391.2v-3.3h-5.5v3.3H220.6z M251.5,378v19.5h-1.8v-0.7h-15.1v0.7h-1.8V378H251.5
            z M249.7,395.1v-15.3h-15.1v15.3H249.7z M242.5,382.3c0-0.7,0-1.4,0-2.2l1.7,0.4c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.5
            c0,0.4,0,0.7,0,1.1h2.6c-0.5-0.6-1-1-1.5-1.4l1.1-1.1c0.6,0.4,1.2,0.9,1.8,1.6l-1,0.9h1.6v1.6h-4.6c0.1,1.7,0.3,3.2,0.6,4.5
            c0.1,0.3,0.2,0.5,0.2,0.7c0,0,0,0,0,0c0.7-1.2,1.2-2.5,1.5-3.9l1.6,0.5c0.2,0.1,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.3,0.5
            c-0.6,2-1.3,3.5-2.1,4.6c0,0.1-0.1,0.1-0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.5c0.3,0.4,0.5,0.5,0.7,0.5c0.2,0,0.5-0.6,0.9-1.9l1.4,1.2
            c-0.9,2.1-1.6,3.1-2.2,3.1c-0.5,0-1.1-0.4-1.9-1.2c-0.2-0.2-0.4-0.5-0.6-0.8c-0.8,0.7-1.7,1.4-2.8,2.1l-1.4-1.1
            c1-0.5,1.8-1.1,2.5-1.7c0.3-0.2,0.6-0.5,0.9-0.8c-0.3-0.7-0.6-1.5-0.8-2.3c-0.3-1.2-0.5-2.7-0.6-4.6h-7.2v-1.6H242.5z M242,392.2
            c-1.2,0.3-3,0.6-5.5,1c-0.1,0.2-0.3,0.4-0.4,0.4c-0.1,0-0.2-0.1-0.2-0.3l-0.4-1.8c2.2-0.2,4.4-0.5,6.5-0.9V392.2z M241.7,385.1v4.8
            H236v-4.8H241.7z M240,386.6h-2.4v1.8h2.4V386.6z M262.1,383c0.2,0.1,0.3,0.2,0.3,0.2c0,0.2-0.1,0.3-0.4,0.5
            c-1.2,3.4-2.5,6.2-3.8,8.5l-2.2-1.1c1.7-2.5,3-5.5,4.1-9L262.1,383z M267.8,377.6c0.3,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.4-0.5,0.6
            v16.3c0,0.9-0.3,1.7-0.9,2.1c-0.5,0.5-1.8,0.7-3.8,0.7l-0.9-2.1h2.2c0.8,0,1.2-0.4,1.2-1.1v-17L267.8,377.6z M274,386.7
            c0.6,1.4,1.4,2.9,2.6,4.5l-2.3,1.1c-0.9-1.5-1.6-3-2.2-4.4c-0.7-1.6-1.1-3.3-1.5-5.2l1.9-0.5C273,383.8,273.5,385.3,274,386.7z
            M402.1,317.8c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7c1-1,2.2-1.5,3.7-1.5
            c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7C403.7,315.5,403.2,316.8,402.1,317.8z M419.2,315.1h6.3v1.8h-6.1
            c0.1,0.5,0.2,1,0.3,1.4c0.5,1.4,1.1,2.5,2,3.3c0.7,0.7,1.2,1,1.6,1c0.1,0,0.3-0.1,0.5-0.3c0.2-0.2,0.6-1,1.1-2.5l1.6,1.3
            c-0.5,1.2-0.9,2.1-1.3,2.7c-0.6,0.7-1.2,1.1-1.8,1.1c-0.6,0-1.3-0.2-1.9-0.7c-0.8-0.5-1.6-1.3-2.3-2.3c-0.8-1.1-1.4-2.5-1.7-4.2
            c0-0.2-0.1-0.5-0.1-0.8h-6.4v4.9c1.7-0.5,3.4-1,5-1.6v1.8l-8.5,2.6c-0.2,0.5-0.4,0.7-0.5,0.7c-0.1,0-0.2-0.1-0.3-0.4l-0.7-1.9
            c1-0.2,1.9-0.5,2.9-0.7v-16.8h15.4v6.4h-5.3C419.1,313.2,419.1,314.2,419.2,315.1z M411.1,310.1h11.2v-2.7h-11.2V310.1z
            M411.1,315.1h6.2c-0.1-0.9-0.1-1.9-0.1-3.1h-6.1V315.1z M433,309.1v-4.8l1.7,0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.1-0.1,0.3-0.3,0.5v3.9
            h1.6v1.8h-1.6v0c0.6,1.9,1.4,3.4,2.4,4.6l-1.3,1.4c-0.4-0.7-0.7-1.5-1-2.3v10.8H433v-9.8c-0.4,1.4-0.9,2.8-1.4,4l-1.8-1.1
            c0.7-1.1,1.4-2.3,1.9-3.7c0.4-0.9,0.8-2.1,1.3-3.8v0h-2.2v-1.8H433z M443.1,309.4v3.9h-3l0.8,0.3c0.2,0,0.3,0.1,0.3,0.3
            c0,0.1-0.2,0.2-0.5,0.4c-0.1,0.3-0.2,0.6-0.3,0.8h3.6l0.9-1.5l1.5,0.3c0.3,0,0.4,0.2,0.4,0.3c0,0.2-0.2,0.4-0.5,0.5l-0.3,0.3h4.3
            v1.6h-4.8v1.2h4.2v1.5h-4.2v1.1h4.2v1.5h-4.2v1.1h5.2v1.6h-10.5v0.6h-1.8v-7.1c-0.3,0.5-0.7,0.9-1,1.2l-1.7-1.1
            c1.4-1.3,2.5-3,3.4-5h-1.5v-3.9H443.1z M442.2,304.8c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.3,0.5v3.3h-1.9v-1.1h-3.3V306h3.3v-1.3
            L442.2,304.8z M441.5,310.7h-2.2v1.3h2.2V310.7z M443.8,316.7h-3.4v1.2h3.4V316.7z M443.8,319.4h-3.4v1.1h3.4V319.4z M443.8,322
            h-3.4v1.1h3.4V322z M449.7,309.4v3.9h-5.4v-3.9H449.7z M447,304.8c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.3,0.5v0.4h3.3v1.8h-3.3
            v1.1h-1.9v-4.2L447,304.8z M448.1,310.7H446v1.3h2.2V310.7z M474.4,305.5V325h-1.8v-0.7h-15.1v0.7h-1.8v-19.5H474.4z M472.6,322.5
            v-15.3h-15.1v15.3H472.6z M465.4,309.8c0-0.7,0-1.4,0-2.2l1.7,0.4c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.5c0,0.4,0,0.7,0,1.1
            h2.6c-0.5-0.6-1-1-1.5-1.4l1.1-1.1c0.6,0.4,1.2,0.9,1.8,1.6l-1,0.9h1.6v1.6h-4.6c0.1,1.7,0.3,3.2,0.6,4.5c0.1,0.3,0.2,0.5,0.2,0.7
            c0,0,0,0,0,0c0.7-1.2,1.2-2.5,1.5-3.9l1.6,0.5c0.2,0.1,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.3,0.5c-0.6,2-1.3,3.5-2.1,4.6
            c0,0.1-0.1,0.1-0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.5c0.3,0.4,0.5,0.5,0.7,0.5c0.2,0,0.5-0.6,0.9-1.9l1.4,1.2c-0.9,2.1-1.6,3.1-2.2,3.1
            c-0.5,0-1.1-0.4-1.9-1.2c-0.2-0.2-0.4-0.5-0.6-0.8c-0.8,0.7-1.7,1.4-2.8,2.1l-1.4-1.1c1-0.5,1.8-1.1,2.5-1.7
            c0.3-0.2,0.6-0.5,0.9-0.8c-0.3-0.7-0.6-1.5-0.8-2.3c-0.3-1.2-0.5-2.7-0.6-4.6h-7.2v-1.6H465.4z M464.8,319.7c-1.2,0.3-3,0.6-5.5,1
            c-0.1,0.2-0.3,0.4-0.4,0.4c-0.1,0-0.2-0.1-0.2-0.3l-0.4-1.8c2.2-0.2,4.4-0.5,6.5-0.9V319.7z M464.6,312.6v4.8h-5.7v-4.8H464.6z
            M462.9,314.1h-2.4v1.8h2.4V314.1z M488.1,309.2v-4.4l2,0.2c0.4,0,0.6,0.1,0.6,0.3c0,0.1-0.2,0.3-0.5,0.5v3.4h7v10h-2.1v-1.3h-4.8
            v7.2h-2.1v-7.2h-4.8v1.3h-2.1v-10H488.1z M488.1,315.9v-4.8h-4.8v4.8H488.1z M490.2,315.9h4.8v-4.8h-4.8V315.9z M402.1,354
            c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7c1-1,2.2-1.5,3.7-1.5
            c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7C403.7,351.8,403.2,353,402.1,354z M415.3,346.9c0-0.2,0-0.3,0-0.5v-3.7
            c0-0.5-0.1-1-0.2-1.7l2.2,0.2c0.4,0,0.6,0.1,0.6,0.3c0,0.2-0.2,0.5-0.5,0.7v4.2c0,0.2,0,0.4,0,0.5h8.5v1.9h-8.4
            c0.8,2.6,1.9,4.6,3.3,6.2c1.3,1.6,3.2,3,5.9,4.1l-1.8,2.1c-2.3-1.2-4.2-2.8-5.7-4.9c-0.9-1.2-1.7-2.7-2.4-4.4
            c-0.2,0.6-0.4,1.1-0.6,1.6c-0.7,1.6-2,3.2-3.8,4.6c-1.3,1.1-2.9,2.1-4.7,3.1l-1.8-1.7c1.9-0.9,3.3-1.7,4.4-2.5
            c1.7-1.2,2.9-2.5,3.5-3.7c0.7-1.3,1.2-2.8,1.4-4.5h-8.5v-1.9H415.3z M449.7,342.1v17.1c0,1-0.6,1.6-1.6,1.9
            c-0.9,0.2-1.7,0.4-2.6,0.4l-0.8-2l2.1,0c0.6,0,0.9-0.3,0.9-0.8V344h-14v17.4h-2v-19.3h1H449.7z M445.9,345.9v1.8h-10.5v-1.8H445.9z
            M445.3,349.8v7H438v1.2h-1.9v-8.2H445.3z M443.5,354.9v-3.3H438v3.3H443.5z M459.7,350.8v6.6c0.5,0.7,1.2,1.2,2.2,1.5
            c1.1,0.3,2.8,0.5,5.2,0.5c3,0,5.8-0.1,8.4-0.3l-0.7,2.2h-7.7c-2,0-3.6-0.1-4.8-0.4c-1.3-0.3-2.3-0.7-3-1.4
            c-0.3-0.2-0.6-0.5-0.8-0.9l-2.1,2.3c-0.1,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.3-0.1-0.4-0.3l-1.1-1.6c1.2-0.7,2.2-1.4,3.1-2.1v-4.6h-1.7
            v-1.8H459.7z M459.8,347.9l-1.3,1.4c-1.2-0.9-2.4-1.6-3.8-2.3l1.4-1.4C457.6,346.3,458.8,347.1,459.8,347.9z M460.3,343.7l-1.3,1.4
            c-1.1-0.8-2.2-1.6-3.4-2.3l1.4-1.4C458.3,342.1,459.4,342.9,460.3,343.7z M475.1,341.6v4h-1.9v-2.5h-10.1v2.4h-1.9v-4H475.1z
            M467.1,345.3v-1.4l1.8,0.2c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.4,0.4v0.5h3.4v1.5h-3.4v1.1h4.5v6.8h-4.5v1.3h5.8v1.5h-5.8v1.6
            h-1.9v-1.6h-5.7v-1.5h5.7v-1.3h-4.4v-6.8h4.4v-1.1h-3.3v-1.5H467.1z M467.1,350.4v-1.2h-2.5v1.2H467.1z M467.1,351.9h-2.5v1.3h2.5
            V351.9z M469.1,350.4h2.6v-1.2h-2.6V350.4z M471.7,351.9h-2.6v1.3h2.6V351.9z M488.4,343c-0.7,0.2-1.6,0.3-2.4,0.5v1.3h4.7v1.7H486
            v1h4.2v6.9H486v1.1h4.1v1.7H486v1.3c1.5-0.1,3-0.3,4.4-0.5v1c0.6-0.9,1-1.8,1.3-2.7c0.4-1.3,0.8-2.9,1-4.7c0.1-1,0.2-2.4,0.2-4.4
            h-1.8v-1.8h1.8c0-1.2,0-2.6,0-4.1l1.8,0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.1-0.2,0.2-0.4,0.4c0,1.3,0,2.4,0,3.4h4
            c0,6.2-0.1,10.1-0.4,11.8c-0.2,1.6-0.5,2.6-1.1,3.2c-0.6,0.6-1.5,0.9-2.9,0.9l-1-2c1.4,0,2.2-0.2,2.4-0.5c0.2-0.3,0.5-1,0.6-2.1
            c0.2-1.2,0.3-4.3,0.3-9.4h-2.1c-0.1,2.3-0.2,4.1-0.4,5.4c-0.3,2-0.6,3.5-1,4.6c-0.5,1.6-1.2,3-1.9,4.1l-1.9-1.1
            c0.1-0.2,0.2-0.3,0.3-0.5c-2.2,0.3-5.5,0.7-9.7,1.1c-0.2,0.2-0.3,0.4-0.4,0.4c-0.2,0-0.2-0.1-0.2-0.3l-0.5-2
            c1.7-0.1,3.4-0.2,5.1-0.3v-1.4h-4.2v-1.7h4.2v-1.1h-4.3v-6.9h4.3v-1h-4.9v-1.7h4.9v-1.1c-1.2,0.1-2.5,0.1-3.9,0.1l-0.8-1.7h2.5
            c2.5,0,4.8-0.3,7-1.1l1.4,1.5c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.3,0-0.5-0.2c-0.3-0.1-0.4-0.1-0.5-0.1
            C489.2,342.9,488.8,342.9,488.4,343z M484.1,350.3v-1.1h-2.5v1.1H484.1z M484.1,351.7h-2.5v1.1h2.5V351.7z M486,350.3h2.4v-1.1H486
            V350.3z M488.4,351.7H486v1.1h2.4V351.7z M512.5,345.4V341l2,0.2c0.4,0,0.6,0.1,0.6,0.3c0,0.1-0.2,0.3-0.5,0.5v3.4h7v10h-2.1v-1.3
            h-4.8v7.2h-2.1v-7.2h-4.8v1.3h-2.1v-10H512.5z M512.5,352.2v-4.8h-4.8v4.8H512.5z M514.5,352.2h4.8v-4.8h-4.8V352.2z M532.2,348.9
            c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.2,0.2-0.7,0.3c-0.3,2-0.6,3.7-0.9,5.2c-0.4,1.4-0.7,2.6-1.2,3.8l-2-1.3c0.6-1.3,1-2.6,1.4-3.9
            c0.4-1.3,0.7-3,1-5.2L532.2,348.9z M535.8,346.9c0.3,0,0.5,0.2,0.5,0.4c0,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.4-0.4,0.8v8.4
            c0,0.7,0.1,1.2,0.3,1.5c0.2,0.3,0.7,0.4,1.4,0.4h3.6c1,0,1.7-0.2,2.2-0.7c0.5-0.4,1-1.6,1.3-3.6l2,0.9c-0.5,2.3-1.1,3.7-1.8,4.4
            c-0.6,0.6-1.4,0.9-2.3,0.9h-5.9c-1,0-1.7-0.3-2.2-0.9c-0.5-0.6-0.7-1.4-0.7-2.4v-11L535.8,346.9z M541.4,347.3l-1.3,1.4
            c-1.8-2-3.9-3.8-6.1-5.5l1.4-1.4C537.7,343.5,539.7,345.4,541.4,347.3z M548.3,354.1l-1.4,1.5c-1.5-2.4-3.2-4.6-4.9-6.6l1.5-1.4
            C545.4,349.7,547,351.9,548.3,354.1z"/>
        </g>
        <circle class="st4 an3" cx="76" cy="475.8" r="48"/>
        <g class="ant3">
          <path class="st5" d="M76.5,457.6c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.1-0.4,0.2c-1.6,1.3-2.8,2.2-3.5,2.6
            c-1.3,0.7-2.5,1.3-3.5,1.6c-1.4,0.4-3.1,0.8-5.2,0.9l-0.7-1.4c1.9-0.1,3.5-0.3,4.8-0.6c1.3-0.3,2.5-0.9,3.8-1.6
            c0.9-0.5,2-1.4,3.3-2.7L76.5,457.6z M71.4,449.2c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.4,0.4v1.1h4.3v1.3h-4.3v1.6H77v1.3h-5.7v3
            c0,0.7-0.2,1.1-0.5,1.4c-0.3,0.3-1,0.5-2.1,0.5l-0.6-1.4h1.1c0.4,0,0.6-0.3,0.6-0.8v-2.7h-6.3v-1.3h2.6v-3.3l1.3,0.1
            c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.2,0.4v2.7h2.4v-4.8L71.4,449.2z M67.9,456.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
            c-0.8,1.1-1.6,2.1-2.4,2.9l-1.4-0.7c1.1-0.9,1.9-1.9,2.6-3.2L67.9,456.4z M84.8,452.6c0.2,0,0.2,0.1,0.2,0.2c0,0,0,0.1-0.1,0.2
            c-0.2,0.1-0.6,0.5-1.2,1.3c-0.3,0.3-0.5,0.7-0.8,1v7.7h-1.4v-6.2c-0.4,0.4-0.8,0.8-1.3,1.1l-1.3-0.7c2-1.4,3.5-3,4.8-4.9
            L84.8,452.6z M84,449.8c0.2,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.1-0.1,0.2c-0.2,0-0.5,0.3-0.9,0.8c-0.8,0.8-1.7,1.5-2.8,2.2l-1.3-0.7
            c1.5-0.9,2.8-1.9,3.8-3.1L84,449.8z M92.7,454.3v1.3h-2.6v5.8c0,0.6-0.2,1-0.6,1.3c-0.3,0.3-1.2,0.4-2.5,0.4l-0.6-1.5h1.4
            c0.5,0,0.8-0.1,0.8-0.4v-5.6h-4.2v-1.3H92.7z M92.1,450.2v1.3h-6.6v-1.3H92.1z M50.7,475.7c0.2,0.1,0.3,0.2,0.3,0.3
            c0,0.2-0.1,0.4-0.4,0.5l-2.9,3.7c0.7,0.4,1.3,0.9,1.8,1.3l2.4-3.1l1.5,1c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.2,0.2-0.5,0.3l-4.7,5.7
            c1.2-0.1,2.3-0.3,3.4-0.6c-0.3-0.6-0.5-1.2-0.8-1.8L53,483c0.9,1.8,1.6,3.6,1.9,5.3l-1.8,0.4c-0.2-0.7-0.4-1.3-0.6-1.9
            c-0.5,0.1-1.1,0.2-1.8,0.4v9.4h-2v-9.1c-1.1,0.2-2.3,0.4-3.7,0.6c-0.1,0.3-0.3,0.4-0.5,0.4c-0.1,0-0.2-0.1-0.2-0.3l-0.4-1.9
            c0.8,0,1.5-0.1,2.3-0.2l2.2-3c-1.3-0.9-2.7-1.9-4.3-2.8l1.2-1.5c0.3,0.2,0.6,0.4,0.9,0.5l2.9-4.3L50.7,475.7z M47.7,489.7
            c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.4-0.4,0.6c-0.4,2-0.9,3.7-1.5,5.2l-1.9-0.6c0.4-0.7,0.7-1.5,0.9-2.3c0.3-1.1,0.6-2.3,0.8-3.6
            L47.7,489.7z M55.3,494.5l-1.8,0.4c-0.5-1.9-1.1-3.7-1.8-5.3l1.8-0.4C54.3,491,54.9,492.8,55.3,494.5z M58.9,475.8
            c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.4-0.5,0.7c-0.2,0.9-0.5,1.8-0.8,2.7h7c0,6.7-0.2,10.9-0.5,12.6c-0.2,1.7-0.7,2.9-1.4,3.5
            c-0.8,0.7-2.1,1-4,1l-1-2.1c2,0,3.2-0.2,3.6-0.6c0.4-0.3,0.7-1,0.9-2.2c0.3-1.3,0.4-4.7,0.5-10.2h-5.6c-0.5,1.1-0.9,2.2-1.4,3.1
            l-2-0.7c0.7-1,1.2-2.1,1.7-3.3c0.6-1.5,1-3.3,1.4-5.2L58.9,475.8z M61.1,488.7l-1.7,1.5c-1.1-1.5-2.1-2.7-3.1-3.6l1.5-1.4
            C58.9,486.2,60,487.4,61.1,488.7z M86.2,495.3c-1.8,1.6-4.1,2.5-6.7,2.5c-5,0-8-2.5-9.1-7.4l3.9-1.3c0.6,3.3,2.4,4.9,5.2,4.9
            c1.6,0,2.9-0.5,3.8-1.5c0.8-0.9,1.3-2,1.3-3.3c0-1.5-0.5-2.8-1.3-3.8c-0.9-1-2.1-1.6-3.5-1.6h-3.7v-3.6h3.1c1,0,2-0.5,3-1.7
            c0.9-1.1,1.4-2.3,1.4-3.5c0-2.6-1.3-4-4-4c-2.5,0-3.9,1.5-4.4,4.4l-3.9-1.2c0.4-2.1,1.3-3.9,2.6-5.2c1.5-1.4,3.4-2.1,5.7-2.1
            c2.5,0,4.5,0.7,6,2.2c1.5,1.5,2.2,3.4,2.2,5.8c0,2.7-1.2,4.9-3.5,6.5c1.4,0.7,2.4,1.7,3.2,3.1c0.8,1.4,1.2,2.9,1.2,4.6
            C88.9,491.6,88,493.7,86.2,495.3z M99.9,476.1c0.1,0,0.2,0.1,0.2,0.2c0,0-0.1,0.1-0.4,0.2c-0.4,0.9-0.8,1.8-1.3,2.5
            c-0.5,0.7-1,1.2-1.6,1.8l-1-0.7c0.7-0.6,1.3-1.2,1.7-1.8c0.4-0.6,0.9-1.4,1.2-2.4L99.9,476.1z M104.3,479.6c0,2.3-0.1,3.8-0.3,4.3
            c-0.1,0.6-0.4,1-0.7,1.2c-0.4,0.2-1,0.4-1.9,0.4l-0.5-1.1c1,0,1.5-0.1,1.7-0.2c0.2-0.1,0.3-0.3,0.4-0.7c0.1-0.4,0.2-1.4,0.2-3h-2.4
            c-0.1,0.9-0.2,1.7-0.5,2.2c-0.3,0.7-0.7,1.2-1.1,1.6c-0.4,0.4-1,0.8-1.7,1.2l-1-0.8c0.8-0.3,1.3-0.6,1.7-1c0.4-0.4,0.7-0.8,1-1.4
            c0.2-0.5,0.4-1.1,0.5-1.9h-1.7v-1H104.3z M103.8,475.4c0.1,1.2,0.4,2.1,1,2.8c0.4,0.5,1.1,1.1,2,1.8l-1,0.7c-1-0.8-1.8-1.6-2.3-2.3
            c-0.4-0.5-0.6-1.2-0.8-2h-2.3v-1H103.8z M99.1,487.3c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
            c0.5,0.4,1.1,0.8,1.7,1.1l-0.7,0.7c-0.6-0.4-1.1-0.8-1.5-1.2c-0.2,0.3-0.5,0.5-0.7,0.7c-0.2,0.2-0.5,0.5-0.7,0.7h2.9v0.9h-1.1v1.2
            h1.5v0.9h-1.5v3.1c0.5-0.1,1-0.1,1.5-0.2v0.8c-0.8,0.1-2,0.3-3.7,0.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.2l-0.2-1
            c0.6,0,1.2-0.1,1.8-0.2v-3.2h-1.6V492h1.6v-1.2h-1.1v-0.7c-0.2,0.1-0.3,0.3-0.5,0.4l-0.7-0.7c0.6-0.4,1.1-0.9,1.5-1.4
            c0.4-0.4,0.8-0.9,1.2-1.6L99.1,487.3z M97.6,495.3l-0.7,0.4c-0.2-0.6-0.5-1.1-0.8-1.7l0.8-0.4C97.1,494.2,97.4,494.7,97.6,495.3z
            M100.3,493.9c0.1,0,0.2,0.1,0.2,0.1c0,0-0.1,0.1-0.2,0.2c-0.2,0.4-0.4,0.8-0.6,1.3l-0.6-0.2c0.1-0.3,0.2-0.5,0.3-0.7
            c0.1-0.3,0.2-0.7,0.3-1L100.3,493.9z M103.9,486.9c0.2,0,0.3,0.1,0.3,0.2c0,0-0.1,0.1-0.2,0.2v0.4h2.3v0.8h-0.8l-0.4,1h1.3v0.8
            h-6.1v-0.8h1.6l-0.4-1h-0.9v-0.8h2.4v-0.9L103.9,486.9z M106.1,490.9v3.6h-2.2v0.6h2.3v0.8h-2.3v0.7h2.6v0.8h-6.1v-0.8h2.5v-0.7
            h-2.2V495h2.2v-0.6h-2.2v-3.6H106.1z M102.9,492.4v-0.7h-1.2v0.7H102.9z M102.9,493.1h-1.2v0.7h1.2V493.1z M104.2,489.4l0.4-1h-2.1
            l0.4,1H104.2z M103.9,492.4h1.2v-0.7h-1.2V492.4z M105.1,493.1h-1.2v0.7h1.2V493.1z"/>
          <path class="st6" d="M179.2,480.9c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7
            c1-1,2.2-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7C180.8,478.7,180.3,479.9,179.2,480.9z M192.4,473.8
            c0-0.2,0-0.3,0-0.5v-3.7c0-0.5-0.1-1-0.2-1.7l2.2,0.2c0.4,0,0.6,0.1,0.6,0.3c0,0.2-0.2,0.5-0.5,0.7v4.2c0,0.2,0,0.4,0,0.5h8.5v1.9
            h-8.4c0.8,2.6,1.9,4.6,3.3,6.2c1.3,1.6,3.2,3,5.9,4.1l-1.8,2.1c-2.3-1.2-4.2-2.8-5.7-4.9c-0.9-1.2-1.7-2.7-2.4-4.4
            c-0.2,0.6-0.4,1.1-0.6,1.6c-0.7,1.6-2,3.2-3.8,4.6c-1.3,1.1-2.9,2.1-4.7,3.1l-1.8-1.7c1.9-0.9,3.3-1.7,4.4-2.5
            c1.7-1.2,2.9-2.5,3.5-3.7c0.7-1.3,1.2-2.8,1.4-4.5h-8.5v-1.9H192.4z M210.5,472.4v-4.6l1.7,0.1c0.3,0,0.5,0.1,0.5,0.2
            c0,0.1-0.1,0.3-0.4,0.5v3.7h1.7v1.8h-1.7v0.3c0.7,1.6,1.6,3,2.6,4l-1.3,1.4c-0.5-0.7-0.9-1.5-1.2-2.3v10.8h-1.8v-9.7
            c0,0,0,0.1,0,0.1c-0.5,1.4-1,2.7-1.6,3.9l-1.8-1.2c0.8-1.1,1.4-2.2,2-3.4c0.4-0.9,0.9-2.1,1.4-3.9h-2.7v-1.8H210.5z M224.7,469.4
            c-1.2,0.2-2.5,0.4-3.7,0.6c-0.2,0.6-0.3,1.1-0.5,1.6h6.4v1.6h-2.8c0.7,0.7,1.2,1.1,1.7,1.4c0.6,0.4,1.5,0.8,2.6,1.1l-1.3,1.6
            c-1-0.5-1.8-1-2.6-1.7v3.1h-7.1v-2.8c-0.6,0.5-1.4,0.9-2.3,1.4l-1.4-1.2c0.9-0.4,1.6-0.8,2.1-1.1c0.6-0.4,1.1-0.8,1.6-1.3
            c0.1-0.1,0.2-0.3,0.4-0.5h-3.1v-1.6h4c0.2-0.4,0.3-0.9,0.5-1.4c-1.1,0.1-2.2,0.2-3.3,0.3l-1.1-1.6c2.1,0,3.9-0.2,5.3-0.4
            c1.3-0.1,3-0.4,5-0.8l1.3,1.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.2,0.2l-0.5-0.1C225.3,469.3,225.1,469.3,224.7,469.4z
            M214.8,479.7h12.3v6.7c0,0.9-0.4,1.6-1.2,1.8c-0.7,0.2-1.3,0.3-1.9,0.3l-0.8-1.9l1.4,0c0.4,0,0.6-0.3,0.6-0.8v-4.5h-8.5v7.1h-1.9
            v-7.1V479.7z M223.8,482.4v4.1H218v-4.1H223.8z M223.5,474.6c-0.5-0.5-0.9-0.9-1.3-1.5h-2.3c-0.3,0.6-0.7,1.1-1,1.5H223.5z
            M222.6,476h-3.4v1.2h3.4V476z M222.1,483.7h-2.5v1.4h2.5V483.7z M240.3,480.1c0.2,0.1,0.4,0.2,0.4,0.3c0,0.1-0.2,0.2-0.5,0.5
            c-0.4,1.1-0.8,2.3-1.4,3.5c0.8-0.2,1.5-0.3,2.3-0.6v1.7c-1.8,0.5-4.5,1.2-8.2,2.1c-0.2,0.2-0.3,0.4-0.4,0.4c-0.2,0-0.2-0.1-0.2-0.3
            l-0.5-2c1.8-0.2,3.5-0.5,5.2-0.9c0.3-0.7,0.5-1.4,0.7-1.9c0.4-1.1,0.7-2.3,1-3.4L240.3,480.1z M240.9,468.7v1.8h-9v-1.8H240.9z
            M235.9,483.5l-1.6,1c-0.6-1.3-1.4-2.6-2.1-3.9l1.8-0.9C234.6,481,235.3,482.2,235.9,483.5z M240.3,472.4v6.2h-7.8v-6.2H240.3z
            M238.3,474.1h-3.9v2.7h3.9V474.1z M252,468.7v1.7h-4.3l-0.5,1.9h3.8v12.1h-2.3c1,0.9,2.3,1.6,3.8,2.2l-1.5,1.6
            c-1.4-0.8-2.6-1.8-3.5-2.8l1-1h-3.9l1.1,0.7c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.3,0.2c-0.8,0.8-2,1.6-3.5,2.6l-1.7-1.3
            c1.4-0.6,2.7-1.5,3.9-2.5H242v-12.1h3.1l0.5-1.9h-4.5v-1.7H252z M249.1,475.8V474h-5.2v1.8H249.1z M249.1,479.3v-1.8h-5.2v1.8
            H249.1z M249.1,481h-5.2v1.7h5.2V481z M261.9,468.1c0.4,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.3-0.4,0.5v3.1h3.4v1.8h-9.2V472h3.8V468
            L261.9,468.1z M264.6,475.2c0.3,0.1,0.5,0.2,0.5,0.3c0,0.2-0.2,0.4-0.5,0.5l-1.6,7.5c0.9-0.2,1.7-0.3,2.6-0.5v1.8
            c-1.7,0.5-4.3,1.1-8,1.8c-0.2,0.3-0.3,0.4-0.5,0.4c-0.2,0-0.2-0.1-0.3-0.4l-0.5-2.1c1.5-0.2,3.1-0.4,4.6-0.6l2.1-9.1L264.6,475.2z
            M260.1,482.7l-1.8,0.3c-0.5-2.7-1.1-5.3-1.8-7.6l1.8-0.3C259.1,477.7,259.8,480.2,260.1,482.7z M270.8,468.1
            c0.4,0,0.6,0.1,0.6,0.3c0,0.1-0.2,0.3-0.4,0.5v3.1h5.5v1.8H271v4.1h5v10.3h-2v-1.4h-6.2v1.4h-2v-10.3h3.1v-10L270.8,468.1z
            M273.9,485v-5.2h-6.2v5.2H273.9z"/>
        </g>
        <circle class="st4 an4" cx="76" cy="598.7" r="48"/>
        <g class="ant4">
          <path class="st5" d="M76.5,580.6c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.1-0.4,0.2c-1.6,1.3-2.8,2.2-3.5,2.6
            c-1.3,0.7-2.5,1.3-3.5,1.6c-1.4,0.4-3.1,0.8-5.2,0.9l-0.7-1.4c1.9-0.1,3.5-0.3,4.8-0.6c1.3-0.3,2.5-0.9,3.8-1.6
            c0.9-0.5,2-1.4,3.3-2.7L76.5,580.6z M71.4,572.2c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.4,0.4v1.1h4.3v1.3h-4.3v1.6H77v1.3h-5.7v3
            c0,0.7-0.2,1.1-0.5,1.4c-0.3,0.3-1,0.5-2.1,0.5l-0.6-1.4h1.1c0.4,0,0.6-0.3,0.6-0.8v-2.7h-6.3v-1.3h2.6v-3.3l1.3,0.1
            c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.2,0.4v2.7h2.4V572L71.4,572.2z M67.9,579.3c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
            c-0.8,1.1-1.6,2.1-2.4,2.9l-1.4-0.7c1.1-0.9,1.9-1.9,2.6-3.2L67.9,579.3z M84.8,575.6c0.2,0,0.2,0.1,0.2,0.2c0,0,0,0.1-0.1,0.2
            c-0.2,0.1-0.6,0.5-1.2,1.3c-0.3,0.3-0.5,0.7-0.8,1v7.7h-1.4v-6.2c-0.4,0.4-0.8,0.8-1.3,1.1l-1.3-0.7c2-1.4,3.5-3,4.8-4.9
            L84.8,575.6z M84,572.7c0.2,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.1-0.1,0.2c-0.2,0-0.5,0.3-0.9,0.8c-0.8,0.8-1.7,1.5-2.8,2.2l-1.3-0.7
            c1.5-0.9,2.8-1.9,3.8-3.1L84,572.7z M92.7,577.2v1.3h-2.6v5.8c0,0.6-0.2,1-0.6,1.3c-0.3,0.3-1.2,0.4-2.5,0.4l-0.6-1.5h1.4
            c0.5,0,0.8-0.1,0.8-0.4v-5.6h-4.2v-1.3H92.7z M92.1,573.1v1.3h-6.6v-1.3H92.1z M50.7,598.7c0.2,0.1,0.3,0.2,0.3,0.3
            c0,0.2-0.1,0.4-0.4,0.5l-2.9,3.7c0.7,0.4,1.3,0.9,1.8,1.3l2.4-3.1l1.5,1c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.2,0.2-0.5,0.3l-4.7,5.7
            c1.2-0.1,2.3-0.3,3.4-0.6c-0.3-0.6-0.5-1.2-0.8-1.8l1.8-0.4c0.9,1.8,1.6,3.6,1.9,5.3l-1.8,0.4c-0.2-0.7-0.4-1.3-0.6-1.9
            c-0.5,0.1-1.1,0.2-1.8,0.4v9.4h-2v-9.1c-1.1,0.2-2.3,0.4-3.7,0.6c-0.1,0.3-0.3,0.4-0.5,0.4c-0.1,0-0.2-0.1-0.2-0.3l-0.4-1.9
            c0.8,0,1.5-0.1,2.3-0.2l2.2-3c-1.3-0.9-2.7-1.9-4.3-2.8l1.2-1.5c0.3,0.2,0.6,0.4,0.9,0.5l2.9-4.3L50.7,598.7z M47.7,612.7
            c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.4-0.4,0.6c-0.4,2-0.9,3.7-1.5,5.2l-1.9-0.6c0.4-0.7,0.7-1.5,0.9-2.3c0.3-1.1,0.6-2.3,0.8-3.6
            L47.7,612.7z M55.3,617.5l-1.8,0.4c-0.5-1.9-1.1-3.7-1.8-5.3l1.8-0.4C54.3,614,54.9,615.8,55.3,617.5z M58.9,598.7
            c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.4-0.5,0.7c-0.2,0.9-0.5,1.8-0.8,2.7h7c0,6.7-0.2,10.9-0.5,12.6c-0.2,1.7-0.7,2.9-1.4,3.5
            c-0.8,0.7-2.1,1-4,1l-1-2.1c2,0,3.2-0.2,3.6-0.6c0.4-0.3,0.7-1,0.9-2.2c0.3-1.3,0.4-4.7,0.5-10.2h-5.6c-0.5,1.1-0.9,2.2-1.4,3.1
            l-2-0.7c0.7-1,1.2-2.1,1.7-3.3c0.6-1.5,1-3.3,1.4-5.2L58.9,598.7z M61.1,611.7l-1.7,1.5c-1.1-1.5-2.1-2.7-3.1-3.6l1.5-1.4
            C58.9,609.1,60,610.3,61.1,611.7z M86,617.9c-1.8,1.9-4,2.8-6.7,2.8c-4.2,0-7.2-2.2-8.8-6.7l3.7-1.6c1.1,2.8,2.8,4.2,5.2,4.2
            c3.5,0,5.3-2.3,5.3-6.9c0-4.3-1.5-6.4-4.4-6.4c-1.3,0-2.3,0.3-3,0.8c-0.6,0.5-1.2,1.3-1.8,2.5h-3.8v-16.2h16.1v3.9H75.8v6.3
            c1.1-0.9,2.6-1.4,4.4-1.4c2.6,0,4.8,1,6.3,2.9c1.6,1.9,2.3,4.5,2.3,7.6C88.9,613.1,87.9,615.9,86,617.9z M99.9,599
            c0.1,0,0.2,0.1,0.2,0.2c0,0-0.1,0.1-0.4,0.2c-0.4,0.9-0.8,1.8-1.3,2.5c-0.5,0.7-1,1.2-1.6,1.8l-1-0.7c0.7-0.6,1.3-1.2,1.7-1.8
            c0.4-0.6,0.9-1.4,1.2-2.4L99.9,599z M104.3,602.5c0,2.3-0.1,3.8-0.3,4.3c-0.1,0.6-0.4,1-0.7,1.2c-0.4,0.2-1,0.4-1.9,0.4l-0.5-1.1
            c1,0,1.5-0.1,1.7-0.2c0.2-0.1,0.3-0.3,0.4-0.7c0.1-0.4,0.2-1.4,0.2-3h-2.4c-0.1,0.9-0.2,1.7-0.5,2.2c-0.3,0.7-0.7,1.2-1.1,1.6
            c-0.4,0.4-1,0.8-1.7,1.2l-1-0.8c0.8-0.3,1.3-0.6,1.7-1c0.4-0.4,0.7-0.8,1-1.4c0.2-0.5,0.4-1.1,0.5-1.9h-1.7v-1H104.3z M103.8,598.3
            c0.1,1.2,0.4,2.1,1,2.8c0.4,0.5,1.1,1.1,2,1.8l-1,0.7c-1-0.8-1.8-1.6-2.3-2.3c-0.4-0.5-0.6-1.2-0.8-2h-2.3v-1H103.8z M99.1,610.2
            c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0.5,0.4,1.1,0.8,1.7,1.1l-0.7,0.7
            c-0.6-0.4-1.1-0.8-1.5-1.2c-0.2,0.3-0.5,0.5-0.7,0.7c-0.2,0.2-0.5,0.5-0.7,0.7h2.9v0.9h-1.1v1.2h1.5v0.9h-1.5v3.1
            c0.5-0.1,1-0.1,1.5-0.2v0.8c-0.8,0.1-2,0.3-3.7,0.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.2l-0.2-1c0.6,0,1.2-0.1,1.8-0.2
            v-3.2h-1.6v-0.9h1.6v-1.2h-1.1v-0.7c-0.2,0.1-0.3,0.3-0.5,0.4l-0.7-0.7c0.6-0.4,1.1-0.9,1.5-1.4c0.4-0.4,0.8-0.9,1.2-1.6
            L99.1,610.2z M97.6,618.2l-0.7,0.4c-0.2-0.6-0.5-1.1-0.8-1.7l0.8-0.4C97.1,617.1,97.4,617.7,97.6,618.2z M100.3,616.9
            c0.1,0,0.2,0.1,0.2,0.1c0,0-0.1,0.1-0.2,0.2c-0.2,0.4-0.4,0.8-0.6,1.3l-0.6-0.2c0.1-0.3,0.2-0.5,0.3-0.7c0.1-0.3,0.2-0.7,0.3-1
            L100.3,616.9z M103.9,609.8c0.2,0,0.3,0.1,0.3,0.2c0,0-0.1,0.1-0.2,0.2v0.4h2.3v0.8h-0.8l-0.4,1h1.3v0.8h-6.1v-0.8h1.6l-0.4-1h-0.9
            v-0.8h2.4v-0.9L103.9,609.8z M106.1,613.8v3.6h-2.2v0.6h2.3v0.8h-2.3v0.7h2.6v0.8h-6.1v-0.8h2.5v-0.7h-2.2V618h2.2v-0.6h-2.2v-3.6
            H106.1z M102.9,615.3v-0.7h-1.2v0.7H102.9z M102.9,616h-1.2v0.7h1.2V616z M104.2,612.4l0.4-1h-2.1l0.4,1H104.2z M103.9,615.3h1.2
            v-0.7h-1.2V615.3z M105.1,616h-1.2v0.7h1.2V616z"/>
          <path class="st6" d="M179.2,571.8c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7
            c1-1,2.2-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7C180.8,569.6,180.3,570.8,179.2,571.8z M196.3,569.1h6.3v1.8h-6.1
            c0.1,0.5,0.2,1,0.3,1.4c0.5,1.4,1.1,2.5,2,3.3c0.7,0.7,1.2,1,1.6,1c0.1,0,0.3-0.1,0.5-0.3c0.2-0.2,0.6-1,1.1-2.5l1.6,1.3
            c-0.5,1.2-0.9,2.1-1.3,2.7c-0.6,0.7-1.2,1.1-1.8,1.1c-0.6,0-1.3-0.2-1.9-0.7c-0.8-0.5-1.6-1.3-2.3-2.3c-0.8-1.1-1.4-2.5-1.7-4.2
            c0-0.2-0.1-0.5-0.1-0.8h-6.4v4.9c1.7-0.5,3.4-1,5-1.6v1.8l-8.5,2.6c-0.2,0.5-0.4,0.7-0.5,0.7c-0.1,0-0.2-0.1-0.3-0.4l-0.7-1.9
            c1-0.2,1.9-0.5,2.9-0.7v-16.8h15.4v6.4h-5.3C196.2,567.2,196.2,568.2,196.3,569.1z M188.2,564.2h11.2v-2.7h-11.2V564.2z
            M188.2,569.1h6.2c-0.1-0.9-0.1-1.9-0.1-3.1h-6.1V569.1z M210.1,563.1v-4.8l1.7,0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.1-0.1,0.3-0.3,0.5
            v3.9h1.6v1.8H212v0c0.6,1.9,1.4,3.4,2.4,4.6l-1.3,1.4c-0.4-0.7-0.7-1.5-1-2.3v10.8h-1.8v-9.8c-0.4,1.4-0.9,2.8-1.4,4l-1.8-1.1
            c0.7-1.1,1.4-2.3,1.9-3.7c0.4-0.9,0.8-2.1,1.3-3.8v0h-2.2v-1.8H210.1z M220.3,563.4v3.9h-3l0.8,0.3c0.2,0,0.3,0.1,0.3,0.3
            c0,0.1-0.2,0.2-0.5,0.4c-0.1,0.3-0.2,0.6-0.3,0.8h3.6l0.9-1.5l1.5,0.3c0.3,0,0.4,0.2,0.4,0.3c0,0.2-0.2,0.4-0.5,0.5l-0.3,0.3h4.3
            v1.6h-4.8v1.2h4.2v1.5h-4.2v1.1h4.2v1.5h-4.2v1.1h5.2v1.6h-10.5v0.6h-1.8v-7.1c-0.3,0.5-0.7,0.9-1,1.2l-1.7-1.1
            c1.4-1.3,2.5-3,3.4-5h-1.5v-3.9H220.3z M219.3,558.9c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.3,0.5v3.3h-1.9v-1.1h-3.3V560h3.3v-1.3
            L219.3,558.9z M218.7,564.7h-2.2v1.3h2.2V564.7z M221,570.7h-3.4v1.2h3.4V570.7z M221,573.4h-3.4v1.1h3.4V573.4z M221,576.1h-3.4
            v1.1h3.4V576.1z M226.9,563.4v3.9h-5.4v-3.9H226.9z M224.1,558.9c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.3,0.5v0.4h3.3v1.8h-3.3
            v1.1h-1.9v-4.2L224.1,558.9z M225.3,564.7h-2.2v1.3h2.2V564.7z M252.2,560.3v1.8H245v2.9h6.2v13.4h-2v-1.7H235v1.7h-2v-13.4h5.7
            v-2.9h-6.8v-1.8H252.2z M249.2,574.8v-2.1h-4.7c-1,0-1.5-0.4-1.5-1.2V567h-2.5c-0.2,1-0.4,1.9-0.8,2.7c-0.4,0.9-1.3,2.2-2.6,3.6
            l-1.9-1c1.3-1.2,2.1-2.3,2.6-3.2c0.4-0.7,0.6-1.5,0.8-2.2H235v7.8H249.2z M243,562.2h-2.4v2.9h2.4V562.2z M245,570.5
            c0,0.2,0.1,0.4,0.5,0.4h3.7V567H245V570.5z M264.1,559.8v6.9h-2v3.2h2.4v1.8H262v3.7c0.9-0.2,1.9-0.5,2.8-0.8v1.7
            c-1.7,0.6-4.3,1.4-7.8,2.4c-0.1,0.2-0.3,0.4-0.4,0.4c-0.1,0-0.2-0.1-0.2-0.3l-0.5-1.9c0.3,0,0.6-0.1,0.9-0.2V569l1.6,0.1
            c0.3,0,0.5,0.1,0.5,0.2c0,0.1-0.1,0.2-0.3,0.5v6.2c0.5-0.1,1.1-0.2,1.6-0.3v-9.1H257v-6.9H264.1z M262.1,561.5H259v3.4h3.2V561.5z
            M269.8,559.2c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.2,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.3,0.7h4.8c0.1,0,0.2-0.1,0.3-0.1l0.3,0.2l0.8,0.9
            c0.1,0.2,0.2,0.3,0.2,0.4c0,0.1-0.2,0.3-0.6,0.5c-1,1.8-1.9,3-2.7,3.8c-0.1,0.1-0.2,0.2-0.4,0.4c1.2,0.7,2.9,1.5,5.1,2.3l-1.9,1.6
            c-2-0.9-3.5-1.8-4.7-2.7c-0.2,0.2-0.4,0.3-0.7,0.5c-1,0.6-2.4,1.3-4.2,2.1l-1.4-1.5c2.2-0.9,3.8-1.6,4.8-2.3
            c-0.6-0.7-1.2-1.5-1.7-2.4c-0.4,0.6-0.8,1.1-1.3,1.6l-1.8-1.2c1.6-1.5,2.9-3.5,3.8-6L269.8,559.2z M274.8,570.9v8.3h-1.9v-1.3H268
            v1.3h-1.9v-8.3H274.8z M272.9,576.1v-3.4H268v3.4H272.9z M270.4,565.6c0.4-0.3,0.7-0.7,1-1.1c0.5-0.5,0.9-1.2,1.5-2.1h-4.4
            c-0.1,0.1-0.1,0.2-0.2,0.3C268.9,563.9,269.6,564.9,270.4,565.6z M286.2,559c0.4,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.3-0.4,0.5v3.1h3.4
            v1.8h-9.2v-1.8h3.8v-4.1L286.2,559z M288.9,566.1c0.3,0.1,0.5,0.2,0.5,0.3c0,0.2-0.2,0.4-0.5,0.5l-1.6,7.5c0.9-0.2,1.7-0.3,2.6-0.5
            v1.8c-1.7,0.5-4.3,1.1-8,1.8c-0.2,0.3-0.3,0.4-0.5,0.4c-0.2,0-0.2-0.1-0.3-0.4l-0.5-2.1c1.5-0.2,3.1-0.4,4.6-0.6l2.1-9.1
            L288.9,566.1z M284.4,573.6l-1.8,0.3c-0.5-2.7-1.1-5.3-1.8-7.6l1.8-0.3C283.5,568.6,284.1,571.2,284.4,573.6z M295.2,559
            c0.4,0,0.6,0.1,0.6,0.3c0,0.1-0.2,0.3-0.4,0.5v3.1h5.5v1.8h-5.5v4.1h5v10.3h-2v-1.4h-6.2v1.4h-2v-10.3h3.1v-10L295.2,559z
            M298.3,575.9v-5.2h-6.2v5.2H298.3z M309.5,559.3c0.3,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.3-0.6,0.4c-0.1,0.3-0.3,0.6-0.4,0.9h1.8
            l0.8-1.8l1.6,0.4c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.4l-0.5,0.8h2.1v1.6h-2.4v1.6h1.4l-0.2-0.1c1.5-1.6,2.6-3.3,3.3-5.1
            l1.9,0.5c0.3,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.3-0.6,0.4c-0.2,0.3-0.3,0.7-0.5,1h1.9l0.7-1.8l1.6,0.4c0.2,0,0.3,0.1,0.3,0.2
            c0,0.1-0.1,0.2-0.2,0.4l-0.4,0.9h2.6v1.6h-2.9v1.3h2.6v1.5h-2.6v1.4h2.6v1.5h-2.6v1.5h3.2v1.6h-8.7V564c-0.2,0.3-0.5,0.6-0.7,0.8
            l-1-0.5v1.2h-2v1.4h2v1.5h-2v1.3h2.5v1.6h-7.9V564c-0.3,0.4-0.6,0.8-0.9,1.1l-1.8-0.9c1.5-1.7,2.6-3.5,3.3-5.4L309.5,559.3z
            M322.4,572.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2,0.1,0.3,0.2l0.7,0.8c0.1,0.1,0.1,0.3,0.1,0.5c0,0.1-0.2,0.3-0.6,0.4
            c-1.6,1.2-3.1,2.2-4.5,2.9c1.4,0.3,3.5,0.5,6.3,0.7l-1.4,1.8c-2.8-0.4-5.1-0.8-6.7-1.3c-0.3-0.1-0.5-0.2-0.8-0.2
            c-2.4,0.7-5.5,1.2-9.3,1.5l-1.1-1.8c2.5,0,4.4-0.1,5.7-0.4c0.8-0.1,1.6-0.2,2.3-0.4c-0.3-0.2-0.6-0.3-0.9-0.5
            c-1.1-0.6-2-1.4-2.7-2.4h-2.6v-1.6H322.4z M310.6,562.5h-1.9v1.6h1.9V562.5z M310.6,565.6h-1.9v1.4h1.9V565.6z M310.6,568.5h-1.9
            v1.3h1.9V568.5z M314.3,575.5c0.5,0.2,1.2,0.5,1.9,0.7c0.3-0.1,0.5-0.2,0.8-0.3c1-0.4,2.1-1.1,3.4-2H312
            C312.6,574.5,313.4,575.1,314.3,575.5z M319.9,562.6h-2v1.3h2V562.6z M319.9,565.4h-2v1.4h2V565.4z M319.9,568.3h-2v1.5h2V568.3z
            M331.9,562.1v-3.6l2.1,0.2c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.3-0.5,0.5v2.5h2.4v1.8h-2.4v4.3c0.8-0.4,1.6-0.9,2.4-1.3v1.8
            l-2.4,1.4v6.8c0,0.9-0.2,1.5-0.6,1.9c-0.4,0.4-1.3,0.6-2.7,0.6l-0.8-1.9h1.4c0.5,0,0.7-0.3,0.8-1v-5.3l-1.5,0.9
            c-0.2,0.5-0.4,0.7-0.5,0.7c-0.1,0-0.2-0.1-0.3-0.4l-0.7-1.9c1-0.4,2-0.8,3.1-1.3v-5.3h-2.8v-1.8H331.9z M341.4,560.4v-1.8l1.8,0.1
            c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.3-0.4,0.5v1h5.5v1.7h-5.5v1.1h4.8v3h1.7v1.6h-1.7v3.2h-4.8v1.9h5.3v1.7h-5.3v2.7
            c1,0.1,2.4,0.2,4.1,0.2h2.4l-0.9,1.9c-2.4,0-4.2-0.1-5.5-0.2c-1.1-0.1-1.9-0.3-2.6-0.6c-0.8-0.3-1.5-0.8-2.1-1.4
            c-0.2-0.2-0.5-0.5-0.7-0.9c-0.1,0.2-0.2,0.4-0.3,0.6c-0.5,0.9-1.2,1.8-2,2.5l-1.7-1.1c0.9-0.7,1.7-1.6,2.3-2.7
            c0.4-0.9,0.8-2.1,1.2-3.6l1.8,0.4c0.2,0.1,0.4,0.2,0.4,0.2c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.6-0.3,1.1-0.5,1.5
            c0.5,0.7,0.9,1.3,1.2,1.6c0.4,0.3,0.8,0.6,1.3,0.8v-5.9h-4.1v-1.7h4.1v-1.5h-5v-1.6h5v-1.3h-3.9v-1.7h3.9v-1.1h-4.7v-1.7H341.4z
            M343.3,566.2h2.8v-1.3h-2.8V566.2z M343.3,569.3h2.8v-1.5h-2.8V569.3z M358.5,568.6v6.6c0.5,0.7,1.2,1.2,2.2,1.5
            c1.1,0.3,2.8,0.5,5.2,0.5c3,0,5.8-0.1,8.4-0.3l-0.7,2.2h-7.7c-2,0-3.6-0.1-4.8-0.4c-1.3-0.3-2.3-0.7-3-1.4
            c-0.3-0.2-0.6-0.5-0.8-0.9l-2.1,2.3c-0.1,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.3-0.1-0.4-0.3l-1.1-1.6c1.2-0.7,2.2-1.4,3.1-2.1v-4.6h-1.7
            v-1.8H358.5z M358.6,565.7l-1.3,1.4c-1.2-0.9-2.4-1.6-3.8-2.3l1.4-1.4C356.3,564.1,357.6,564.9,358.6,565.7z M359.1,561.5l-1.3,1.4
            c-1.1-0.8-2.2-1.6-3.4-2.3l1.4-1.4C357.1,559.9,358.2,560.6,359.1,561.5z M373.9,559.4v4H372V561h-10.1v2.4H360v-4H373.9z
            M365.9,563v-1.4l1.8,0.2c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.4,0.4v0.5h3.4v1.5h-3.4v1.1h4.5v6.8h-4.5v1.3h5.8v1.5h-5.8v1.6
            h-1.9v-1.6h-5.7v-1.5h5.7v-1.3h-4.4v-6.8h4.4v-1.1h-3.3V563H365.9z M365.9,568.2V567h-2.5v1.2H365.9z M365.9,569.6h-2.5v1.3h2.5
            V569.6z M367.8,568.2h2.6V567h-2.6V568.2z M370.5,569.6h-2.6v1.3h2.6V569.6z M179.2,607.9c-1,1-2.3,1.5-3.7,1.5
            c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7c1-1,2.2-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5
            c1,1,1.5,2.3,1.5,3.7C180.8,605.7,180.3,606.9,179.2,607.9z M196.3,605.2h6.3v1.8h-6.1c0.1,0.5,0.2,1,0.3,1.4
            c0.5,1.4,1.1,2.5,2,3.3c0.7,0.7,1.2,1,1.6,1c0.1,0,0.3-0.1,0.5-0.3c0.2-0.2,0.6-1,1.1-2.5l1.6,1.3c-0.5,1.2-0.9,2.1-1.3,2.7
            c-0.6,0.7-1.2,1.1-1.8,1.1c-0.6,0-1.3-0.2-1.9-0.7c-0.8-0.5-1.6-1.3-2.3-2.3c-0.8-1.1-1.4-2.5-1.7-4.2c0-0.2-0.1-0.5-0.1-0.8h-6.4
            v4.9c1.7-0.5,3.4-1,5-1.6v1.8l-8.5,2.6c-0.2,0.5-0.4,0.7-0.5,0.7c-0.1,0-0.2-0.1-0.3-0.4l-0.7-1.9c1-0.2,1.9-0.5,2.9-0.7v-16.8
            h15.4v6.4h-5.3C196.2,603.3,196.2,604.3,196.3,605.2z M188.2,600.3h11.2v-2.7h-11.2V600.3z M188.2,605.2h6.2
            c-0.1-0.9-0.1-1.9-0.1-3.1h-6.1V605.2z M210.1,599.2v-4.8l1.7,0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.1-0.1,0.3-0.3,0.5v3.9h1.6v1.8H212v0
            c0.6,1.9,1.4,3.4,2.4,4.6L213,607c-0.4-0.7-0.7-1.5-1-2.3v10.8h-1.8v-9.8c-0.4,1.4-0.9,2.8-1.4,4l-1.8-1.1c0.7-1.1,1.4-2.3,1.9-3.7
            c0.4-0.9,0.8-2.1,1.3-3.8v0h-2.2v-1.8H210.1z M220.3,599.5v3.9h-3l0.8,0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.2,0.2-0.5,0.4
            c-0.1,0.3-0.2,0.6-0.3,0.8h3.6l0.9-1.5l1.5,0.3c0.3,0,0.4,0.2,0.4,0.3c0,0.2-0.2,0.4-0.5,0.5l-0.3,0.3h4.3v1.6h-4.8v1.2h4.2v1.5
            h-4.2v1.1h4.2v1.5h-4.2v1.1h5.2v1.6h-10.5v0.6h-1.8v-7.1c-0.3,0.5-0.7,0.9-1,1.2l-1.7-1.1c1.4-1.3,2.5-3,3.4-5h-1.5v-3.9H220.3z
            M219.3,595c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.3,0.5v3.3h-1.9v-1.1h-3.3v-1.8h3.3v-1.3L219.3,595z M218.7,600.8h-2.2v1.3h2.2
            V600.8z M221,606.8h-3.4v1.2h3.4V606.8z M221,609.5h-3.4v1.1h3.4V609.5z M221,612.2h-3.4v1.1h3.4V612.2z M226.9,599.5v3.9h-5.4
            v-3.9H226.9z M224.1,595c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.3,0.5v0.4h3.3v1.8h-3.3v1.1h-1.9v-4.2L224.1,595z M225.3,600.8
            h-2.2v1.3h2.2V600.8z M252.2,596.4v1.8H245v2.9h6.2v13.4h-2v-1.7H235v1.7h-2v-13.4h5.7v-2.9h-6.8v-1.8H252.2z M249.2,610.9v-2.1
            h-4.7c-1,0-1.5-0.4-1.5-1.2v-4.5h-2.5c-0.2,1-0.4,1.9-0.8,2.7c-0.4,0.9-1.3,2.2-2.6,3.6l-1.9-1c1.3-1.2,2.1-2.3,2.6-3.2
            c0.4-0.7,0.6-1.5,0.8-2.2H235v7.8H249.2z M243,598.3h-2.4v2.9h2.4V598.3z M245,606.6c0,0.2,0.1,0.4,0.5,0.4h3.7v-3.9H245V606.6z
            M264.1,595.9v6.9h-2v3.2h2.4v1.8H262v3.7c0.9-0.2,1.9-0.5,2.8-0.8v1.7c-1.7,0.6-4.3,1.4-7.8,2.4c-0.1,0.2-0.3,0.4-0.4,0.4
            c-0.1,0-0.2-0.1-0.2-0.3l-0.5-1.9c0.3,0,0.6-0.1,0.9-0.2v-7.3l1.6,0.1c0.3,0,0.5,0.1,0.5,0.2c0,0.1-0.1,0.2-0.3,0.5v6.2
            c0.5-0.1,1.1-0.2,1.6-0.3v-9.1H257v-6.9H264.1z M262.1,597.6H259v3.4h3.2V597.6z M269.8,595.3c0.2,0,0.3,0.2,0.3,0.3
            c0,0.1-0.2,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.3,0.7h4.8c0.1,0,0.2-0.1,0.3-0.1l0.3,0.2l0.8,0.9c0.1,0.2,0.2,0.3,0.2,0.4
            c0,0.1-0.2,0.3-0.6,0.5c-1,1.8-1.9,3-2.7,3.8c-0.1,0.1-0.2,0.2-0.4,0.4c1.2,0.7,2.9,1.5,5.1,2.3l-1.9,1.6c-2-0.9-3.5-1.8-4.7-2.7
            c-0.2,0.2-0.4,0.3-0.7,0.5c-1,0.6-2.4,1.3-4.2,2.1l-1.4-1.5c2.2-0.9,3.8-1.6,4.8-2.3c-0.6-0.7-1.2-1.5-1.7-2.4
            c-0.4,0.6-0.8,1.1-1.3,1.6l-1.8-1.2c1.6-1.5,2.9-3.5,3.8-6L269.8,595.3z M274.8,607v8.3h-1.9v-1.3H268v1.3h-1.9V607H274.8z
            M272.9,612.2v-3.4H268v3.4H272.9z M270.4,601.7c0.4-0.3,0.7-0.7,1-1.1c0.5-0.5,0.9-1.2,1.5-2.1h-4.4c-0.1,0.1-0.1,0.2-0.2,0.3
            C268.9,600,269.6,601,270.4,601.7z M286.2,595.1c0.4,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.3-0.4,0.5v3.1h3.4v1.8h-9.2V599h3.8V595
            L286.2,595.1z M288.9,602.2c0.3,0.1,0.5,0.2,0.5,0.3c0,0.2-0.2,0.4-0.5,0.5l-1.6,7.5c0.9-0.2,1.7-0.3,2.6-0.5v1.8
            c-1.7,0.5-4.3,1.1-8,1.8c-0.2,0.3-0.3,0.4-0.5,0.4c-0.2,0-0.2-0.1-0.3-0.4l-0.5-2.1c1.5-0.2,3.1-0.4,4.6-0.6l2.1-9.1L288.9,602.2z
            M284.4,609.7l-1.8,0.3c-0.5-2.7-1.1-5.3-1.8-7.6l1.8-0.3C283.5,604.7,284.1,607.3,284.4,609.7z M295.2,595.1
            c0.4,0,0.6,0.1,0.6,0.3c0,0.1-0.2,0.3-0.4,0.5v3.1h5.5v1.8h-5.5v4.1h5v10.3h-2v-1.4h-6.2v1.4h-2v-10.3h3.1v-10L295.2,595.1z
            M298.3,612v-5.2h-6.2v5.2H298.3z M315.7,595.1c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.3-0.3,0.5v1.1h7.8v1.7h-3.7l-0.9,2.8h4.2V613
            c0,1-0.5,1.6-1.4,1.9c-0.7,0.2-1.5,0.4-2.2,0.4l-0.8-1.9h-5.8v0.8h-1.8v-6.1h7.7v5.2l1.6,0c0.5,0,0.7-0.3,0.7-0.8v-9.2h-4v2
            c0,0.5,0.2,0.8,0.6,0.8h0.7c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.5,0.3-1.1l1.8,0.5c-0.2,1-0.4,1.6-0.7,1.9
            c-0.3,0.3-0.8,0.5-1.3,0.5h-1.5c-1.4,0-2-0.5-2-1.4v-3H314c-0.1,0.9-0.3,1.5-0.5,2.1c-0.4,0.7-0.8,1.3-1.3,1.8
            c-0.5,0.5-1.2,0.9-2,1.2l-1.3-1v8h-2v-13.8h4.2c-0.2-1-0.5-1.9-0.7-2.8h-3.9v-1.7h7.5v-2L315.7,595.1z M310.5,606
            c0.5-0.4,0.9-0.8,1.2-1.4c0.2-0.4,0.4-0.9,0.5-1.5h-3.3v3.7C309.6,606.6,310.2,606.3,310.5,606z M317.1,601.4l0.9-2.8h-5.7
            c0.3,1,0.5,1.9,0.7,2.8H317.1z M317.1,611.7v-1.9h-4v1.9H317.1z M349,595.6v19.8h-1.8v-0.7H332v0.7h-1.8v-19.8H349z M347.1,612.9
            v-15.6H332v15.6H347.1z M338,600.5c0.2-0.8,0.3-1.7,0.4-2.7l1.7,0.2c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.2,0.2-0.4,0.5
            c-0.1,0.7-0.2,1.2-0.4,1.8h5.3v1.6h-2.6c0.2,0.5,0.3,0.9,0.5,1.2h3.4v1.7h-2.4c0,0,0,0,0,0c0.6,0.8,1.5,1.6,2.8,2.3l-1.7,1.2
            c-0.8-0.7-1.5-1.3-2-2v1.6c0,0.8-0.5,1.3-1.4,1.6c-0.7,0.2-1.4,0.3-2.1,0.3l-0.6-1.7l1.7,0c0.5,0,0.7-0.2,0.7-0.7v-0.1h-3.9v2.2
            c0,0.6,0.2,0.9,0.6,0.9h3.7c0.5,0,1-0.1,1.3-0.2c0.2-0.1,0.5-0.6,0.7-1.4l1.8,0.5c-0.3,1-0.7,1.7-1.2,2c-0.6,0.4-1.3,0.5-2.2,0.5
            h-4.5c-1.4,0-2-0.5-2-1.4v-3.7c-0.6,0.5-1.3,1-2,1.5l-1.6-1.1c1.4-0.8,2.5-1.6,3.3-2.5h-2.9v-1.7h4.2l0,0c0.2-0.4,0.4-0.8,0.6-1.2
            h-3.7v-1.6H338z M337.4,599.4l-1.4,0.8c-0.5-0.5-1.1-0.9-1.7-1.3l1.5-0.7C336.3,598.6,336.9,599,337.4,599.4z M342.7,606.2
            c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.3-0.4-0.4-0.6h-4.1c-0.3,0.4-0.7,0.8-1,1.1H342.7z M340.8,602.1h-1.4
            c-0.2,0.5-0.3,0.9-0.5,1.2h2.3C341.1,603,340.9,602.6,340.8,602.1z M344.2,598.6c0.2,0.1,0.3,0.2,0.3,0.2c0,0.2-0.1,0.3-0.4,0.5
            l-1.5,0.9l-1.3-0.6l1.6-1.6L344.2,598.6z M183.9,639.1c-1.8-1.9-2.8-4.1-2.8-6.6c0-2.5,0.9-4.7,2.8-6.6l0.8,1
            c-1.5,1.6-2.3,3.4-2.3,5.6c0,2.1,0.8,4,2.3,5.6L183.9,639.1z M191.3,625.9c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.2h3.7v-1
            l1.2,0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v0.4h4.1v1.1h-4.1v0.7h3.7v4.6h-0.7c0.6,0.5,1.1,1,1.7,1.6l-1.1,0.7
            c-0.2-0.3-0.4-0.5-0.6-0.8c-1.7,0.1-4.3,0.3-7.7,0.5c-0.1,0.2-0.2,0.2-0.3,0.2s-0.1-0.1-0.1-0.2l-0.3-1.2c1.4,0,2.8-0.1,4.1-0.2
            v-0.7h-3.6V628h3.6v-0.7h-3.8v-0.9c-0.1,0-0.1,0.1-0.1,0.1c-0.3,0.9-0.6,1.7-0.9,2.4V639H189v-7.4c-0.3,0.5-0.6,1.1-1,1.7l-1-1
            c0.7-1.1,1.3-2.2,1.7-3.2c0.4-1,0.9-2.2,1.3-3.7L191.3,625.9z M197.8,635.3v-0.8l1.3,0.2c0.2,0,0.2,0.1,0.2,0.2
            c0,0.1-0.1,0.2-0.3,0.4v0.1h1.7v1.1H199v0.9c0,0.6-0.2,1-0.5,1.2c-0.3,0.3-1.1,0.4-2.3,0.4l-0.5-1.2h1.3c0.5,0,0.7-0.2,0.7-0.7
            v-0.7h-4.5c0.5,0.3,1,0.7,1.6,1.2l-1.1,0.7c-0.4-0.5-0.9-1-1.4-1.4l0.7-0.6h-2v-1.1H197.8z M195.1,629.8v-0.7h-2.4v0.7H195.1z
            M195.1,630.8h-2.4v0.8h2.4V630.8z M196.4,629.8h2.5v-0.7h-2.5V629.8z M198.9,630.8h-2.5v0.8h2.5V630.8z M198.1,632.9l0.3-0.2h-2
            v0.7c0.7,0,1.4-0.1,2-0.1C198.3,633.1,198.2,633,198.1,632.9z M205.8,634.5c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.3
            c-0.2,1.2-0.5,2.2-0.8,3l-1.2-0.4c0.2-0.4,0.4-0.8,0.5-1.3c0.2-0.6,0.3-1.3,0.4-2.1L205.8,634.5z M207.4,625.9
            c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.3l-1.4,1.8c0.4,0.2,0.7,0.5,1.1,0.7l1-1.4l1,0.6c0.1,0.1,0.2,0.2,0.2,0.3
            c0,0.1-0.1,0.1-0.3,0.2l-2.5,3.2c0.7-0.1,1.3-0.1,1.9-0.2c-0.1-0.3-0.2-0.6-0.3-0.8l1.2-0.2c0.4,1,0.8,1.9,0.9,2.8l-1.2,0.2
            c-0.1-0.3-0.2-0.6-0.3-0.9c-0.4,0.1-0.8,0.1-1.2,0.2v6.2h-1.2v-6c-0.5,0.1-1.1,0.2-1.7,0.2c-0.1,0.1-0.2,0.2-0.3,0.2
            c-0.1,0-0.2-0.1-0.2-0.2l-0.3-1.2c0.4,0,0.8,0,1.2-0.1l1.4-2c-1-0.6-1.8-1.2-2.6-1.7l0.7-1c0.2,0.1,0.3,0.2,0.5,0.3l1.4-2.2
            L207.4,625.9z M210,637.2l-1.2,0.2c-0.2-1.1-0.5-2.1-0.9-3l1.2-0.2C209.5,635.2,209.8,636.3,210,637.2z M212.7,632.7
            c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.4,0.5c0,1.7-0.1,2.8-0.3,3.3c-0.2,0.5-0.4,1-0.7,1.3c-0.4,0.4-0.9,0.7-1.6,1l-0.9-1.1
            c0.6-0.2,1.1-0.5,1.4-0.8c0.3-0.3,0.6-0.7,0.7-1.2c0.1-0.5,0.2-1.3,0.2-2.3c0-0.2-0.1-0.6-0.2-1.1L212.7,632.7z M213.9,625.5
            c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.2,0.3v0.8h3.4v1.1h-3.5l-1.5,2.8c0.9-0.1,1.9-0.2,2.8-0.3c-0.2-0.3-0.5-0.7-0.7-1l1-0.7
            c1,1.2,1.6,2.3,2,3.2l-1.1,0.5c-0.2-0.3-0.3-0.7-0.5-1.1c-1.2,0.2-3,0.4-5.2,0.7c-0.1,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.1-0.1-0.1-0.2
            l-0.3-1.2c0.4,0,0.7,0,1.1-0.1l1.6-2.9h-3.2v-1.1h3.3v-1.5L213.9,625.5z M215.1,632.7c0.2,0,0.3,0,0.3,0.1c0,0.1-0.1,0.2-0.2,0.3v4
            c0,0.3,0.2,0.5,0.5,0.5c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.5,0.4-1.1l1.2,0.5c-0.1,0.9-0.3,1.5-0.7,1.7c-0.2,0.2-0.7,0.3-1.3,0.3
            c-0.7,0-1.2-0.1-1.4-0.3c-0.2-0.2-0.3-0.5-0.3-0.9v-5L215.1,632.7z M227.6,625.4c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.3,0.3v1.5
            h6.1v1.3h-13.6v-1.3h6v-2.1L227.6,625.4z M226.2,630.7V629l1.3,0.1c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.3,0.3v1h5v5.7
            c0,0.7-0.4,1.2-1.3,1.3c-0.7,0.2-1.3,0.2-1.9,0.2l-0.5-1.4l1.6,0c0.5,0,0.7-0.2,0.7-0.6v-4h-3.6v7h-1.4v-7h-3.3v6.2h-1.4v-7.5h0.7
            H226.2z M238.3,628.8v-3.5l1.3,0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v2.8h1.7v1.2h-1.7v3.5c0.6-0.2,1.1-0.3,1.7-0.5v1.2
            c-0.9,0.3-2.2,0.8-4,1.4c-0.1,0.2-0.2,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.2-0.3l-0.3-1.5c0.6-0.1,1.2-0.2,1.8-0.3v-3.8h-1.7v-1.2H238.3z
            M250.2,631.4v1.2h-5.9c-0.1,0.4-0.3,0.7-0.4,0.9h6.1c0,2.2-0.1,3.5-0.3,4.1c-0.1,0.5-0.3,0.9-0.7,1.1c-0.4,0.2-1,0.3-1.9,0.3
            l-0.7-1.4c0.9,0,1.4,0,1.6-0.2c0.2-0.1,0.3-0.3,0.4-0.6c0.1-0.3,0.2-1,0.2-2.2h-0.9c-0.3,1-0.7,1.8-1.1,2.3
            c-0.6,0.8-1.3,1.5-2.2,2.1l-1.2-0.8c1-0.6,1.7-1.2,2.1-1.8c0.4-0.5,0.7-1.1,1-1.8h-1c-0.3,0.8-0.7,1.3-1.1,1.7
            c-0.6,0.6-1.4,1.2-2.3,1.6l-1.2-0.8c1-0.4,1.8-0.9,2.3-1.3c0.4-0.3,0.7-0.7,1-1.2h-0.8c-0.5,0.5-1.1,1-1.7,1.5l-1.2-0.8
            c0.9-0.5,1.5-0.9,1.9-1.4c0.3-0.4,0.6-0.8,0.8-1.3h-1.7v-1.2H250.2z M249,625.7v5h-6.6v-5H249z M247.7,627.7v-0.8h-3.8v0.8H247.7z
            M247.7,628.7h-3.8v0.9h3.8V628.7z M260.6,634c-0.8-1.1-1.7-2.1-2.7-2.9l0.9-1c1.1,0.9,2.1,1.9,2.9,2.9L260.6,634z M272.1,627v-1.7
            l1.2,0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v1.1h2.4v1.2h-2.4v1.7h2.7v0.4c0.6-0.4,1-0.8,1.3-1.2c0.3-0.5,0.6-1.1,0.7-2
            h-2v-1.2h6.7c0,1.4-0.1,2.5-0.3,3.2c-0.1,0.7-0.4,1.2-0.7,1.4c-0.3,0.3-0.9,0.4-1.8,0.5l-0.7-1.2l1.1-0.2c0.5-0.1,0.8-0.3,0.9-0.8
            c0.1-0.4,0.2-1,0.2-1.8h-2.1c-0.2,1-0.4,1.9-0.8,2.6c-0.3,0.6-0.8,1.1-1.4,1.6h5.3v5.7h-1.3v-0.8h-3.4v0.9h-1.3v-5.8h0.2l-0.6-0.4
            v0.4h-2.2v1.8h2v1.2h-2v2.6c0.4,0.2,0.9,0.3,1.4,0.3c1,0.2,3.1,0.3,6.2,0.3h1.7l-0.6,1.4c-2.5,0-4.5-0.1-5.8-0.2
            c-1.1-0.1-2-0.2-2.7-0.5c-0.8-0.2-1.6-0.6-2.2-1.1c-0.2-0.2-0.5-0.4-0.7-0.7c0,0.1,0,0.1,0,0.2c-0.2,0.9-0.4,1.7-0.7,2.4l-1.2-0.8
            c0.3-0.7,0.6-1.6,0.8-2.7c0.2-0.8,0.3-2,0.4-3.5l1.3,0.3c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.3,0.3c-0.1,0.8-0.2,1.5-0.3,2.1
            c0.5,0.6,1,1,1.4,1.3v-5h-3.3v-1.2h2.8v-1.7h-2.5V627H272.1z M281.2,635v-2.6h-3.4v2.6H281.2z M293,625.4c0.2,0,0.4,0.1,0.4,0.2
            c0,0.1-0.1,0.2-0.3,0.3v1.5h6.1v1.3h-13.6v-1.3h6v-2.1L293,625.4z M291.7,630.7V629l1.3,0.1c0.2,0,0.4,0.1,0.4,0.2
            c0,0.1-0.1,0.2-0.3,0.3v1h5v5.7c0,0.7-0.4,1.2-1.3,1.3c-0.7,0.2-1.3,0.2-1.9,0.2l-0.5-1.4l1.6,0c0.5,0,0.7-0.2,0.7-0.6v-4h-3.6v7
            h-1.4v-7h-3.3v6.2h-1.4v-7.5h0.7H291.7z M309.6,634c-0.8-1.1-1.7-2.1-2.7-2.9l0.9-1c1.1,0.9,2.1,1.9,2.9,2.9L309.6,634z
            M324.4,634.3c0.1-0.3,0.2-0.7,0.2-1.1l1.4,0.2c0.2,0,0.3,0.1,0.3,0.1c0,0.1-0.1,0.3-0.4,0.4c0,0.2-0.1,0.3-0.1,0.4h5.8v1.2h-5.2
            c0.6,0.6,1.2,1,1.8,1.3c0.9,0.5,2.3,0.8,4,1.1l-1.2,1.2c-1.6-0.4-2.9-0.9-3.8-1.5c-0.8-0.5-1.5-1.2-2-1.9c-0.1,0.1-0.1,0.2-0.2,0.3
            c-0.6,0.8-1.4,1.5-2.5,2c-0.9,0.4-2,0.8-3.4,1.1l-0.9-1.1c1.8-0.3,3.2-0.7,3.9-1.2c0.7-0.4,1.3-0.8,1.7-1.3h-5v-1.2H324.4z
            M323.8,627.3h2.8c0.5-0.7,0.9-1.3,1.2-2.1l1.3,0.3c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.4,0.3c-0.2,0.4-0.4,0.8-0.7,1.2h3.3v1.2
            h-5.7v1.1h4.9v1.1h-4.9v1.2h5.7v1.2h-12.8v-1.2h5.8v-1.2h-5.1v-1.1h5.1v-1.1h-5.8v-1.2h3.5c-0.4-0.5-0.8-1-1.2-1.5l1.2-0.6
            C322.9,625.9,323.4,626.6,323.8,627.3z M342.2,625.6c0.2,0.1,0.2,0.2,0.2,0.2c0,0.1-0.1,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.2
            c0.9,0.8,1.8,1.3,2.6,1.7c1,0.5,2.4,1,4,1.3l-1,1.3c-1.8-0.6-3.1-1.1-3.9-1.7v0.7h-5v-0.7c-0.8,0.5-1.9,1.1-3.2,1.7l-1.1-0.9
            c1.4-0.5,2.7-1.2,3.7-1.8c1-0.7,2-1.5,3.1-2.5L342.2,625.6z M345.3,630.2v5h-3.7c0.6,0.6,1.1,1.1,1.7,1.3c1.2-0.6,2.3-1.2,3.2-1.9
            l0.9,0.9c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.1-0.4,0.1c-0.8,0.4-1.6,0.8-2.5,1.2c1,0.4,2.3,0.7,3.9,0.8l-1.3,1.2
            c-1.8-0.4-3.2-0.9-4.2-1.5c-1-0.6-2-1.4-2.8-2.5h-1.5v2.1c0.9-0.1,1.8-0.3,2.7-0.5l-0.1,1.1l-4.9,0.9c-0.2,0.2-0.3,0.3-0.4,0.3
            c-0.2,0-0.2-0.1-0.2-0.2l-0.5-1.4c0.7,0,1.4,0,2.1-0.1v-7.2H345.3z M344,632.2v-0.9h-5.3v0.9H344z M344,634v-0.8h-5.3v0.8H344z
            M343,628.4c-0.6-0.4-1.3-0.9-1.9-1.5c-0.7,0.5-1.3,1-1.9,1.5c0,0,0,0,0,0H343z M358.4,625.4c0.2,0,0.3,0.1,0.3,0.2
            c0,0.1-0.1,0.2-0.2,0.3v0.7h5.3v1.1h-2.5l-0.6,1.9h2.8v7.8c0,0.7-0.3,1.1-1,1.3c-0.5,0.2-1,0.2-1.5,0.2l-0.5-1.3h-3.9v0.5h-1.2
            v-4.1h5.2v3.5l1.1,0c0.3,0,0.5-0.2,0.5-0.6v-6.2h-2.7v1.3c0,0.4,0.1,0.5,0.4,0.5h0.5c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.3,0.2-0.8
            l1.2,0.3c-0.1,0.7-0.3,1.1-0.5,1.3c-0.2,0.2-0.5,0.3-0.9,0.3h-1c-0.9,0-1.4-0.3-1.4-1v-2h-1.1c-0.1,0.6-0.2,1-0.3,1.4
            c-0.2,0.5-0.5,0.9-0.8,1.2c-0.3,0.3-0.8,0.6-1.3,0.8l-0.9-0.7v5.4h-1.4v-9.3h2.8c-0.2-0.7-0.3-1.3-0.5-1.9h-2.6v-1.1h5v-1.4
            L358.4,625.4z M354.9,632.8c0.3-0.2,0.6-0.5,0.8-0.9c0.1-0.3,0.2-0.6,0.3-1h-2.2v2.5C354.2,633.2,354.6,633,354.9,632.8z
            M359.3,629.7l0.6-1.9H356c0.2,0.7,0.3,1.3,0.5,1.9H359.3z M359.3,636.6v-1.3h-2.7v1.3H359.3z M374.8,625.4c0.2,0,0.4,0.1,0.4,0.2
            c0,0.1-0.1,0.2-0.3,0.3v0.8h5.8v2.8h-1.4V628h-10.2v1.6h-1.4v-2.8h5.7v-1.5L374.8,625.4z M378.6,628.9v1.2h-3.5
            c-0.2,0.2-0.4,0.3-0.5,0.4c0.6,0.7,1.1,1.3,1.4,1.9c1.1-0.6,2-1.2,2.8-1.8l1,1c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1-0.2,0.1-0.4,0.1
            c-0.9,0.5-1.9,1-2.9,1.5c0.6,0.9,1.2,1.6,1.8,2.1c0.7,0.6,1.6,1.2,2.9,1.8l-1.3,1.1c-1.1-0.6-2-1.3-2.7-2.1c-0.3-0.3-0.6-0.6-0.8-1
            c0,0.8-0.1,1.5-0.3,2.1c-0.2,0.5-0.4,0.9-0.7,1.1c-0.4,0.2-1.1,0.3-2.1,0.3c-0.1-0.6-0.2-1.1-0.4-1.5c0.5,0,0.9,0.1,1.1,0.1
            c0.4,0,0.7-0.2,0.8-0.7c0.1-0.5,0.2-1,0.2-1.5v-0.2c-0.7,0.7-1.4,1.2-2,1.6c-0.9,0.6-2.2,1.2-3.8,1.8l-1.2-1.1c1.6-0.5,3-1,4.1-1.6
            c0.8-0.4,1.7-1,2.8-1.9c-0.1-0.4-0.2-0.7-0.3-1c0,0,0-0.1,0-0.1c-0.7,0.7-1.4,1.2-2,1.5c-0.8,0.5-2,1-3.4,1.6l-1.1-1
            c1.4-0.4,2.7-0.9,3.7-1.4c0.7-0.3,1.5-0.8,2.4-1.5c-0.2-0.2-0.4-0.5-0.6-0.7c-0.3,0.2-0.5,0.3-0.7,0.4c-0.9,0.5-2,1-3.5,1.5l-1.1-1
            c1.5-0.4,2.8-0.8,3.8-1.3c0.5-0.2,1-0.5,1.5-0.8h-3.5v-1.2H378.6z M389.7,626.1v2.6h0.8v-0.9h1.5c-0.4-0.6-0.8-1.2-1.2-1.8l1.1-0.5
            c0.5,0.6,0.8,1.2,1.2,1.8l-0.9,0.5h2.7l-0.8-0.4l1.6-2l1,0.5c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.3l-1.4,1.4h2v1.2h-2.7
            v2.1h2.4v1.1h-2.4v2h2.7v1.2h-2.7v3.7h-1.3v-3.7h-3v-1.2h3v-2h-2.5V631h2.5v-2.1h-2.7v0.9h-0.9v2.4h-3.1c-0.1,0.3-0.2,0.7-0.2,0.9
            c0,0.2-0.1,0.3-0.1,0.4h3.7v5.5h-1.2v-0.9h-2v0.8h-1.2v-3.3c-0.2,0.4-0.4,0.8-0.7,1.1l-1.2-0.8c0.7-1,1.2-2.1,1.5-3.3
            c0-0.1,0.1-0.2,0.1-0.3h-1.1v-1.2h1.3c0.1-0.4,0.1-0.8,0.2-1.2h-1.9v-1.1h2c0-0.5,0.1-0.9,0.1-1.4h-1.6v-1.2H389.7z M388.7,637
            v-2.3h-2v2.3H388.7z M386.8,631.1h1.6v-1.2H387C386.9,630.3,386.9,630.7,386.8,631.1z M387.1,628.7h1.4v-1.4h-1.3
            C387.1,627.8,387.1,628.2,387.1,628.7z M407.2,625.7v1.1h-0.8v5.7h-1.2v-0.9c-1.1,0.2-2.6,0.3-4.5,0.6c-0.1,0.1-0.2,0.2-0.2,0.2
            c-0.1,0-0.1-0.1-0.1-0.2l-0.3-1.1c0.4,0,0.8,0,1.1-0.1v-4.2h-1v-1.1H407.2z M411.5,633.4c-1.2,0.1-2.4,0.3-3.6,0.4v5.2h-1.3v-5.1
            c-1.7,0.1-3.3,0.2-5,0.3l-0.9-1.2c2.3,0,4.2-0.1,5.9-0.3c1.5-0.1,3.3-0.3,5.4-0.6l0.9,0.9c0.1,0.1,0.1,0.1,0.1,0.2
            c0,0.1-0.1,0.1-0.2,0.1l-0.3-0.1C412.2,633.4,411.9,633.4,411.5,633.4z M404.5,634.7c0.2,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.4,0.4
            c-0.1,0.2-0.1,0.4-0.2,0.5c0.5,0.6,1.2,1.2,2.2,1.8l-1.1,0.7c-0.7-0.5-1.2-1-1.6-1.4c-0.1,0.1-0.1,0.2-0.1,0.2
            c-0.4,0.5-0.9,1-1.7,1.6l-1-0.9c0.7-0.5,1.3-1,1.6-1.5c0.3-0.4,0.5-1,0.8-1.8L404.5,634.7z M405.2,627.5v-0.6h-2.8v0.6H405.2z
            M405.2,629.1v-0.6h-2.8v0.6H405.2z M405.2,630.7v-0.5h-2.8v0.8C403.3,630.9,404.3,630.8,405.2,630.7z M412.3,625.9
            c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.2,0,0.3,0.1l0.7,0.7c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.2,0.2-0.4,0.4c-0.5,1.4-1.1,2.4-1.8,3
            c0.6,0.3,1.4,0.6,2.2,0.9l-1.2,1c-0.8-0.3-1.5-0.7-2.1-1.1c-0.8,0.5-1.7,0.9-2.9,1.2l-0.9-1.2c0.8-0.2,1.5-0.4,2.1-0.6
            c0.2-0.1,0.4-0.2,0.6-0.3c-0.7-0.6-1.3-1.3-2-2.1l0.8-0.6c0.7,0.9,1.4,1.6,2.2,2c0.1-0.1,0.3-0.2,0.3-0.3c0.4-0.5,0.8-1.1,1.2-2
            h-4.3v-1.1H412.3z M411.8,634.2c0.2,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.4,0.3c-0.1,0.2-0.2,0.4-0.2,0.6c0.2,0.4,0.4,0.7,0.7,0.9
            c0.3,0.4,0.8,0.7,1.4,0.9l-1.2,0.9c-0.4-0.3-0.7-0.6-1-0.9c-0.2-0.2-0.3-0.4-0.5-0.7c0,0,0,0,0,0c-0.4,0.5-0.9,1-1.6,1.6l-1-0.9
            c0.7-0.5,1.2-1,1.6-1.5c0.3-0.4,0.5-0.9,0.8-1.7L411.8,634.2z M423.1,634.2l-0.5-0.7c0.5-0.4,0.8-0.9,0.9-1.4h-1v-1.9h1.9v1.7
            C424.3,632.8,423.9,633.6,423.1,634.2z M434.7,627.5v-2.4l1.4,0.2c0.2,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.4,0.4v1.7h1.6v1.2H436
            v2.9c0.5-0.3,1.1-0.6,1.6-0.9v1.2l-1.6,0.9v4.6c0,0.6-0.2,1-0.4,1.3c-0.2,0.3-0.8,0.4-1.8,0.4l-0.5-1.3h0.9c0.3,0,0.5-0.2,0.5-0.7
            v-3.6l-1,0.6c-0.1,0.3-0.3,0.5-0.3,0.5c-0.1,0-0.2-0.1-0.2-0.2l-0.5-1.3c0.7-0.3,1.4-0.5,2.1-0.9v-3.6h-1.9v-1.2H434.7z
            M441.1,626.4v-1.2l1.2,0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v0.7h3.7v1.1h-3.7v0.8h3.2v2h1.1v1.1h-1.1v2.1h-3.2v1.3
            h3.6v1.1h-3.6v1.8c0.7,0.1,1.6,0.1,2.8,0.1h1.6l-0.6,1.3c-1.6,0-2.8-0.1-3.7-0.2c-0.7-0.1-1.3-0.2-1.7-0.4c-0.5-0.2-1-0.5-1.4-0.9
            c-0.2-0.2-0.3-0.3-0.5-0.6c-0.1,0.2-0.2,0.3-0.2,0.4c-0.4,0.6-0.8,1.2-1.3,1.7l-1.1-0.8c0.6-0.5,1.1-1.1,1.5-1.8
            c0.3-0.6,0.5-1.4,0.8-2.4l1.2,0.3c0.2,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.4-0.2,0.7-0.3,1c0.3,0.5,0.6,0.8,0.8,1
            c0.3,0.2,0.5,0.4,0.9,0.5v-4h-2.8v-1.1h2.8v-1h-3.4v-1.1h3.4v-0.9h-2.7v-1.1h2.7v-0.8h-3.1v-1.1H441.1z M442.4,630.3h1.9v-0.9h-1.9
            V630.3z M442.4,632.4h1.9v-1h-1.9V632.4z M452.6,631.9v4.5c0.3,0.5,0.8,0.8,1.5,1c0.8,0.2,1.9,0.3,3.5,0.3c2,0,3.9-0.1,5.6-0.2
            l-0.5,1.5h-5.2c-1.3,0-2.4-0.1-3.2-0.3c-0.8-0.2-1.5-0.5-2-0.9c-0.2-0.2-0.4-0.4-0.5-0.6l-1.4,1.5c-0.1,0.2-0.2,0.2-0.2,0.2
            c-0.1,0-0.2-0.1-0.2-0.2l-0.8-1c0.8-0.5,1.5-1,2.1-1.4v-3.1H450v-1.2H452.6z M452.6,629.9l-0.9,1c-0.8-0.6-1.6-1.1-2.5-1.5l1-0.9
            C451.1,628.9,452,629.4,452.6,629.9z M453,627.1l-0.9,0.9c-0.7-0.6-1.5-1.1-2.3-1.5l1-1C451.6,626,452.4,626.6,453,627.1z
            M462.9,625.7v2.7h-1.3v-1.7h-6.8v1.6h-1.3v-2.7H462.9z M457.6,628.2v-1l1.2,0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v0.4
            h2.3v1h-2.3v0.7h3v4.6h-3v0.9h3.9v1h-3.9v1.1h-1.3v-1.1h-3.8v-1h3.8v-0.9h-3v-4.6h3v-0.7h-2.2v-1H457.6z M457.6,631.7v-0.8h-1.7
            v0.8H457.6z M457.6,632.6h-1.7v0.9h1.7V632.6z M458.9,631.7h1.8v-0.8h-1.8V631.7z M460.6,632.6h-1.8v0.9h1.8V632.6z M468.8,630.8
            l-0.9,0.9c-0.7-0.6-1.5-1.2-2.4-1.7l1-1C467.4,629.7,468.1,630.2,468.8,630.8z M469.4,634.1c-0.7,1.5-1.5,2.9-2.3,4.3
            c0,0.3-0.1,0.5-0.3,0.5l-1.2-1c1.1-1.5,2.1-3.1,3-4.9L469.4,634.1z M469.8,627l-0.9,0.9c-0.7-0.6-1.4-1.1-2.2-1.6l0.9-1
            C468.4,626,469.2,626.5,469.8,627z M472.9,626c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,1-0.2,1.8-0.4,2.4
            c-0.2,0.6-0.5,1.2-0.9,1.6c-0.4,0.5-0.8,0.9-1.3,1.3l-1.1-0.8c0.5-0.3,0.9-0.7,1.2-1c0.4-0.4,0.6-0.9,0.8-1.4
            c0.2-0.7,0.4-1.6,0.4-2.8L472.9,626z M478.1,632.7v6.2h-1.4v-1H472v1h-1.4v-6.2H478.1z M476.8,636.7V634H472v2.8H476.8z
            M476.6,626.6v3.1c0,0.3,0.1,0.5,0.2,0.5h2.4l-0.2,1.2h-3.1c-0.5,0-0.7-0.5-0.7-1.6v-3.2H476.6z M483.7,634.2
            c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1-0.1,0.2c-0.3,1.5-0.6,2.7-0.9,3.7l-1.1-0.5c0.4-1.1,0.7-2.4,1-4L483.7,634.2z M485.5,625.9
            c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.3l-1.6,2.3c0.3,0.3,0.5,0.5,0.8,0.8l1.4-2l1,0.5c0.1,0.1,0.2,0.1,0.2,0.2
            c0,0.1-0.1,0.2-0.2,0.3l-2.3,3.1c0.5,0,1.1-0.1,1.6-0.1c-0.1-0.3-0.2-0.7-0.4-1l1.1-0.2c0.4,1,0.7,2,0.9,2.9l-1.1,0.2
            c-0.1-0.3-0.2-0.6-0.2-0.9l-1,0.1v6.6h-1.2v-6.4l-1.6,0.2c-0.2,0.2-0.3,0.4-0.3,0.4c-0.1,0-0.2-0.1-0.2-0.3l-0.1-1.4
            c0.4,0,0.8,0,1.2,0l0.7-1.1c-0.7-0.7-1.4-1.4-2.2-2.1l0.8-1c0.2,0.2,0.4,0.3,0.5,0.5l1.5-2.5L485.5,625.9z M487.5,636.6l-1.1,0.2
            c-0.2-1-0.3-1.9-0.5-2.8l1.1-0.2C487.2,634.8,487.4,635.7,487.5,636.6z M489.4,633.5c0.2-0.1,0.3-0.2,0.4-0.2c0,0,0.3,0.2,0.9,0.7
            c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.3-0.4,0.8c-0.4,1-0.8,1.7-1.2,2.1c-0.5,0.6-1.1,1-1.8,1.4l-0.8-0.9
            c0.5-0.2,0.9-0.4,1.2-0.7c0.3-0.2,0.6-0.6,0.8-0.9c0.2-0.3,0.5-0.8,0.7-1.5h-1.8v-1.1H489.4z M492.2,625.7c0.2,0.1,0.3,0.1,0.3,0.2
            c0,0.1-0.1,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3h2.8v5.6h-6.6v-5.6h2.3c0-0.1,0.1-0.2,0.1-0.3c0.2-0.3,0.3-0.7,0.4-1L492.2,625.7z
            M492.2,632.5c0.2,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.3,0.3v1.3c0.1,0.2,0.2,0.4,0.3,0.6c0.8-0.6,1.5-1.3,2.1-1.9l0.9,0.9
            c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.2,0.1-0.4,0.1c-0.7,0.5-1.4,1.1-2.1,1.6c0.2,0.3,0.4,0.5,0.6,0.7c0.5,0.5,1.2,1,2,1.4l-1.1,1
            c-0.8-0.5-1.4-1-1.8-1.6c-0.2-0.3-0.5-0.6-0.7-0.9v0.9c0,0.6-0.2,1-0.4,1.2c-0.2,0.3-0.8,0.4-1.8,0.4l-0.5-1.2h0.9
            c0.3,0,0.5-0.2,0.5-0.7v-4.7L492.2,632.5z M493.4,628.8v-1.1h-4v1.1H493.4z M493.4,629.9h-4v1.1h4V629.9z M500.2,627.1
            c0-0.5,0-1,0-1.7l1.2,0.1c0.2,0,0.3,0.1,0.3,0.1c0,0.1-0.1,0.2-0.2,0.3c0,0.5,0,0.9,0,1.2h1.3v-1.7l1.2,0.1c0.2,0,0.3,0.1,0.3,0.2
            c0,0.1-0.1,0.2-0.2,0.3v1.1h1.5v-1.7l1.2,0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v1.1h1.2v-1.8l1.2,0.1
            c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v1.1h2.1v1.2h-2.1v1.5c0,0.3,0.1,0.4,0.4,0.4c0.2,0,0.3,0,0.4-0.1
            c0.1-0.1,0.3-0.5,0.4-1.1l1.2,0.5c-0.2,0.9-0.4,1.4-0.7,1.6c-0.2,0.2-0.7,0.3-1.3,0.3c-0.7,0-1.2-0.1-1.4-0.3
            c-0.2-0.2-0.3-0.5-0.3-0.9v-1.9H507v2.8h-4.2v-2.8h-1.4c0,0.3-0.1,0.5-0.1,0.7c-0.2,0.6-0.3,1-0.5,1.3c-0.3,0.5-0.6,0.9-1,1.2
            l-1.3-0.8c0.5-0.4,0.8-0.7,1.1-1.1c0.2-0.4,0.4-0.8,0.5-1.3v0h-1.5v-1.2H500.2z M511.7,631.7v2.6h-1.4V633h-4.5v1h4.1v2.9
            c0,0.7-0.4,1.1-1.1,1.3c-0.6,0.2-1.2,0.2-1.8,0.2l-0.5-1.4l1.5,0c0.4,0,0.6-0.2,0.6-0.6v-1.2h-2.8v3.8h-1.4v-3.8h-2.5v3h-1.4V634
            h3.9v-1h-4.5v1.4h-1.4v-2.6H511.7z M504.2,629.9h1.5v-1.6h-1.5V629.9z M516.6,626c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.4v2.7
            h2.1v-3.8l1.2,0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v13h-1.3v-5.3h-1.5c-0.1,0.9-0.2,1.7-0.3,2.4
            c-0.2,0.7-0.3,1.3-0.5,1.8c-0.2,0.4-0.3,0.8-0.6,1.2l-1.2-0.8c0.2-0.3,0.4-0.6,0.5-0.9c0.2-0.5,0.4-1.1,0.6-1.8
            c0.1-0.5,0.2-1.2,0.3-1.9h-1.3v-1.2h4v-2.1h-2.1v0.6h-1.2v-5L516.6,626z M523.6,629.7c0-1.2,0.1-2.6,0.1-4.3l1.4,0.2
            c0.2,0,0.3,0.1,0.3,0.1c0,0.1-0.1,0.2-0.3,0.4c0,1.4,0,2.6-0.1,3.6h3.3v1.3h-3.4v0c0.2,1.5,0.5,2.7,0.9,3.5
            c0.5,1.1,1.4,2.3,2.7,3.4l-1.1,1.1c-1.2-1.2-2.2-2.5-2.7-3.9c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2,0.7-0.4,1.3-0.7,1.8
            c-0.5,1.1-1.2,2.1-2,2.9l-1.2-1.1c0.9-0.7,1.6-1.6,2.1-2.8c0.5-0.9,0.8-2.1,1-3.5c0-0.2,0-0.4,0.1-0.7h-3v-1.3H523.6z M528.3,628.4
            l-1.2,0.8c-0.4-0.9-0.8-1.7-1.3-2.3l0.9-0.8C527.2,626.7,527.7,627.5,528.3,628.4z M535.8,627.7c0.2,0.1,0.2,0.1,0.2,0.2
            c0,0.1-0.1,0.2-0.4,0.4c-0.4,1.2-0.8,2-1.2,2.6c-0.5,0.8-1.3,1.6-2.3,2.4l-1.1-1c1-0.7,1.7-1.5,2.2-2.3c0.4-0.5,0.7-1.4,1.1-2.6
            L535.8,627.7z M537.9,631c0.2,0.1,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.3,0.3c-0.6,1.6-1.4,3.4-2.4,5.3c1.9-0.1,3.7-0.3,5.4-0.5
            c-0.5-0.8-1.1-1.6-1.7-2.2l1-0.8c1.2,1.3,2.4,2.9,3.5,4.8l-1.3,0.9c-0.3-0.5-0.6-1-0.8-1.5l-8.6,1c-0.2,0.2-0.3,0.4-0.5,0.4
            c-0.2,0-0.3-0.1-0.3-0.2l-0.5-1.7c0.8,0,1.5,0,2.3-0.1c0.5-0.9,0.8-1.7,1.2-2.5c0.6-1.3,1-2.7,1.4-4L537.9,631z M540.8,625.9
            c0.2,1.6,0.6,3,1.3,3.9c0.5,0.7,1.4,1.6,2.7,2.6l-1.3,1c-1.4-1.1-2.4-2.2-3.1-3.2c-0.5-0.8-0.9-1.8-1.1-3h-3.6v-1.2H540.8z
            M560.6,625.8v13.1h-1.2v-0.4h-10.2v0.4h-1.2v-13.1H560.6z M559.4,637.3V627h-10.2v10.3H559.4z M557.7,630.9v2.5h-2.5
            c0.3,0.5,0.6,0.8,1,1.2c0.6-0.3,1.1-0.7,1.6-1l0.8,0.8c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.1-0.4,0.1c-0.4,0.2-0.8,0.4-1.2,0.5
            c0.5,0.3,1.2,0.6,2.1,0.9l-1.2,1c-1.2-0.6-2.1-1.2-2.6-1.8c-0.5-0.5-0.8-1.1-1.2-1.9H554c-0.2,0.2-0.5,0.5-0.7,0.7v1.7
            c0.7-0.1,1.4-0.2,2-0.4l0.1,0.9c-1.3,0.3-2.4,0.6-3.4,0.8c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.2-0.2l-0.6-1
            c0.4,0,0.8-0.1,1.2-0.2v-1.1c-0.5,0.3-1.1,0.5-1.8,0.7l-1-0.9c0.9-0.2,1.7-0.5,2.4-0.8c0.2-0.1,0.5-0.2,0.8-0.4h-1.7v-2.5H557.7z
            M553.6,627.9v-0.7l1.2,0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v0.1h3.1v1h-3.1v0.5h3.8v1h-8.9v-1h3.8v-0.5h-3.1v-1H553.6
            z M556.5,631.8h-4.4v0.6h4.4V631.8z M564.6,639.1l-0.8-1c1.5-1.6,2.3-3.4,2.3-5.6c0-2.1-0.8-4-2.3-5.6l0.8-1
            c1.8,1.9,2.8,4.1,2.8,6.6C567.4,635,566.4,637.2,564.6,639.1z"/>
        </g>
        <circle class="st4 an5" cx="76" cy="721.7" r="48"/>
        <g class="ant5">
          <path class="st5" d="M63.7,704.7c0,1.1,0,2-0.1,2.9l-1-0.1c0.1-0.9,0.2-1.8,0.2-2.8H63.7z M69.5,696.6h-2v1.3h1.6v1.1h-1.6v1.2h1.6
            v1.1h-1.6v1.3h2c0,2.5-0.1,4.1-0.2,4.7c-0.1,0.6-0.3,1.1-0.7,1.3c-0.4,0.2-0.9,0.4-1.8,0.4l-0.6-1.3c0.8,0,1.3-0.1,1.5-0.2
            c0.2-0.1,0.3-0.4,0.4-0.8c0.1-0.4,0.2-1.4,0.2-3h-5v-8.1h6.2V696.6z M65.3,706.9l-0.9,0.2c-0.1-0.7-0.2-1.4-0.4-2.3l0.8-0.2
            C65,705.4,65.2,706.2,65.3,706.9z M66.3,696.6h-1.8v1.3h1.8V696.6z M66.3,698.9h-1.8v1.2h1.8V698.9z M66.3,702.4v-1.3h-1.8v1.3
            H66.3z M66.9,706.4l-0.9,0.2c-0.1-0.5-0.4-1.2-0.8-2l0.8-0.2C66.4,705.1,66.7,705.8,66.9,706.4z M68,706.1l-0.8,0.2
            c-0.1-0.5-0.3-1.1-0.7-1.8l0.7-0.2C67.6,704.9,67.8,705.5,68,706.1z M76.6,700.8v1.1h-0.9v5.5c0,0.5-0.2,0.8-0.5,1.1
            c-0.3,0.2-1,0.4-2.1,0.4l-0.5-1.3h1.2c0.4,0,0.6-0.2,0.7-0.4v-5.3h-5.1v-1.1H76.6z M72.2,696.4c0.1-0.4,0.1-0.8,0.1-1.3l1.1,0.1
            c0.2,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.2,0.4c0,0.2,0,0.5-0.1,0.7h2.8v1.1h-2.5c0.3,0.4,0.6,0.7,0.8,0.9c0.4,0.3,1,0.7,1.9,1
            l-1.1,1c-0.7-0.4-1.2-0.8-1.6-1.3c-0.2-0.2-0.5-0.5-0.7-0.9c-0.2,0.5-0.5,0.9-0.8,1.2c-0.4,0.4-1,0.7-1.7,0.9l-0.8-1
            c0.8-0.2,1.3-0.4,1.7-0.8c0.3-0.3,0.5-0.6,0.7-1.1h-2.3v-1.1H72.2z M73.5,703v3.8h-2.3v0.6H70V703H73.5z M72.3,705.8V704h-1.2v1.8
            H72.3z M84.6,696.4V695l1.2,0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3v0.8h5.4v1.2h-5.4v1.1h4.2v5.8h-4.2v1.1h6v1.2h-6v2.1
            h-1.3v-2.1h-6.1v-1.2h6.1v-1.1h-4v-5.8h4v-1.1h-5.5v-1.2H84.6z M84.6,701v-1.2h-2.7v1.2H84.6z M84.6,702.1h-2.7v1.3h2.7V702.1z
            M85.9,701h2.9v-1.2h-2.9V701z M88.8,702.1h-2.9v1.3h2.9V702.1z M50,721.6c0.2,0.1,0.3,0.2,0.3,0.3c0,0.2-0.1,0.4-0.4,0.5l-2.9,3.7
            c0.7,0.4,1.3,0.9,1.8,1.3l2.4-3.1l1.5,1c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.2,0.2-0.5,0.3l-4.7,5.7c1.2-0.1,2.3-0.3,3.4-0.6
            c-0.3-0.6-0.5-1.2-0.8-1.8l1.8-0.4c0.9,1.8,1.6,3.6,1.9,5.3l-1.8,0.4c-0.2-0.7-0.4-1.3-0.6-1.9c-0.5,0.1-1.1,0.2-1.8,0.4v9.4h-2
            v-9.1c-1.1,0.2-2.3,0.4-3.7,0.6c-0.1,0.3-0.3,0.4-0.5,0.4c-0.1,0-0.2-0.1-0.2-0.3l-0.4-1.9c0.8,0,1.5-0.1,2.3-0.2l2.2-3
            c-1.3-0.9-2.7-1.9-4.3-2.8l1.2-1.5c0.3,0.2,0.6,0.4,0.9,0.5l2.9-4.3L50,721.6z M47.1,735.6c0.2,0,0.3,0.1,0.3,0.3
            c0,0.2-0.1,0.4-0.4,0.6c-0.4,2-0.9,3.7-1.5,5.2l-1.9-0.6c0.4-0.7,0.7-1.5,0.9-2.3c0.3-1.1,0.6-2.3,0.8-3.6L47.1,735.6z M54.6,740.4
            l-1.8,0.4c-0.5-1.9-1.1-3.7-1.8-5.3l1.8-0.4C53.6,736.9,54.2,738.7,54.6,740.4z M58.3,721.6c0.2,0,0.3,0.1,0.3,0.3
            c0,0.2-0.2,0.4-0.5,0.7c-0.2,0.9-0.5,1.8-0.8,2.7h7c0,6.7-0.2,10.9-0.5,12.6c-0.2,1.7-0.7,2.9-1.4,3.5c-0.8,0.7-2.1,1-4,1l-1-2.1
            c2,0,3.2-0.2,3.6-0.6c0.4-0.3,0.7-1,0.9-2.2c0.3-1.3,0.4-4.7,0.5-10.2h-5.6c-0.5,1.1-0.9,2.2-1.4,3.1l-2-0.7c0.7-1,1.2-2.1,1.7-3.3
            c0.6-1.5,1-3.3,1.4-5.2L58.3,721.6z M60.4,734.6l-1.7,1.5c-1.1-1.5-2.1-2.7-3.1-3.6l1.5-1.4C58.3,732,59.4,733.2,60.4,734.6z
            M86.6,740.3c-1.9,2.2-4.3,3.3-7.4,3.3c-3.4,0-6-1.3-7.7-4c-1.6-2.5-2.5-6.1-2.5-10.8c0-5.1,0.8-9,2.6-11.8
            c1.7-2.8,4.2-4.2,7.6-4.2c4.2,0,7.1,2.1,8.8,6.4l-3.8,1.5c-0.6-1.4-1.2-2.3-1.7-2.8c-0.7-0.7-1.8-1-3.2-1c-3.4,0-5.3,3.1-5.8,9.3
            c1.9-1.5,3.9-2.2,6-2.2c3.1,0,5.4,1,7.2,2.8c1.6,1.7,2.4,4,2.4,6.7C89,736.1,88.2,738.3,86.6,740.3z M79.4,728.1
            c-1.2,0-2.3,0.3-3.5,0.8c-1.1,0.5-2,1.2-2.6,2.1c0.3,5.8,2.2,8.7,5.8,8.7c1.7,0,3.1-0.6,4.2-1.9c0.9-1.1,1.3-2.5,1.3-4.2
            C84.7,729.9,82.9,728.1,79.4,728.1z M99.3,722c0.1,0,0.2,0.1,0.2,0.2c0,0-0.1,0.1-0.4,0.2c-0.4,0.9-0.8,1.8-1.3,2.5
            c-0.5,0.7-1,1.2-1.6,1.8l-1-0.7c0.7-0.6,1.3-1.2,1.7-1.8c0.4-0.6,0.9-1.4,1.2-2.4L99.3,722z M103.7,725.4c0,2.3-0.1,3.8-0.3,4.3
            c-0.1,0.6-0.4,1-0.7,1.2c-0.4,0.2-1,0.4-1.9,0.4l-0.5-1.1c1,0,1.5-0.1,1.7-0.2c0.2-0.1,0.3-0.3,0.4-0.7c0.1-0.4,0.2-1.4,0.2-3h-2.4
            c-0.1,0.9-0.2,1.7-0.5,2.2c-0.3,0.7-0.7,1.2-1.1,1.6c-0.4,0.4-1,0.8-1.7,1.2l-1-0.8c0.8-0.3,1.3-0.6,1.7-1c0.4-0.4,0.7-0.8,1-1.4
            c0.2-0.5,0.4-1.1,0.5-1.9h-1.7v-1H103.7z M103.1,721.2c0.1,1.2,0.4,2.1,1,2.8c0.4,0.5,1.1,1.1,2,1.8l-1,0.7c-1-0.8-1.8-1.6-2.3-2.3
            c-0.4-0.5-0.6-1.2-0.8-2h-2.3v-1H103.1z M98.5,733.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
            c0.5,0.4,1.1,0.8,1.7,1.1l-0.7,0.7c-0.6-0.4-1.1-0.8-1.5-1.2c-0.2,0.3-0.5,0.5-0.7,0.7c-0.2,0.2-0.5,0.5-0.7,0.7h2.9v0.9h-1.1v1.2
            h1.5v0.9h-1.5v3.1c0.5-0.1,1-0.1,1.5-0.2v0.8c-0.8,0.1-2,0.3-3.7,0.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.2l-0.2-1
            c0.6,0,1.2-0.1,1.8-0.2v-3.2h-1.6v-0.9h1.6v-1.2H96V736c-0.2,0.1-0.3,0.3-0.5,0.4l-0.7-0.7c0.6-0.4,1.1-0.9,1.5-1.4
            c0.4-0.4,0.8-0.9,1.2-1.6L98.5,733.2z M97,741.2l-0.7,0.4c-0.2-0.6-0.5-1.1-0.8-1.7l0.8-0.4C96.5,740.1,96.7,740.6,97,741.2z
            M99.7,739.8c0.1,0,0.2,0.1,0.2,0.1c0,0-0.1,0.1-0.2,0.2c-0.2,0.4-0.4,0.8-0.6,1.3l-0.6-0.2c0.1-0.3,0.2-0.5,0.3-0.7
            c0.1-0.3,0.2-0.7,0.3-1L99.7,739.8z M103.3,732.8c0.2,0,0.3,0.1,0.3,0.2c0,0-0.1,0.1-0.2,0.2v0.4h2.3v0.8h-0.8l-0.4,1h1.3v0.8h-6.1
            v-0.8h1.6l-0.4-1H100v-0.8h2.4v-0.9L103.3,732.8z M105.4,736.8v3.6h-2.2v0.6h2.3v0.8h-2.3v0.7h2.6v0.8h-6.1v-0.8h2.5v-0.7h-2.2
            v-0.8h2.2v-0.6h-2.2v-3.6H105.4z M102.3,738.2v-0.7H101v0.7H102.3z M102.3,738.9H101v0.7h1.2V738.9z M103.5,735.3l0.4-1h-2.1l0.4,1
            H103.5z M103.2,738.2h1.2v-0.7h-1.2V738.2z M104.5,738.9h-1.2v0.7h1.2V738.9z"/>
          <path class="st6" d="M179.2,726.7c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.2-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7
            c1-1,2.2-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7C180.8,724.5,180.3,725.7,179.2,726.7z M187.8,722.5l-1.3,1.4
            c-1.1-1-2.2-1.8-3.4-2.7l1.4-1.4C185.8,720.7,186.9,721.6,187.8,722.5z M189,727.4c-1.2,2.1-2.4,4.1-3.7,5.9c0,0.5-0.2,0.7-0.4,0.7
            l-1.7-1.4c1.7-2,3.2-4.2,4.6-6.7L189,727.4z M189.2,716.4l-1.3,1.4c-1-1-2.1-1.8-3.3-2.6l1.4-1.4
            C187.2,714.6,188.3,715.5,189.2,716.4z M197.2,714c0.3,0,0.5,0.1,0.5,0.2c0,0.2-0.2,0.4-0.5,0.6v0.9c0,0.6,0,1.1-0.1,1.5
            c0.1,0.6,0.3,1.1,0.6,1.5c0.5,0.9,1.3,1.7,2.3,2.3c0.9,0.6,2.1,1.2,3.5,1.6l-1.7,1.4c-1.4-0.6-2.4-1.3-3.1-1.9
            c-1-0.8-1.8-1.6-2.3-2.5c0,0,0,0.1,0,0.1c-0.5,0.9-1.3,1.8-2.5,2.6c-0.9,0.6-1.9,1.2-3,1.7l-1.6-1.4c1.2-0.5,2.1-0.9,2.8-1.3
            c1.1-0.7,1.8-1.3,2.3-2c0.7-1,1-2.2,1-3.7v-0.3c0-0.4,0-0.9-0.1-1.5L197.2,714z M197,723.2c0.3,0,0.5,0.1,0.5,0.2
            c0,0.2-0.2,0.4-0.5,0.6v1.6c0,0.4,0,0.7,0,1c0.1,0.6,0.3,1.2,0.6,1.6c0.5,1,1.3,1.9,2.3,2.6c0.9,0.7,2.1,1.3,3.5,1.8l-1.7,1.4
            c-1.4-0.7-2.4-1.4-3.1-2.1c-1-0.8-1.7-1.7-2.2-2.6c0,0.1-0.1,0.2-0.1,0.3c-0.4,0.9-1.2,1.8-2.3,2.6c-0.8,0.6-1.8,1.2-2.9,1.7
            l-1.6-1.4c1.1-0.5,2-0.9,2.6-1.3c1-0.6,1.7-1.3,2.1-2c0.6-1,0.9-2.2,0.9-3.7v-1.1c0-0.4,0-0.9-0.1-1.5L197,723.2z M193,715.7
            c0.2,0,0.3,0.1,0.3,0.2c0,0.2-0.1,0.3-0.4,0.5c-0.4,1.3-0.9,2.4-1.5,3.3l-1.8-0.6c0.4-0.5,0.7-1,0.9-1.5c0.3-0.7,0.6-1.5,0.8-2.3
            L193,715.7z M192.9,725.4c0.2,0,0.3,0.1,0.3,0.2c0,0.2-0.1,0.3-0.4,0.5c-0.4,1.3-0.8,2.5-1.3,3.4l-1.8-0.6c0.3-0.5,0.6-1,0.8-1.6
            c0.3-0.7,0.5-1.6,0.7-2.5L192.9,725.4z M202.6,725.6c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.3-0.2,0.4c-0.6,1.2-1.3,2.2-1.9,3
            l-1.8-0.9c0.9-0.9,1.6-2.1,2.1-3.4L202.6,725.6z M202.7,715.6c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.4
            c-0.6,1.1-1.3,2.1-2,2.9l-1.8-0.9c0.9-0.9,1.6-2.1,2.2-3.4L202.7,715.6z M213.3,718.9c0.3-0.2,0.5-0.4,0.7-0.4
            c0.1,0,0.6,0.5,1.6,1.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.2-0.5,0.9-0.8,2c-0.8,2.5-1.7,4.3-2.6,5.4
            c-1,1.4-2.4,2.7-4.1,3.6l-1.4-1.7c1.1-0.5,2-1.2,2.8-1.9c0.7-0.7,1.4-1.6,1.9-2.6c0.6-1,1.1-2.5,1.7-4.6h-5.3v-1.9H213.3z
            M219.2,714.1c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.4-0.5,0.6v4c0.3,0.8,0.5,1.5,0.9,2.1c2-1.2,3.7-2.4,5.1-3.6l1.6,1.6
            c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.2,0.3-0.7,0.3c-1.7,1.1-3.4,2.1-5.3,3.2c0.5,0.9,0.9,1.6,1.5,2.3c1.4,1.9,3.3,3.5,5.6,5l-2.1,1.6
            c-1.7-1.3-3.2-2.7-4.4-4.4c-0.8-1-1.6-2.3-2.3-3.7v7.7c0,0.9-0.3,1.7-0.9,2.1c-0.5,0.5-1.9,0.7-4,0.7l-0.9-2.1h2.4
            c0.8,0,1.3-0.4,1.3-1.1v-17.1L219.2,714.1z M235.8,721.9l-1.3,1.4c-1.1-0.8-2.3-1.6-3.6-2.3l1.4-1.4
            C233.7,720.3,234.9,721.1,235.8,721.9z M236.6,726.7c-1.1,2.3-2.2,4.4-3.3,6.3c0,0.5-0.2,0.8-0.5,0.8l-1.8-1.5
            c1.6-2.1,3.1-4.6,4.4-7.2L236.6,726.7z M236.5,716.5l-1.3,1.4c-1.1-0.9-2.3-1.7-3.5-2.4l1.4-1.4
            C234.5,714.9,235.6,715.7,236.5,716.5z M251.3,715.1v1.9h-1.6v14.3c0,0.9-0.3,1.5-1,2c-0.5,0.4-1.9,0.7-4,0.7l-0.8-2h2.4
            c0.8,0,1.3-0.3,1.4-1v-13.9h-10.6v-1.9H251.3z M245.1,720.1v8.6h-1.9v-1.5h-3.2v2H238v-9.1H245.1z M243.1,725.4v-3.6h-3.2v3.6
            H243.1z M258.7,723.9c0,2.1-0.2,3.9-0.5,5.5c-0.2,1.2-0.7,2.7-1.4,4.6l-2-1c0.7-1.6,1.1-2.9,1.4-4.1c0.3-1.7,0.5-3.4,0.5-5v-3.2
            h18.2v1.9h-16.2V723.9z M266.5,713.9c0.4,0,0.6,0.1,0.6,0.3c0,0.1-0.2,0.3-0.4,0.5v3.1h5.1v-3l1.8,0.2c0.4,0,0.5,0.1,0.5,0.3
            c0,0.1-0.1,0.3-0.4,0.5v3.7h-16.4v-4.7l1.8,0.2c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.3-0.3,0.5v2h5.1v-4.1L266.5,713.9z M273.2,724.5
            v1.8H268v2.2h6.4v1.8H268v3.6h-2v-3.6h-6.4v-1.8h6.4v-2.2h-5.2v-1.8H273.2z M290.2,714c0.3,0,0.5,0.2,0.5,0.3
            c0,0.2-0.2,0.4-0.5,0.6l-1.1,1.9h7.4v17h-2v-1.2h-10.7v1.2h-2v-17h5l1.7-3.3L290.2,714z M294.4,721.6v-2.8h-10.7v2.8H294.4z
            M294.4,726.2v-2.7h-10.7v2.7H294.4z M294.4,730.7v-2.6h-10.7v2.6H294.4z M311.2,718.8c0.2,0.1,0.4,0.2,0.4,0.3
            c0,0.1-0.1,0.2-0.2,0.2c-0.3,0.1-0.9,0.7-1.8,1.9c-0.4,0.5-0.8,1-1.2,1.4V734h-2.1v-9.2c-0.6,0.6-1.2,1.1-1.9,1.7l-1.9-1.1
            c2.9-2,5.2-4.4,7-7.3L311.2,718.8z M310,714.6c0.2,0.1,0.4,0.2,0.4,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.2,0-0.7,0.5-1.4,1.2
            c-1.1,1.2-2.5,2.3-4.1,3.3l-1.9-1.1c2.3-1.3,4.1-2.8,5.5-4.6L310,714.6z M322.9,721.3v1.8h-3.8v8.6c0,0.8-0.3,1.4-0.9,1.8
            c-0.5,0.4-1.8,0.6-3.8,0.6l-0.9-2.2h2.1c0.7,0,1.1-0.2,1.2-0.6v-8.3h-6.2v-1.8H322.9z M322,715.2v1.9h-9.8v-1.9H322z M335.8,715.7
            v-2.1l1.8,0.2c0.3,0,0.5,0.1,0.5,0.3c0,0.1-0.1,0.2-0.4,0.5v1.2h8v1.8h-8v1.6h6.2v8.5h-6.2v1.7h8.9v1.8h-8.9v3.1h-1.9v-3.1h-9v-1.8
            h9v-1.7h-6v-8.5h6v-1.6h-8.2v-1.8H335.8z M335.8,722.5v-1.8h-4v1.8H335.8z M335.8,724.1h-4v1.8h4V724.1z M337.7,722.5h4.2v-1.8
            h-4.2V722.5z M342,724.1h-4.2v1.8h4.2V724.1z M355.2,723.4v6.7c0.5,0.8,1.2,1.3,2.2,1.6c1.1,0.4,2.8,0.5,5.1,0.5
            c3.2,0,6.1-0.1,8.7-0.3l-0.7,2.2h-8c-2,0-3.6-0.2-4.7-0.4c-1.2-0.3-2.2-0.8-2.9-1.4c-0.3-0.3-0.6-0.6-0.8-1l-2.1,2.2
            c-0.1,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.3-0.1-0.3-0.3L350,732c1.2-0.7,2.2-1.4,3.1-2.1v-4.7h-1.6v-1.8H355.2z M355.3,720.5l-1.3,1.4
            c-1.1-0.9-2.4-1.7-3.7-2.4l1.4-1.4C353.1,718.9,354.3,719.6,355.3,720.5z M355.7,716l-1.3,1.4c-1-0.8-2-1.5-3.2-2.2l1.4-1.4
            C353.8,714.5,354.9,715.2,355.7,716z M362.3,716.4l-0.8,0.4h2.7l1.9-3l1.6,0.3c0.3,0,0.4,0.2,0.4,0.3c0,0.2-0.2,0.4-0.4,0.6
            l-1.3,1.8h3.6v1.7h-5.8l-0.7,1.6h5.2v11.3h-1.9v-1h-6.9v1H358v-11.3h3.4l0.7-1.6h-5.5v-1.7h3.8c-0.6-0.8-1.3-1.6-2-2.4l1.7-0.8
            C360.9,714.6,361.6,715.5,362.3,716.4z M366.8,723.1v-1.5h-6.9v1.5H366.8z M366.8,726v-1.4h-6.9v1.4H366.8z M366.8,728.8v-1.2h-6.9
            v1.2H366.8z"/>
        </g>
      </svg>
      <img
        src="./s4/m.png"
        alt=""
        class="img absolute"
        v-if="isMobile"
        data-aos="fade-right"
        data-aos-delay="0"
      >

      <img
        src="./s1/資產 2914.png"
        alt=""
        class="red absolute"
        v-if="isMobile"
        data-aos="fade-right"
        data-aos-delay="0"
      >
      <div class="tree absolute"
        data-aos="fade-left"
        data-aos-delay="600">
      <img
        src="./s4/資產 2014.png"
        alt="tree"
      ></div>
      <div
        class="title absolute"
        data-aos="fade-right"
        data-aos-delay="400"
      >6分鐘 生活圈全滿足</div>
      <div
        class="swipe absolute"
        data-aos="fade-up"
        data-aos-delay="200"
        @mouseenter.stop="toggleTimer = false"
        @mouseleave.stop="toggleTimer = true"
      >
        <div
          class="swipe-wrap relative"
          v-touch:swipe.left="decIndex"
          v-touch:swipe.right="addIndex"
        >
          <transition-group
            name="swipe-fade"
            mode="out-in"
          >
            <div
              v-for="(slide, i) in slideList"
              v-show="slideIndex === i"
              :key="slide.img"
              :class="`swipe-item absolute`"
            >
              <img
                :src="slide.img"
                alt=""
              >
            </div>
          </transition-group>
          <div
            class="swipe-btns absolute flex-ac flex-jb"
            v-if="isMobile"
          >
            <img
              src="./all/prev-btn.png"
              alt=""
              class="prev-btn"
              @click="decIndex"
            >
            <img
              src="./all/next-btn.png"
              alt=""
              class="next-btn"
              @click="addIndex"
            >
          </div>
        </div>
      </div>
      <div
        class="pagination absolute flex-ac"
        data-aos="fade-up"
        data-aos-delay="200"
        v-if="isPC"
      >
        <div
          :class="`pagination-dot`"
          v-for="(slide, index) in slideList"
          :key="slide.img + '-dot'"
          @click="goTo(index)"
        ><span :class="`${slideIndex === index ? 'active' : ''}`"></span></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#s4{
	.st0{fill:none;stroke:#CD0012;stroke-width:3;stroke-miterlimit:10;animation: letterDraw 5s linear 0.6s forwards;
    stroke-dasharray:540;
    stroke-dashoffset:540;}
	.st1{fill:none;stroke:#CD0012;stroke-width:3;stroke-miterlimit:10;animation: letterDraw 1s .6s forwards;
    stroke-dasharray:420;transform: rotate(90deg);transform-origin: 75.97px 79px;
    stroke-dashoffset: 420;}
	.st2{fill:#3E3A39;}
	.st3{fill:#CD0012;}
	.st4{fill:#CD001200;stroke:#CD0012;stroke-width:3;stroke-miterlimit:10;
    stroke-dasharray: 310;
    stroke-dashoffset:310;}
	.an1{animation: letterDraw2 1s 1s forwards;transform: rotate(-90deg);transform-origin: 75.97px 229.92px;}
	.an2{animation: letterDraw2 1s 1.5s forwards;transform: rotate(-90deg);transform-origin: 75.97px 352.864px;}
	.an3{animation: letterDraw2 1s 2s forwards;transform: rotate(-90deg);transform-origin: 75.97px 475.8px;}
	.an4{animation: letterDraw2 1s 2.5s forwards;transform: rotate(-90deg);transform-origin: 75.97px 598.74px;}
  .an5{animation: letterDraw2 1s 3s forwards;transform: rotate(-90deg);transform-origin: 75.97px 721.68px;}
  .ant0{animation: op 0.5s 0.6s forwards;opacity: 0}
  .ant1{animation: op 0.5s 1.5s forwards;opacity: 0}
  .ant2{animation: op 0.5s 2s forwards;opacity: 0}
  .ant3{animation: op 0.5s 2.5s forwards;opacity: 0}
  .ant4{animation: op 0.5s 3s forwards;opacity: 0}
  .ant5{animation: op 0.5s 3.5s forwards;opacity: 0}
	.st5{fill:#FFFFFF;}
	.st6{fill:#4D4D4D;}
}
@keyframes letterDraw {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes letterDraw2 {
    50% {
        stroke-dashoffset: 0;fill:#CD001200;
    }
    to {
        stroke-dashoffset: 0;fill:#CD0012;
    }
}
@keyframes op {
    to {
        opacity: 1
    }
}
  </style>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section4 {
  width: size(1920);
  height: size(971);
  // min-height: size(900);
  // background-image: url('./s2/bg.jpg');
  // background-size: 100% 100%;
  // background-position: 0 0;
  // background-attachment: fixed;
  // overflow: hidden;
}

.bg-img {
  width: 100vw;
  height: 100vh;
  min-height: size(900);
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  object-fit: cover;

  &:nth-child(1) {
    position: relative;
  }
}

.img {
  width: size(582);
  top: size(62);
  right: size(178);
}

.tree {
  width: size(534);
  top: size(525);
  left: size(0);
  z-index: 1;
  img{width: 100%;}
}

.title {
  width: size(399);
  top: size(98);
  right: size(191);
  font-size: size(42);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.49;
  letter-spacing: 2.54px;
  text-align: left;
  color: #000000;
  white-space: nowrap;
}

/* Swipe */
.swipe {
  width: size(889);
  height: size(751);
  min-height: size(751);
  top: size(87);
  left: size(178);
  object-fit: cover;
}

// begin
.swipe-fade-leave-to {
  opacity: 0;
  z-index: 0;
}
// end
.swipe-fade-enter {
  opacity: 0;
  z-index: 1;
}

.swipe-fade-enter-active {
  transition: all 0.5s ease;
}

.swipe-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

// begin
// .swipe-left-leave-to {
//   margin-left: -100vw;
//   z-index: 0;
// }
// // end
// .swipe-left-enter {
//   opacity: 0.5;
//   margin-left: 0;
//   z-index: 1;
// }

// .swipe-left-enter-active {
//   transition: all 0.5s ease;
// }

// .swipe-left-leave-active {
//   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
// }

.swipe-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swipe-item {
  width: 100%;
  height: 100%;
  z-index: 0;

  img {
    width: 100%;
  }

  // &:nth-child(1) {
  //   z-index: 1;
  //   // opacity: 1;
  // }

  // &.base {
  //   z-index: 1;
  //   opacity: 1;
  // }
  // &.active {
  //   z-index: 2;
  //   // opacity: 1;
  // }
}

.pagination {
  width: auto;
  bottom: size(91);
  left: 0;
  right: 0;
  margin: 0 auto;
  justify-content: center;
}

.pagination-dot {
  padding: 5px;
  margin: 0 10px;
  cursor: pointer;
  z-index: 4;

  span {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    box-shadow: 0 0 0 1px #bd2b27;
    position: relative;
    background-color: rgba(0, 0, 0, 0.01);
    transition: all 0.5s;

    &::before {
      content: '';
      width: 60%;
      height: 60%;
      display: block;
      background: #bd2b27;
      border-radius: 20px;
      opacity: 1;
      position: absolute;
      top: 20%;
      // transform: translateY(-50%);
      left: 20%;
      transition: all 0.3s;
      transform-origin: center;
      transform: scale(0);
    }
    &.active {
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: #bd2b27;
        border-radius: 20px;
        opacity: 1;
        position: absolute;
        top: 0%;
        // transform: translateY(-50%);
        left: 0%;
        transform: scale(1);
      }
    }
  }
}

.swipe-btns {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  z-index: 3;

  .prev-btn,
  .next-btn {
    width: size(20);
    cursor: pointer;
  }
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section4 {
    width: 100vw;
    height: sizem(279 + 382);
    min-height: auto;
    // background-image: url('./s2/bg.jpg');
    // background-size: 100% 100%;
    // background-position: 0 0;
    // background-attachment: fixed;
    overflow: hidden;
  }

  .img {
    width: sizem(283);
    top: sizem(14.5);
    left: sizem(24);
    z-index: 1;
  }

  .red {
    width: sizem(108);
    top: sizem(181);
    right: sizem(-50);
    z-index: 1;
  }

  .title {
    width: sizem(238);
    top: sizem(21);
    left: sizem(105);
    font-size: sizem(25);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 1.5px;
    text-align: left;
    color: #000000;
  }

  .tree {
    width: sizem(202);
    top: sizem(287);
    left:auto;
    right: sizem(-45);
    z-index: 2;
    img{transform: scaleX(-1);}
  }

  /* Swipe */
  .swipe {
    width: sizem(330);
    height: sizem(279);
    min-height: sizem(279);
    top: sizem(382);
    left: sizem(23);
    object-fit: cover;
  }

  // begin
  .swipe-fade-leave-to {
    opacity: 0;
    z-index: 0;
  }
  // end
  .swipe-fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .swipe-fade-enter-active {
    transition: all 0.5s ease;
  }

  .swipe-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  // begin
  // .swipe-left-leave-to {
  //   margin-left: -100vw;
  //   z-index: 0;
  // }
  // // end
  // .swipe-left-enter {
  //   opacity: 0.5;
  //   margin-left: 0;
  //   z-index: 1;
  // }

  // .swipe-left-enter-active {
  //   transition: all 0.5s ease;
  // }

  // .swipe-left-leave-active {
  //   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  // }

  .swipe-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .swipe-item {
    width: 100%;
    height: 100%;
    z-index: 0;

    img {
      width: 100%;
    }

    // &:nth-child(1) {
    //   z-index: 1;
    //   // opacity: 1;
    // }

    // &.base {
    //   z-index: 1;
    //   opacity: 1;
    // }
    // &.active {
    //   z-index: 2;
    //   // opacity: 1;
    // }
  }

  .pagination {
    width: auto;
    bottom: size(91);
    left: 0;
    right: 0;
    margin: 0 auto;
    justify-content: center;
  }

  .pagination-dot {
    padding: 5px;
    margin: 0 10px;
    cursor: pointer;
    z-index: 4;

    span {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      box-shadow: 0 0 0 1px #bd2b27;
      position: relative;
      background-color: rgba(0, 0, 0, 0.01);
      transition: all 0.5s;

      &::before {
        content: '';
        width: 60%;
        height: 60%;
        display: block;
        background: #bd2b27;
        border-radius: 20px;
        opacity: 1;
        position: absolute;
        top: 20%;
        // transform: translateY(-50%);
        left: 20%;
        transition: all 0.3s;
        transform-origin: center;
        transform: scale(0);
      }
      &.active {
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          background: #bd2b27;
          border-radius: 20px;
          opacity: 1;
          position: absolute;
          top: 0%;
          // transform: translateY(-50%);
          left: 0%;
          transform: scale(1);
        }
      }
    }
  }

  .swipe-btns {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    z-index: 3;

    .prev-btn,
    .next-btn {
      width: sizem(15);
      cursor: pointer;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'
import slider from '@/mixins/slider.js'

export default {
  name: 'section4',

  mixins: [slider],

  data() {
    return {
      isPC,
      isMobile,
      isTablet,
      isDialog: false,
      slideList: [
        {
          img: require('./s4/資產 1414.jpg'),
        },
        {
          img: require('./s4/資產 1614.jpg'),
        },
        {
          img: require('./s4/資產 1714.jpg'),
        },
      ],
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},
}
</script>
