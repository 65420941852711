<template>
  <div>
    <div
      class="section1"
      v-if="isPC"
    >
      <transition
        name="swipe-fade"
        mode="out-in"
      >
        <div
          class="all"
          v-if="isShow"
        >
          <svg
            version="1.1"
            id="s1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-140 63.5 2200 1207"
            style="enable-background:new -140 63.5 2200 1207"
            xml:space="preserve"
          >
            <g class="right_1">
              <path
                class="st0 an4"
                d="M1964.6,219c-1.2-1.2-1.2-3.1,0-4.2c1.2-1.2,3.1-1.2,4.2,0c1.2,1.2,1.2,3.1,0,4.2
            C1967.7,220.2,1965.8,220.2,1964.6,219z M1990.1,219c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,220.2,1988.9,220.2,1990.1,219z M2011.4,219c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,220.2,2010.2,220.2,2011.4,219z M2032.6,219c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,220.2,2031.4,220.2,2032.6,219z M2053.8,219c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,220.2,2052.6,220.2,2053.8,219z M2075,219c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2072,220.2,2073.9,220.2,2075,219z M2096.3,219c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2093.2,220.2,2095.1,220.2,2096.3,219z M1968.9,197.8c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,199,1967.7,199,1968.9,197.8z M1990.1,197.8c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,199,1988.9,199,1990.1,197.8z M2011.4,197.8c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,199,2010.2,199,2011.4,197.8z M2032.6,197.8c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,199,2031.4,199,2032.6,197.8z M2053.8,197.8c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,199,2052.6,199,2053.8,197.8z M2075,197.8c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2072,199,2073.9,199,2075,197.8z M2096.3,197.8c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2093.2,199,2095.1,199,2096.3,197.8z M1968.9,176.6c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,177.8,1967.7,177.8,1968.9,176.6z M1990.1,176.6c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,177.8,1988.9,177.8,1990.1,176.6z M2011.4,176.6c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,177.8,2010.2,177.8,2011.4,176.6z M2032.6,176.6c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,177.8,2031.4,177.8,2032.6,176.6z M2053.8,176.6c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,177.8,2052.6,177.8,2053.8,176.6z M2075,176.6c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2072,177.8,2073.9,177.8,2075,176.6z M2096.3,176.6c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2093.2,177.8,2095.1,177.8,2096.3,176.6z M1968.9,155.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,156.5,1967.7,156.5,1968.9,155.4z M1990.1,155.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,156.5,1988.9,156.5,1990.1,155.4z M2011.4,155.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,156.5,2010.2,156.5,2011.4,155.4z M2032.6,155.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,156.5,2031.4,156.5,2032.6,155.4z M2053.8,155.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,156.5,2052.6,156.5,2053.8,155.4z M2075,155.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2072,156.5,2073.9,156.5,2075,155.4z M2096.3,155.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2093.2,156.5,2095.1,156.5,2096.3,155.4z M1968.9,134.1c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,135.3,1967.7,135.3,1968.9,134.1z M1990.1,134.1c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,135.3,1988.9,135.3,1990.1,134.1z M2011.4,134.1c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,135.3,2010.2,135.3,2011.4,134.1z M2032.6,134.1c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,135.3,2031.4,135.3,2032.6,134.1z M2053.8,134.1c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,135.3,2052.6,135.3,2053.8,134.1z M2075,134.1c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2072,135.3,2073.9,135.3,2075,134.1z M1968.9,112.9c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,114.1,1967.7,114.1,1968.9,112.9z M1990.1,112.9c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,114.1,1988.9,114.1,1990.1,112.9z M2011.4,112.9c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,114.1,2010.2,114.1,2011.4,112.9z M2032.6,112.9c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,114.1,2031.4,114.1,2032.6,112.9z M2053.8,112.9c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,114.1,2052.6,114.1,2053.8,112.9z M1968.9,91.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,92.8,1967.7,92.8,1968.9,91.7z M1990.1,91.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,92.8,1988.9,92.8,1990.1,91.7z M2011.4,91.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,92.8,2010.2,92.8,2011.4,91.7z M2032.6,91.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,92.8,2031.4,92.8,2032.6,91.7z M1968.9,70.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,71.6,1967.7,71.6,1968.9,70.4z M1990.1,70.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,71.6,1988.9,71.6,1990.1,70.4z M1968.9,49.1c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,50.3,1967.7,50.3,1968.9,49.1z M1990.1,49.1c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,50.3,1988.9,50.3,1990.1,49.1z M2011.4,70.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,71.6,2010.2,71.6,2011.4,70.4z M2032.6,70.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,71.6,2031.4,71.6,2032.6,70.4z M2053.8,91.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,92.8,2052.6,92.8,2053.8,91.7z M2075,112.9c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2072,114.1,2073.9,114.1,2075,112.9z M2096.3,134.1c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2093.2,135.3,2095.1,135.3,2096.3,134.1z M1968.9,240.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,241.5,1967.7,241.5,1968.9,240.3z M1990.1,240.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,241.5,1988.9,241.5,1990.1,240.3z M2011.4,240.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,241.5,2010.2,241.5,2011.4,240.3z M2032.6,240.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,241.5,2031.4,241.5,2032.6,240.3z M2053.8,240.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,241.5,2052.6,241.5,2053.8,240.3z M2075,240.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2072,241.5,2073.9,241.5,2075,240.3z M2096.3,240.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2093.2,241.5,2095.1,241.5,2096.3,240.3z M1968.9,261.5c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,262.7,1967.7,262.7,1968.9,261.5z M1990.1,261.5c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,262.7,1988.9,262.7,1990.1,261.5z M2011.4,261.5c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,262.7,2010.2,262.7,2011.4,261.5z M2032.6,261.5c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,262.7,2031.4,262.7,2032.6,261.5z M2053.8,261.5c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,262.7,2052.6,262.7,2053.8,261.5z M2075,261.5c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2072,262.7,2073.9,262.7,2075,261.5z M1968.9,282.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,283.9,1967.7,283.9,1968.9,282.7z M1990.1,282.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,283.9,1988.9,283.9,1990.1,282.7z M2011.4,282.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,283.9,2010.2,283.9,2011.4,282.7z M2032.6,282.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,283.9,2031.4,283.9,2032.6,282.7z M2053.8,282.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,283.9,2052.6,283.9,2053.8,282.7z M1968.9,304c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,305.2,1967.7,305.2,1968.9,304z M1990.1,304c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,305.2,1988.9,305.2,1990.1,304z M2011.4,304c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,305.2,2010.2,305.2,2011.4,304z M2032.6,304c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,305.2,2031.4,305.2,2032.6,304z M1968.9,325.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,326.4,1967.7,326.4,1968.9,325.3z M1990.1,325.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,326.4,1988.9,326.4,1990.1,325.3z M1968.9,346.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1965.8,347.6,1967.7,347.6,1968.9,346.4z M1990.1,346.4c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C1987,347.6,1988.9,347.6,1990.1,346.4z M2011.4,325.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2008.3,326.4,2010.2,326.4,2011.4,325.3z M2032.6,325.3c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2029.5,326.4,2031.4,326.4,2032.6,325.3z M2053.8,304c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2050.7,305.2,2052.6,305.2,2053.8,304z M2075,282.7c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2072,283.9,2073.9,283.9,2075,282.7z M2096.3,261.5c1.2-1.2,1.2-3.1,0-4.2c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2
            C2093.2,262.7,2095.1,262.7,2096.3,261.5z M1944.1,172.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,171.1,1945.3,171.1,1944.1,172.3z M1922.9,172.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,171.1,1924,171.1,1922.9,172.3z M1901.6,172.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,171.1,1902.8,171.1,1901.6,172.3z M1880.4,172.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,171.1,1881.6,171.1,1880.4,172.3z M1859.2,172.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,171.1,1860.3,171.1,1859.2,172.3z M1837.9,172.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,171.1,1839.1,171.1,1837.9,172.3z M1816.7,172.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1819.8,171.1,1817.9,171.1,1816.7,172.3z M1795.5,172.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1798.5,171.1,1796.6,171.1,1795.5,172.3z M1944.1,193.5c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,192.3,1945.3,192.3,1944.1,193.5z M1922.9,193.5c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,192.3,1924,192.3,1922.9,193.5z M1901.6,193.5c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,192.3,1902.8,192.3,1901.6,193.5z M1880.4,193.5c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,192.3,1881.6,192.3,1880.4,193.5z M1859.2,193.5c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,192.3,1860.3,192.3,1859.2,193.5z M1837.9,193.5c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,192.3,1839.1,192.3,1837.9,193.5z M1816.7,193.5c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1819.8,192.3,1817.9,192.3,1816.7,193.5z M1795.5,193.5c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1798.5,192.3,1796.6,192.3,1795.5,193.5z M1944.1,214.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,213.6,1945.3,213.6,1944.1,214.7z M1922.9,214.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,213.6,1924,213.6,1922.9,214.7z M1901.6,214.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,213.6,1902.8,213.6,1901.6,214.7z M1880.4,214.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,213.6,1881.6,213.6,1880.4,214.7z M1859.2,214.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,213.6,1860.3,213.6,1859.2,214.7z M1837.9,214.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,213.6,1839.1,213.6,1837.9,214.7z M1816.7,214.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1819.8,213.6,1817.9,213.6,1816.7,214.7z M1795.5,214.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1798.5,213.6,1796.6,213.6,1795.5,214.7z M1944.1,236c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,234.8,1945.3,234.8,1944.1,236z M1922.9,236c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,234.8,1924,234.8,1922.9,236z M1901.6,236c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,234.8,1902.8,234.8,1901.6,236z M1880.4,236c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,234.8,1881.6,234.8,1880.4,236z M1859.2,236c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,234.8,1860.3,234.8,1859.2,236z M1837.9,236c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,234.8,1839.1,234.8,1837.9,236z M1816.7,236c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1819.8,234.8,1817.9,234.8,1816.7,236z M1795.5,236c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1798.5,234.8,1796.6,234.8,1795.5,236z M1944.1,257.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,256,1945.3,256,1944.1,257.2z M1922.9,257.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,256,1924,256,1922.9,257.2z M1901.6,257.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,256,1902.8,256,1901.6,257.2z M1880.4,257.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,256,1881.6,256,1880.4,257.2z M1859.2,257.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,256,1860.3,256,1859.2,257.2z M1837.9,257.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,256,1839.1,256,1837.9,257.2z M1816.7,257.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1819.8,256,1817.9,256,1816.7,257.2z M1944.1,278.4c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,277.3,1945.3,277.3,1944.1,278.4z M1922.9,278.4c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,277.3,1924,277.3,1922.9,278.4z M1901.6,278.4c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,277.3,1902.8,277.3,1901.6,278.4z M1880.4,278.4c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,277.3,1881.6,277.3,1880.4,278.4z M1859.2,278.4c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,277.3,1860.3,277.3,1859.2,278.4z M1837.9,278.4c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,277.3,1839.1,277.3,1837.9,278.4z M1944.1,299.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,298.5,1945.3,298.5,1944.1,299.7z M1922.9,299.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,298.5,1924,298.5,1922.9,299.7z M1901.6,299.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,298.5,1902.8,298.5,1901.6,299.7z M1880.4,299.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,298.5,1881.6,298.5,1880.4,299.7z M1859.2,299.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,298.5,1860.3,298.5,1859.2,299.7z M1944.1,320.9c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,319.7,1945.3,319.7,1944.1,320.9z M1922.9,320.9c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,319.7,1924,319.7,1922.9,320.9z M1901.6,320.9c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,319.7,1902.8,319.7,1901.6,320.9z M1944.1,342.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,341,1945.3,341,1944.1,342.2z M1922.9,342.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,341,1924,341,1922.9,342.2z M1901.6,342.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,341,1902.8,341,1901.6,342.2z M1880.4,320.9c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,319.7,1881.6,319.7,1880.4,320.9z M1859.2,320.9c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,319.7,1860.3,319.7,1859.2,320.9z M1837.9,299.7c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,298.5,1839.1,298.5,1837.9,299.7z M1816.7,278.4c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1819.8,277.3,1817.9,277.3,1816.7,278.4z M1795.5,257.2c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1798.5,256,1796.6,256,1795.5,257.2z M1944.1,151c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,149.9,1945.3,149.9,1944.1,151z M1922.9,151c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,149.9,1924,149.9,1922.9,151z M1901.6,151c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,149.9,1902.8,149.9,1901.6,151z M1880.4,151c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,149.9,1881.6,149.9,1880.4,151z M1859.2,151c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,149.9,1860.3,149.9,1859.2,151z M1837.9,151c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,149.9,1839.1,149.9,1837.9,151z M1816.7,151c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1819.8,149.9,1817.9,149.9,1816.7,151z M1795.5,151c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1798.5,149.9,1796.6,149.9,1795.5,151z M1944.1,129.8c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,128.6,1945.3,128.6,1944.1,129.8z M1922.9,129.8c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,128.6,1924,128.6,1922.9,129.8z M1901.6,129.8c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,128.6,1902.8,128.6,1901.6,129.8z M1880.4,129.8c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,128.6,1881.6,128.6,1880.4,129.8z M1859.2,129.8c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,128.6,1860.3,128.6,1859.2,129.8z M1837.9,129.8c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,128.6,1839.1,128.6,1837.9,129.8z M1816.7,129.8c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1819.8,128.6,1817.9,128.6,1816.7,129.8z M1944.1,108.6c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,107.4,1945.3,107.4,1944.1,108.6z M1922.9,108.6c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,107.4,1924,107.4,1922.9,108.6z M1901.6,108.6c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,107.4,1902.8,107.4,1901.6,108.6z M1880.4,108.6c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,107.4,1881.6,107.4,1880.4,108.6z M1859.2,108.6c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,107.4,1860.3,107.4,1859.2,108.6z M1837.9,108.6c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,107.4,1839.1,107.4,1837.9,108.6z M1944.1,87.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,86.2,1945.3,86.2,1944.1,87.3z M1922.9,87.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,86.2,1924,86.2,1922.9,87.3z M1901.6,87.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,86.2,1902.8,86.2,1901.6,87.3z M1880.4,87.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,86.2,1881.6,86.2,1880.4,87.3z M1859.2,87.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,86.2,1860.3,86.2,1859.2,87.3z M1944.1,66.1c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,64.9,1945.3,64.9,1944.1,66.1z M1922.9,66.1c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,64.9,1924,64.9,1922.9,66.1z M1901.6,66.1c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,64.9,1902.8,64.9,1901.6,66.1z M1944.1,44.9c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1947.2,43.7,1945.3,43.7,1944.1,44.9z M1922.9,44.9c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1925.9,43.7,1924,43.7,1922.9,44.9z M1901.6,44.9c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1904.7,43.7,1902.8,43.7,1901.6,44.9z M1880.4,66.1c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1883.5,64.9,1881.6,64.9,1880.4,66.1z M1859.2,66.1c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1862.2,64.9,1860.3,64.9,1859.2,66.1z M1837.9,87.3c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1841,86.2,1839.1,86.2,1837.9,87.3z M1816.7,108.6c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1819.8,107.4,1817.9,107.4,1816.7,108.6z M1795.5,129.8c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2
            C1798.5,128.6,1796.6,128.6,1795.5,129.8z"
              />
            </g>
            <g class="bottom1">
              <path
                class="st0 an1"
                d="M867.1,1001.8c-1.2-1.2-1.2-3.2,0-4.4c1.2-1.2,3.2-1.2,4.4,0c1.2,1.2,1.2,3.2,0,4.4
          C870.3,1003,868.4,1003,867.1,1001.8z M893.6,1001.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,1003,892.4,1003,893.6,1001.8z M915.7,1001.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,1003,914.4,1003,915.7,1001.8z M937.7,1001.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,1003,936.5,1003,937.7,1001.8z M959.8,1001.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,1003,958.5,1003,959.8,1001.8z M981.8,1001.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C978.6,1003,980.6,1003,981.8,1001.8z M1003.9,1001.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C1000.7,1003,1002.6,1003,1003.9,1001.8z M871.6,979.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,981,870.3,981,871.6,979.7z M893.6,979.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,981,892.4,981,893.6,979.7z M915.7,979.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,981,914.4,981,915.7,979.7z M937.7,979.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,981,936.5,981,937.7,979.7z M959.8,979.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,981,958.5,981,959.8,979.7z M981.8,979.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C978.6,981,980.6,981,981.8,979.7z M1003.9,979.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C1000.7,981,1002.6,981,1003.9,979.7z M871.6,957.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,958.9,870.3,958.9,871.6,957.7z M893.6,957.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,958.9,892.4,958.9,893.6,957.7z M915.7,957.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,958.9,914.4,958.9,915.7,957.7z M937.7,957.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,958.9,936.5,958.9,937.7,957.7z M959.8,957.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,958.9,958.5,958.9,959.8,957.7z M981.8,957.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C978.6,958.9,980.6,958.9,981.8,957.7z M1003.9,957.7c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C1000.7,958.9,1002.6,958.9,1003.9,957.7z M871.6,935.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,936.9,870.3,936.9,871.6,935.6z M893.6,935.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,936.9,892.4,936.9,893.6,935.6z M915.7,935.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,936.9,914.4,936.9,915.7,935.6z M937.7,935.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,936.9,936.5,936.9,937.7,935.6z M959.8,935.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,936.9,958.5,936.9,959.8,935.6z M981.8,935.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C978.6,936.9,980.6,936.9,981.8,935.6z M1003.9,935.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C1000.7,936.9,1002.6,936.9,1003.9,935.6z M871.6,913.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,914.8,870.3,914.8,871.6,913.6z M893.6,913.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,914.8,892.4,914.8,893.6,913.6z M915.7,913.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,914.8,914.4,914.8,915.7,913.6z M937.7,913.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,914.8,936.5,914.8,937.7,913.6z M959.8,913.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,914.8,958.5,914.8,959.8,913.6z M981.8,913.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C978.6,914.8,980.6,914.8,981.8,913.6z M871.6,891.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,892.8,870.3,892.8,871.6,891.5z M893.6,891.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,892.8,892.4,892.8,893.6,891.5z M915.7,891.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,892.8,914.4,892.8,915.7,891.5z M937.7,891.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,892.8,936.5,892.8,937.7,891.5z M959.8,891.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,892.8,958.5,892.8,959.8,891.5z M871.6,869.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,870.7,870.3,870.7,871.6,869.5z M893.6,869.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,870.7,892.4,870.7,893.6,869.5z M915.7,869.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,870.7,914.4,870.7,915.7,869.5z M937.7,869.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,870.7,936.5,870.7,937.7,869.5z M871.6,847.4c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,848.7,870.3,848.7,871.6,847.4z M893.6,847.4c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,848.7,892.4,848.7,893.6,847.4z M871.6,825.3c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,826.5,870.3,826.5,871.6,825.3z M893.6,825.3c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,826.5,892.4,826.5,893.6,825.3z M915.7,847.4c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,848.7,914.4,848.7,915.7,847.4z M937.7,847.4c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,848.7,936.5,848.7,937.7,847.4z M959.8,869.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,870.7,958.5,870.7,959.8,869.5z M981.8,891.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C978.6,892.8,980.6,892.8,981.8,891.5z M1003.9,913.6c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C1000.7,914.8,1002.6,914.8,1003.9,913.6z M871.6,1023.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,1025.1,870.3,1025.1,871.6,1023.8z M893.6,1023.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,1025.1,892.4,1025.1,893.6,1023.8z M915.7,1023.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,1025.1,914.4,1025.1,915.7,1023.8z M937.7,1023.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,1025.1,936.5,1025.1,937.7,1023.8z M959.8,1023.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,1025.1,958.5,1025.1,959.8,1023.8z M981.8,1023.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C978.6,1025.1,980.6,1025.1,981.8,1023.8z M1003.9,1023.8c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C1000.7,1025.1,1002.6,1025.1,1003.9,1023.8z M871.6,1045.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,1047.1,870.3,1047.1,871.6,1045.9z M893.6,1045.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,1047.1,892.4,1047.1,893.6,1045.9z M915.7,1045.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,1047.1,914.4,1047.1,915.7,1045.9z M937.7,1045.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,1047.1,936.5,1047.1,937.7,1045.9z M959.8,1045.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,1047.1,958.5,1047.1,959.8,1045.9z M981.8,1045.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C978.6,1047.1,980.6,1047.1,981.8,1045.9z M871.6,1067.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,1069.2,870.3,1069.2,871.6,1067.9z M893.6,1067.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,1069.2,892.4,1069.2,893.6,1067.9z M915.7,1067.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,1069.2,914.4,1069.2,915.7,1067.9z M937.7,1067.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,1069.2,936.5,1069.2,937.7,1067.9z M959.8,1067.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,1069.2,958.5,1069.2,959.8,1067.9z M871.6,1090c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,1091.2,870.3,1091.2,871.6,1090z M893.6,1090c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,1091.2,892.4,1091.2,893.6,1090z M915.7,1090c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,1091.2,914.4,1091.2,915.7,1090z M937.7,1090c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,1091.2,936.5,1091.2,937.7,1090z M871.6,1112.1c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,1113.3,870.3,1113.3,871.6,1112.1z M893.6,1112.1c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,1113.3,892.4,1113.3,893.6,1112.1z M871.6,1134.1c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C868.4,1135.3,870.3,1135.3,871.6,1134.1z M893.6,1134.1c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C890.4,1135.3,892.4,1135.3,893.6,1134.1z M915.7,1112.1c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C912.5,1113.3,914.4,1113.3,915.7,1112.1z M937.7,1112.1c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C934.5,1113.3,936.5,1113.3,937.7,1112.1z M959.8,1090c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C956.6,1091.2,958.5,1091.2,959.8,1090z M981.8,1067.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C978.6,1069.2,980.6,1069.2,981.8,1067.9z M1003.9,1045.9c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
          C1000.7,1047.1,1002.6,1047.1,1003.9,1045.9z M845.8,953.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,952,847,952,845.8,953.2z M823.7,953.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,952,825,952,823.7,953.2z M801.7,953.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,952,802.9,952,801.7,953.2z M779.6,953.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,952,780.9,952,779.6,953.2z M757.6,953.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,952,758.8,952,757.6,953.2z M735.5,953.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,952,736.8,952,735.5,953.2z M713.5,953.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C716.7,952,714.7,952,713.5,953.2z M691.4,953.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C694.6,952,692.7,952,691.4,953.2z M845.8,975.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,974,847,974,845.8,975.3z M823.7,975.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,974,825,974,823.7,975.3z M801.7,975.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,974,802.9,974,801.7,975.3z M779.6,975.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,974,780.9,974,779.6,975.3z M757.6,975.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,974,758.8,974,757.6,975.3z M735.5,975.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,974,736.8,974,735.5,975.3z M713.5,975.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C716.7,974,714.7,974,713.5,975.3z M691.4,975.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C694.6,974,692.7,974,691.4,975.3z M845.8,997.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,996.1,847,996.1,845.8,997.3z M823.7,997.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,996.1,825,996.1,823.7,997.3z M801.7,997.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,996.1,802.9,996.1,801.7,997.3z M779.6,997.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,996.1,780.9,996.1,779.6,997.3z M757.6,997.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,996.1,758.8,996.1,757.6,997.3z M735.5,997.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,996.1,736.8,996.1,735.5,997.3z M713.5,997.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C716.7,996.1,714.7,996.1,713.5,997.3z M691.4,997.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C694.6,996.1,692.7,996.1,691.4,997.3z M845.8,1019.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,1018.1,847,1018.1,845.8,1019.3z M823.7,1019.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,1018.1,825,1018.1,823.7,1019.3z M801.7,1019.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,1018.1,802.9,1018.1,801.7,1019.3z M779.6,1019.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,1018.1,780.9,1018.1,779.6,1019.3z M757.6,1019.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,1018.1,758.8,1018.1,757.6,1019.3z M735.5,1019.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,1018.1,736.8,1018.1,735.5,1019.3z M713.5,1019.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C716.7,1018.1,714.7,1018.1,713.5,1019.3z M691.4,1019.3c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C694.6,1018.1,692.7,1018.1,691.4,1019.3z M845.8,1041.4c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,1040.2,847,1040.2,845.8,1041.4z M823.7,1041.4c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,1040.2,825,1040.2,823.7,1041.4z M801.7,1041.4c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,1040.2,802.9,1040.2,801.7,1041.4z M779.6,1041.4c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,1040.2,780.9,1040.2,779.6,1041.4z M757.6,1041.4c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,1040.2,758.8,1040.2,757.6,1041.4z M735.5,1041.4c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,1040.2,736.8,1040.2,735.5,1041.4z M713.5,1041.4c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C716.7,1040.2,714.7,1040.2,713.5,1041.4z M845.8,1063.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,1062.2,847,1062.2,845.8,1063.5z M823.7,1063.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,1062.2,825,1062.2,823.7,1063.5z M801.7,1063.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,1062.2,802.9,1062.2,801.7,1063.5z M779.6,1063.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,1062.2,780.9,1062.2,779.6,1063.5z M757.6,1063.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,1062.2,758.8,1062.2,757.6,1063.5z M735.5,1063.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,1062.2,736.8,1062.2,735.5,1063.5z M845.8,1085.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,1084.3,847,1084.3,845.8,1085.5z M823.7,1085.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,1084.3,825,1084.3,823.7,1085.5z M801.7,1085.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,1084.3,802.9,1084.3,801.7,1085.5z M779.6,1085.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,1084.3,780.9,1084.3,779.6,1085.5z M757.6,1085.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,1084.3,758.8,1084.3,757.6,1085.5z M845.8,1107.6c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,1106.3,847,1106.3,845.8,1107.6z M823.7,1107.6c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,1106.3,825,1106.3,823.7,1107.6z M801.7,1107.6c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,1106.3,802.9,1106.3,801.7,1107.6z M845.8,1129.7c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,1128.5,847,1128.5,845.8,1129.7z M823.7,1129.7c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,1128.5,825,1128.5,823.7,1129.7z M801.7,1129.7c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,1128.5,802.9,1128.5,801.7,1129.7z M779.6,1107.6c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,1106.3,780.9,1106.3,779.6,1107.6z M757.6,1107.6c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,1106.3,758.8,1106.3,757.6,1107.6z M735.5,1085.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,1084.3,736.8,1084.3,735.5,1085.5z M713.5,1063.5c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C716.7,1062.2,714.7,1062.2,713.5,1063.5z M691.4,1041.4c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C694.6,1040.2,692.7,1040.2,691.4,1041.4z M845.8,931.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,929.9,847,929.9,845.8,931.2z M823.7,931.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,929.9,825,929.9,823.7,931.2z M801.7,931.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,929.9,802.9,929.9,801.7,931.2z M779.6,931.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,929.9,780.9,929.9,779.6,931.2z M757.6,931.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,929.9,758.8,929.9,757.6,931.2z M735.5,931.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,929.9,736.8,929.9,735.5,931.2z M713.5,931.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C716.7,929.9,714.7,929.9,713.5,931.2z M691.4,931.2c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C694.6,929.9,692.7,929.9,691.4,931.2z M845.8,909.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,907.9,847,907.9,845.8,909.1z M823.7,909.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,907.9,825,907.9,823.7,909.1z M801.7,909.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,907.9,802.9,907.9,801.7,909.1z M779.6,909.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,907.9,780.9,907.9,779.6,909.1z M757.6,909.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,907.9,758.8,907.9,757.6,909.1z M735.5,909.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,907.9,736.8,907.9,735.5,909.1z M713.5,909.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C716.7,907.9,714.7,907.9,713.5,909.1z M845.8,887.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,885.8,847,885.8,845.8,887.1z M823.7,887.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,885.8,825,885.8,823.7,887.1z M801.7,887.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,885.8,802.9,885.8,801.7,887.1z M779.6,887.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,885.8,780.9,885.8,779.6,887.1z M757.6,887.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,885.8,758.8,885.8,757.6,887.1z M735.5,887.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,885.8,736.8,885.8,735.5,887.1z M845.8,865c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,863.8,847,863.8,845.8,865z M823.7,865c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,863.8,825,863.8,823.7,865z M801.7,865c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,863.8,802.9,863.8,801.7,865z M779.6,865c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,863.8,780.9,863.8,779.6,865z M757.6,865c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,863.8,758.8,863.8,757.6,865z M845.8,842.9c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,841.7,847,841.7,845.8,842.9z M823.7,842.9c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,841.7,825,841.7,823.7,842.9z M801.7,842.9c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,841.7,802.9,841.7,801.7,842.9z M845.8,820.9c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C849,819.7,847,819.7,845.8,820.9z M823.7,820.9c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C826.9,819.7,825,819.7,823.7,820.9z M801.7,820.9c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C804.9,819.7,802.9,819.7,801.7,820.9z M779.6,842.9c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C782.8,841.7,780.9,841.7,779.6,842.9z M757.6,842.9c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C760.8,841.7,758.8,841.7,757.6,842.9z M735.5,865c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C738.7,863.8,736.8,863.8,735.5,865z M713.5,887.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C716.7,885.8,714.7,885.8,713.5,887.1z M691.4,909.1c-1.2,1.2-1.2,3.2,0,4.4c1.2,1.2,3.2,1.2,4.4,0c1.2-1.2,1.2-3.2,0-4.4
          C694.6,907.9,692.7,907.9,691.4,909.1z"
              />
            </g>
            <g class="right_2">
              <circle
                class="st1 an2"
                cx="397.5"
                cy="793.5"
                r="79.5"
              />
              <path
                class="st2 an1"
                d="M476.4,738.9c-0.5-0.1-0.8-0.7-0.7-1.2c0.1-0.5,0.7-0.8,1.2-0.7c0.5,0.1,0.8,0.7,0.7,1.2
            C477.5,738.7,476.9,739,476.4,738.9z M483.6,734.9c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C482.9,735.7,483.4,735.5,483.6,734.9z M489.5,731.7c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C488.9,732.5,489.4,732.2,489.5,731.7z M495.5,728.4c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C494.8,729.2,495.4,728.9,495.5,728.4z M501.5,725.1c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C500.8,725.9,501.3,725.6,501.5,725.1z M507.4,721.8c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C506.8,722.6,507.3,722.3,507.4,721.8z M513.4,718.6c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C512.7,719.4,513.3,719.1,513.4,718.6z M474.3,732.2c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C473.6,733.1,474.2,732.8,474.3,732.2z M480.3,729c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C479.6,729.8,480.1,729.5,480.3,729z M486.3,725.7c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C485.6,726.5,486.1,726.2,486.3,725.7z M492.2,722.4c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C491.5,723.2,492.1,722.9,492.2,722.4z M498.2,719.1c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C497.5,720,498.1,719.7,498.2,719.1z M504.2,715.9c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C503.5,716.7,504,716.4,504.2,715.9z M510.1,712.6c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C509.5,713.4,510,713.1,510.1,712.6z M471.1,726.3c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C470.4,727.1,470.9,726.8,471.1,726.3z M477,723c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C476.3,723.8,476.9,723.5,477,723z M483,719.7c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C482.3,720.5,482.8,720.2,483,719.7z M489,716.5c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C488.3,717.3,488.8,717,489,716.5z M494.9,713.2c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C494.2,714,494.8,713.7,494.9,713.2z M500.9,709.9c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C500.2,710.7,500.7,710.4,500.9,709.9z M506.9,706.6c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C506.2,707.4,506.7,707.1,506.9,706.6z M467.8,720.3c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C467.1,721.1,467.6,720.8,467.8,720.3z M473.7,717c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C473.1,717.8,473.6,717.6,473.7,717z M479.7,713.8c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C479,714.6,479.6,714.3,479.7,713.8z M485.7,710.5c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C485,711.3,485.5,711,485.7,710.5z M491.6,707.2c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C491,708,491.5,707.7,491.6,707.2z M497.6,703.9c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C496.9,704.7,497.5,704.4,497.6,703.9z M503.6,700.7c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C502.9,701.5,503.4,701.2,503.6,700.7z M464.5,714.4c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C463.8,715.2,464.4,714.9,464.5,714.4z M470.5,711.1c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C469.8,711.9,470.3,711.6,470.5,711.1z M476.4,707.8c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C475.8,708.6,476.3,708.3,476.4,707.8z M482.4,704.5c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C481.7,705.3,482.3,705,482.4,704.5z M488.4,701.2c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C487.7,702.1,488.2,701.8,488.4,701.2z M494.3,698c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C493.7,698.8,494.2,698.5,494.3,698z M461.2,708.4c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C460.5,709.2,461.1,708.9,461.2,708.4z M467.2,705.1c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C466.5,705.9,467,705.6,467.2,705.1z M473.2,701.8c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C472.5,702.6,473,702.3,473.2,701.8z M479.1,698.6c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C478.4,699.4,479,699.1,479.1,698.6z M485.1,695.3c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C484.4,696.1,484.9,695.8,485.1,695.3z M458,702.4c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C457.3,703.2,457.8,702.9,458,702.4z M463.9,699.1c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C463.2,699.9,463.8,699.6,463.9,699.1z M469.9,695.9c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C469.2,696.7,469.7,696.4,469.9,695.9z M475.9,692.6c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C475.2,693.4,475.7,693.1,475.9,692.6z M454.7,696.4c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C454,697.2,454.5,697,454.7,696.4z M460.6,693.2c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C460,694,460.5,693.7,460.6,693.2z M451.4,690.5c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C450.7,691.3,451.2,691,451.4,690.5z M457.4,687.2c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C456.7,688,457.2,687.7,457.4,687.2z M466.6,689.9c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C465.9,690.7,466.5,690.4,466.6,689.9z M472.6,686.6c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C471.9,687.4,472.4,687.1,472.6,686.6z M481.8,689.3c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C481.1,690.1,481.7,689.8,481.8,689.3z M491.1,692c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C490.4,692.8,490.9,692.5,491.1,692z M500.3,694.7c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C499.6,695.5,500.2,695.2,500.3,694.7z M480.9,744.2c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C480.2,745,480.7,744.7,480.9,744.2z M486.8,740.9c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C486.2,741.7,486.7,741.4,486.8,740.9z M492.8,737.6c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C492.1,738.4,492.7,738.1,492.8,737.6z M498.8,734.4c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C498.1,735.2,498.6,734.9,498.8,734.4z M504.8,731.1c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C504.1,731.9,504.6,731.6,504.8,731.1z M510.7,727.8c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C510,728.6,510.6,728.3,510.7,727.8z M516.7,724.5c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C516,725.3,516.5,725,516.7,724.5z M484.2,750.2c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C483.5,751,484,750.7,484.2,750.2z M490.1,746.9c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C489.4,747.7,490,747.4,490.1,746.9z M496.1,743.6c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C495.4,744.4,495.9,744.1,496.1,743.6z M502.1,740.3c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C501.4,741.1,501.9,740.8,502.1,740.3z M508,737c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C507.3,737.9,507.9,737.6,508,737z M514,733.8c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C513.3,734.6,513.8,734.3,514,733.8z M487.4,756.1c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C486.8,756.9,487.3,756.6,487.4,756.1z M493.4,752.8c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C492.7,753.6,493.3,753.4,493.4,752.8z M499.4,749.6c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C498.7,750.4,499.2,750.1,499.4,749.6z M505.3,746.3c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C504.7,747.1,505.2,746.8,505.3,746.3z M511.3,743c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C510.6,743.8,511.2,743.5,511.3,743z M490.7,762.1c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C490,762.9,490.6,762.6,490.7,762.1z M496.7,758.8c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C496,759.6,496.5,759.3,496.7,758.8z M502.6,755.5c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C502,756.3,502.5,756,502.6,755.5z M508.6,752.3c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C507.9,753.1,508.5,752.8,508.6,752.3z M494,768.1c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C493.3,768.9,493.8,768.6,494,768.1z M500,764.8c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C499.3,765.6,499.8,765.3,500,764.8z M497.3,774c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C496.6,774.8,497.1,774.5,497.3,774z M503.2,770.7c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C502.5,771.5,503.1,771.3,503.2,770.7z M505.9,761.5c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C505.2,762.3,505.8,762,505.9,761.5z M511.9,758.2c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C511.2,759,511.7,758.8,511.9,758.2z M514.6,749c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C513.9,749.8,514.4,749.5,514.6,749z M517.3,739.7c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C516.6,740.5,517.1,740.2,517.3,739.7z M520,730.5c0.1-0.5-0.1-1-0.7-1.2c-0.5-0.1-1,0.1-1.2,0.7c-0.1,0.5,0.1,1,0.7,1.2
            C519.3,731.3,519.8,731,520,730.5z M463.4,728.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C464.1,728.1,463.6,728.4,463.4,728.9z M457.5,732.2c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C458.1,731.4,457.6,731.7,457.5,732.2z M451.5,735.4c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C452.2,734.6,451.6,734.9,451.5,735.4z M445.5,738.7c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C446.2,737.9,445.7,738.2,445.5,738.7z M439.5,742c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C440.2,741.2,439.7,741.5,439.5,742z M433.6,745.3c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C434.3,744.5,433.7,744.8,433.6,745.3z M427.6,748.5c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C428.3,747.7,427.8,748,427.6,748.5z M421.6,751.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C422.3,751,421.8,751.3,421.6,751.8z M466.7,734.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C467.4,734.1,466.8,734.4,466.7,734.9z M460.7,738.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C461.4,737.3,460.9,737.6,460.7,738.1z M454.8,741.4c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C455.4,740.6,454.9,740.9,454.8,741.4z M448.8,744.7c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C449.5,743.9,448.9,744.2,448.8,744.7z M442.8,748c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C443.5,747.2,443,747.5,442.8,748z M436.9,751.2c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C437.5,750.4,437,750.7,436.9,751.2z M430.9,754.5c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C431.6,753.7,431,754,430.9,754.5z M424.9,757.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C425.6,757,425.1,757.3,424.9,757.8z M470,740.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C470.7,740,470.1,740.3,470,740.8z M464,744.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C464.7,743.3,464.1,743.6,464,744.1z M458,747.4c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C458.7,746.6,458.2,746.9,458,747.4z M452.1,750.7c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C452.7,749.9,452.2,750.1,452.1,750.7z M446.1,753.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C446.8,753.1,446.2,753.4,446.1,753.9z M440.1,757.2c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C440.8,756.4,440.3,756.7,440.1,757.2z M434.2,760.5c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C434.8,759.7,434.3,760,434.2,760.5z M428.2,763.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C428.9,763,428.3,763.2,428.2,763.8z M473.2,746.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C473.9,746,473.4,746.3,473.2,746.8z M467.3,750.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C468,749.3,467.4,749.6,467.3,750.1z M461.3,753.3c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C462,752.5,461.5,752.8,461.3,753.3z M455.3,756.6c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C456,755.8,455.5,756.1,455.3,756.6z M449.4,759.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C450.1,759.1,449.5,759.4,449.4,759.9z M443.4,763.2c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C444.1,762.4,443.6,762.7,443.4,763.2z M437.4,766.4c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C438.1,765.6,437.6,765.9,437.4,766.4z M431.5,769.7c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C432.2,768.9,431.6,769.2,431.5,769.7z M476.5,752.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C477.2,752,476.7,752.2,476.5,752.8z M470.6,756c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C471.2,755.2,470.7,755.5,470.6,756z M464.6,759.3c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C465.3,758.5,464.7,758.8,464.6,759.3z M458.6,762.6c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C459.3,761.8,458.8,762.1,458.6,762.6z M452.6,765.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C453.3,765.1,452.8,765.4,452.6,765.9z M446.7,769.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C447.4,768.3,446.8,768.6,446.7,769.1z M440.7,772.4c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C441.4,771.6,440.9,771.9,440.7,772.4z M479.8,758.7c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C480.5,757.9,479.9,758.2,479.8,758.7z M473.8,762c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C474.5,761.2,474,761.5,473.8,762z M467.9,765.3c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C468.5,764.5,468,764.8,467.9,765.3z M461.9,768.6c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C462.6,767.8,462,768,461.9,768.6z M455.9,771.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C456.6,771,456.1,771.3,455.9,771.8z M450,775.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C450.6,774.3,450.1,774.6,450,775.1z M483.1,764.7c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C483.8,763.9,483.2,764.2,483.1,764.7z M477.1,768c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C477.8,767.2,477.3,767.5,477.1,768z M471.1,771.2c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C471.8,770.4,471.3,770.7,471.1,771.2z M465.2,774.5c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C465.9,773.7,465.3,774,465.2,774.5z M459.2,777.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C459.9,777,459.4,777.3,459.2,777.8z M486.3,770.7c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C487,769.9,486.5,770.2,486.3,770.7z M480.4,773.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C481.1,773.1,480.5,773.4,480.4,773.9z M474.4,777.2c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C475.1,776.4,474.6,776.7,474.4,777.2z M489.6,776.7c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C490.3,775.8,489.8,776.1,489.6,776.7z M483.7,779.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C484.3,779.1,483.8,779.4,483.7,779.9z M477.7,783.2c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C478.4,782.4,477.8,782.7,477.7,783.2z M468.4,780.5c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C469.1,779.7,468.6,780,468.4,780.5z M462.5,783.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C463.2,783,462.6,783.3,462.5,783.8z M453.2,781.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C453.9,780.3,453.4,780.6,453.2,781.1z M444,778.4c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C444.7,777.6,444.1,777.9,444,778.4z M434.7,775.7c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C435.4,774.9,434.9,775.2,434.7,775.7z M460.1,722.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C460.8,722.1,460.3,722.4,460.1,722.9z M454.2,726.2c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C454.9,725.4,454.3,725.7,454.2,726.2z M448.2,729.5c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C448.9,728.7,448.4,729,448.2,729.5z M442.2,732.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C442.9,731.9,442.4,732.2,442.2,732.8z M436.3,736c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C437,735.2,436.4,735.5,436.3,736z M430.3,739.3c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C431,738.5,430.5,738.8,430.3,739.3z M424.3,742.6c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C425,741.8,424.5,742.1,424.3,742.6z M418.4,745.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C419.1,745.1,418.5,745.3,418.4,745.9z M456.9,717c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C457.5,716.2,457,716.4,456.9,717z M450.9,720.2c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C451.6,719.4,451,719.7,450.9,720.2z M444.9,723.5c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C445.6,722.7,445.1,723,444.9,723.5z M439,726.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C439.6,726,439.1,726.3,439,726.8z M433,730.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C433.7,729.3,433.1,729.6,433,730.1z M427,733.3c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C427.7,732.5,427.2,732.8,427,733.3z M421.1,736.6c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C421.7,735.8,421.2,736.1,421.1,736.6z M453.6,711c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C454.3,710.2,453.7,710.5,453.6,711z M447.6,714.3c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C448.3,713.5,447.8,713.8,447.6,714.3z M441.7,717.5c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C442.3,716.7,441.8,717,441.7,717.5z M435.7,720.8c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C436.4,720,435.8,720.3,435.7,720.8z M429.7,724.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C430.4,723.3,429.9,723.6,429.7,724.1z M423.8,727.4c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C424.4,726.6,423.9,726.9,423.8,727.4z M450.3,705c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C451,704.2,450.5,704.5,450.3,705z M444.3,708.3c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C445,707.5,444.5,707.8,444.3,708.3z M438.4,711.6c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C439.1,710.8,438.5,711.1,438.4,711.6z M432.4,714.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C433.1,714,432.6,714.3,432.4,714.9z M426.4,718.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C427.1,717.3,426.6,717.6,426.4,718.1z M447,699c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C447.7,698.2,447.2,698.5,447,699z M441.1,702.3c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C441.7,701.5,441.2,701.8,441.1,702.3z M435.1,705.6c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C435.8,704.8,435.2,705.1,435.1,705.6z M443.8,693.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C444.4,692.3,443.9,692.6,443.8,693.1z M437.8,696.4c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C438.5,695.6,437.9,695.9,437.8,696.4z M431.8,699.6c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C432.5,698.8,432,699.1,431.8,699.6z M429.1,708.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C429.8,708.1,429.3,708.4,429.1,708.9z M423.2,712.1c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C423.8,711.3,423.3,711.6,423.2,712.1z M420.5,721.4c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C421.2,720.6,420.6,720.9,420.5,721.4z M417.8,730.6c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C418.5,729.8,417.9,730.1,417.8,730.6z M415.1,739.9c-0.1,0.5,0.1,1,0.7,1.2c0.5,0.1,1-0.1,1.2-0.7c0.1-0.5-0.1-1-0.7-1.2
            C415.8,739.1,415.2,739.4,415.1,739.9z"
              />
            </g>
            <g class="right_3">
              <path
                class="st0 an5"
                d="M1753.1,644.5c-1-1-1-2.7,0-3.7c1-1,2.7-1,3.7,0c1,1,1,2.7,0,3.7C1755.8,645.5,1754.1,645.5,1753.1,644.5z
            M1775.5,644.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7C1772.8,645.5,1774.5,645.5,1775.5,644.5z M1794.3,644.5
            c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7C1791.6,645.5,1793.2,645.5,1794.3,644.5z M1813,644.5c1-1,1-2.7,0-3.7
            c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7C1810.3,645.5,1812,645.5,1813,644.5z M1831.8,644.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0
            c-1,1-1,2.7,0,3.7C1829,645.5,1830.7,645.5,1831.8,644.5z M1850.5,644.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1847.8,645.5,1849.5,645.5,1850.5,644.5z M1869.2,644.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1866.5,645.5,1868.2,645.5,1869.2,644.5z M1756.8,625.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,626.8,1755.8,626.8,1756.8,625.7z M1775.5,625.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,626.8,1774.5,626.8,1775.5,625.7z M1794.3,625.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,626.8,1793.2,626.8,1794.3,625.7z M1813,625.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,626.8,1812,626.8,1813,625.7z M1831.8,625.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1829,626.8,1830.7,626.8,1831.8,625.7z M1850.5,625.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1847.8,626.8,1849.5,626.8,1850.5,625.7z M1869.2,625.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1866.5,626.8,1868.2,626.8,1869.2,625.7z M1756.8,607c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,608,1755.8,608,1756.8,607z M1775.5,607c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,608,1774.5,608,1775.5,607z M1794.3,607c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,608,1793.2,608,1794.3,607z M1813,607c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7C1810.3,608,1812,608,1813,607z
            M1831.8,607c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7C1829,608,1830.7,608,1831.8,607z M1850.5,607c1-1,1-2.7,0-3.7
            c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7C1847.8,608,1849.5,608,1850.5,607z M1869.2,607c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0
            c-1,1-1,2.7,0,3.7C1866.5,608,1868.2,608,1869.2,607z M1756.8,588.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,589.3,1755.8,589.3,1756.8,588.3z M1775.5,588.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,589.3,1774.5,589.3,1775.5,588.3z M1794.3,588.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,589.3,1793.2,589.3,1794.3,588.3z M1813,588.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,589.3,1812,589.3,1813,588.3z M1831.8,588.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1829,589.3,1830.7,589.3,1831.8,588.3z M1850.5,588.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1847.8,589.3,1849.5,589.3,1850.5,588.3z M1869.2,588.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1866.5,589.3,1868.2,589.3,1869.2,588.3z M1756.8,569.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,570.6,1755.8,570.6,1756.8,569.5z M1775.5,569.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,570.6,1774.5,570.6,1775.5,569.5z M1794.3,569.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,570.6,1793.2,570.6,1794.3,569.5z M1813,569.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,570.6,1812,570.6,1813,569.5z M1831.8,569.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1829,570.6,1830.7,570.6,1831.8,569.5z M1850.5,569.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1847.8,570.6,1849.5,570.6,1850.5,569.5z M1756.8,550.8c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,551.8,1755.8,551.8,1756.8,550.8z M1775.5,550.8c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,551.8,1774.5,551.8,1775.5,550.8z M1794.3,550.8c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,551.8,1793.2,551.8,1794.3,550.8z M1813,550.8c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,551.8,1812,551.8,1813,550.8z M1831.8,550.8c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1829,551.8,1830.7,551.8,1831.8,550.8z M1756.8,532.1c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,533.1,1755.8,533.1,1756.8,532.1z M1775.5,532.1c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,533.1,1774.5,533.1,1775.5,532.1z M1794.3,532.1c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,533.1,1793.2,533.1,1794.3,532.1z M1813,532.1c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,533.1,1812,533.1,1813,532.1z M1756.8,513.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,514.4,1755.8,514.3,1756.8,513.3z M1775.5,513.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,514.4,1774.5,514.3,1775.5,513.3z M1756.8,494.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,495.6,1755.8,495.6,1756.8,494.5z M1775.5,494.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,495.6,1774.5,495.6,1775.5,494.5z M1794.3,513.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,514.4,1793.2,514.3,1794.3,513.3z M1813,513.3c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,514.4,1812,514.3,1813,513.3z M1831.8,532.1c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1829,533.1,1830.7,533.1,1831.8,532.1z M1850.5,550.8c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1847.8,551.8,1849.5,551.8,1850.5,550.8z M1869.2,569.5c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1866.5,570.6,1868.2,570.6,1869.2,569.5z M1756.8,663.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,664.2,1755.8,664.2,1756.8,663.2z M1775.5,663.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,664.2,1774.5,664.2,1775.5,663.2z M1794.3,663.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,664.2,1793.2,664.2,1794.3,663.2z M1813,663.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,664.2,1812,664.2,1813,663.2z M1831.8,663.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1829,664.2,1830.7,664.2,1831.8,663.2z M1850.5,663.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1847.8,664.2,1849.5,664.2,1850.5,663.2z M1869.2,663.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1866.5,664.2,1868.2,664.2,1869.2,663.2z M1756.8,681.9c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,683,1755.8,683,1756.8,681.9z M1775.5,681.9c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,683,1774.5,683,1775.5,681.9z M1794.3,681.9c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,683,1793.2,683,1794.3,681.9z M1813,681.9c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,683,1812,683,1813,681.9z M1831.8,681.9c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1829,683,1830.7,683,1831.8,681.9z M1850.5,681.9c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1847.8,683,1849.5,683,1850.5,681.9z M1756.8,700.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,701.7,1755.8,701.7,1756.8,700.7z M1775.5,700.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,701.7,1774.5,701.7,1775.5,700.7z M1794.3,700.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,701.7,1793.2,701.7,1794.3,700.7z M1813,700.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,701.7,1812,701.7,1813,700.7z M1831.8,700.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1829,701.7,1830.7,701.7,1831.8,700.7z M1756.8,719.4c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,720.4,1755.8,720.4,1756.8,719.4z M1775.5,719.4c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,720.4,1774.5,720.4,1775.5,719.4z M1794.3,719.4c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,720.4,1793.2,720.4,1794.3,719.4z M1813,719.4c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,720.4,1812,720.4,1813,719.4z M1756.8,738.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,739.2,1755.8,739.2,1756.8,738.2z M1775.5,738.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,739.2,1774.5,739.2,1775.5,738.2z M1756.8,756.9c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1754.1,757.9,1755.8,757.9,1756.8,756.9z M1775.5,756.9c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1772.8,757.9,1774.5,757.9,1775.5,756.9z M1794.3,738.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1791.6,739.2,1793.2,739.2,1794.3,738.2z M1813,738.2c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1810.3,739.2,1812,739.2,1813,738.2z M1831.8,719.4c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1829,720.4,1830.7,720.4,1831.8,719.4z M1850.5,700.7c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1847.8,701.7,1849.5,701.7,1850.5,700.7z M1869.2,681.9c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7
            C1866.5,683,1868.2,683,1869.2,681.9z M1734.9,603.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,602.1,1736,602.1,1734.9,603.2z M1716.2,603.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,602.1,1717.2,602.1,1716.2,603.2z M1697.5,603.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,602.1,1698.5,602.1,1697.5,603.2z M1678.7,603.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,602.1,1679.8,602.1,1678.7,603.2z M1660,603.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,602.1,1661,602.1,1660,603.2z M1641.2,603.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,602.1,1642.3,602.1,1641.2,603.2z M1622.5,603.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1625.2,602.1,1623.5,602.1,1622.5,603.2z M1603.8,603.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1606.5,602.1,1604.8,602.1,1603.8,603.2z M1734.9,621.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,620.9,1736,620.9,1734.9,621.9z M1716.2,621.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,620.9,1717.2,620.9,1716.2,621.9z M1697.5,621.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,620.9,1698.5,620.9,1697.5,621.9z M1678.7,621.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,620.9,1679.8,620.9,1678.7,621.9z M1660,621.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,620.9,1661,620.9,1660,621.9z M1641.2,621.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,620.9,1642.3,620.9,1641.2,621.9z M1622.5,621.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1625.2,620.9,1623.5,620.9,1622.5,621.9z M1603.8,621.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1606.5,620.9,1604.8,620.9,1603.8,621.9z M1734.9,640.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,639.6,1736,639.6,1734.9,640.6z M1716.2,640.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,639.6,1717.2,639.6,1716.2,640.6z M1697.5,640.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,639.6,1698.5,639.6,1697.5,640.6z M1678.7,640.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,639.6,1679.8,639.6,1678.7,640.6z M1660,640.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,639.6,1661,639.6,1660,640.6z M1641.2,640.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,639.6,1642.3,639.6,1641.2,640.6z M1622.5,640.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1625.2,639.6,1623.5,639.6,1622.5,640.6z M1603.8,640.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1606.5,639.6,1604.8,639.6,1603.8,640.6z M1734.9,659.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,658.3,1736,658.3,1734.9,659.4z M1716.2,659.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,658.3,1717.2,658.3,1716.2,659.4z M1697.5,659.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,658.3,1698.5,658.3,1697.5,659.4z M1678.7,659.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,658.3,1679.8,658.3,1678.7,659.4z M1660,659.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,658.3,1661,658.3,1660,659.4z M1641.2,659.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,658.3,1642.3,658.3,1641.2,659.4z M1622.5,659.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1625.2,658.3,1623.5,658.3,1622.5,659.4z M1603.8,659.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1606.5,658.3,1604.8,658.3,1603.8,659.4z M1734.9,678.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,677.1,1736,677.1,1734.9,678.1z M1716.2,678.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,677.1,1717.2,677.1,1716.2,678.1z M1697.5,678.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,677.1,1698.5,677.1,1697.5,678.1z M1678.7,678.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,677.1,1679.8,677.1,1678.7,678.1z M1660,678.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,677.1,1661,677.1,1660,678.1z M1641.2,678.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,677.1,1642.3,677.1,1641.2,678.1z M1622.5,678.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1625.2,677.1,1623.5,677.1,1622.5,678.1z M1734.9,696.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,695.8,1736,695.8,1734.9,696.9z M1716.2,696.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,695.8,1717.2,695.8,1716.2,696.9z M1697.5,696.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,695.8,1698.5,695.8,1697.5,696.9z M1678.7,696.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,695.8,1679.8,695.8,1678.7,696.9z M1660,696.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,695.8,1661,695.8,1660,696.9z M1641.2,696.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,695.8,1642.3,695.8,1641.2,696.9z M1734.9,715.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,714.6,1736,714.6,1734.9,715.6z M1716.2,715.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,714.6,1717.2,714.6,1716.2,715.6z M1697.5,715.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,714.6,1698.5,714.6,1697.5,715.6z M1678.7,715.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,714.6,1679.8,714.6,1678.7,715.6z M1660,715.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,714.6,1661,714.6,1660,715.6z M1734.9,734.3c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,733.3,1736,733.3,1734.9,734.3z M1716.2,734.3c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,733.3,1717.2,733.3,1716.2,734.3z M1697.5,734.3c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,733.3,1698.5,733.3,1697.5,734.3z M1734.9,753.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,752.1,1736,752.1,1734.9,753.1z M1716.2,753.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,752.1,1717.2,752.1,1716.2,753.1z M1697.5,753.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,752.1,1698.5,752.1,1697.5,753.1z M1678.7,734.3c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,733.3,1679.8,733.3,1678.7,734.3z M1660,734.3c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,733.3,1661,733.3,1660,734.3z M1641.2,715.6c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,714.6,1642.3,714.6,1641.2,715.6z M1622.5,696.9c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1625.2,695.8,1623.5,695.8,1622.5,696.9z M1603.8,678.1c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1606.5,677.1,1604.8,677.1,1603.8,678.1z M1734.9,584.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,583.4,1736,583.4,1734.9,584.4z M1716.2,584.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,583.4,1717.2,583.4,1716.2,584.4z M1697.5,584.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,583.4,1698.5,583.4,1697.5,584.4z M1678.7,584.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,583.4,1679.8,583.4,1678.7,584.4z M1660,584.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,583.4,1661,583.4,1660,584.4z M1641.2,584.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,583.4,1642.3,583.4,1641.2,584.4z M1622.5,584.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1625.2,583.4,1623.5,583.4,1622.5,584.4z M1603.8,584.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1606.5,583.4,1604.8,583.4,1603.8,584.4z M1734.9,565.7c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,564.7,1736,564.7,1734.9,565.7z M1716.2,565.7c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,564.7,1717.2,564.7,1716.2,565.7z M1697.5,565.7c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,564.7,1698.5,564.7,1697.5,565.7z M1678.7,565.7c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,564.7,1679.8,564.7,1678.7,565.7z M1660,565.7c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,564.7,1661,564.7,1660,565.7z M1641.2,565.7c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,564.7,1642.3,564.7,1641.2,565.7z M1622.5,565.7c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1625.2,564.7,1623.5,564.7,1622.5,565.7z M1734.9,547c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,545.9,1736,545.9,1734.9,547z M1716.2,547c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,545.9,1717.2,545.9,1716.2,547z M1697.5,547c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,545.9,1698.5,545.9,1697.5,547z M1678.7,547c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,545.9,1679.8,545.9,1678.7,547z M1660,547c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,545.9,1661,545.9,1660,547z M1641.2,547c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,545.9,1642.3,545.9,1641.2,547z M1734.9,528.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,527.2,1736,527.2,1734.9,528.2z M1716.2,528.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,527.2,1717.2,527.2,1716.2,528.2z M1697.5,528.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,527.2,1698.5,527.2,1697.5,528.2z M1678.7,528.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,527.2,1679.8,527.2,1678.7,528.2z M1660,528.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,527.2,1661,527.2,1660,528.2z M1734.9,509.5c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,508.4,1736,508.4,1734.9,509.5z M1716.2,509.5c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,508.4,1717.2,508.4,1716.2,509.5z M1697.5,509.5c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,508.4,1698.5,508.4,1697.5,509.5z M1734.9,490.8c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1737.6,489.7,1736,489.7,1734.9,490.8z M1716.2,490.8c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1718.9,489.7,1717.2,489.7,1716.2,490.8z M1697.5,490.8c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1700.2,489.7,1698.5,489.7,1697.5,490.8z M1678.7,509.5c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1681.4,508.4,1679.8,508.4,1678.7,509.5z M1660,509.5c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1662.7,508.4,1661,508.4,1660,509.5z M1641.2,528.2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1644,527.2,1642.3,527.2,1641.2,528.2z M1622.5,547c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1625.2,545.9,1623.5,545.9,1622.5,547z M1603.8,565.7c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0c1-1,1-2.7,0-3.7
            C1606.5,564.7,1604.8,564.7,1603.8,565.7z"
              />

            </g>
            <g class="a1">
              <path
                class="st3"
                d="M1701.3,971.5c0,0,16.7-171.1,176.3-171.1h-176.3V971.5z"
              />
              <path
                class="st3"
                d="M1753.4,250.1v176.3c0,0,171.1,16.7,171.1,176.3V426.4C1924.4,426.4,1753.4,409.7,1753.4,250.1z"
              />
              <path
                class="st2"
                d="M1813,858.6h165.4c2.1,1.3,4.2,2.6,6.3,4h-178C1808.7,861.3,1810.8,859.9,1813,858.6z M1942.3,842.7H1849
            c-3.9,1.2-7.8,2.5-11.6,4h116.5C1950.1,845.2,1946.3,843.8,1942.3,842.7z M1786.4,878.6h218.5c-1.5-1.4-3-2.7-4.5-4h-209.6
            C1789.3,875.9,1787.8,877.3,1786.4,878.6z M1771.3,894.6H2020c-1.1-1.4-2.2-2.7-3.4-4h-241.9
            C1773.6,891.9,1772.4,893.3,1771.3,894.6z M1759.8,910.6h271.8c-0.9-1.3-1.7-2.7-2.6-4h-266.5
            C1761.5,907.9,1760.6,909.3,1759.8,910.6z M1750.9,926.6h289.6c-0.7-1.3-1.3-2.7-2-4h-285.6
            C1752.2,923.9,1751.5,925.2,1750.9,926.6z M1744.2,942.6h302.9c-0.5-1.3-1-2.7-1.5-4h-300C1745.2,939.9,1744.7,941.2,1744.2,942.6z
            M1739.4,958.6h312.5c-0.3-1.3-0.7-2.7-1-4h-310.4C1740.1,955.9,1739.7,957.2,1739.4,958.6z M1736.4,974.5h318.5
            c-0.2-1.3-0.4-2.7-0.6-4H1737C1736.8,971.9,1736.6,973.2,1736.4,974.5z M1735,990.5h321.3c0-1.3-0.1-2.7-0.2-4h-320.9
            C1735.1,987.9,1735.1,989.2,1735,990.5z M1735.2,1006.5h320.9c0.1-1.3,0.1-2.7,0.2-4H1735
            C1735.1,1003.8,1735.1,1005.2,1735.2,1006.5z M1737,1022.5h317.3c0.2-1.3,0.4-2.7,0.6-4h-318.5
            C1736.6,1019.8,1736.8,1021.2,1737,1022.5z M1740.4,1038.5h310.4c0.4-1.3,0.7-2.7,1-4h-312.5
            C1739.7,1035.8,1740.1,1037.2,1740.4,1038.5z M1745.7,1054.5h300c0.5-1.3,1-2.7,1.5-4h-302.9
            C1744.7,1051.8,1745.2,1053.1,1745.7,1054.5z M1752.9,1070.5h285.5c0.7-1.3,1.4-2.7,2-4h-289.6
            C1751.5,1067.8,1752.2,1069.1,1752.9,1070.5z M1762.4,1071.4h266.5c0.9-1.3,1.8-2.7,2.6-4h-271.8
            C1760.6,1068.8,1761.5,1070.1,1762.4,1071.4z M1774.7,1087.4h241.9c1.2-1.3,2.3-2.6,3.4-4h-248.7
            C1772.4,1084.8,1773.6,1086.1,1774.7,1087.4z M1790.9,1103.4h209.6c1.5-1.3,3-2.6,4.5-4h-218.5
            C1787.8,1100.8,1789.3,1102.1,1790.9,1103.4z M1813,1119.4h165.4c2.1-1.3,4.2-2.6,6.3-4h-178
            C1808.7,1116.8,1810.8,1118.1,1813,1119.4z M1849,1135.4h93.4c3.9-1.2,7.8-2.5,11.6-4h-116.5
            C1841.2,1132.9,1845,1134.2,1849,1135.4z"
              />
            </g>
            <g>
              <path
                class="st0 an2"
                d="M242.1,83.2c-1.1-1.1-1.1-2.9,0-4c1.1-1.1,2.9-1.1,4,0c1.1,1.1,1.1,2.9,0,4C245,84.3,243.2,84.3,242.1,83.2z
            M266.1,83.2c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C263.2,84.3,265,84.3,266.1,83.2z M286.2,83.2
            c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C283.3,84.3,285.1,84.3,286.2,83.2z M306.2,83.2
            c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C303.3,84.3,305.1,84.3,306.2,83.2z M326.2,83.2
            c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C323.3,84.3,325.1,84.3,326.2,83.2z M346.3,83.2
            c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C343.4,84.3,345.2,84.3,346.3,83.2z M366.3,83.2
            c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C363.4,84.3,365.2,84.3,366.3,83.2z M246.1,63.2
            c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C243.2,64.3,245,64.3,246.1,63.2z M266.1,63.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C263.2,64.3,265,64.3,266.1,63.2z M286.2,63.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C283.3,64.3,285.1,64.3,286.2,63.2z M306.2,63.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C303.3,64.3,305.1,64.3,306.2,63.2z M326.2,63.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C323.3,64.3,325.1,64.3,326.2,63.2z M346.3,63.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C343.4,64.3,345.2,64.3,346.3,63.2z M366.3,63.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C363.4,64.3,365.2,64.3,366.3,63.2z M246.1,43.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C243.2,44.3,245,44.3,246.1,43.2z M266.1,43.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C263.2,44.3,265,44.3,266.1,43.2z M286.2,43.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C283.3,44.3,285.1,44.3,286.2,43.2z M306.2,43.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C303.3,44.3,305.1,44.3,306.2,43.2z M326.2,43.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C323.3,44.3,325.1,44.3,326.2,43.2z M346.3,43.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C343.4,44.3,345.2,44.3,346.3,43.2z M366.3,43.2c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C363.4,44.3,365.2,44.3,366.3,43.2z M246.1,23.1c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C243.2,24.2,245,24.2,246.1,23.1z M266.1,23.1c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C263.2,24.2,265,24.2,266.1,23.1z M286.2,23.1c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C283.3,24.2,285.1,24.2,286.2,23.1z M306.2,23.1c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C303.3,24.2,305.1,24.2,306.2,23.1z M326.2,23.1c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C323.3,24.2,325.1,24.2,326.2,23.1z M346.3,23.1c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C343.4,24.2,345.2,24.2,346.3,23.1z M366.3,23.1c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C363.4,24.2,365.2,24.2,366.3,23.1z M246.1,3.1c1.1-1.1,1.1-2.9,0-4
            c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4C243.2,4.2,245,4.2,246.1,3.1z M266.1,3.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0
            c-1.1,1.1-1.1,2.9,0,4C263.2,4.2,265,4.2,266.1,3.1z M286.2,3.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C283.3,4.2,285.1,4.2,286.2,3.1z M306.2,3.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C303.3,4.2,305.1,4.2,306.2,3.1z M326.2,3.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C323.3,4.2,325.1,4.2,326.2,3.1z M346.3,3.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C343.4,4.2,345.2,4.2,346.3,3.1z M246.1-17c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2-15.9,245-15.9,246.1-17z M266.1-17c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2-15.9,265-15.9,266.1-17z M286.2-17c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C283.3-15.9,285.1-15.9,286.2-17z M306.2-17c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C303.3-15.9,305.1-15.9,306.2-17z M326.2-17c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C323.3-15.9,325.1-15.9,326.2-17z M246.1-37c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2-35.9,245-35.9,246.1-37z M266.1-37c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2-35.9,265-35.9,266.1-37z M286.2-37c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C283.3-35.9,285.1-35.9,286.2-37z M306.2-37c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C303.3-35.9,305.1-35.9,306.2-37z M246.1-57.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2-56,245-56,246.1-57.1z M266.1-57.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2-56,265-56,266.1-57.1z M246.1-77.2c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2-76.1,245-76.1,246.1-77.2z M266.1-77.2c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2-76.1,265-76.1,266.1-77.2z M286.2-57.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C283.3-56,285.1-56,286.2-57.1z M306.2-57.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C303.3-56,305.1-56,306.2-57.1z M326.2-37c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C323.3-35.9,325.1-35.9,326.2-37z M346.3-17c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C343.4-15.9,345.2-15.9,346.3-17z M366.3,3.1c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C363.4,4.2,365.2,4.2,366.3,3.1z M246.1,103.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2,104.4,245,104.4,246.1,103.3z M266.1,103.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2,104.4,265,104.4,266.1,103.3z M286.2,103.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C283.3,104.4,285.1,104.4,286.2,103.3z M306.2,103.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C303.3,104.4,305.1,104.4,306.2,103.3z M326.2,103.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C323.3,104.4,325.1,104.4,326.2,103.3z M346.3,103.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C343.4,104.4,345.2,104.4,346.3,103.3z M366.3,103.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C363.4,104.4,365.2,104.4,366.3,103.3z M246.1,123.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2,124.4,245,124.4,246.1,123.3z M266.1,123.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2,124.4,265,124.4,266.1,123.3z M286.2,123.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C283.3,124.4,285.1,124.4,286.2,123.3z M306.2,123.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C303.3,124.4,305.1,124.4,306.2,123.3z M326.2,123.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C323.3,124.4,325.1,124.4,326.2,123.3z M346.3,123.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C343.4,124.4,345.2,124.4,346.3,123.3z M246.1,143.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2,144.5,245,144.5,246.1,143.4z M266.1,143.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2,144.5,265,144.5,266.1,143.4z M286.2,143.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C283.3,144.5,285.1,144.5,286.2,143.4z M306.2,143.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C303.3,144.5,305.1,144.5,306.2,143.4z M326.2,143.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C323.3,144.5,325.1,144.5,326.2,143.4z M246.1,163.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2,164.5,245,164.5,246.1,163.4z M266.1,163.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2,164.5,265,164.5,266.1,163.4z M286.2,163.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C283.3,164.5,285.1,164.5,286.2,163.4z M306.2,163.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C303.3,164.5,305.1,164.5,306.2,163.4z M246.1,183.5c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2,184.6,245,184.6,246.1,183.5z M266.1,183.5c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2,184.6,265,184.6,266.1,183.5z M246.1,203.5c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C243.2,204.6,245,204.6,246.1,203.5z M266.1,203.5c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C263.2,204.6,265,204.6,266.1,203.5z M286.2,183.5c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C283.3,184.6,285.1,184.6,286.2,183.5z M306.2,183.5c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C303.3,184.6,305.1,184.6,306.2,183.5z M326.2,163.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C323.3,164.5,325.1,164.5,326.2,163.4z M346.3,143.4c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C343.4,144.5,345.2,144.5,346.3,143.4z M366.3,123.3c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4,0c-1.1,1.1-1.1,2.9,0,4
            C363.4,124.4,365.2,124.4,366.3,123.3z M222.7,39.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,38,223.8,38,222.7,39.1z M202.6,39.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,38,203.7,38,202.6,39.1z M182.6,39.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,38,183.7,38,182.6,39.1z M162.5,39.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4,38,163.6,38,162.5,39.1z M142.5,39.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4,38,143.6,38,142.5,39.1z M122.4,39.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3,38,123.6,38,122.4,39.1z M102.4,39.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C105.3,38,103.5,38,102.4,39.1z M82.4,39.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C85.3,38,83.5,38,82.4,39.1z M222.7,59.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,58,223.8,58,222.7,59.1z M202.6,59.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,58,203.7,58,202.6,59.1z M182.6,59.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,58,183.7,58,182.6,59.1z M162.5,59.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4,58,163.6,58,162.5,59.1z M142.5,59.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4,58,143.6,58,142.5,59.1z M122.4,59.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3,58,123.6,58,122.4,59.1z M102.4,59.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C105.3,58,103.5,58,102.4,59.1z M82.4,59.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C85.3,58,83.5,58,82.4,59.1z M222.7,79.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,78,223.8,78,222.7,79.2z M202.6,79.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,78,203.7,78,202.6,79.2z M182.6,79.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,78,183.7,78,182.6,79.2z M162.5,79.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4,78,163.6,78,162.5,79.2z M142.5,79.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4,78,143.6,78,142.5,79.2z M122.4,79.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3,78,123.6,78,122.4,79.2z M102.4,79.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C105.3,78,103.5,78,102.4,79.2z M82.4,79.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C85.3,78,83.5,78,82.4,79.2z M222.7,99.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,98.1,223.8,98.1,222.7,99.2z M202.6,99.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,98.1,203.7,98.1,202.6,99.2z M182.6,99.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,98.1,183.7,98.1,182.6,99.2z M162.5,99.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4,98.1,163.6,98.1,162.5,99.2z M142.5,99.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4,98.1,143.6,98.1,142.5,99.2z M122.4,99.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3,98.1,123.6,98.1,122.4,99.2z M102.4,99.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C105.3,98.1,103.5,98.1,102.4,99.2z M82.4,99.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C85.3,98.1,83.5,98.1,82.4,99.2z M222.7,119.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,118.1,223.8,118.1,222.7,119.2z M202.6,119.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,118.1,203.7,118.1,202.6,119.2z M182.6,119.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,118.1,183.7,118.1,182.6,119.2z M162.5,119.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4,118.1,163.6,118.1,162.5,119.2z M142.5,119.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4,118.1,143.6,118.1,142.5,119.2z M122.4,119.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3,118.1,123.6,118.1,122.4,119.2z M102.4,119.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C105.3,118.1,103.5,118.1,102.4,119.2z M222.7,139.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,138.2,223.8,138.2,222.7,139.3z M202.6,139.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,138.2,203.7,138.2,202.6,139.3z M182.6,139.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,138.2,183.7,138.2,182.6,139.3z M162.5,139.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4,138.2,163.6,138.2,162.5,139.3z M142.5,139.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4,138.2,143.6,138.2,142.5,139.3z M122.4,139.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3,138.2,123.6,138.2,122.4,139.3z M222.7,159.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,158.2,223.8,158.2,222.7,159.3z M202.6,159.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,158.2,203.7,158.2,202.6,159.3z M182.6,159.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,158.2,183.7,158.2,182.6,159.3z M162.5,159.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4,158.2,163.6,158.2,162.5,159.3z M142.5,159.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4,158.2,143.6,158.2,142.5,159.3z M222.7,179.4c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,178.3,223.8,178.3,222.7,179.4z M202.6,179.4c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,178.3,203.7,178.3,202.6,179.4z M182.6,179.4c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,178.3,183.7,178.3,182.6,179.4z M222.7,199.5c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,198.4,223.8,198.4,222.7,199.5z M202.6,199.5c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,198.4,203.7,198.4,202.6,199.5z M182.6,199.5c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,198.4,183.7,198.4,182.6,199.5z M162.5,179.4c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4,178.3,163.6,178.3,162.5,179.4z M142.5,179.4c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4,178.3,143.6,178.3,142.5,179.4z M122.4,159.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3,158.2,123.6,158.2,122.4,159.3z M102.4,139.3c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C105.3,138.2,103.5,138.2,102.4,139.3z M82.4,119.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C85.3,118.1,83.5,118.1,82.4,119.2z M222.7,19c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6,17.9,223.8,17.9,222.7,19z M202.6,19c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5,17.9,203.7,17.9,202.6,19z M182.6,19c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5,17.9,183.7,17.9,182.6,19z M162.5,19c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4,17.9,163.6,17.9,162.5,19z M142.5,19c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4,17.9,143.6,17.9,142.5,19z M122.4,19c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3,17.9,123.6,17.9,122.4,19z M102.4,19c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C105.3,17.9,103.5,17.9,102.4,19z M82.4,19c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C85.3,17.9,83.5,17.9,82.4,19z M222.7-1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6-2.1,223.8-2.1,222.7-1z M202.6-1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5-2.1,203.7-2.1,202.6-1z M182.6-1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5-2.1,183.7-2.1,182.6-1z M162.5-1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4-2.1,163.6-2.1,162.5-1z M142.5-1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4-2.1,143.6-2.1,142.5-1z M122.4-1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3-2.1,123.6-2.1,122.4-1z M102.4-1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C105.3-2.1,103.5-2.1,102.4-1z M222.7-21.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6-22.2,223.8-22.2,222.7-21.1z M202.6-21.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5-22.2,203.7-22.2,202.6-21.1z M182.6-21.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5-22.2,183.7-22.2,182.6-21.1z M162.5-21.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4-22.2,163.6-22.2,162.5-21.1z M142.5-21.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4-22.2,143.6-22.2,142.5-21.1z M122.4-21.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3-22.2,123.6-22.2,122.4-21.1z M222.7-41.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6-42.2,223.8-42.2,222.7-41.1z M202.6-41.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5-42.2,203.7-42.2,202.6-41.1z M182.6-41.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5-42.2,183.7-42.2,182.6-41.1z M162.5-41.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4-42.2,163.6-42.2,162.5-41.1z M142.5-41.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4-42.2,143.6-42.2,142.5-41.1z M222.7-61.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6-62.3,223.8-62.3,222.7-61.2z M202.6-61.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5-62.3,203.7-62.3,202.6-61.2z M182.6-61.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5-62.3,183.7-62.3,182.6-61.2z M222.7-81.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C225.6-82.3,223.8-82.3,222.7-81.2z M202.6-81.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C205.5-82.3,203.7-82.3,202.6-81.2z M182.6-81.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C185.5-82.3,183.7-82.3,182.6-81.2z M162.5-61.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C165.4-62.3,163.6-62.3,162.5-61.2z M142.5-61.2c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C145.4-62.3,143.6-62.3,142.5-61.2z M122.4-41.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C125.3-42.2,123.6-42.2,122.4-41.1z M102.4-21.1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C105.3-22.2,103.5-22.2,102.4-21.1z M82.4-1c-1.1,1.1-1.1,2.9,0,4c1.1,1.1,2.9,1.1,4,0c1.1-1.1,1.1-2.9,0-4
            C85.3-2.1,83.5-2.1,82.4-1z"
              />
              <path
                class="st2 an1"
                d="M13.3,817.8c-0.8-0.8-0.8-2.2,0-3c0.8-0.8,2.2-0.8,3,0c0.8,0.8,0.8,2.2,0,3C15.5,818.7,14.1,818.7,13.3,817.8z
            M31.5,817.8c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C29.3,818.7,30.6,818.7,31.5,817.8z M46.6,817.8
            c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C44.4,818.7,45.8,818.7,46.6,817.8z M61.7,817.8
            c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C59.5,818.7,60.9,818.7,61.7,817.8z M76.9,817.8
            c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C74.7,818.7,76,818.7,76.9,817.8z M92,817.8c0.8-0.8,0.8-2.2,0-3
            c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C89.8,818.7,91.2,818.7,92,817.8z M107.2,817.8c0.8-0.8,0.8-2.2,0-3
            c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C105,818.7,106.3,818.7,107.2,817.8z M16.3,802.7c0.8-0.8,0.8-2.2,0-3
            c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C14.1,803.5,15.5,803.5,16.3,802.7z M31.5,802.7c0.8-0.8,0.8-2.2,0-3
            c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C29.3,803.5,30.6,803.5,31.5,802.7z M46.6,802.7c0.8-0.8,0.8-2.2,0-3
            c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C44.4,803.5,45.8,803.5,46.6,802.7z M61.7,802.7c0.8-0.8,0.8-2.2,0-3
            c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C59.5,803.5,60.9,803.5,61.7,802.7z M76.9,802.7c0.8-0.8,0.8-2.2,0-3
            c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3C74.7,803.5,76,803.5,76.9,802.7z M92,802.7c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0
            c-0.8,0.8-0.8,2.2,0,3C89.8,803.5,91.2,803.5,92,802.7z M107.2,802.7c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0
            c-0.8,0.8-0.8,2.2,0,3C105,803.5,106.3,803.5,107.2,802.7z M16.3,787.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0
            c-0.8,0.8-0.8,2.2,0,3C14.1,788.4,15.5,788.4,16.3,787.6z M31.5,787.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0
            c-0.8,0.8-0.8,2.2,0,3C29.3,788.4,30.6,788.4,31.5,787.6z M46.6,787.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0
            c-0.8,0.8-0.8,2.2,0,3C44.4,788.4,45.8,788.4,46.6,787.6z M61.7,787.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0
            c-0.8,0.8-0.8,2.2,0,3C59.5,788.4,60.9,788.4,61.7,787.6z M76.9,787.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0
            c-0.8,0.8-0.8,2.2,0,3C74.7,788.4,76,788.4,76.9,787.6z M92,787.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C89.8,788.4,91.2,788.4,92,787.6z M107.2,787.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C105,788.4,106.3,788.4,107.2,787.6z M16.3,772.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,773.3,15.5,773.3,16.3,772.4z M31.5,772.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,773.3,30.6,773.3,31.5,772.4z M46.6,772.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,773.3,45.8,773.3,46.6,772.4z M61.7,772.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,773.3,60.9,773.3,61.7,772.4z M76.9,772.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C74.7,773.3,76,773.3,76.9,772.4z M92,772.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C89.8,773.3,91.2,773.3,92,772.4z M107.2,772.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C105,773.3,106.3,773.3,107.2,772.4z M16.3,757.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,758.1,15.5,758.1,16.3,757.3z M31.5,757.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,758.1,30.6,758.1,31.5,757.3z M46.6,757.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,758.1,45.8,758.1,46.6,757.3z M61.7,757.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,758.1,60.9,758.1,61.7,757.3z M76.9,757.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C74.7,758.1,76,758.1,76.9,757.3z M92,757.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C89.8,758.1,91.2,758.1,92,757.3z M16.3,742.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,743,15.5,743,16.3,742.1z M31.5,742.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,743,30.6,743,31.5,742.1z M46.6,742.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,743,45.8,743,46.6,742.1z M61.7,742.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,743,60.9,743,61.7,742.1z M76.9,742.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C74.7,743,76,743,76.9,742.1z M16.3,727c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,727.8,15.5,727.8,16.3,727z M31.5,727c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,727.8,30.6,727.8,31.5,727z M46.6,727c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,727.8,45.8,727.8,46.6,727z M61.7,727c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,727.8,60.9,727.8,61.7,727z M16.3,711.8c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,712.7,15.5,712.7,16.3,711.8z M31.5,711.8c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,712.7,30.6,712.7,31.5,711.8z M16.3,696.7c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,697.5,15.5,697.5,16.3,696.7z M31.5,696.7c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,697.5,30.6,697.5,31.5,696.7z M46.6,711.8c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,712.7,45.8,712.7,46.6,711.8z M61.7,711.8c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,712.7,60.9,712.7,61.7,711.8z M76.9,727c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C74.7,727.8,76,727.8,76.9,727z M92,742.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C89.8,743,91.2,743,92,742.1z M107.2,757.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C105,758.1,106.3,758.1,107.2,757.3z M16.3,833c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,833.8,15.5,833.8,16.3,833z M31.5,833c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,833.8,30.6,833.8,31.5,833z M46.6,833c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,833.8,45.8,833.8,46.6,833z M61.7,833c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,833.8,60.9,833.8,61.7,833z M76.9,833c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C74.7,833.8,76,833.8,76.9,833z M92,833c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C89.8,833.8,91.2,833.8,92,833z M107.2,833c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C105,833.8,106.3,833.8,107.2,833z M16.3,848.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,849,15.5,849,16.3,848.1z M31.5,848.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,849,30.6,849,31.5,848.1z M46.6,848.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,849,45.8,849,46.6,848.1z M61.7,848.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,849,60.9,849,61.7,848.1z M76.9,848.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C74.7,849,76,849,76.9,848.1z M92,848.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C89.8,849,91.2,849,92,848.1z M16.3,863.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,864.1,15.5,864.1,16.3,863.3z M31.5,863.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,864.1,30.6,864.1,31.5,863.3z M46.6,863.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,864.1,45.8,864.1,46.6,863.3z M61.7,863.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,864.1,60.9,864.1,61.7,863.3z M76.9,863.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C74.7,864.1,76,864.1,76.9,863.3z M16.3,878.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,879.2,15.5,879.2,16.3,878.4z M31.5,878.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,879.2,30.6,879.2,31.5,878.4z M46.6,878.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,879.2,45.8,879.2,46.6,878.4z M61.7,878.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,879.2,60.9,879.2,61.7,878.4z M16.3,893.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,894.4,15.5,894.4,16.3,893.6z M31.5,893.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,894.4,30.6,894.4,31.5,893.6z M16.3,908.7c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C14.1,909.5,15.5,909.5,16.3,908.7z M31.5,908.7c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C29.3,909.5,30.6,909.5,31.5,908.7z M46.6,893.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C44.4,894.4,45.8,894.4,46.6,893.6z M61.7,893.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C59.5,894.4,60.9,894.4,61.7,893.6z M76.9,878.4c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C74.7,879.2,76,879.2,76.9,878.4z M92,863.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C89.8,864.1,91.2,864.1,92,863.3z M107.2,848.1c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3
            C105,849,106.3,849,107.2,848.1z M-1.4,784.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,783.6-0.5,783.6-1.4,784.5z M-16.5,784.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,783.6-15.7,783.6-16.5,784.5z M-31.7,784.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,783.6-30.8,783.6-31.7,784.5z M-46.8,784.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,783.6-46,783.6-46.8,784.5z M-61.9,784.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,783.6-61.1,783.6-61.9,784.5z M-77.1,784.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,783.6-76.3,783.6-77.1,784.5z M-92.2,784.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-90,783.6-91.4,783.6-92.2,784.5z M-107.4,784.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-105.2,783.6-106.5,783.6-107.4,784.5z M-1.4,799.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,798.8-0.5,798.8-1.4,799.6z M-16.5,799.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,798.8-15.7,798.8-16.5,799.6z M-31.7,799.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,798.8-30.8,798.8-31.7,799.6z M-46.8,799.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,798.8-46,798.8-46.8,799.6z M-61.9,799.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,798.8-61.1,798.8-61.9,799.6z M-77.1,799.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,798.8-76.3,798.8-77.1,799.6z M-92.2,799.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-90,798.8-91.4,798.8-92.2,799.6z M-107.4,799.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-105.2,798.8-106.5,798.8-107.4,799.6z M-1.4,814.8c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,813.9-0.5,813.9-1.4,814.8z M-16.5,814.8c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,813.9-15.7,813.9-16.5,814.8z M-31.7,814.8c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,813.9-30.8,813.9-31.7,814.8z M-46.8,814.8c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,813.9-46,813.9-46.8,814.8z M-61.9,814.8c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,813.9-61.1,813.9-61.9,814.8z M-77.1,814.8c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,813.9-76.3,813.9-77.1,814.8z M-92.2,814.8c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-90,813.9-91.4,813.9-92.2,814.8z M-107.4,814.8c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-105.2,813.9-106.5,813.9-107.4,814.8z M-1.4,829.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,829.1-0.5,829.1-1.4,829.9z M-16.5,829.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,829.1-15.7,829.1-16.5,829.9z M-31.7,829.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,829.1-30.8,829.1-31.7,829.9z M-46.8,829.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,829.1-46,829.1-46.8,829.9z M-61.9,829.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,829.1-61.1,829.1-61.9,829.9z M-77.1,829.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,829.1-76.3,829.1-77.1,829.9z M-92.2,829.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-90,829.1-91.4,829.1-92.2,829.9z M-107.4,829.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-105.2,829.1-106.5,829.1-107.4,829.9z M-1.4,845c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,844.2-0.5,844.2-1.4,845z M-16.5,845c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,844.2-15.7,844.2-16.5,845z M-31.7,845c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,844.2-30.8,844.2-31.7,845z M-46.8,845c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,844.2-46,844.2-46.8,845z M-61.9,845c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,844.2-61.1,844.2-61.9,845z M-77.1,845c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,844.2-76.3,844.2-77.1,845z M-92.2,845c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-90,844.2-91.4,844.2-92.2,845z M-1.4,860.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,859.4-0.5,859.4-1.4,860.2z M-16.5,860.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,859.4-15.7,859.4-16.5,860.2z M-31.7,860.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,859.4-30.8,859.4-31.7,860.2z M-46.8,860.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,859.4-46,859.4-46.8,860.2z M-61.9,860.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,859.4-61.1,859.4-61.9,860.2z M-77.1,860.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,859.4-76.3,859.4-77.1,860.2z M-1.4,875.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,874.5-0.5,874.5-1.4,875.3z M-16.5,875.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,874.5-15.7,874.5-16.5,875.3z M-31.7,875.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,874.5-30.8,874.5-31.7,875.3z M-46.8,875.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,874.5-46,874.5-46.8,875.3z M-61.9,875.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,874.5-61.1,874.5-61.9,875.3z M-1.4,890.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,889.6-0.5,889.6-1.4,890.5z M-16.5,890.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,889.6-15.7,889.6-16.5,890.5z M-31.7,890.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,889.6-30.8,889.6-31.7,890.5z M-1.4,905.7c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,904.8-0.5,904.8-1.4,905.7z M-16.5,905.7c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,904.8-15.7,904.8-16.5,905.7z M-31.7,905.7c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,904.8-30.8,904.8-31.7,905.7z M-46.8,890.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,889.6-46,889.6-46.8,890.5z M-61.9,890.5c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,889.6-61.1,889.6-61.9,890.5z M-77.1,875.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,874.5-76.3,874.5-77.1,875.3z M-92.2,860.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-90,859.4-91.4,859.4-92.2,860.2z M-107.4,845c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-105.2,844.2-106.5,844.2-107.4,845z M-1.4,769.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,768.5-0.5,768.5-1.4,769.3z M-16.5,769.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,768.5-15.7,768.5-16.5,769.3z M-31.7,769.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,768.5-30.8,768.5-31.7,769.3z M-46.8,769.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,768.5-46,768.5-46.8,769.3z M-61.9,769.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,768.5-61.1,768.5-61.9,769.3z M-77.1,769.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,768.5-76.3,768.5-77.1,769.3z M-92.2,769.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-90,768.5-91.4,768.5-92.2,769.3z M-107.4,769.3c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-105.2,768.5-106.5,768.5-107.4,769.3z M-1.4,754.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,753.4-0.5,753.4-1.4,754.2z M-16.5,754.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,753.4-15.7,753.4-16.5,754.2z M-31.7,754.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,753.4-30.8,753.4-31.7,754.2z M-46.8,754.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,753.4-46,753.4-46.8,754.2z M-61.9,754.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,753.4-61.1,753.4-61.9,754.2z M-77.1,754.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,753.4-76.3,753.4-77.1,754.2z M-92.2,754.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-90,753.4-91.4,753.4-92.2,754.2z M-1.4,739.1c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,738.2-0.5,738.2-1.4,739.1z M-16.5,739.1c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,738.2-15.7,738.2-16.5,739.1z M-31.7,739.1c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,738.2-30.8,738.2-31.7,739.1z M-46.8,739.1c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,738.2-46,738.2-46.8,739.1z M-61.9,739.1c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,738.2-61.1,738.2-61.9,739.1z M-77.1,739.1c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,738.2-76.3,738.2-77.1,739.1z M-1.4,723.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,723.1-0.5,723.1-1.4,723.9z M-16.5,723.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,723.1-15.7,723.1-16.5,723.9z M-31.7,723.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,723.1-30.8,723.1-31.7,723.9z M-46.8,723.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,723.1-46,723.1-46.8,723.9z M-61.9,723.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,723.1-61.1,723.1-61.9,723.9z M-1.4,708.7c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,707.9-0.5,707.9-1.4,708.7z M-16.5,708.7c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,707.9-15.7,707.9-16.5,708.7z M-31.7,708.7c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,707.9-30.8,707.9-31.7,708.7z M-1.4,693.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C0.8,692.8-0.5,692.8-1.4,693.6z M-16.5,693.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-14.3,692.8-15.7,692.8-16.5,693.6z M-31.7,693.6c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-29.5,692.8-30.8,692.8-31.7,693.6z M-46.8,708.7c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-44.6,707.9-46,707.9-46.8,708.7z M-61.9,708.7c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-59.8,707.9-61.1,707.9-61.9,708.7z M-77.1,723.9c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-74.9,723.1-76.3,723.1-77.1,723.9z M-92.2,739.1c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-90,738.2-91.4,738.2-92.2,739.1z M-107.4,754.2c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3
            C-105.2,753.4-106.5,753.4-107.4,754.2z"
              />
              <path
                class="st3 an3"
                d="M-55,826.8c-51.5-31.8-112.9,0-99.8,46.4c41.9,148.1,262.1,148.1,304.1,0c13.1-46.4-48.3-78.2-99.8-46.4
            C18.4,794.9-23.9,794.9-55,826.8z"
              />
              <circle
                class="st3  an1"
                cx="-14"
                cy="77"
                r="179"
              />
            </g>
            <g class="logoall">
              <g class="logo">
                <polygon
                  class="st4"
                  points="1024.8,417.9 995.4,417.9 995.4,398.6 1005.5,398.6 1005.5,337.6 1130.8,337.6 1130.8,327.8 
              1150.1,327.8 1150.1,356.9 1024.8,356.9 		"
                />
                <polygon
                  class="st4"
                  points="1066.7,417.5 1047.4,417.5 1047.4,394 1015.1,394 1015.1,374.7 1066.7,374.7 		"
                />
                <rect
                  x="995.4"
                  y="463.8"
                  class="st4"
                  width="154.4"
                  height="19.3"
                />
                <polygon
                  class="st4"
                  points="1150.1,482.6 1130.8,482.6 1130.8,447.8 1024.8,447.8 1024.8,473.4 1005.5,473.4 1005.5,428.5 
              1150.1,428.5 		"
                />
                <rect
                  x="1047.4"
                  y="435"
                  class="st4"
                  width="19.3"
                  height="36.3"
                />
                <rect
                  x="1089.1"
                  y="435"
                  class="st4"
                  width="19.3"
                  height="38.4"
                />
                <rect
                  x="822.4"
                  y="440.8"
                  class="st4"
                  width="150.4"
                  height="19.3"
                />
                <rect
                  x="822.4"
                  y="398.6"
                  class="st4"
                  width="150.4"
                  height="19.3"
                />
                <rect
                  x="887.9"
                  y="327.8"
                  class="st4"
                  width="19.3"
                  height="154.8"
                />
                <polygon
                  class="st4"
                  points="1150.1,417.9 1089.1,417.9 1089.1,327.8 1108.4,327.8 1108.4,398.6 1130.8,398.6 1130.8,376.7 
              1150.1,376.7 		"
                />
                <polygon
                  class="st1"
                  points="821,354.9 821,374 974.1,357 974.1,337.6 		"
                />
                <path
                  class="st4"
                  d="M742.9,457.6c0.5-0.1,1.3-0.2,2.1-0.2c1.2,0,1.9,0.2,2.5,0.7c0.5,0.3,0.7,0.9,0.7,1.6c0,0.9-0.6,1.6-1.5,2v0
              c0.9,0.2,1.9,0.9,1.9,2.3c0,0.8-0.3,1.4-0.8,1.8c-0.6,0.6-1.6,0.8-3.1,0.8c-0.8,0-1.4-0.1-1.8-0.1V457.6z M744.1,461.2h1.1
              c1.2,0,1.9-0.6,1.9-1.5c0-1.1-0.8-1.5-2-1.5c-0.5,0-0.8,0-1,0.1V461.2z M744.1,465.5c0.2,0,0.6,0.1,1,0.1c1.2,0,2.3-0.4,2.3-1.7
              c0-1.2-1.1-1.7-2.3-1.7h-1V465.5z M757.8,461.8c0,3.1-1.9,4.7-4.2,4.7c-2.4,0-4-1.8-4-4.6c0-2.9,1.8-4.7,4.2-4.7
              C756.2,457.3,757.8,459.2,757.8,461.8z M750.8,462c0,1.9,1,3.6,2.9,3.6c1.8,0,2.9-1.7,2.9-3.7c0-1.8-0.9-3.7-2.9-3.7
              C751.8,458.2,750.8,460,750.8,462z M760.5,457.5v5.3c0,2,0.9,2.9,2.1,2.9c1.3,0,2.2-0.9,2.2-2.9v-5.3h1.2v5.2
              c0,2.8-1.5,3.9-3.4,3.9c-1.8,0-3.2-1.1-3.2-3.8v-5.3H760.5z M769.6,458.4h-2.7v-1h6.6v1h-2.7v8h-1.2V458.4z M775.7,457.5v9h-1.2
              v-9H775.7z M785.1,467.7c-1.2-0.3-2.4-0.7-3.4-1c-0.2-0.1-0.4-0.1-0.5-0.1c-2.1-0.1-4-1.7-4-4.5c0-2.9,1.8-4.7,4.2-4.7
              c2.4,0,4,1.9,4,4.5c0,2.3-1.1,3.8-2.6,4.3v0.1c0.9,0.2,1.9,0.4,2.6,0.6L785.1,467.7z M784.2,461.9c0-1.8-0.9-3.7-2.8-3.7
              c-2,0-2.9,1.8-2.9,3.8c0,1.9,1,3.6,2.9,3.6C783.1,465.6,784.2,463.9,784.2,461.9z M788,457.5v5.3c0,2,0.9,2.9,2.1,2.9
              c1.3,0,2.2-0.9,2.2-2.9v-5.3h1.2v5.2c0,2.8-1.5,3.9-3.4,3.9c-1.8,0-3.2-1.1-3.2-3.8v-5.3H788z M800.1,462.2h-3.5v3.2h3.9v1h-5v-9
              h4.8v1h-3.7v2.8h3.5V462.2z M758.7,470.8c0.6-0.1,1.4-0.2,2.2-0.2c1.2,0,2,0.2,2.6,0.7c0.5,0.4,0.7,1,0.7,1.7c0,1.2-0.7,2-1.7,2.3
              v0c0.7,0.2,1.1,0.9,1.3,1.8c0.3,1.3,0.5,2.1,0.7,2.5h-1.2c-0.1-0.3-0.3-1-0.6-2.1c-0.3-1.2-0.7-1.7-1.8-1.7h-1.1v3.9h-1.2V470.8z
              M759.9,474.9h1.2c1.2,0,2-0.7,2-1.7c0-1.2-0.8-1.7-2.1-1.7c-0.6,0-1,0.1-1.1,0.1V474.9z M770.5,475.5H767v3.2h3.9v1h-5v-9h4.8v1
              H767v2.8h3.5V475.5z M772.2,478.3c0.5,0.3,1.3,0.6,2.1,0.6c1.2,0,1.9-0.6,1.9-1.5c0-0.8-0.5-1.3-1.7-1.8c-1.5-0.5-2.4-1.3-2.4-2.5
              c0-1.4,1.2-2.4,2.9-2.4c0.9,0,1.6,0.2,2,0.4l-0.3,0.9c-0.3-0.2-0.9-0.4-1.7-0.4c-1.2,0-1.7,0.7-1.7,1.3c0,0.8,0.5,1.3,1.8,1.7
              c1.5,0.6,2.3,1.3,2.3,2.6c0,1.4-1,2.6-3.1,2.6c-0.9,0-1.8-0.3-2.3-0.6L772.2,478.3z M786.6,475.1c0,3.1-1.9,4.7-4.2,4.7
              c-2.4,0-4-1.8-4-4.6c0-2.9,1.8-4.7,4.2-4.7C785.1,470.6,786.6,472.5,786.6,475.1z M779.7,475.3c0,1.9,1,3.6,2.9,3.6
              c1.8,0,2.9-1.7,2.9-3.7c0-1.8-0.9-3.6-2.9-3.6C780.6,471.5,779.7,473.3,779.7,475.3z M788.1,470.8c0.6-0.1,1.4-0.2,2.2-0.2
              c1.2,0,2,0.2,2.6,0.7c0.5,0.4,0.7,1,0.7,1.7c0,1.2-0.7,2-1.7,2.3v0c0.7,0.2,1.1,0.9,1.3,1.8c0.3,1.3,0.5,2.1,0.7,2.5h-1.2
              c-0.1-0.3-0.3-1-0.6-2.1c-0.3-1.2-0.7-1.7-1.8-1.7h-1.1v3.9h-1.2V470.8z M789.3,474.9h1.2c1.2,0,2-0.7,2-1.7
              c0-1.2-0.8-1.7-2.1-1.7c-0.6,0-1,0.1-1.1,0.1V474.9z M797.1,471.7h-2.7v-1h6.6v1h-2.7v8h-1.2V471.7z M801.5,335h-0.9v107.2h0.9
              V335z M742.1,335h-0.9v107.2h0.9V335z M771.1,341.9c-0.1,0.1-0.3,0.2-0.5,0.2h-4.4l2.4,3.8c0.1,0.2,0.1,0.4,0,0.5
              c-0.1,0.1-0.2,0.2-0.3,0.2H762c-0.1,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.4-0.4l-2.5-4.1h-2.2l-2.8,4.2c-0.1,0.2-0.3,0.3-0.4,0.4
              c-0.2,0.1-0.4,0.1-0.6,0.1h-6.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2,0-0.3,0-0.5l6.6-10c0.1-0.1,0.2-0.2,0.4-0.3
              c0.2-0.1,0.4-0.1,0.6-0.1h6.1c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.3-0.1,0.5l-0.9,1.3h11.3c0.2,0,0.4,0.1,0.5,0.2
              c0.1,0.1,0.2,0.3,0.2,0.4v2.9C771.4,341.5,771.3,341.7,771.1,341.9z M755.1,383.2c-0.2,0.3-0.6,0.4-1.1,0.4h-7.1
              c-0.3,0-0.4-0.1-0.5-0.3c-0.1-0.2,0-0.4,0.1-0.6l9.6-8.9c0.4-0.4,0.9-0.7,1.4-0.7h6.8c0.3,0,0.4,0.1,0.5,0.3
              c0.1,0.2,0,0.4-0.2,0.6L755.1,383.2z M768.4,362.8c-0.1,0.1-0.3,0.2-0.5,0.2l-20.4,1.7c-0.2,0-0.4-0.1-0.5-0.2
              c-0.1-0.1-0.2-0.2-0.2-0.4v-3.1c0-0.4,0.3-0.6,0.8-0.7l7-0.6v-7.3h-6c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-3.1
              c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.6-0.2h18.7c0.5,0,0.7,0.2,0.7,0.6v2.9c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2
              h-5.8v6.7l6.3-0.5c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.3,0.2,0.5v2.9C768.6,362.5,768.6,362.7,768.4,362.8z M795.3,371.6h-20.4v12
              c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.3,0.2-0.5,0.2h-5.7c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.2-0.2-0.3-0.2-0.5v-12h-20.2
              c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-3.2c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.5-0.1h20.3v-1.6
              c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.2-0.2,0.4-0.2h5.9c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.5v1.6h20.3
              c0.5,0,0.8,0.2,0.8,0.6v2.9C796,371.3,795.8,371.6,795.3,371.6z M794.9,365c-0.1,0.3-0.3,0.4-0.6,0.4h-5.1c-0.8,0-1.5-0.2-2.1-0.7
              c-0.7-0.6-1.1-1.3-1.2-2.1h-5.1c-0.3,0-0.6-0.1-0.9-0.2l-1.1-0.7l-1.9,1.6c-0.2,0.2-0.4,0.3-0.7,0.5c-0.3,0.1-0.6,0.2-1,0.2h-6.1
              c-0.2,0-0.3-0.1-0.4-0.3c-0.1-0.2-0.1-0.4,0.1-0.5l4.9-4.4l-6.7-3.9c-0.2-0.1-0.3-0.3-0.2-0.5c0-0.2,0.1-0.3,0.3-0.3h5.7
              c0.3,0,0.6,0.1,0.8,0.2l2.2,1.3v-3.1h-5.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-3.1c0-0.2,0.1-0.4,0.2-0.5
              c0.1-0.1,0.3-0.2,0.6-0.2h17.4c1.7,0,3,0.4,3.8,1.2c0.7,0.7,1,1.6,1,2.9v9.2h3.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.2,0,0.3
              L794.9,365z M795.7,341.9c-0.1,0.1-0.3,0.2-0.5,0.2h-4.4l2.3,3.8c0.1,0.2,0.1,0.4,0,0.5c-0.1,0.1-0.2,0.2-0.4,0.2h-6.2
              c-0.1,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.4-0.4l-2.4-4.1h-2.9l-2.5,4.1c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-6.2
              c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0-0.3,0.1-0.5l5.8-9.6c0.3-0.5,0.7-0.7,1.2-0.7h6.1c0.2,0,0.4,0.1,0.4,0.3c0.1,0.2,0,0.3,0,0.5
              l-0.8,1.3h12.1c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.4v2.9C796,341.5,795.9,341.7,795.7,341.9z M796.2,383.3
              c-0.1,0.2-0.3,0.3-0.4,0.3h-7.3c-0.5,0-0.9-0.1-1.1-0.4l-9.4-9.1c-0.2-0.2-0.2-0.4-0.2-0.6c0.1-0.2,0.2-0.3,0.5-0.3h6.6
              c0.6,0,1.1,0.2,1.6,0.7l9.7,8.9C796.3,382.9,796.3,383.1,796.2,383.3z M785.9,352.4h-3.6v4.2c0,0.3-0.1,0.7-0.2,1
              c-0.2,0.4-0.4,0.7-0.6,1.1l4.4,2.7V352.4z M753.6,410.6h2.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.5v29
              c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.3,0.3-0.4,0.3H750c-0.2,0-0.3-0.1-0.4-0.3c-0.1-0.2-0.2-0.3-0.2-0.5v-28.4h-2.7
              c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4l6.3-18.3c0-0.1,0.1-0.2,0.3-0.3c0.2-0.1,0.3-0.2,0.5-0.2h5.7
              c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.2,0.4,0.1,0.5L753.6,410.6z M764.6,440.3c-0.1,0.3-0.3,0.4-0.7,0.4h-5.8c-0.1,0-0.3-0.1-0.3-0.2
              c-0.1-0.1-0.1-0.3,0-0.5l2.6-8.6c0-0.1,0.2-0.2,0.4-0.3c0.2,0,0.4-0.1,0.5-0.1h5.5c0.1,0,0.3,0.1,0.4,0.2c0.1,0.2,0.1,0.3,0.1,0.4
              L764.6,440.3z M795.9,408.6c-0.1,0.1-0.3,0.2-0.4,0.2h-36.6c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.2-0.4v-2.8
              c0-0.4,0.2-0.6,0.6-0.6h8.7l-2.1-5.3l-4.5,0.1c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-2.6c0-0.2,0.1-0.3,0.2-0.4
              c0.1-0.1,0.3-0.1,0.5-0.1h13.2l-1.7-2.8c-0.1-0.1-0.1-0.3,0.1-0.5c0.1-0.2,0.2-0.2,0.4-0.2h6.6c0.4,0,0.6,0.1,0.7,0.4l1.9,3.1
              h11.5c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.3,0.2,0.4v2.4c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.3,0.2-0.5,0.2l-3.8-0.1l-1.9,5.3h7.4
              c0.5,0,0.7,0.2,0.7,0.6v2.7C796.1,408.4,796,408.5,795.9,408.6z M792.7,427.3c-0.5,0.5-1.2,0.8-2.1,0.8h-27.2
              c-0.7,0-1.3-0.2-1.7-0.5c-0.7-0.5-1.1-1.2-1.1-2.2v-11.2c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.4-0.6,0.6-0.9c0.7-0.7,1.4-1,2.2-1h26.5
              c0.9,0,1.7,0.3,2.3,1c0.6,0.7,0.9,1.4,0.9,2.3v10.7C793.6,426,793.3,426.7,792.7,427.3z M786.9,415.2h-19.2v3h19.2V415.2z
              M786.9,421.6h-19.2v3h19.2V421.6z M785.3,440.7c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.2,0.1h-12.4c-0.5,0-0.9-0.1-1.3-0.3
              c-0.4-0.2-0.8-0.5-1.1-0.7c-0.7-0.8-1.1-1.7-1.1-2.8v-6.6c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.2-0.2,0.4-0.2h5.8
              c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.5v6.9h10.3c0.1,0,0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.3,0.1,0.3L785.3,440.7z
              M772.4,399.6l2.1,5.3h6.6l1.7-5.3H772.4z M785.5,435.8c-0.1,0.1-0.2,0.2-0.3,0.2h-4.7c-0.1,0-0.3,0-0.4-0.1
              c-0.1,0-0.2-0.1-0.2-0.2l-1.7-5.5c0-0.1,0-0.2,0.1-0.4c0.1-0.2,0.2-0.3,0.4-0.3h4.5c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.3
              l1.7,5.4C785.6,435.5,785.6,435.7,785.5,435.8z M796.3,440.5c-0.1,0.1-0.2,0.2-0.3,0.2h-5.9c-0.4,0-0.6-0.1-0.7-0.4l-2.6-9.2
              c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.2h5.7c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3l2.6,9.2
              C796.4,440.2,796.4,440.4,796.3,440.5z"
                />
              </g>
              <g class="t1">
                <path
                  class="st4 ans2"
                  d="M769.9,674.2c13.9-12.5,22.1-22.1,22.1-30c0-5.2-2.8-8.3-7.7-8.3c-3.7,0-6.9,2.4-9.4,5.3l-5.5-5.5
			c4.7-5,9.3-7.8,16.3-7.8c9.7,0,16.1,6.2,16.1,15.8c0,9.3-7.7,19.2-17,28.4c2.6-0.3,6-0.6,8.3-0.6h11.1v8.6h-34.3L769.9,674.2
			L769.9,674.2z M810.8,674.1l4.7-6.5c2.9,2.8,6.5,5.2,11.3,5.2c5.3,0,9.1-3.4,9.1-9.4s-3.5-9.3-8.7-9.3c-3,0-4.7,0.8-7.7,2.7
			l-4.6-3l1.4-24.8h27.4v8.5h-18.6l-1,10.9c2-1,3.8-1.5,6.2-1.5c8.6,0,15.8,5.1,15.8,16.3c0,11.4-8.4,18-17.9,18
			C820,681,814.6,677.9,810.8,674.1z M854.4,667.3c0-6.6,4-10.8,8.8-13.4v-0.3c-3.9-2.8-6.8-6.7-6.8-12.2c0-8.2,6.5-13.5,15.4-13.5
			c9.1,0,14.9,5.5,14.9,13.6c0,5.1-3.2,9.3-6.6,11.7v0.3c4.7,2.6,8.6,6.7,8.6,13.7c0,7.8-6.9,13.7-17.2,13.7
			C861.8,681,854.4,675.4,854.4,667.3z M879.3,667c0-5.3-4.7-7.5-11.4-10.2c-2.8,2.2-4.7,5.7-4.7,9.5c0,4.6,3.8,7.6,8.6,7.6
			C876.1,673.8,879.3,671.4,879.3,667z M878.5,642.2c0-4.2-2.5-7.1-6.8-7.1c-3.5,0-6.2,2.3-6.2,6.4c0,4.8,3.9,7.2,9.3,9.3
			C877.3,648.2,878.5,645.3,878.5,642.2z M919.8,637.4h-23v-8.5h34.1v6.2c-11.9,14.5-13.5,25-14.3,45.1h-10.3
			C907.1,662.3,910.1,650.9,919.8,637.4z M938.9,650.8h18.9v7.3h-18.9V650.8z M964.3,674.1l4.7-6.5c2.9,2.8,6.5,5.2,11.3,5.2
			c5.3,0,9.1-3.4,9.1-9.4s-3.5-9.3-8.7-9.3c-3,0-4.7,0.8-7.7,2.7l-4.6-3l1.4-24.8h27.4v8.5h-18.6l-1,10.9c2-1,3.8-1.5,6.2-1.5
			c8.6,0,15.8,5.1,15.8,16.3c0,11.4-8.4,18-17.9,18C973.5,681,968.1,677.9,964.3,674.1z M1007.6,674.2c13.9-12.5,22.1-22.1,22.1-30
			c0-5.2-2.8-8.3-7.7-8.3c-3.7,0-6.9,2.4-9.4,5.3l-5.5-5.5c4.7-5,9.3-7.8,16.3-7.8c9.7,0,16.1,6.2,16.1,15.8c0,9.3-7.7,19.2-17,28.4
			c2.6-0.3,6-0.6,8.3-0.6h11.1v8.6h-34.3V674.2z M1048.6,674.1l4.7-6.5c2.9,2.8,6.5,5.2,11.3,5.2c5.3,0,9.1-3.4,9.1-9.4
			s-3.5-9.3-8.7-9.3c-3,0-4.7,0.8-7.7,2.7l-4.6-3l1.4-24.8h27.4v8.5h-18.7l-1,10.9c2-1,3.8-1.5,6.2-1.5c8.6,0,15.8,5.1,15.8,16.3
			c0,11.4-8.4,18-17.9,18C1057.8,681,1052.4,677.9,1048.6,674.1z M1115.5,637.4h-23v-8.5h34.1v6.2c-11.9,14.5-13.5,25-14.3,45.1
			h-10.5C1102.7,662.3,1105.7,650.9,1115.5,637.4z"/>
		<path class="st4" d="M429.8,579.7c-0.7,4.4-2,9.3-3.2,12.4c-1.2-0.7-3.7-1.4-5.1-1.8c1.4-2.9,2.6-7.6,3.2-11.6L429.8,579.7z
			 M438.8,576.6c-0.1-0.8-0.3-1.6-0.5-2.6c-11.5,1.8-13.5,2.4-14.7,3c-0.3-1.1-1.2-4-1.9-5.6c1.4-0.3,2.6-1.3,4.3-3.2
			c0.8-0.7,2.1-2.2,3.7-4.3c-4.4,0.6-5.6,0.9-6.4,1.2c-0.3-1.1-1.2-3.9-1.8-5.4c1-0.3,2-1.2,3.1-2.7c1.2-1.4,4.8-7,6.9-12.5l5.4,2.4
			c-2.2,4.2-4.9,8.5-7.7,12.1l4.2-0.3c1.4-2,2.7-4.3,3.9-6.5l4.9,3.4c-3.1,5-7.1,10-11,14.3l5.6-0.8c-0.4-1.3-0.8-2.6-1.2-3.8
			l4.6-1.2c1.4,3.5,2.8,8.1,3.4,11L438.8,576.6z M435.6,578.2c0.8,3.7,1.5,8.3,1.8,11.5l-5,1.3c-0.1-3.1-0.8-7.9-1.5-11.6
			L435.6,578.2z M441.3,576.9c1.3,3,2.6,6.9,3.2,9.5l-4.5,1.8c-0.5-2.6-1.8-6.7-3-9.7L441.3,576.9z M467.3,554c0,0-0.1,2.2-0.1,2.9
			c-0.6,22.2-1.2,30.1-3.1,32.7c-1.4,1.9-2.8,2.5-4.9,2.8c-1.8,0.4-4.9,0.3-7.9,0.2c-0.1-1.7-0.9-4.4-2-6.1c3.1,0.3,5.9,0.3,7.2,0.3
			c1,0.1,1.6-0.2,2.1-0.8c1.4-1.5,2-8.9,2.4-26.1h-11.9c-1.2,2.6-2.5,4.9-3.8,6.8c-1.1-0.9-3.7-2.7-5.1-3.4
			c3.5-4.3,6.2-11.4,7.6-18.3l5.9,1.2c-0.6,2.7-1.4,5.4-2.3,7.9h15.9V554z M454.1,579.7c-1.5-3.2-5.2-7.9-8.6-11.2l4-3.1
			c3.4,3.1,7.4,7.6,9,10.8L454.1,579.7z M474.9,585.1l4.3-6c2.6,2.6,5.9,4.8,10.2,4.8c4.8,0,8.2-3.1,8.2-8.7c0-5.5-3.1-8.6-7.8-8.6
			c-2.8,0-4.3,0.7-6.9,2.5l-4.2-2.8l1.3-22.9h24.8v7.9H488l-0.9,10c1.8-0.9,3.4-1.3,5.6-1.3c7.8,0,14.3,4.7,14.3,15
			c0,10.5-7.6,16.6-16.2,16.6C483.2,591.6,478.3,588.6,474.9,585.1z M548.8,545.6c2.3,7.5,7.5,14.8,14.5,18.5
			c-1.2,1.3-3.3,3.9-4.2,5.7c-1.3-0.9-2.6-1.9-3.8-3c-0.6,15.2-1.2,21.1-2.9,23.2c-1.3,1.7-2.6,2.2-4.6,2.5
			c-1.7,0.3-4.6,0.3-7.6,0.2c-0.1-1.9-0.9-4.4-2-6.2c2.8,0.2,5.4,0.3,6.7,0.3c0.9,0,1.5-0.1,2-0.7c1-1.1,1.6-5.7,2-16.4H539
			c-1.3,9.9-4.5,18.7-17.6,23.7c-0.8-1.8-2.5-4.3-4-5.7c11.4-3.8,14-10.6,15-18h-8.5v-3.4c-1.2,1.3-2.5,2.6-3.8,3.7
			c-1.1-1.2-3.6-3.5-5-4.5c5.7-4.2,10.2-11.1,12.5-18.8l6.3,1.5c-1.9,5.8-4.6,11.1-7.9,15.5h26c-3.3-3.7-5.9-8-7.6-12.3h-8v-5.7
			L548.8,545.6L548.8,545.6z M586.6,582.7l0.3,4.8c-5.8,1.4-11.8,2.8-16.3,3.9l-1.1-5.5c1.9-0.3,4.3-0.8,6.9-1.3v-12.8h-6.2v-5.2
			h6.2v-3.8h-3.5v-2.3c-0.8,0.8-1.5,1.5-2.2,2.2c-0.3-1.3-1.4-4.8-2-6.3c3.4-2.8,7.2-7.3,9.3-11.9l4,2.1c-0.3,0.6-0.6,1.2-0.9,1.9
			c2.8,1.9,5.7,4,7.3,5.8l-2.3,5c-0.4-0.4-0.7-0.8-1.1-1.2v4.8h-4.1v3.8h5.6v5.2h-5.6v12L586.6,582.7z M573.9,573.7
			c0.8,2.8,1.5,6.5,1.7,8.8l-3.5,1.2c-0.2-2.4-0.9-6.1-1.6-8.9L573.9,573.7z M584.7,557.8c-1.7-1.6-3.9-3.4-6.1-4.9
			c-1.1,1.8-2.2,3.3-3.4,4.9H584.7z M581.3,581.9c0.6-2.3,1.3-6,1.6-8.6l3.9,1.2c-0.9,3-1.8,6.4-2.4,8.6L581.3,581.9z M604.7,587.4
			h11.6v4.3h-28.8v-4.3H599v-2.3h-9.7v-4.2h9.7v-2h-9v-15h24.1v15h-9.4v2h9.9v4.2h-9.9L604.7,587.4L604.7,587.4z M616.5,557.7v4.6
			h-28.9v-4.6h6.2c-0.3-1.1-0.7-2.3-1.2-3.3l3.2-0.6h-7v-4.6h10.3c-0.4-1.1-0.9-2.3-1.4-3.1l5.5-1.3c1,1.3,1.8,3.1,2.1,4.5h9.7v4.6
			h-7l3.7,0.7c-0.6,1.2-1.1,2.3-1.6,3.3h6.4V557.7z M595,569.7h4.3v-2.2H595V569.7z M595,575.3h4.3V573H595V575.3z M597.8,553.7
			c0.6,1.3,1.2,2.9,1.4,4h5.5c0.5-1.3,1-2.7,1.3-4H597.8z M608.8,567.5h-4.4v2.2h4.4V567.5z M608.8,573.1h-4.4v2.3h4.4V573.1z
			 M626.9,559.3c-0.6,0.7-1.2,1.3-1.8,2c-0.5-1.1-2-3.4-2.9-4.4c3-2.9,5.8-7.4,7.2-11.9l4.6,1.3c-0.4,1.3-1,2.6-1.6,4h3.8
			c-0.4-1.2-1-2.6-1.5-3.8l4.9-1.4c0.8,1.6,1.7,3.6,2.2,5.1h3.7v3.9h-5.2v2h4.2v0.1c2.6-3,4.9-7.1,6.3-11.2l4.7,1.3
			c-0.5,1.3-1,2.6-1.6,4h3.9c-0.4-1.2-1-2.6-1.5-3.7l4.9-1.4c0.8,1.5,1.7,3.5,2.2,5h4.5v3.8h-6.3v2h4.9v3.6h-4.9v2h4.9v3.6h-4.9v2
			h6.9v3.8h-20.2v-11.5c-0.6,0.8-1.3,1.5-1.8,2.2c-0.4-0.7-1.3-1.9-2.1-2.9v0.9h-4.2v2h4.2v3.6h-4.2v2h5.7v3.8h-19.2v-11.8H626.9z
			 M664,575.3c-2.4,3.9-5.8,7-9.8,9.5c4.5,1,9.5,1.6,14.7,2c-1.3,1.4-2.9,4.1-3.7,5.8c-7-0.7-13.4-2-18.9-4.1
			c-6.3,2.3-13.4,3.7-20.8,4.4c-0.5-1.6-1.5-4.1-2.4-5.5c5.5-0.4,10.8-1.2,15.8-2.4c-2.8-1.7-5.3-3.8-7.6-6l2.3-1.1h-7.7V573h33
			l1.1-0.3L664,575.3z M635.6,554.1H632v2h3.6V554.1z M635.6,559.6H632v2h3.6V559.6z M635.6,565.1H632v2h3.6V565.1z M638.3,578
			c2.2,1.8,5,3.2,8.3,4.5c2.9-1.2,5.3-2.7,7.4-4.5H638.3z M657.1,554.1h-3.5v2h3.5V554.1z M657.1,559.6h-3.5v2h3.5V559.6z
			 M657.1,565.1h-3.5v2h3.5V565.1z M691.9,571.9c-1.6,0.5-3.2,1-4.8,1.5v13c0,2.8-0.5,4.4-2,5.4c-1.6,1-3.7,1.2-7,1.1
			c-0.2-1.5-0.8-4-1.5-5.7c1.8,0.1,3.5,0.1,4.1,0.1s0.9-0.2,0.9-0.9V575c-1.7,0.5-3.3,1-4.8,1.4l-1.3-5.9c1.7-0.4,3.8-0.9,6.1-1.5
			v-8.9h-5.3v-5.6h5.3v-9.7h5.4v9.7h4.6v5.6H687v7.4l4.1-1.2L691.9,571.9z M709.5,587c1.4,0.2,2.8,0.2,4.2,0.2c1.6,0,7.6,0,9.6-0.1
			c-0.8,1.3-1.5,3.8-1.7,5.3h-8.4c-7,0-12.4-1.2-16.2-6.1c-1.2,2.7-2.6,5-4.4,6.8c-0.9-0.9-3.3-2.6-4.5-3.3
			c3.6-3.2,5.4-8.5,6.2-14.6l5.4,0.7c-0.3,1.6-0.5,3.2-0.9,4.8c1.3,2.3,3,3.8,5.1,4.8v-11h-9.4V570h9.4v-2.6h-12.4v-4.7h12.4v-2.4
			h-9.4v-4.6h9.4v-2.2h-11.6v-4.8h11.6v-3.9h5.5v3.9h12.7v4.8h-12.7v2.2h10.7v6.9h3.1v4.7h-3.1v7.2h-10.7v3h11v4.8h-11v4.7H709.5z
			 M709.5,562.6h5.1v-2.4h-5.1V562.6z M709.5,570h5.1v-2.6h-5.1V570z M743.4,569.7c-0.8,4.2-2,8.8-3.6,12.7c0.8,0.5,1.7,1.2,3,2
			c3.4,2.1,8.2,2.4,14.1,2.4c5.8,0,14.8-0.5,20.4-1.3c-0.7,1.6-1.7,4.6-1.8,6.3c-4.1,0.3-13.1,0.6-19,0.6c-6.7,0-10.9-0.6-14.6-2.7
			c-2.1-1.2-3.9-2.9-4.9-2.9c-1.2,0-3,3.5-4.2,6.4l-4-5.8c1.8-2.2,3.7-4,5.4-5c1.2-2.6,2.2-5.8,2.8-8.9h-6.4c1.2-3,2.6-7.2,3.7-11.2
			h-4.6v-5.5h12c-1.1,3.7-2.4,7.8-3.5,11.3h0.8l0.9-0.2L743.4,569.7z M735.2,555.4c-0.6-2.4-1.9-6.1-2.8-8.9l5.3-1.6
			c1,2.8,2.3,6.2,2.9,8.5L735.2,555.4z M747.4,556.7h-4.2v-10.2h32.1v10.2H770v1.9h-7.8v1.9h10.5v15.1h-10.5v1.9h13v4.4h-13v3.4
			h-5.9v-3.4h-12.8v-4.4h12.8v-1.9H746v-15.1h10.4v-1.9h-9V556.7z M748.6,550.9v3.4h7.8v-2h5.9v2h7.3v-3.4H748.6z M751,566.3h5.3
			v-2.1H751V566.3z M756.4,571.8v-2.1H751v2.1H756.4z M762.2,564.2v2.1h5.1v-2.1H762.2z M767.4,569.8h-5.1v2.1h5.1V569.8z
			 M802.9,578.1l0.6,5.7c-6.5,1.6-13.5,3.3-18.8,4.5l-1.3-6.1c2.8-0.5,6.5-1.2,10.4-2.1c1.2-5.3,2.3-13.4,2.8-19.2l5.5,0.9
			c-1,5.6-2.2,12.3-3.5,17.2L802.9,578.1z M793.8,553.9H804v5.6h-20.2v-5.6h8.5c-0.6-2.1-1.8-5-2.9-7.3l5.1-1.5
			c1.4,2.3,2.7,5.4,3.3,7.4L793.8,553.9z M790,561.3c1.1,5.4,2,12.3,2.3,16.9l-4.9,1c-0.2-4.6-1.1-11.7-2-17.1L790,561.3z
			 M818.2,560.1v8.9h10v23.7H822v-2.3h-12v2.5h-5.9V569h7.8v-24.1h6.2v9.5h11.7v5.8h-11.6V560.1z M822.1,574.7h-12v10h12V574.7z
			 M861.1,543.2v51.5h-2.3v-51.5H861.1z M909.8,548.2c-3.7,8.4-9.6,17.1-15,22.5c-1.2-1.3-3.8-4-5.3-5.3c5.3-4.6,10.6-12,13.7-19.5
			L909.8,548.2z M923.9,571.8c4.1,5.7,8.7,13,11.2,17.8l-6.2,3.3c-0.7-1.5-1.6-3.2-2.6-5c-24.4,2-27.8,2.3-30.3,3.2
			c-0.4-1.3-1.6-4.7-2.5-6.5c1.7-0.5,3-1.9,5-4.1c2.1-2.2,8.4-11,12.4-19.5l7,3c-3.9,6.9-8.7,13.8-13.6,19.4l18.5-1.2
			c-1.6-2.6-3.3-5.3-4.8-7.5L923.9,571.8z M924.9,546.1c2.2,7.2,6.8,14.5,13,18.3c-1.4,1.3-3.5,4.3-4.5,6.2
			c-5.8-4.5-10.2-11.4-13.1-18.2h-8.1v-6.3H924.9z M988.3,546.8V593h-5.9v-1.9h-31.5v1.9h-5.6v-46.2H988.3z M982.5,585.6v-33.3H951
			v33.3H982.5z M968.8,578.7c-0.2,1.1-0.1,2.8,0,3.7c-8.6,2.2-10,2.7-10.9,3.2c-0.2-0.9-0.9-2.5-1.4-3.1c0.8-0.4,1.7-0.9,1.7-2.9
			v-1.9c-1.4,0.5-2.7,1-4,1.4c-0.6-0.8-2-2.6-2.9-3.4c3.3-0.7,6.8-1.8,9.6-3.1h-5.8v-7.4h23.3v7.4h-10.8c-1.4,1.2-3.2,2.3-5.1,3.2
			v4.4L968.8,578.7z M980.7,563.8h-28v-3.4h11.2v-2H955v-3.3h8.9v-2.2h5.4v2.2h9.3v3.3h-9.3v2h11.3L980.7,563.8L980.7,563.8z
			 M960.2,570h13v-2.1h-13V570z M981.3,575.5c-1.3,1-2.8,1.9-4.2,2.7c1.8,1.3,3.3,2.5,4.4,3.5l-3.8,2.3c-2.5-2.4-7.8-6.3-12.1-9
			l3.6-2.1c1.4,0.9,3,2,4.7,3c1.4-1,2.9-2.1,3.8-2.9L981.3,575.5z M1016.8,587.4c0,2.3-0.4,3.6-2,4.4c-1.5,0.8-3.5,0.8-6.6,0.8
			c-0.2-1.4-1-3.3-1.6-4.6c1.8,0.1,3.8,0.1,4.3,0.1c0.6,0,0.8-0.2,0.8-0.8v-2.7h-7.9c-0.5,3.1-1.5,6.2-3.3,8.5
			c-0.7-1.1-2.7-3.2-3.8-3.9c2.3-3.2,2.5-7.5,2.5-11.2v-10.5h17.5L1016.8,587.4L1016.8,587.4z M1018.9,565.8h-21.7v-4.4h8.4v-2.1
			h-6.4v-4.1h6.4v-1.9h-7.7V549h7.7v-4.2h5.5v4.2h7.1v4.3h-7.1v1.9h5.8v4.1h-5.8v2.1h7.7L1018.9,565.8L1018.9,565.8z M1011.8,580.6
			v-2.4h-7.4c0,0.8,0,1.5-0.1,2.4H1011.8z M1004.4,571.9v2.4h7.4v-2.4H1004.4z M1043.7,573.5h-2.8v7.8h-8.5v5.5
			c0,2.7-0.5,4.1-2.3,4.9c-1.7,0.8-4.1,0.9-7.4,0.9c-0.2-1.5-0.9-3.7-1.6-5.2c1.9,0.1,4.1,0.1,4.8,0.1c0.7,0,0.9-0.2,0.9-0.8v-5.4
			h-6.7v-4.8h6.7v-3h-8.7v-5.2h8.7v-2.7h-6.4v-4.8h1.6c-0.6-1.8-1.7-4-2.7-5.7l4.2-1.7c1.3,1.9,2.6,4.5,3.2,6.2l-2.6,1.2h16.8v7.5
			h2.8V573.5z M1042.8,549.3c-6.3,2-15.2,3.2-22.9,3.8c-0.2-1.2-0.8-3.1-1.4-4.2c7.2-0.7,15.4-2,20.2-3.6L1042.8,549.3z
			 M1028.6,560.5c-0.2-1.9-1-4.8-1.7-7l4.5-1c0.9,2.2,1.6,5,2,6.8L1028.6,560.5z M1032.4,568.3h3.6v-2.7h-3.6V568.3z M1036,573.5
			h-3.6v3h3.6V573.5z M1034.1,559.3c1-2.1,2.3-5.3,3-7.6l4.9,1.2c-1.2,2.8-2.5,5.6-3.5,7.6L1034.1,559.3z M1085.3,566.2
			c3.1,3.7,7.7,6.8,12.7,8.5c-1.4,1.2-3.3,3.7-4.2,5.3c-3-1.3-5.9-3.2-8.4-5.6v8.4H1067v1.5c0,2.1,0.7,2.5,4.7,2.5h12.2
			c3.2,0,3.8-0.8,4.2-5.4c1.4,0.9,3.8,1.8,5.5,2.2c-0.8,6.9-2.7,8.7-9.3,8.7h-12.9c-8,0-10.3-1.6-10.3-7.9v-8.8
			c-2.2,2-4.6,3.7-7.1,5c-1-1.2-3.1-3.6-4.4-4.7c4.8-2.2,9.2-5.6,12.2-9.8H1051v-5.5h12.2v-5h-9.1v-5.4h9.1v-5.4h6v5.4h9.1v-5.4h6.1
			v5.4h9.5v5.4h-9.5v5h12.5v5.5L1085.3,566.2L1085.3,566.2z M1068.8,566.2c-0.6,1.1-1.3,2.1-2,3.1h14c-0.8-1-1.4-2.1-2-3.1H1068.8z
			 M1079.6,574.2h-12.7v3.6h12.7V574.2z M1069.1,560.6h9.1v-5h-9.1V560.6z M1128.4,543.2v51.5h-2.3v-51.5H1128.4z M1185.3,565
			c3.3,10,9.4,18.1,18.9,22c-1.5,1.3-3.5,4-4.4,5.8c-9.1-4.4-15-11.9-18.9-21.8c-2.7,8.6-8.2,16.6-18.9,21.9c-1-1.7-2.7-3.9-4.5-5.2
			c11.6-5.3,16.3-13.8,18.3-22.6h-17.4v-6.3h18.4c0.5-4.8,0.5-9.5,0.5-13.7h6.6c0,4.2-0.1,9-0.6,13.7h19.8v6.3h-17.8V565z
			 M1255.6,585.9c0,3.2-0.7,4.9-2.6,5.9s-4.7,1.2-8.9,1.2c-0.3-1.7-1.2-4.5-2.1-6.1c2.7,0.2,5.6,0.2,6.5,0.1s1.2-0.4,1.2-1.2v-32.7
			h-31v39.8h-5.9v-45.7h42.8V585.9z M1246.7,561.8h-25.2v-5.1h25.2V561.8z M1229.5,583v3.4h-5.5v-20.6h20.5V583H1229.5z
			 M1229.5,570.8v7.1h9.4v-7.1H1229.5z M1300.6,585.5c3.2,0,7.2-0.3,9.3-0.7c0.2,1.5,0.6,4.3,0.9,5.7c-1.9,0.5-6.4,0.6-10.4,0.6
			h-19.9c-8.9,0-13.1-2.8-13.1-12.3V552h-2v-5.4h42.9v5.4h-35v27.2c0,4.5,1.5,6.2,6.7,6.2L1300.6,585.5L1300.6,585.5z M1275.5,568.9
			h13.9v13.8h-13.9V568.9z M1280.4,578.7h3.9v-5.6h-3.9V578.7z M1300.5,566.7h-19.8v-12.2h19.8V566.7z M1294.5,558.4h-8.3v4.2h8.3
			V558.4z M1305.9,582.8h-14.2V569h14.2V582.8z M1300.7,573.1h-4.1v5.6h4.1V573.1z M1364,585v6h-45.9v-6h6.2v-22.9h6.3V585h8.8
			v-30.8h-19.5v-6h42.7v6h-16.9v11.3h14.3v6h-14.3V585H1364z M1385.8,575c-0.5-1.4-1.4-3.4-2.4-5.5v23.3h-5.8v-18.9
			c-1.3,3.8-2.8,7.3-4.4,9.7c-0.6-1.8-1.9-4.5-2.9-6.2c3.1-4,5.8-11.2,7.2-17.4h-6.1v-5.7h6.1v-9.6h5.8v9.6h5v5.7h-5v1.6
			c1.4,1.9,5,7.7,5.9,9.2L1385.8,575z M1417.8,571.5c-2,3.4-4.6,6.6-7.6,9.6c3,2.4,6.4,5.4,8.3,7.4l-4.6,4c-1.7-2.1-4.9-5.2-8-7.8
			c-4.7,3.5-10.1,6.4-16.2,8.3c-0.8-1.4-2.4-3.9-3.5-5.2c11.9-3.3,21.3-10.4,25.4-18.5L1417.8,571.5z M1414.3,561.4
			c-4.7,8.3-12.7,15.8-22.2,20.2c-0.8-1.3-2.3-3.6-3.3-4.7c4.6-1.9,8.9-4.7,12.4-7.9c-7.6,0.6-9.2,0.9-10.3,1.3
			c-0.2-1.4-1-4.3-1.5-5.6c1.2-0.3,3-0.7,4-1.7c0.8-0.9,2.7-4,4.3-6.6h-8.4v-5.6h11.8c-0.4-1.6-1.1-3.3-1.8-4.8l5.6-1.8
			c1.1,2.1,2.2,4.6,2.6,6.6h10.2v5.6h-13.9c-1.5,2.5-3.2,5.3-4.7,7.6l6.7-0.3c1-1.4,2-2.9,2.7-4.4L1414.3,561.4z M1434.5,563.7
			c-0.7,6.6-2,15.5-3.7,21.4l-6.1-2.5c1.8-5.5,3.2-13.2,3.9-20L1434.5,563.7z M1444,559.5v23.9c0,2.4,0.4,2.8,2.8,2.8h7.2
			c2.5,0,3-1.6,3.3-9.7c1.4,1.1,4,2.2,5.7,2.6c-0.7,9.8-2.3,12.8-8.5,12.8h-8.4c-6.6,0-8.4-2.1-8.4-8.5v-23.9L1444,559.5L1444,559.5
			z M1443.5,545.7c4.5,2.9,10.9,7.5,14,10.7l-4.4,4.8c-2.7-3.1-8.9-8-13.6-11.3L1443.5,545.7z M1465.5,560.9
			c2.8,6.1,5.4,13.8,6.5,19.1l-6.1,2.6c-0.8-5.2-3.4-13.4-6-19.4L1465.5,560.9z"
                />
                <path
                  class="st4 ans1"
                  d="M429.8,579.7c-0.7,4.4-2,9.3-3.2,12.4c-1.2-0.7-3.7-1.4-5.1-1.8c1.4-2.9,2.6-7.6,3.2-11.6L429.8,579.7z
              M438.8,576.6c-0.1-0.8-0.3-1.6-0.5-2.6c-11.5,1.8-13.5,2.4-14.7,3c-0.3-1.1-1.2-4-1.9-5.6c1.4-0.3,2.6-1.3,4.3-3.2
              c0.8-0.7,2.1-2.2,3.7-4.3c-4.4,0.6-5.6,0.9-6.4,1.2c-0.3-1.1-1.2-3.9-1.8-5.4c1-0.3,2-1.2,3.1-2.7c1.2-1.4,4.8-7,6.9-12.5l5.4,2.4
              c-2.2,4.2-4.9,8.5-7.7,12.1l4.2-0.3c1.4-2,2.7-4.3,3.9-6.5l4.9,3.4c-3.1,5-7.1,10-11,14.3l5.6-0.8c-0.4-1.3-0.8-2.6-1.2-3.8
              l4.6-1.2c1.4,3.5,2.8,8.1,3.4,11L438.8,576.6z M435.6,578.2c0.8,3.7,1.5,8.3,1.8,11.5l-5,1.3c-0.1-3.1-0.8-7.9-1.5-11.6
              L435.6,578.2z M441.3,576.9c1.3,3,2.6,6.9,3.2,9.5l-4.5,1.8c-0.5-2.6-1.8-6.7-3-9.7L441.3,576.9z M467.3,554c0,0-0.1,2.2-0.1,2.9
              c-0.6,22.2-1.2,30.1-3.1,32.7c-1.4,1.9-2.8,2.5-4.9,2.8c-1.8,0.4-4.9,0.3-7.9,0.2c-0.1-1.7-0.9-4.4-2-6.1c3.1,0.3,5.9,0.3,7.2,0.3
              c1,0.1,1.6-0.2,2.1-0.8c1.4-1.5,2-8.9,2.4-26.1h-11.9c-1.2,2.6-2.5,4.9-3.8,6.8c-1.1-0.9-3.7-2.7-5.1-3.4
              c3.5-4.3,6.2-11.4,7.6-18.3l5.9,1.2c-0.6,2.7-1.4,5.4-2.3,7.9H467.3z M454.1,579.7c-1.5-3.2-5.2-7.9-8.6-11.2l4-3.1
              c3.4,3.1,7.4,7.6,9,10.8L454.1,579.7z M474.9,585.1l4.3-6c2.6,2.6,5.9,4.8,10.2,4.8c4.8,0,8.2-3.1,8.2-8.7c0-5.5-3.1-8.6-7.8-8.6
              c-2.8,0-4.3,0.7-6.9,2.5l-4.2-2.8l1.3-22.9h24.8v7.9h-16.8l-0.9,10c1.8-0.9,3.4-1.3,5.6-1.3c7.8,0,14.3,4.7,14.3,15
              c0,10.5-7.6,16.6-16.2,16.6C483.2,591.6,478.3,588.6,474.9,585.1z M548.8,545.6c2.3,7.5,7.5,14.8,14.5,18.5
              c-1.2,1.3-3.3,3.9-4.2,5.7c-1.3-0.9-2.6-1.9-3.8-3c-0.6,15.2-1.2,21.1-2.9,23.2c-1.3,1.7-2.6,2.2-4.6,2.5
              c-1.7,0.3-4.6,0.3-7.6,0.2c-0.1-1.9-0.9-4.4-2-6.2c2.8,0.2,5.4,0.3,6.7,0.3c0.9,0,1.5-0.1,2-0.7c1-1.1,1.6-5.7,2-16.4h-9.9
              c-1.3,9.9-4.5,18.7-17.6,23.7c-0.8-1.8-2.5-4.3-4-5.7c11.4-3.8,14-10.6,15-18h-8.5v-3.4c-1.2,1.3-2.5,2.6-3.8,3.7
              c-1.1-1.2-3.6-3.5-5-4.5c5.7-4.2,10.2-11.1,12.5-18.8l6.3,1.5c-1.9,5.8-4.6,11.1-7.9,15.5H552c-3.3-3.7-5.9-8-7.6-12.3h-8v-5.7
              H548.8z M586.6,582.7l0.3,4.8c-5.8,1.4-11.8,2.8-16.3,3.9l-1.1-5.5c1.9-0.3,4.3-0.8,6.9-1.3v-12.8h-6.2v-5.2h6.2v-3.8h-3.5v-2.3
              c-0.8,0.8-1.5,1.5-2.2,2.2c-0.3-1.3-1.4-4.8-2-6.3c3.4-2.8,7.2-7.3,9.3-11.9l4,2.1c-0.3,0.6-0.6,1.2-0.9,1.9
              c2.8,1.9,5.7,4,7.3,5.8l-2.3,5c-0.4-0.4-0.7-0.8-1.1-1.2v4.8h-4.1v3.8h5.6v5.2h-5.6v12L586.6,582.7z M573.9,573.7
              c0.8,2.8,1.5,6.5,1.7,8.8l-3.5,1.2c-0.2-2.4-0.9-6.1-1.6-8.9L573.9,573.7z M584.7,557.8c-1.7-1.6-3.9-3.4-6.1-4.9
              c-1.1,1.8-2.2,3.3-3.4,4.9H584.7z M581.3,581.9c0.6-2.3,1.3-6,1.6-8.6l3.9,1.2c-0.9,3-1.8,6.4-2.4,8.6L581.3,581.9z M604.7,587.4
              h11.6v4.3h-28.8v-4.3H599v-2.3h-9.7v-4.2h9.7v-2h-9v-15h24.1v15h-9.4v2h9.9v4.2h-9.9V587.4z M616.5,557.7v4.6h-28.9v-4.6h6.2
              c-0.3-1.1-0.7-2.3-1.2-3.3l3.2-0.6h-7v-4.6h10.3c-0.4-1.1-0.9-2.3-1.4-3.1l5.5-1.3c1,1.3,1.8,3.1,2.1,4.5h9.7v4.6H608l3.7,0.7
              c-0.6,1.2-1.1,2.3-1.6,3.3H616.5z M595,569.7h4.3v-2.2H595V569.7z M595,575.3h4.3v-2.3H595V575.3z M597.8,553.7
              c0.6,1.3,1.2,2.9,1.4,4h5.5c0.5-1.3,1-2.7,1.3-4H597.8z M608.8,567.5h-4.4v2.2h4.4V567.5z M608.8,573.1h-4.4v2.3h4.4V573.1z
              M626.9,559.3c-0.6,0.7-1.2,1.3-1.8,2c-0.5-1.1-2-3.4-2.9-4.4c3-2.9,5.8-7.4,7.2-11.9l4.6,1.3c-0.4,1.3-1,2.6-1.6,4h3.8
              c-0.4-1.2-1-2.6-1.5-3.8l4.9-1.4c0.8,1.6,1.7,3.6,2.2,5.1h3.7v3.9h-5.2v2h4.2v0.1c2.6-3,4.9-7.1,6.3-11.2l4.7,1.3
              c-0.5,1.3-1,2.6-1.6,4h3.9c-0.4-1.2-1-2.6-1.5-3.7l4.9-1.4c0.8,1.5,1.7,3.5,2.2,5h4.5v3.8h-6.3v2h4.9v3.6h-4.9v2h4.9v3.6h-4.9v2
              h6.9v3.8h-20.2v-11.5c-0.6,0.8-1.3,1.5-1.8,2.2c-0.4-0.7-1.3-1.9-2.1-2.9v0.9h-4.2v2h4.2v3.6h-4.2v2h5.7v3.8h-19.2V559.3z
              M664,575.3c-2.4,3.9-5.8,7-9.8,9.5c4.5,1,9.5,1.6,14.7,2c-1.3,1.4-2.9,4.1-3.7,5.8c-7-0.7-13.4-2-18.9-4.1
              c-6.3,2.3-13.4,3.7-20.8,4.4c-0.5-1.6-1.5-4.1-2.4-5.5c5.5-0.4,10.8-1.2,15.8-2.4c-2.8-1.7-5.3-3.8-7.6-6l2.3-1.1h-7.7v-4.9h33
              l1.1-0.3L664,575.3z M635.6,554.1H632v2h3.6V554.1z M635.6,559.6H632v2h3.6V559.6z M635.6,565.1H632v2h3.6V565.1z M638.3,578
              c2.2,1.8,5,3.2,8.3,4.5c2.9-1.2,5.3-2.7,7.4-4.5H638.3z M657.1,554.1h-3.5v2h3.5V554.1z M657.1,559.6h-3.5v2h3.5V559.6z
              M657.1,565.1h-3.5v2h3.5V565.1z M691.9,571.9c-1.6,0.5-3.2,1-4.8,1.5v13c0,2.8-0.5,4.4-2,5.4c-1.6,1-3.7,1.2-7,1.1
              c-0.2-1.5-0.8-4-1.5-5.7c1.8,0.1,3.5,0.1,4.1,0.1c0.6,0,0.9-0.2,0.9-0.9v-11.4c-1.7,0.5-3.3,1-4.8,1.4l-1.3-5.9
              c1.7-0.4,3.8-0.9,6.1-1.5v-8.9h-5.3v-5.6h5.3v-9.7h5.4v9.7h4.6v5.6H687v7.4l4.1-1.2L691.9,571.9z M709.5,587
              c1.4,0.2,2.8,0.2,4.2,0.2c1.6,0,7.6,0,9.6-0.1c-0.8,1.3-1.5,3.8-1.7,5.3h-8.4c-7,0-12.4-1.2-16.2-6.1c-1.2,2.7-2.6,5-4.4,6.8
              c-0.9-0.9-3.3-2.6-4.5-3.3c3.6-3.2,5.4-8.5,6.2-14.6l5.4,0.7c-0.3,1.6-0.5,3.2-0.9,4.8c1.3,2.3,3,3.8,5.1,4.8v-11h-9.4V570h9.4
              v-2.6h-12.4v-4.7h12.4v-2.4h-9.4v-4.6h9.4v-2.2h-11.6v-4.8h11.6v-3.9h5.5v3.9h12.7v4.8h-12.7v2.2h10.7v6.9h3.1v4.7h-3.1v7.2h-10.7
              v3h11v4.8h-11V587z M709.5,562.6h5.1v-2.4h-5.1V562.6z M709.5,570h5.1v-2.6h-5.1V570z M743.4,569.7c-0.8,4.2-2,8.8-3.6,12.7
              c0.8,0.5,1.7,1.2,3,2c3.4,2.1,8.2,2.4,14.1,2.4c5.8,0,14.8-0.5,20.4-1.3c-0.7,1.6-1.7,4.6-1.8,6.3c-4.1,0.3-13.1,0.6-19,0.6
              c-6.7,0-10.9-0.6-14.6-2.7c-2.1-1.2-3.9-2.9-4.9-2.9c-1.2,0-3,3.5-4.2,6.4l-4-5.8c1.8-2.2,3.7-4,5.4-5c1.2-2.6,2.2-5.8,2.8-8.9
              h-6.4c1.2-3,2.6-7.2,3.7-11.2h-4.6v-5.5h12c-1.1,3.7-2.4,7.8-3.5,11.3h0.8l0.9-0.2L743.4,569.7z M735.2,555.4
              c-0.6-2.4-1.9-6.1-2.8-8.9l5.3-1.6c1,2.8,2.3,6.2,2.9,8.5L735.2,555.4z M747.4,556.7h-4.2v-10.2h32.1v10.2H770v1.9h-7.8v1.9h10.5
              v15.1h-10.5v1.9h13v4.4h-13v3.4h-5.9v-3.4h-12.8v-4.4h12.8v-1.9H746v-15.1h10.4v-1.9h-9V556.7z M748.6,550.9v3.4h7.8v-2h5.9v2h7.3
              v-3.4H748.6z M751,566.3h5.3v-2.1H751V566.3z M756.4,571.8v-2.1H751v2.1H756.4z M762.2,564.2v2.1h5.1v-2.1H762.2z M767.4,569.8
              h-5.1v2.1h5.1V569.8z M802.9,578.1l0.6,5.7c-6.5,1.6-13.5,3.3-18.8,4.5l-1.3-6.1c2.8-0.5,6.5-1.2,10.4-2.1
              c1.2-5.3,2.3-13.4,2.8-19.2l5.5,0.9c-1,5.6-2.2,12.3-3.5,17.2L802.9,578.1z M793.8,553.9H804v5.6h-20.2v-5.6h8.5
              c-0.6-2.1-1.8-5-2.9-7.3l5.1-1.5c1.4,2.3,2.7,5.4,3.3,7.4L793.8,553.9z M790,561.3c1.1,5.4,2,12.3,2.3,16.9l-4.9,1
              c-0.2-4.6-1.1-11.7-2-17.1L790,561.3z M818.2,560.1v8.9h10v23.7h-6.2v-2.3h-12v2.5h-5.9V569h7.8v-24.1h6.2v9.5h11.7v5.8H818.2z
              M822.1,574.7h-12v10h12V574.7z M861.1,543.2v51.5h-2.3v-51.5H861.1z M909.8,548.2c-3.7,8.4-9.6,17.1-15,22.5
              c-1.2-1.3-3.8-4-5.3-5.3c5.3-4.6,10.6-12,13.7-19.5L909.8,548.2z M923.9,571.8c4.1,5.7,8.7,13,11.2,17.8l-6.2,3.3
              c-0.7-1.5-1.6-3.2-2.6-5c-24.4,2-27.8,2.3-30.3,3.2c-0.4-1.3-1.6-4.7-2.5-6.5c1.7-0.5,3-1.9,5-4.1c2.1-2.2,8.4-11,12.4-19.5l7,3
              c-3.9,6.9-8.7,13.8-13.6,19.4l18.5-1.2c-1.6-2.6-3.3-5.3-4.8-7.5L923.9,571.8z M924.9,546.1c2.2,7.2,6.8,14.5,13,18.3
              c-1.4,1.3-3.5,4.3-4.5,6.2c-5.8-4.5-10.2-11.4-13.1-18.2h-8.1v-6.3H924.9z M988.3,546.8V593h-5.9v-1.9h-31.5v1.9h-5.6v-46.2H988.3
              z M982.5,585.6v-33.3h-31.5v33.3H982.5z M968.8,578.7c-0.2,1.1-0.1,2.8,0,3.7c-8.6,2.2-10,2.7-10.9,3.2c-0.2-0.9-0.9-2.5-1.4-3.1
              c0.8-0.4,1.7-0.9,1.7-2.9v-1.9c-1.4,0.5-2.7,1-4,1.4c-0.6-0.8-2-2.6-2.9-3.4c3.3-0.7,6.8-1.8,9.6-3.1h-5.8v-7.4h23.3v7.4h-10.8
              c-1.4,1.2-3.2,2.3-5.1,3.2v4.4L968.8,578.7z M980.7,563.8h-28v-3.4h11.2v-2H955v-3.3h8.9v-2.2h5.4v2.2h9.3v3.3h-9.3v2h11.3V563.8z
              M960.2,570h13v-2.1h-13V570z M981.3,575.5c-1.3,1-2.8,1.9-4.2,2.7c1.8,1.3,3.3,2.5,4.4,3.5l-3.8,2.3c-2.5-2.4-7.8-6.3-12.1-9
              l3.6-2.1c1.4,0.9,3,2,4.7,3c1.4-1,2.9-2.1,3.8-2.9L981.3,575.5z M1016.8,587.4c0,2.3-0.4,3.6-2,4.4c-1.5,0.8-3.5,0.8-6.6,0.8
              c-0.2-1.4-1-3.3-1.6-4.6c1.8,0.1,3.8,0.1,4.3,0.1c0.6,0,0.8-0.2,0.8-0.8v-2.7h-7.9c-0.5,3.1-1.5,6.2-3.3,8.5
              c-0.7-1.1-2.7-3.2-3.8-3.9c2.3-3.2,2.5-7.5,2.5-11.2v-10.5h17.5V587.4z M1018.9,565.8h-21.7v-4.4h8.4v-2.1h-6.4v-4.1h6.4v-1.9
              h-7.7V549h7.7v-4.2h5.5v4.2h7.1v4.3h-7.1v1.9h5.8v4.1h-5.8v2.1h7.7V565.8z M1011.8,580.6v-2.4h-7.4c0,0.8,0,1.5-0.1,2.4H1011.8z
              M1004.4,571.9v2.4h7.4v-2.4H1004.4z M1043.7,573.5h-2.8v7.8h-8.5v5.5c0,2.7-0.5,4.1-2.3,4.9c-1.7,0.8-4.1,0.9-7.4,0.9
              c-0.2-1.5-0.9-3.7-1.6-5.2c1.9,0.1,4.1,0.1,4.8,0.1c0.7,0,0.9-0.2,0.9-0.8v-5.4h-6.7v-4.8h6.7v-3h-8.7v-5.2h8.7v-2.7h-6.4v-4.8
              h1.6c-0.6-1.8-1.7-4-2.7-5.7l4.2-1.7c1.3,1.9,2.6,4.5,3.2,6.2l-2.6,1.2h16.8v7.5h2.8V573.5z M1042.8,549.3
              c-6.3,2-15.2,3.2-22.9,3.8c-0.2-1.2-0.8-3.1-1.4-4.2c7.2-0.7,15.4-2,20.2-3.6L1042.8,549.3z M1028.6,560.5c-0.2-1.9-1-4.8-1.7-7
              l4.5-1c0.9,2.2,1.6,5,2,6.8L1028.6,560.5z M1032.4,568.3h3.6v-2.7h-3.6V568.3z M1036,573.5h-3.6v3h3.6V573.5z M1034.1,559.3
              c1-2.1,2.3-5.3,3-7.6l4.9,1.2c-1.2,2.8-2.5,5.6-3.5,7.6L1034.1,559.3z M1085.3,566.2c3.1,3.7,7.7,6.8,12.7,8.5
              c-1.4,1.2-3.3,3.7-4.2,5.3c-3-1.3-5.9-3.2-8.4-5.6v8.4h-18.4v1.5c0,2.1,0.7,2.5,4.7,2.5h12.2c3.2,0,3.8-0.8,4.2-5.4
              c1.4,0.9,3.8,1.8,5.5,2.2c-0.8,6.9-2.7,8.7-9.3,8.7h-12.9c-8,0-10.3-1.6-10.3-7.9v-8.8c-2.2,2-4.6,3.7-7.1,5
              c-1-1.2-3.1-3.6-4.4-4.7c4.8-2.2,9.2-5.6,12.2-9.8h-10.8v-5.5h12.2v-5h-9.1v-5.4h9.1v-5.4h6v5.4h9.1v-5.4h6.1v5.4h9.5v5.4h-9.5v5
              h12.5v5.5H1085.3z M1068.8,566.2c-0.6,1.1-1.3,2.1-2,3.1h14c-0.8-1-1.4-2.1-2-3.1H1068.8z M1079.6,574.2h-12.7v3.6h12.7V574.2z
              M1069.1,560.6h9.1v-5h-9.1V560.6z M1128.4,543.2v51.5h-2.3v-51.5H1128.4z M1185.3,565c3.3,10,9.4,18.1,18.9,22
              c-1.5,1.3-3.5,4-4.4,5.8c-9.1-4.4-15-11.9-18.9-21.8c-2.7,8.6-8.2,16.6-18.9,21.9c-1-1.7-2.7-3.9-4.5-5.2
              c11.6-5.3,16.3-13.8,18.3-22.6h-17.4v-6.3h18.4c0.5-4.8,0.5-9.5,0.5-13.7h6.6c0,4.2-0.1,9-0.6,13.7h19.8v6.3H1185.3z
              M1255.6,585.9c0,3.2-0.7,4.9-2.6,5.9c-1.9,1-4.7,1.2-8.9,1.2c-0.3-1.7-1.2-4.5-2.1-6.1c2.7,0.2,5.6,0.2,6.5,0.1s1.2-0.4,1.2-1.2
              v-32.7h-31v39.8h-5.9v-45.7h42.8V585.9z M1246.7,561.8h-25.2v-5.1h25.2V561.8z M1229.5,583v3.4h-5.5v-20.6h20.5V583H1229.5z
              M1229.5,570.8v7.1h9.4v-7.1H1229.5z M1300.6,585.5c3.2,0,7.2-0.3,9.3-0.7c0.2,1.5,0.6,4.3,0.9,5.7c-1.9,0.5-6.4,0.6-10.4,0.6
              h-19.9c-8.9,0-13.1-2.8-13.1-12.3v-26.8h-2v-5.4h42.9v5.4h-35v27.2c0,4.5,1.5,6.2,6.7,6.2H1300.6z M1275.5,568.9h13.9v13.8h-13.9
              V568.9z M1280.4,578.7h3.9v-5.6h-3.9V578.7z M1300.5,566.7h-19.8v-12.2h19.8V566.7z M1294.5,558.4h-8.3v4.2h8.3V558.4z
              M1305.9,582.8h-14.2v-13.8h14.2V582.8z M1300.7,573.1h-4.1v5.6h4.1V573.1z M1364,585v6h-45.9v-6h6.2v-22.9h6.3V585h8.8v-30.8
              h-19.5v-6h42.7v6h-16.9v11.3h14.3v6h-14.3V585H1364z M1385.8,575c-0.5-1.4-1.4-3.4-2.4-5.5v23.3h-5.8v-18.9
              c-1.3,3.8-2.8,7.3-4.4,9.7c-0.6-1.8-1.9-4.5-2.9-6.2c3.1-4,5.8-11.2,7.2-17.4h-6.1v-5.7h6.1v-9.6h5.8v9.6h5v5.7h-5v1.6
              c1.4,1.9,5,7.7,5.9,9.2L1385.8,575z M1417.8,571.5c-2,3.4-4.6,6.6-7.6,9.6c3,2.4,6.4,5.4,8.3,7.4l-4.6,4c-1.7-2.1-4.9-5.2-8-7.8
              c-4.7,3.5-10.1,6.4-16.2,8.3c-0.8-1.4-2.4-3.9-3.5-5.2c11.9-3.3,21.3-10.4,25.4-18.5L1417.8,571.5z M1414.3,561.4
              c-4.7,8.3-12.7,15.8-22.2,20.2c-0.8-1.3-2.3-3.6-3.3-4.7c4.6-1.9,8.9-4.7,12.4-7.9c-7.6,0.6-9.2,0.9-10.3,1.3
              c-0.2-1.4-1-4.3-1.5-5.6c1.2-0.3,3-0.7,4-1.7c0.8-0.9,2.7-4,4.3-6.6h-8.4v-5.6h11.8c-0.4-1.6-1.1-3.3-1.8-4.8l5.6-1.8
              c1.1,2.1,2.2,4.6,2.6,6.6h10.2v5.6h-13.9c-1.5,2.5-3.2,5.3-4.7,7.6l6.7-0.3c1-1.4,2-2.9,2.7-4.4L1414.3,561.4z M1434.5,563.7
              c-0.7,6.6-2,15.5-3.7,21.4l-6.1-2.5c1.8-5.5,3.2-13.2,3.9-20L1434.5,563.7z M1444,559.5v23.9c0,2.4,0.4,2.8,2.8,2.8h7.2
              c2.5,0,3-1.6,3.3-9.7c1.4,1.1,4,2.2,5.7,2.6c-0.7,9.8-2.3,12.8-8.5,12.8h-8.4c-6.6,0-8.4-2.1-8.4-8.5v-23.9H1444z M1443.5,545.7
              c4.5,2.9,10.9,7.5,14,10.7l-4.4,4.8c-2.7-3.1-8.9-8-13.6-11.3L1443.5,545.7z M1465.5,560.9c2.8,6.1,5.4,13.8,6.5,19.1l-6.1,2.6
              c-0.8-5.2-3.4-13.4-6-19.4L1465.5,560.9z"
                />
                <line
                  class="st5 ans1"
                  x1="1492.5"
                  y1="608.1"
                  x2="398.8"
                  y2="608.1"
                />
                <line
                  class="st5 ans1"
                  x1="398.8"
                  y1="531.6"
                  x2="1492.5"
                  y2="531.6"
                />
              </g>
              <g class="t2">
                <g>
                  <path
                    class="st4"
                    d="M743.3,520.1c-0.5,0.5-1,1.1-1.5,1.6c-0.4-0.9-1.6-2.7-2.3-3.5c2.5-2.3,4.7-5.9,5.9-9.5l3.7,1
                c-0.3,1.1-0.8,2.1-1.3,3.2h3.1c-0.3-1-0.8-2-1.2-3l4-1.1c0.6,1.3,1.4,2.9,1.8,4.1h3v3.1h-4.3v1.6h3.4v0.1c2.1-2.4,4-5.6,5.1-8.9
                l3.8,1.1c-0.4,1.1-0.8,2.1-1.3,3.2h3.2c-0.3-1-0.8-2-1.2-2.9l4-1.1c0.6,1.2,1.4,2.8,1.8,4h3.7v3h-5.1v1.6h4v2.8h-4v1.6h4v2.9h-4
                v1.6h5.6v3h-16.4v-9.2c-0.5,0.6-1,1.2-1.5,1.7c-0.3-0.5-1-1.5-1.7-2.3v0.7h-3.4v1.6h3.4v2.9h-3.4v1.6h4.6v3h-15.6V520.1z
                M773.4,532.9c-2,3.1-4.7,5.6-7.9,7.5c3.7,0.8,7.7,1.3,11.9,1.6c-1.1,1.1-2.4,3.3-3,4.6c-5.7-0.5-10.9-1.6-15.4-3.2
                c-5.1,1.8-10.9,2.9-16.9,3.5c-0.4-1.3-1.2-3.2-2-4.4c4.5-0.3,8.8-0.9,12.8-1.9c-2.3-1.4-4.3-3-6.1-4.8l1.9-0.9h-6.3v-3.9h26.8
                l0.9-0.2L773.4,532.9z M750.3,515.9h-2.9v1.6h2.9V515.9z M750.3,520.3h-2.9v1.6h2.9V520.3z M750.3,524.8h-2.9v1.6h2.9V524.8z
                M752.5,535c1.8,1.4,4.1,2.6,6.7,3.6c2.3-1,4.3-2.1,6-3.6H752.5z M767.8,515.9h-2.9v1.6h2.9V515.9z M767.8,520.3h-2.9v1.6h2.9
                V520.3z M767.8,524.8h-2.9v1.6h2.9V524.8z M801.8,530.2c-1.3,0.4-2.6,0.8-3.9,1.2v10.4c0,2.3-0.4,3.5-1.6,4.3
                c-1.3,0.8-3,0.9-5.7,0.9c-0.1-1.2-0.7-3.2-1.2-4.5c1.4,0.1,2.9,0.1,3.4,0c0.5,0,0.7-0.2,0.7-0.7v-9.1c-1.4,0.4-2.7,0.8-3.9,1.1
                l-1.1-4.7c1.4-0.3,3.1-0.7,5-1.2v-7.1h-4.3v-4.5h4.3v-7.7h4.4v7.7h3.7v4.5h-3.7v5.9l3.3-0.9L801.8,530.2z M816.1,542.2
                c1.1,0.1,2.3,0.2,3.4,0.2c1.3,0,6.1,0,7.8-0.1c-0.6,1-1.2,3-1.4,4.2h-6.8c-5.7,0-10.1-1-13.2-4.8c-0.9,2.2-2.1,4-3.6,5.4
                c-0.7-0.7-2.7-2-3.7-2.7c3-2.6,4.4-6.8,5-11.6l4.4,0.6c-0.2,1.3-0.4,2.6-0.7,3.8c1.1,1.8,2.5,3,4.1,3.8v-8.8h-7.7v-3.6h7.7v-2.1
                h-10v-3.8h10v-1.9h-7.7v-3.6h7.7v-1.7h-9.4v-3.8h9.4v-3.1h4.5v3.1h10.3v3.8h-10.3v1.7h8.7v5.5h2.5v3.8h-2.5v5.7h-8.7v2.4h9v3.8
                h-9V542.2z M816.1,522.7h4.2v-1.9h-4.2V522.7z M816.1,528.6h4.2v-2.1h-4.2V528.6z M854.3,542.5c0,1.8-0.3,2.8-1.6,3.5
                c-1.2,0.6-2.9,0.7-5.3,0.7c-0.2-1.1-0.8-2.7-1.3-3.6c1.5,0.1,3.1,0,3.5,0c0.5,0,0.7-0.2,0.7-0.6v-2.1h-6.4
                c-0.4,2.5-1.2,4.9-2.7,6.8c-0.6-0.9-2.2-2.5-3.1-3.1c1.9-2.5,2-5.9,2-8.9v-8.4h14.2V542.5z M856,525.3h-17.6v-3.5h6.8v-1.6H840
                v-3.3h5.2v-1.5h-6.3v-3.4h6.3v-3.3h4.5v3.3h5.8v3.4h-5.8v1.5h4.7v3.3h-4.7v1.6h6.3V525.3z M850.2,537.1v-1.9h-6
                c0,0.6,0,1.2,0,1.9H850.2z M844.2,530.2v1.9h6v-1.9H844.2z M876.2,531.4h-2.3v6.2H867v4.4c0,2.2-0.4,3.3-1.8,3.9
                c-1.4,0.7-3.3,0.7-6,0.7c-0.2-1.2-0.7-2.9-1.3-4.1c1.6,0,3.4,0,3.9,0c0.5,0,0.7-0.2,0.7-0.6v-4.3H857v-3.9h5.4v-2.4h-7v-4.1h7
                v-2.1h-5.2v-3.9h1.3c-0.5-1.4-1.4-3.2-2.2-4.5l3.4-1.4c1.1,1.5,2.1,3.6,2.6,4.9l-2.1,0.9h13.7v6h2.3V531.4z M875.4,512.2
                c-5.1,1.6-12.4,2.6-18.6,3c-0.2-0.9-0.7-2.5-1.1-3.4c5.8-0.5,12.5-1.6,16.4-2.9L875.4,512.2z M863.8,521.1
                c-0.2-1.5-0.8-3.8-1.4-5.6l3.6-0.8c0.7,1.7,1.3,4,1.6,5.4L863.8,521.1z M867,527.3h2.9v-2.1H867V527.3z M869.9,531.4H867v2.4h2.9
                V531.4z M868.3,520.1c0.8-1.6,1.9-4.2,2.5-6l4,1c-1,2.3-2,4.5-2.9,6L868.3,520.1z M915.6,525.6c2.5,2.9,6.3,5.4,10.3,6.8
                c-1.1,1-2.7,2.9-3.4,4.2c-2.5-1.1-4.8-2.6-6.8-4.5v6.7h-14.9v1.2c0,1.7,0.6,2,3.8,2h9.9c2.6,0,3.1-0.7,3.4-4.3
                c1.1,0.7,3.1,1.4,4.5,1.7c-0.7,5.5-2.2,6.9-7.5,6.9h-10.5c-6.5,0-8.4-1.3-8.4-6.3v-7c-1.8,1.6-3.8,3-5.7,4
                c-0.8-0.9-2.5-2.9-3.6-3.7c3.9-1.8,7.5-4.5,9.9-7.8h-8.8v-4.4h9.9v-4h-7.4v-4.3h7.4v-4.3h4.9v4.3h7.4v-4.3h4.9v4.3h7.7v4.3h-7.7
                v4H925v4.4H915.6z M902.3,525.6c-0.5,0.9-1.1,1.7-1.6,2.5H912c-0.6-0.8-1.1-1.6-1.6-2.5H902.3z M911,532h-10.3v2.8H911V532z
                M902.5,521.2h7.4v-4h-7.4V521.2z"
                  />
                  <circle
                    class="st1"
                    cx="941.6"
                    cy="528.4"
                    r="5.9"
                  />
                  <path
                    class="st4"
                    d="M980.8,511.3c-3,6.7-7.8,13.6-12.2,17.9c-0.9-1.1-3.1-3.2-4.3-4.2c4.3-3.6,8.6-9.6,11.1-15.5L980.8,511.3z
                M992.2,530.1c3.3,4.5,7.1,10.3,9.1,14.1l-5,2.7c-0.5-1.2-1.3-2.5-2.1-4c-19.8,1.6-22.6,1.8-24.6,2.5c-0.3-1-1.3-3.8-2-5.2
                c1.4-0.4,2.5-1.5,4.1-3.3c1.7-1.8,6.8-8.7,10.1-15.5l5.7,2.4c-3.2,5.5-7.1,11-11.1,15.4l15-1c-1.3-2-2.7-4.2-3.9-6L992.2,530.1z
                M993,509.6c1.8,5.7,5.5,11.5,10.5,14.6c-1.1,1.1-2.9,3.4-3.7,4.9c-4.7-3.6-8.3-9.1-10.6-14.5h-6.6v-5H993z M1050.3,510.2v36.8
                h-4.8v-1.5h-25.6v1.5h-4.6v-36.8H1050.3z M1045.5,541.1v-26.5h-25.6v26.5H1045.5z M1034.5,535.5c-0.1,0.9-0.1,2.3,0,2.9
                c-7,1.7-8.2,2.1-8.8,2.5c-0.2-0.7-0.7-2-1.1-2.5c0.6-0.3,1.4-0.7,1.4-2.3v-1.5c-1.1,0.4-2.2,0.8-3.2,1.1c-0.5-0.7-1.6-2-2.3-2.7
                c2.7-0.5,5.5-1.4,7.8-2.5h-4.7v-5.9h18.9v5.9h-8.8c-1.1,0.9-2.6,1.8-4.1,2.5v3.5L1034.5,535.5z M1044.1,523.7h-22.8V521h9.1v-1.6
                h-7.2v-2.6h7.2V515h4.4v1.7h7.5v2.6h-7.5v1.6h9.2V523.7z M1027.5,528.6h10.6v-1.7h-10.6V528.6z M1044.6,533
                c-1.1,0.8-2.3,1.5-3.4,2.2c1.4,1,2.7,2,3.6,2.8l-3.1,1.8c-2-1.9-6.4-5-9.8-7.1l2.9-1.6c1.1,0.7,2.5,1.6,3.8,2.4
                c1.1-0.8,2.3-1.6,3.1-2.3L1044.6,533z M1085,518.6c-0.4,0.9-0.8,1.8-1.1,2.6h12.4v25.8h-5.1V545h-18.7v1.9h-4.8v-25.8h10.8
                c0.3-0.9,0.5-1.7,0.7-2.6h-15.6v-4.5h9.4c-0.8-1.3-2-2.7-3-3.8l4.3-2c1.5,1.5,3.2,3.5,4,4.8l-1.8,0.9h9.4
                c1.2-1.7,2.5-3.9,3.2-5.6l5.6,1.2c-1.1,1.6-2.2,3.1-3.3,4.3h9.2v4.5H1085z M1091.1,525.4h-18.7v2.5h18.7V525.4z M1091.1,531.6
                h-18.7v2.7h18.7V531.6z M1091.1,540.7v-2.8h-18.7v2.8H1091.1z M1149.9,512.5v4.3H1120v7.1c0,6.6-0.6,16.7-4.3,23
                c-0.8-0.8-2.9-2.2-4-2.7c3.4-5.8,3.6-14.3,3.6-20.3v-11.5h15.1c-0.4-1-0.8-2-1.3-3l4.8-1.4c0.8,1.4,1.6,2.9,2,4.3H1149.9z
                M1143.4,520.6h6.1v3.9h-6.1v6.2h-6.2v2.2h10.4v7.6c0,2.1-0.3,3.2-1.8,3.9c-1.4,0.7-3.3,0.7-5.9,0.7c-0.2-1.3-0.7-3-1.3-4.2
                c1.5,0.1,3.2,0.1,3.6,0c0.5,0,0.7-0.1,0.7-0.6v-3.3h-5.6v9.8h-4.8v-9.8h-5.4v7.7h-4.7v-11.9h10.1v-2.2h-6.2v-6.2h-5.2v-3.9h5.2
                v-3h4.5v3h8v-3h4.7V520.6z M1138.7,524.6h-8v2.4h8V524.6z"
                  />
                </g>
                <g>
                  <rect
                    x="738.7"
                    y="555"
                    class="st4"
                    width="411.4"
                    height="36.3"
                  />
                  <path
                    class="st6"
                    d="M759.1,562.5h2.6v10.4c0,4.8-0.6,9-5.4,11.6c-0.4-0.6-1.4-1.6-2-2c2.8-1.5,4-3.5,4.5-5.9
                c-0.9,0.8-1.9,1.5-2.8,2.2l-1.5-2.4c1.1-0.7,2.8-1.8,4.5-2.9l0,0.1v-0.7v-2.4l-1.4,0.9c-0.4-1.2-1.2-3-2.1-4.5v2.5h-2v3.1
                l1.8-0.7l0.5,2.5c-0.8,0.3-1.6,0.6-2.4,0.9v6.4c0,1.3-0.2,2-1,2.5c-0.7,0.5-1.8,0.6-3.3,0.5c-0.1-0.7-0.4-1.9-0.7-2.6
                c0.9,0,1.7,0,2,0c0.3,0,0.4-0.1,0.4-0.4v-5.4l-2.2,0.8l-0.7-2.7c0.8-0.3,1.8-0.6,2.9-1v-4h-2.6v-2.6h2.6v-4.5h2.6v4.5h1.9
                l-0.2-0.4l2.1-1.2c0.6,0.9,1.2,1.9,1.7,2.9V562.5z M767.9,581.9c0.5,0,0.6-0.7,0.6-3.1c0.6,0.5,1.7,1,2.4,1.1
                c-0.3,3.3-0.9,4.5-2.8,4.5H766c-2.2,0-2.8-0.8-2.8-3.8v-18.1h2.7v7c0.7-1.4,1.5-3.2,1.9-4.5l2.5,1.3c-1,1.8-2.1,3.8-3,5.1
                l-1.5-0.9v2.7l0.7-0.7c1.6,1.3,3.4,3.1,4.3,4.2l-1.8,2c-0.7-1-1.9-2.4-3.2-3.6v5.5c0,1.1,0.1,1.3,0.6,1.3H767.9z M796.5,564.5
                v2.4h-21.2v-2.4h9.2c-0.2-0.6-0.4-1.2-0.6-1.7l3-0.7c0.3,0.7,0.6,1.6,0.9,2.4H796.5z M796,582c0,1.2-0.3,1.8-1.2,2.1
                c-0.9,0.3-2.2,0.3-3.8,0.3c-0.1-0.6-0.5-1.4-0.8-2h-6.9v1h-2.6v-6.3H791v5.3h-0.3c0.8,0,1.7,0,1.9,0c0.3,0,0.5-0.1,0.5-0.4v-5.8
                h-14.3v8.4H776v-10.7h20V582z M793.7,572.9h-15.2v-5.1h15.2V572.9z M790.7,569.7h-9.3v1.3h9.3V569.7z M788.5,579.1h-5.3v1.4h5.3
                V579.1z M801.1,581.6l2.1-2.8c1.3,1.3,2.9,2.3,5,2.3c2.3,0,3.8-1.1,3.8-3.1c0-2.2-1.3-3.5-6.1-3.5v-3.2c4.1,0,5.4-1.4,5.4-3.4
                c0-1.7-1.1-2.8-3-2.8c-1.6,0-2.9,0.8-4.2,2l-2.2-2.7c1.9-1.7,4-2.7,6.7-2.7c4.3,0,7.2,2.1,7.2,5.9c0,2.4-1.4,4.1-3.7,5.1v0.1
                c2.5,0.7,4.4,2.6,4.4,5.5c0,4.1-3.6,6.4-7.8,6.4C805.1,584.7,802.7,583.4,801.1,581.6z M830.7,576c-1.5,3.3-4.1,6.6-6.7,8.6
                c-0.5-0.7-1.4-1.8-2.1-2.3c2.5-1.6,5-4.4,6.3-7.1L830.7,576z M834.6,574.1v10.6h-3.1v-10.6h-9.2v-2.9h9.2v-8.9h3.1v8.9h9.2v2.9
                H834.6z M826.4,570.1c-0.4-1.5-1.7-3.9-3-5.6l2.6-1.2c1.3,1.7,2.7,3.9,3.2,5.5L826.4,570.1z M837.6,575c2.6,2.4,5.5,5.7,6.7,8.1
                l-2.5,1.6c-1.1-2.4-3.9-5.9-6.5-8.3L837.6,575z M843,564.5c-1.2,2.1-2.7,4.4-3.9,5.8l-2.5-1.1c1.1-1.6,2.4-4,3.2-6L843,564.5z
                M861.2,566.8c-0.8-0.9-2.1-1.6-3.4-1.6c-2.5,0-4.6,1.9-4.8,7.5c1.2-1.5,3.1-2.4,4.6-2.4c3.7,0,6.4,2.2,6.4,7
                c0,4.6-3.2,7.4-7,7.4c-4.2,0-8-3.4-8-11.2c0-8.3,4.1-11.8,8.6-11.8c2.7,0,4.7,1.1,6,2.5L861.2,566.8z M859.8,577.3
                c0-2.6-1.2-3.9-3.2-3.9c-1.2,0-2.5,0.6-3.6,2.3c0.4,4,1.9,5.6,3.8,5.6C858.5,581.4,859.8,580,859.8,577.3z M876.4,568.4
                c-0.5,1.1-1.2,2.2-1.8,3.3h0v12.9h-2.7v-9.2c-0.6,0.7-1.2,1.4-1.7,2c-0.3-0.6-1.1-2-1.6-2.7c1.9-1.8,4-4.6,5.2-7.3L876.4,568.4z
                M876,563.3c-1.6,2.3-3.9,4.9-5.9,6.7c-0.3-0.6-0.9-1.8-1.3-2.4c1.7-1.4,3.7-3.7,4.7-5.4L876,563.3z M874.7,582.5
                c0.8-1.1,1.5-2.9,1.9-4.4l2.2,0.8c-0.4,1.6-1,3.6-1.9,4.8L874.7,582.5z M891.3,577.1H884c0.5,0.8,1.1,1.9,1.4,2.5l-2.2,0.8
                c-0.3-0.7-0.9-2-1.4-2.9l1.2-0.4h-7.2v-2.3h15.5V577.1z M881.8,567.4c0-0.4,0.1-0.8,0.1-1.2h-5.9v-2.3h6.1c0-0.6,0.1-1.2,0.1-1.8
                l3,0.1l-0.2,1.7h6.1v2.3h-6.5c-0.1,0.4-0.1,0.8-0.2,1.2h5.9v6.4H877v-6.4H881.8z M884.9,582.2c0.7,0,0.8-0.3,0.9-2
                c0.5,0.3,1.5,0.7,2.2,0.8c-0.3,2.6-0.9,3.3-2.8,3.3h-3c-2.5,0-3.1-0.7-3.1-2.8v-2.9h2.5v2.9c0,0.6,0.1,0.7,0.9,0.7H884.9z
                M880.9,569.4h-1.6v2.3h1.6V569.4z M884.5,569.4h-1.7v2.3h1.7V569.4z M886.4,571.8h1.8v-2.3h-1.8V571.8z M889,578
                c1.1,1.4,2.2,3.3,2.5,4.5l-2.2,1c-0.3-1.2-1.3-3.2-2.4-4.6L889,578z M916.7,563.2v21.5h-2.8v-1H899v1h-2.7v-21.5H916.7z
                M913.9,581.2v-15.4h-3c0.8,0.4,1.6,0.9,2,1.4l-1,1h1.2v2h-4.3c0.1,1.3,0.3,2.6,0.5,3.7c0.5-0.9,0.8-1.9,1.1-3l2.2,0.4
                c-0.6,2.1-1.4,3.9-2.5,5.5c0.3,0.7,0.7,1.2,1.1,1.3c0.3,0.1,0.5-0.8,0.6-2.2c0.4,0.4,1.3,1.1,1.8,1.3c-0.4,3-1.6,3.9-3.2,3.1
                c-0.7-0.2-1.3-0.8-1.8-1.6c-0.8,0.8-1.6,1.5-2.5,2c-0.3-0.4-1.1-1.3-1.6-1.7c0.3-0.1,0.5-0.3,0.8-0.5c-1.9,0.3-3.8,0.6-5.4,0.8
                l-0.3-2.1c1.8-0.2,4.3-0.5,6.7-0.8l0,1.2c0.4-0.4,0.8-0.8,1.2-1.2c-0.5-1.7-0.8-3.9-1-6.3h-6.7v-2h6.5c0-0.7-0.1-1.3-0.1-2h2.4
                c0,0.7,0,1.4,0.1,2h2.6c-0.5-0.4-1.2-0.9-1.8-1.3l1.1-1.1H899v15.4H913.9z M905.8,575.8h-5.4v-4.6h5.4V575.8z M903.9,572.8h-1.6
                v1.5h1.6V572.8z M944.1,569.9v2h-13.6v-2h5.3v-1.1h-4.4V567h4.4v-1h-5v-0.9c-0.6,1.6-1.3,3.3-1.8,4.4l-1.6-0.6v1.3h2.6v2.6h-2.6
                v1.7l1-0.7c0.5,0.6,2.1,2.9,2.5,3.5l-1.8,2.1c-0.4-0.9-1.1-2.5-1.7-3.7v8.9h-2.5v-7.9c-0.5,2-1.3,4.1-2.3,5.2
                c-0.2-0.8-0.8-2.1-1.2-2.9c1-1.2,1.9-3.4,2.2-5.3l1.3,0.5v-1.4h-3.1v-2.6h3.1v-7.9h2.5v6.1c0.5-1.3,1-3.2,1.2-4.6l2.1,0.7V564h5
                v-1.8h2.8v1.8h5.2v2h-5.2v1h4.8v1.9h-4.8v1.1H944.1z M922.9,569.4c-0.1-1.4-0.6-3.5-1-5.1l2-0.5c0.5,1.6,1,3.7,1.2,5.1
                L922.9,569.4z M943,582.1c0,1.2-0.2,1.8-1.1,2.2c-0.8,0.4-1.9,0.4-3.4,0.4c-0.1-0.7-0.4-1.6-0.7-2.3c0.9,0,1.9,0,2.2,0
                c0.3,0,0.4-0.1,0.4-0.4v-1h-6.5c-0.3,1.4-0.8,2.8-1.8,3.8c-0.4-0.5-1.5-1.5-2-1.8c1.4-1.7,1.5-3.9,1.5-5.8V573H943V582.1z
                M940.3,579.2V578h-6.1c0,0.4,0,0.8-0.1,1.2H940.3z M934.2,575v1.1h6.1V575H934.2z M949,573.8h9.2v10.7h-2.9v-1.1h-3.6v1.2H949
                V573.8z M951.8,576.6v4.1h3.6v-4.1H951.8z M966.4,571.9h-14.1v-8.7h14.1V571.9z M963.5,565.9h-8.4v3.2h8.4V565.9z M969.7,573.8
                v10.7h-2.9v-1.1H963v1.2h-2.7v-10.8H969.7z M966.8,580.7v-4.1H963v4.1H966.8z M994.7,572.7c-1,0.7-2,1.5-3.1,2.2
                c0.9,2.8,2.9,5.2,5.6,6.3c-0.6,0.5-1.5,1.6-1.9,2.3c-2.9-1.4-4.8-3.9-6-7.2c-0.2,0.2-0.5,0.3-0.7,0.5c0.7,2.9,0.1,5.6-1.1,6.7
                c-0.8,0.9-1.6,1.1-2.9,1.1c-0.5,0-1.2,0-1.9,0c0-0.8-0.3-1.9-0.8-2.7c0.8,0.1,1.6,0.1,2.2,0.1c0.6,0,1-0.1,1.3-0.6
                c0.4-0.4,0.7-1.3,0.7-2.4c-2.7,1.9-6.5,3.7-9.7,4.6c-0.3-0.7-0.9-1.7-1.5-2.4c3.8-0.8,8.4-2.9,10.8-4.9c-0.1-0.3-0.2-0.6-0.4-0.9
                c-2.4,1.5-5.9,3.1-8.6,3.9c-0.3-0.6-0.9-1.6-1.4-2.2c3-0.6,6.9-2.1,9-3.5c-0.2-0.3-0.4-0.5-0.7-0.8c-2.1,1.1-4.4,1.9-6.7,2.5
                c-0.3-0.6-1-1.7-1.5-2.2c2.7-0.6,5.6-1.5,7.7-2.6H979V568h13.4v2.4h-5.3c-0.4,0.4-0.9,0.8-1.4,1.1c0.9,0.9,1.6,1.9,2.1,2.9
                c1.7-1.1,3.4-2.5,4.5-3.6L994.7,572.7z M978.2,569.5h-2.8v-5.4h8.7c-0.2-0.5-0.4-0.9-0.6-1.3l3-0.9c0.4,0.6,0.8,1.4,1,2.1h8.6
                v5.4h-2.9v-2.8h-15V569.5z M1031.2,569.9v2h-13.6v-2h5.3v-1.1h-4.4V567h4.4v-1h-5v-0.9c-0.6,1.6-1.3,3.3-1.8,4.4l-1.6-0.6v1.3
                h2.6v2.6h-2.6v1.7l1-0.7c0.5,0.6,2.1,2.9,2.5,3.5l-1.8,2.1c-0.4-0.9-1.1-2.5-1.7-3.7v8.9h-2.5v-7.9c-0.5,2-1.3,4.1-2.3,5.2
                c-0.2-0.8-0.8-2.1-1.2-2.9c1-1.2,1.9-3.4,2.2-5.3l1.3,0.5v-1.4h-3.1v-2.6h3.1v-7.9h2.5v6.1c0.5-1.3,1-3.2,1.2-4.6l2.1,0.7V564h5
                v-1.8h2.8v1.8h5.2v2h-5.2v1h4.8v1.9h-4.8v1.1H1031.2z M1009.9,569.4c-0.1-1.4-0.6-3.5-1-5.1l2-0.5c0.5,1.6,1,3.7,1.2,5.1
                L1009.9,569.4z M1030,582.1c0,1.2-0.2,1.8-1.1,2.2c-0.8,0.4-1.9,0.4-3.4,0.4c-0.1-0.7-0.4-1.6-0.7-2.3c0.9,0,1.9,0,2.2,0
                c0.3,0,0.4-0.1,0.4-0.4v-1h-6.5c-0.3,1.4-0.8,2.8-1.8,3.8c-0.4-0.5-1.5-1.5-2-1.8c1.4-1.7,1.5-3.9,1.5-5.8V573h11.4V582.1z
                M1027.4,579.2V578h-6.1c0,0.4,0,0.8-0.1,1.2H1027.4z M1021.3,575v1.1h6.1V575H1021.3z M1037.7,578.2c-0.2,2.1-0.5,4.3-1,5.7
                c-0.4-0.2-1.4-0.5-1.9-0.6c0.6-1.4,0.8-3.5,1-5.4L1037.7,578.2z M1050.2,581.1c-2.6,0.8-5.4,1.6-7.5,2.2l-0.7-2.6
                c0.9-0.2,2-0.4,3.2-0.7v-3.2h-2.5v-0.4l-1.5,0.5c0-0.3-0.1-0.6-0.2-1c-3.8,0.8-4.5,1.1-5,1.3c-0.1-0.5-0.5-1.6-0.8-2.2
                c0.6-0.1,1-0.7,1.6-1.6c0.3-0.4,0.7-1.1,1.3-2.2c-1.5,0.2-2,0.3-2.3,0.5c-0.1-0.5-0.6-1.7-0.8-2.3c0.4-0.1,0.7-0.6,1.1-1.3
                c0.4-0.7,1.5-3.4,2-5.9l2.4,0.9c-0.6,2-1.5,4.2-2.5,6l1.2-0.1c0.4-1,0.9-2,1.2-3.1l2.2,1.1c-0.7,1.7-1.7,3.5-2.7,5.1l1.6-0.4
                c0.4,1,0.7,2.2,1,3.3v-0.5h2.5v-2.1h2.3v2.1h2.2v2.4h-2.2v2.6l2.4-0.6L1050.2,581.1z M1039.9,577.9c0.3,1.4,0.5,3.2,0.6,4.5
                l-1.9,0.5c0-1.2-0.2-3.1-0.4-4.6L1039.9,577.9z M1040.4,574c-0.2-0.6-0.3-1.2-0.5-1.8c-0.5,0.7-0.9,1.4-1.4,2.1L1040.4,574z
                M1042,577.1c0.4,1,0.8,2.2,0.9,3l-1.8,0.6c-0.1-0.8-0.5-2.1-0.9-3.1L1042,577.1z M1048,572.5c-0.1-0.3-0.1-0.6-0.2-1
                c-3.5,0.6-4.2,0.8-4.6,1.1c-0.1-0.5-0.5-1.7-0.8-2.4c0.4-0.1,0.6-0.5,0.9-1.1c0.2-0.4,0.8-1.7,1.2-3.1h-2v-2.4h8v2.4h-3.3
                c-0.6,1.3-1.3,2.8-2.1,4l2.1-0.3c-0.2-0.6-0.5-1.2-0.7-1.7l1.9-0.6c0.6,1.4,1.3,3.2,1.6,4.4L1048,572.5z M1057.2,567.1
                c-0.4,4.6-1.3,8.5-2.6,11.6c0.9,1.6,1.9,3,3.1,4.1c-0.6,0.4-1.4,1.3-1.8,1.9c-1-1-1.8-2.2-2.5-3.6c-0.9,1.5-2,2.8-3.2,3.8
                c-0.3-0.6-1.1-1.6-1.7-2c1.4-1,2.6-2.5,3.6-4.5c-0.4-0.9-0.7-1.8-1-2.7c-0.2,0.4-0.4,0.8-0.6,1.2c-0.1-0.7-0.6-2.1-1-2.8
                c0.3-0.5,0.6-1.1,0.8-1.7c-0.4-1.7-0.7-3.4-1-5.1l1.6-0.3c0.1,0.6,0.2,1.2,0.3,1.9c0.4-2,0.7-4.1,0.9-6.3l2.1,0.3
                c-0.2,1.3-0.3,2.5-0.6,3.8h1.6l0.4-0.1L1057.2,567.1z M1053.4,569c-0.3,1.4-0.6,2.7-1.1,3.9c0.3,1,0.6,1.9,1,2.8
                c0.7-2,1.2-4.3,1.5-6.7H1053.4z M1065.9,571.6c0,3.8-0.3,9.5-2.4,13.1c-0.5-0.5-1.6-1.4-2.2-1.7c1.8-3.2,1.9-8,1.9-11.4V564h8.8
                c-0.1-0.4-0.3-0.8-0.4-1.2l3-0.7c0.2,0.6,0.5,1.2,0.7,1.8h8.4v2.4h-17.9V571.6z M1081.7,579.8c0.3,0.6,0.7,1.3,0.9,1.9
                c0.5,0.2,1.2,0.5,1.7,0.5c-0.2,1.8-0.8,2.3-2.5,2.3h-3.4c-2.5,0-3.1-0.5-3.1-2.3v-2.6h-1.5c-0.7,2.6-2.3,4.3-6.9,5.2
                c-0.2-0.6-0.8-1.6-1.3-2c3.7-0.6,4.9-1.6,5.4-3.2h-3.8v-6.2h3.7c0.1-0.3,0.3-0.6,0.4-0.9h-1.7V569h-2.8v-1.6h2.8v-0.8h2v0.8h2.7
                v1.6h-2.7v3l2.7,0.3c-0.2,0.3-0.5,0.6-0.7,0.9h8.7v6.2h-2.3l0.6,0.2c-0.2,0.4-0.4,0.8-0.7,1.1l1-0.1c-0.1-0.2-0.2-0.4-0.3-0.6
                L1081.7,579.8z M1069,570.4c0,0.7-0.2,1.9-1.7,2.6c-0.3-0.3-0.8-0.8-1.2-1.1c1.1-0.4,1.2-1.2,1.2-1.6v-0.8h1.6V570.4z
                M1069.7,575.7h3.9v-0.9h-3.9V575.7z M1069.7,578h3.9V577h-3.9V578z M1076.7,570.4c0,0.6-0.1,1.8-1,2.5c-0.3-0.3-0.8-0.7-1.2-1.1
                l0,0.3c-0.2,0.1-0.5,0.1-0.8,0.1h0h0h-0.1c-1.1,0-1.3-0.6-1.3-1.6v-1h1.6v1c0,0.3,0,0.4,0.2,0.4c0.1,0,0.3,0,0.4-0.1
                c0,0.2,0,0.5,0,0.8c0.5-0.4,0.6-1.1,0.6-1.4v-0.8h1.6V570.4z M1077.4,572.8V569h-2.6v-1.6h2.6v-0.8h2v0.8h3.4v1.6h-3.4v3.7
                H1077.4z M1079.7,574.9h-3.7v0.9h3.7V574.9z M1079.7,578V577h-3.7v0.9H1079.7z M1081.4,582.8c0.6,0,0.7-0.1,0.8-0.9l-0.8,0.3
                l-0.1-0.4c-2.2,0.4-2.7,0.6-3,0.7c-0.1-0.3-0.3-0.9-0.5-1.3c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.6-0.8,0.8-1.5h-1.5v2.6
                c0,0.6,0.1,0.7,1,0.7H1081.4z M1081.7,572.5c-1.2,0-1.4-0.5-1.4-1.7v-1.3h1.6v1.3c0,0.4,0,0.5,0.3,0.5h0.5c0.2,0,0.7,0,1-0.1
                c0,0.4,0,0.8,0.1,1.2c-0.2,0.1-0.6,0.1-1,0.1H1081.7z M1110.4,572.4h-1.8v9c0,1.3-0.2,2.1-1.1,2.6c-0.8,0.5-2,0.5-3.6,0.5
                c-0.1-0.8-0.4-1.9-0.8-2.8c1,0,2,0,2.3,0c0.3,0,0.4-0.1,0.4-0.4v-8.9h-2.1v-1.9h-3.5v14.1h-2.5v-14.1h-3.2v-1.7
                c-0.4,0.8-0.9,1.8-1.4,2.6v13.2h-2.6v-9.6c-0.5,0.5-0.9,1-1.3,1.4c-0.3-0.6-1.1-2-1.6-2.6c1.8-1.6,3.7-4.1,4.7-6.6l2.3,0.7v-0.2
                h3.2v-5.6h2.5v5.6h3.6v1.9h6.6L1110.4,572.4z M1094.6,563.1c-1.2,2.1-3.2,4.4-5,5.9c-0.3-0.6-1-1.6-1.5-2.1
                c1.5-1.2,3.1-3.1,3.9-4.7L1094.6,563.1z M1097.1,571.8c0,4.6-0.3,7.6-2.1,9.4c-0.2-0.5-0.9-1.3-1.3-1.7c1.3-1.5,1.4-3.8,1.5-7.8
                H1097.1z M1102.4,567.4c-0.3-0.8-1.1-2.1-1.8-3.1l1.7-0.9c0.7,0.9,1.6,2.2,1.9,3L1102.4,567.4z M1103.3,580.5
                c-1.9,0-2.3-0.7-2.3-2.8v-5.9h2v5.9c0,0.6,0,0.8,0.1,0.8h0.6c0.2,0,0.6,0,0.8-0.1c0,0.5,0.1,1.5,0.2,1.9c-0.3,0.1-0.6,0.1-1,0.1
                H1103.3z M1110.1,566.5h-5.7V564h5.7V566.5z M1118.3,569.7h-2.9v-5.4h8.8c-0.2-0.5-0.4-1-0.6-1.4l2.8-0.8
                c0.4,0.7,0.8,1.5,1.1,2.2h8.4v5.4h-3v-2.6h-14.6V569.7z M1132.1,581.4c1.2,0,1.4-0.5,1.6-2.9c0.7,0.5,2,1,2.8,1.1
                c-0.4,3.6-1.3,4.6-4.2,4.6h-4.7c-3.5,0-4.5-0.9-4.5-4v-2.9h-7.3v-2.8h7.3v-2.4c-1.8,0.2-3.6,0.4-5.3,0.6
                c-0.1-0.7-0.5-1.8-0.8-2.5c4.9-0.4,10.2-1.3,13.4-2.5l2.2,2.3c-1.8,0.7-4,1.2-6.3,1.6v2.8h8.7v2.8h-8.7v2.9
                c0,1.1,0.3,1.2,1.7,1.2H1132.1z"
                  />
                </g>
              </g>
            </g>
          </svg>
          <div class="img">
            <img
              src="./s1/c1.png"
              alt=""
              class="bg-img cloud1"
            >
            <img
              src="./s1/1.png"
              alt=""
              class="bg-img"
            >
            <img
              src="./s1/3.png"
              alt=""
              class="bg-img"
            >
            <img
              src="./s1/4.png"
              alt=""
              class="bg-img"
            >
            <img
              src="./s1/c2.png"
              alt=""
              class="bg-img cloud2"
            >
          </div>
        </div>
      </transition>
      <!-- 
      <img src="./s1/2.png" alt="" class="bg-img" data-aos="fade-down" data-aos-delay="800">
      <img src="./s1/5.png" alt="" class="bg-img img5" data-aos="fade-up" data-aos-delay="500">
      <img src="./s1/6.png" alt="" class="bg-img img6" data-aos="fade-right" data-aos-delay="600">
    -->
    </div>
    <div
      class="section1"
      v-if="isMobile"
    >
      <transition
        name="swipe-fade"
        mode="out-in"
      >
        <div
          class="all"
          v-if="isShow"
        >
          <svg
            version="1.1"
            id="s1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 375 604"
            style="enable-background:new 0 0 375 604;"
            xml:space="preserve"
          >
            <path
              class="st0 an3"
              d="M109.2-13c-0.5-0.5-0.5-1.4,0-1.9c0.5-0.5,1.4-0.5,1.9,0c0.5,0.5,0.5,1.4,0,1.9C110.7-12.5,109.8-12.5,109.2-13
              z M120.9-13c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-12.5,120.4-12.5,120.9-13z M130.7-13
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3-12.5,130.1-12.5,130.7-13z M140.4-13
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139-12.5,139.9-12.5,140.4-13z M150.1-13
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7-12.5,149.6-12.5,150.1-13z M159.9-13
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C158.5-12.5,159.3-12.5,159.9-13z M169.6-13
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C168.2-12.5,169.1-12.5,169.6-13z M111.2-22.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8-22.2,110.7-22.2,111.2-22.7z M120.9-22.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-22.2,120.4-22.2,120.9-22.7z M130.7-22.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3-22.2,130.1-22.2,130.7-22.7z M140.4-22.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139-22.2,139.9-22.2,140.4-22.7z M150.1-22.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7-22.2,149.6-22.2,150.1-22.7z M159.9-22.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C158.5-22.2,159.3-22.2,159.9-22.7z M169.6-22.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C168.2-22.2,169.1-22.2,169.6-22.7z M111.2-32.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8-31.9,110.7-31.9,111.2-32.5z M120.9-32.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-31.9,120.4-31.9,120.9-32.5z M130.7-32.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3-31.9,130.1-31.9,130.7-32.5z M140.4-32.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139-31.9,139.9-31.9,140.4-32.5z M150.1-32.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7-31.9,149.6-31.9,150.1-32.5z M159.9-32.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C158.5-31.9,159.3-31.9,159.9-32.5z M169.6-32.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C168.2-31.9,169.1-31.9,169.6-32.5z M111.2-42.2
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8-41.7,110.7-41.7,111.2-42.2z M120.9-42.2
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-41.7,120.4-41.7,120.9-42.2z M130.7-42.2
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3-41.7,130.1-41.7,130.7-42.2z M140.4-42.2
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139-41.7,139.9-41.7,140.4-42.2z M150.1-42.2
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7-41.7,149.6-41.7,150.1-42.2z M159.9-42.2
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C158.5-41.7,159.3-41.7,159.9-42.2z M169.6-42.2
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C168.2-41.7,169.1-41.7,169.6-42.2z M111.2-51.9
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8-51.4,110.7-51.4,111.2-51.9z M120.9-51.9
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-51.4,120.4-51.4,120.9-51.9z M130.7-51.9
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3-51.4,130.1-51.4,130.7-51.9z M140.4-51.9
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139-51.4,139.9-51.4,140.4-51.9z M150.1-51.9
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7-51.4,149.6-51.4,150.1-51.9z M159.9-51.9
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C158.5-51.4,159.3-51.4,159.9-51.9z M111.2-61.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8-61.1,110.7-61.1,111.2-61.7z M120.9-61.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-61.1,120.4-61.1,120.9-61.7z M130.7-61.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3-61.1,130.1-61.1,130.7-61.7z M140.4-61.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139-61.1,139.9-61.1,140.4-61.7z M150.1-61.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7-61.1,149.6-61.1,150.1-61.7z M111.2-71.4
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8-70.9,110.7-70.9,111.2-71.4z M120.9-71.4
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-70.9,120.4-70.9,120.9-71.4z M130.7-71.4
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3-70.9,130.1-70.9,130.7-71.4z M140.4-71.4
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139-70.9,139.9-70.9,140.4-71.4z M111.2-81.1
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8-80.6,110.7-80.6,111.2-81.1z M120.9-81.1
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-80.6,120.4-80.6,120.9-81.1z M111.2-90.9
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8-90.4,110.7-90.4,111.2-90.9z M120.9-90.9
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-90.4,120.4-90.4,120.9-90.9z M130.7-81.1
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3-80.6,130.1-80.6,130.7-81.1z M140.4-81.1
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139-80.6,139.9-80.6,140.4-81.1z M150.1-71.4
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7-70.9,149.6-70.9,150.1-71.4z M159.9-61.7
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C158.5-61.1,159.3-61.1,159.9-61.7z M169.6-51.9
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C168.2-51.4,169.1-51.4,169.6-51.9z M111.2-3.3
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8-2.7,110.7-2.7,111.2-3.3z M120.9-3.3
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5-2.7,120.4-2.7,120.9-3.3z M130.7-3.3
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3-2.7,130.1-2.7,130.7-3.3z M140.4-3.3
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139-2.7,139.9-2.7,140.4-3.3z M150.1-3.3
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7-2.7,149.6-2.7,150.1-3.3z M159.9-3.3
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C158.5-2.7,159.3-2.7,159.9-3.3z M169.6-3.3
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C168.2-2.7,169.1-2.7,169.6-3.3z M111.2,6.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8,7,110.7,7,111.2,6.5z M120.9,6.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5,7,120.4,7,120.9,6.5z M130.7,6.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3,7,130.1,7,130.7,6.5z M140.4,6.5
              c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139,7,139.9,7,140.4,6.5z M150.1,6.5c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7,7,149.6,7,150.1,6.5z M159.9,6.5c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C158.5,7,159.3,7,159.9,6.5z M111.2,16.2c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8,16.7,110.7,16.7,111.2,16.2z M120.9,16.2c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5,16.7,120.4,16.7,120.9,16.2z M130.7,16.2c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3,16.7,130.1,16.7,130.7,16.2z M140.4,16.2c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139,16.7,139.9,16.7,140.4,16.2z M150.1,16.2c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7,16.7,149.6,16.7,150.1,16.2z M111.2,25.9c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8,26.5,110.7,26.5,111.2,25.9z M120.9,25.9c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5,26.5,120.4,26.5,120.9,25.9z M130.7,25.9c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3,26.5,130.1,26.5,130.7,25.9z M140.4,25.9c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139,26.5,139.9,26.5,140.4,25.9z M111.2,35.7c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8,36.2,110.7,36.2,111.2,35.7z M120.9,35.7c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5,36.2,120.4,36.2,120.9,35.7z M111.2,45.4c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C109.8,45.9,110.7,45.9,111.2,45.4z M120.9,45.4c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C119.5,45.9,120.4,45.9,120.9,45.4z M130.7,35.7c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C129.3,36.2,130.1,36.2,130.7,35.7z M140.4,35.7c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C139,36.2,139.9,36.2,140.4,35.7z M150.1,25.9c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C148.7,26.5,149.6,26.5,150.1,25.9z M159.9,16.2c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C158.5,16.7,159.3,16.7,159.9,16.2z M169.6,6.5c0.5-0.5,0.5-1.4,0-1.9
              c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9C168.2,7,169.1,7,169.6,6.5z M99.8-34.5c-0.5,0.5-0.5,1.4,0,1.9
              c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9C101.2-35,100.4-35,99.8-34.5z M90.1-34.5c-0.5,0.5-0.5,1.4,0,1.9
              c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9C91.5-35,90.6-35,90.1-34.5z M80.4-34.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0
              c0.5-0.5,0.5-1.4,0-1.9C81.8-35,80.9-35,80.4-34.5z M70.6-34.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72-35,71.2-35,70.6-34.5z M60.9-34.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3-35,61.4-35,60.9-34.5z M51.2-34.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6-35,51.7-35,51.2-34.5z M41.4-34.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C42.8-35,42-35,41.4-34.5z M31.7-34.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C33.1-35,32.2-35,31.7-34.5z M99.8-24.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2-25.3,100.4-25.3,99.8-24.7z M90.1-24.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5-25.3,90.6-25.3,90.1-24.7z M80.4-24.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8-25.3,80.9-25.3,80.4-24.7z M70.6-24.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72-25.3,71.2-25.3,70.6-24.7z M60.9-24.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3-25.3,61.4-25.3,60.9-24.7z M51.2-24.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6-25.3,51.7-25.3,51.2-24.7z M41.4-24.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C42.8-25.3,42-25.3,41.4-24.7z M31.7-24.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C33.1-25.3,32.2-25.3,31.7-24.7z M99.8-15c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2-15.5,100.4-15.5,99.8-15z M90.1-15c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5-15.5,90.6-15.5,90.1-15z M80.4-15c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8-15.5,80.9-15.5,80.4-15z M70.6-15c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72-15.5,71.2-15.5,70.6-15z M60.9-15c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3-15.5,61.4-15.5,60.9-15z M51.2-15c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6-15.5,51.7-15.5,51.2-15z M41.4-15c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C42.8-15.5,42-15.5,41.4-15z M31.7-15c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C33.1-15.5,32.2-15.5,31.7-15z M99.8-5.3c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2-5.8,100.4-5.8,99.8-5.3z M90.1-5.3c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5-5.8,90.6-5.8,90.1-5.3z M80.4-5.3c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8-5.8,80.9-5.8,80.4-5.3z M70.6-5.3c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72-5.8,71.2-5.8,70.6-5.3z M60.9-5.3c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3-5.8,61.4-5.8,60.9-5.3z M51.2-5.3c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6-5.8,51.7-5.8,51.2-5.3z M41.4-5.3c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C42.8-5.8,42-5.8,41.4-5.3z M31.7-5.3c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C33.1-5.8,32.2-5.8,31.7-5.3z M99.8,4.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2,3.9,100.4,3.9,99.8,4.5z M90.1,4.5c-0.5,0.5-0.5,1.4,0,1.9C90.6,7,91.5,7,92,6.4c0.5-0.5,0.5-1.4,0-1.9
              C91.5,3.9,90.6,3.9,90.1,4.5z M80.4,4.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8,3.9,80.9,3.9,80.4,4.5z M70.6,4.5c-0.5,0.5-0.5,1.4,0,1.9C71.2,7,72,7,72.6,6.4c0.5-0.5,0.5-1.4,0-1.9
              C72,3.9,71.2,3.9,70.6,4.5z M60.9,4.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3,3.9,61.4,3.9,60.9,4.5z M51.2,4.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6,3.9,51.7,3.9,51.2,4.5z M41.4,4.5c-0.5,0.5-0.5,1.4,0,1.9C42,7,42.8,7,43.4,6.4c0.5-0.5,0.5-1.4,0-1.9
              C42.8,3.9,42,3.9,41.4,4.5z M99.8,14.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2,13.7,100.4,13.7,99.8,14.2z M90.1,14.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5,13.7,90.6,13.7,90.1,14.2z M80.4,14.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8,13.7,80.9,13.7,80.4,14.2z M70.6,14.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72,13.7,71.2,13.7,70.6,14.2z M60.9,14.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3,13.7,61.4,13.7,60.9,14.2z M51.2,14.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6,13.7,51.7,13.7,51.2,14.2z M99.8,23.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2,23.4,100.4,23.4,99.8,23.9z M90.1,23.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5,23.4,90.6,23.4,90.1,23.9z M80.4,23.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8,23.4,80.9,23.4,80.4,23.9z M70.6,23.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72,23.4,71.2,23.4,70.6,23.9z M60.9,23.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3,23.4,61.4,23.4,60.9,23.9z M99.8,33.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2,33.1,100.4,33.1,99.8,33.7z M90.1,33.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5,33.1,90.6,33.1,90.1,33.7z M80.4,33.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8,33.1,80.9,33.1,80.4,33.7z M99.8,43.4c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2,42.9,100.4,42.9,99.8,43.4z M90.1,43.4c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5,42.9,90.6,42.9,90.1,43.4z M80.4,43.4c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8,42.9,80.9,42.9,80.4,43.4z M70.6,33.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72,33.1,71.2,33.1,70.6,33.7z M60.9,33.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3,33.1,61.4,33.1,60.9,33.7z M51.2,23.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6,23.4,51.7,23.4,51.2,23.9z M41.4,14.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C42.8,13.7,42,13.7,41.4,14.2z M31.7,4.5c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C33.1,3.9,32.2,3.9,31.7,4.5z M99.8-44.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2-44.7,100.4-44.7,99.8-44.2z M90.1-44.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5-44.7,90.6-44.7,90.1-44.2z M80.4-44.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8-44.7,80.9-44.7,80.4-44.2z M70.6-44.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72-44.7,71.2-44.7,70.6-44.2z M60.9-44.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3-44.7,61.4-44.7,60.9-44.2z M51.2-44.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6-44.7,51.7-44.7,51.2-44.2z M41.4-44.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C42.8-44.7,42-44.7,41.4-44.2z M31.7-44.2c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C33.1-44.7,32.2-44.7,31.7-44.2z M99.8-53.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2-54.5,100.4-54.5,99.8-53.9z M90.1-53.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5-54.5,90.6-54.5,90.1-53.9z M80.4-53.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8-54.5,80.9-54.5,80.4-53.9z M70.6-53.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72-54.5,71.2-54.5,70.6-53.9z M60.9-53.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3-54.5,61.4-54.5,60.9-53.9z M51.2-53.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6-54.5,51.7-54.5,51.2-53.9z M41.4-53.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C42.8-54.5,42-54.5,41.4-53.9z M99.8-63.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2-64.2,100.4-64.2,99.8-63.7z M90.1-63.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5-64.2,90.6-64.2,90.1-63.7z M80.4-63.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8-64.2,80.9-64.2,80.4-63.7z M70.6-63.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72-64.2,71.2-64.2,70.6-63.7z M60.9-63.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3-64.2,61.4-64.2,60.9-63.7z M51.2-63.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6-64.2,51.7-64.2,51.2-63.7z M99.8-73.4c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2-73.9,100.4-73.9,99.8-73.4z M90.1-73.4c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5-73.9,90.6-73.9,90.1-73.4z M80.4-73.4c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8-73.9,80.9-73.9,80.4-73.4z M70.6-73.4c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72-73.9,71.2-73.9,70.6-73.4z M60.9-73.4c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3-73.9,61.4-73.9,60.9-73.4z M99.8-83.1c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2-83.7,100.4-83.7,99.8-83.1z M90.1-83.1c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5-83.7,90.6-83.7,90.1-83.1z M80.4-83.1c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8-83.7,80.9-83.7,80.4-83.1z M99.8-92.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C101.2-93.4,100.4-93.4,99.8-92.9z M90.1-92.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C91.5-93.4,90.6-93.4,90.1-92.9z M80.4-92.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C81.8-93.4,80.9-93.4,80.4-92.9z M70.6-83.1c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C72-83.7,71.2-83.7,70.6-83.1z M60.9-83.1c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C62.3-83.7,61.4-83.7,60.9-83.1z M51.2-73.4c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C52.6-73.9,51.7-73.9,51.2-73.4z M41.4-63.7c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C42.8-64.2,42-64.2,41.4-63.7z M31.7-53.9c-0.5,0.5-0.5,1.4,0,1.9c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
              C33.1-54.5,32.2-54.5,31.7-53.9z"
            />
            <g class="right_2">
              <circle
                class="st1 an2"
                cx="325.9"
                cy="612.1"
                r="58.9"
              />
              <path
                class="st2 an1"
                d="M384.4,571.6c-0.4-0.1-0.6-0.5-0.5-0.9c0.1-0.4,0.5-0.6,0.9-0.5c0.4,0.1,0.6,0.5,0.5,0.9
                C385.1,571.5,384.7,571.7,384.4,571.6z M389.7,568.7c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C389.2,569.3,389.6,569.1,389.7,568.7z M394.1,566.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C393.6,566.9,394,566.7,394.1,566.3z M398.5,563.9c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C398,564.5,398.4,564.2,398.5,563.9z M402.9,561.4c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C402.4,562,402.8,561.8,402.9,561.4z M407.4,559c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C406.8,559.6,407.2,559.4,407.4,559z M411.8,556.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C411.3,557.2,411.7,557,411.8,556.6z M382.8,566.7c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C382.3,567.3,382.7,567.1,382.8,566.7z M387.2,564.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C386.7,564.9,387.1,564.7,387.2,564.3z M391.7,561.9c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C391.2,562.5,391.6,562.2,391.7,561.9z M396.1,559.4c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C395.6,560,396,559.8,396.1,559.4z M400.5,557c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C400,557.6,400.4,557.4,400.5,557z M404.9,554.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C404.4,555.2,404.8,555,404.9,554.6z M409.3,552.2c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C408.8,552.8,409.2,552.5,409.3,552.2z M380.4,562.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C379.9,562.9,380.3,562.7,380.4,562.3z M384.8,559.9c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C384.3,560.5,384.7,560.3,384.8,559.9z M389.2,557.4c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C388.7,558,389.1,557.8,389.2,557.4z M393.7,555c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C393.2,555.6,393.5,555.4,393.7,555z M398.1,552.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C397.6,553.2,398,553,398.1,552.6z M402.5,550.2c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C402,550.8,402.4,550.5,402.5,550.2z M406.9,547.7c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C406.4,548.3,406.8,548.1,406.9,547.7z M378,557.9c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C377.5,558.5,377.9,558.3,378,557.9z M382.4,555.5c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C381.9,556,382.3,555.8,382.4,555.5z M386.8,553c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C386.3,553.6,386.7,553.4,386.8,553z M391.2,550.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C390.7,551.2,391.1,551,391.2,550.6z M395.7,548.2c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C395.1,548.8,395.5,548.5,395.7,548.2z M400.1,545.7c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C399.6,546.3,400,546.1,400.1,545.7z M404.5,543.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C404,543.9,404.4,543.7,404.5,543.3z M375.5,553.5c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C375,554.1,375.4,553.8,375.5,553.5z M380,551c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C379.5,551.6,379.9,551.4,380,551z M384.4,548.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C383.9,549.2,384.3,549,384.4,548.6z M388.8,546.2c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C388.3,546.8,388.7,546.6,388.8,546.2z M393.2,543.8c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C392.7,544.3,393.1,544.1,393.2,543.8z M397.6,541.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C397.1,541.9,397.5,541.7,397.6,541.3z M373.1,549c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C372.6,549.6,373,549.4,373.1,549z M377.5,546.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C377,547.2,377.4,547,377.5,546.6z M382,544.2c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C381.5,544.8,381.8,544.6,382,544.2z M386.4,541.8c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C385.9,542.4,386.3,542.1,386.4,541.8z M390.8,539.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C390.3,539.9,390.7,539.7,390.8,539.3z M370.7,544.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C370.2,545.2,370.6,545,370.7,544.6z M375.1,542.2c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C374.6,542.8,375,542.6,375.1,542.2z M379.5,539.8c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C379,540.4,379.4,540.1,379.5,539.8z M384,537.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C383.4,537.9,383.8,537.7,384,537.3z M368.3,540.2c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C367.8,540.8,368.2,540.6,368.3,540.2z M372.7,537.8c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C372.2,538.4,372.6,538.1,372.7,537.8z M365.8,535.8c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C365.3,536.4,365.7,536.1,365.8,535.8z M370.2,533.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C369.7,533.9,370.1,533.7,370.2,533.3z M377.1,535.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C376.6,535.9,377,535.7,377.1,535.3z M381.5,532.9c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C381,533.5,381.4,533.3,381.5,532.9z M388.4,534.9c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C387.9,535.5,388.3,535.3,388.4,534.9z M395.2,536.9c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C394.7,537.5,395.1,537.3,395.2,536.9z M402.1,538.9c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C401.6,539.5,402,539.3,402.1,538.9z M387.7,575.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C387.2,576.2,387.6,575.9,387.7,575.6z M392.1,573.1c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C391.6,573.7,392,573.5,392.1,573.1z M396.5,570.7c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C396,571.3,396.4,571.1,396.5,570.7z M400.9,568.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C400.4,568.9,400.8,568.7,400.9,568.3z M405.4,565.9c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C404.9,566.5,405.3,566.2,405.4,565.9z M409.8,563.4c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C409.3,564,409.7,563.8,409.8,563.4z M414.2,561c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C413.7,561.6,414.1,561.4,414.2,561z M390.1,580c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C389.6,580.6,390,580.4,390.1,580z M394.5,577.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C394,578.2,394.4,577.9,394.5,577.6z M398.9,575.1c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C398.4,575.7,398.8,575.5,398.9,575.1z M403.4,572.7c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C402.9,573.3,403.3,573.1,403.4,572.7z M407.8,570.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C407.3,570.9,407.7,570.7,407.8,570.3z M412.2,567.8c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C411.7,568.4,412.1,568.2,412.2,567.8z M392.5,584.4c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C392,585,392.4,584.8,392.5,584.4z M397,582c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C396.4,582.6,396.8,582.4,397,582z M401.4,579.6c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C400.9,580.1,401.3,579.9,401.4,579.6z M405.8,577.1c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C405.3,577.7,405.7,577.5,405.8,577.1z M410.2,574.7c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C409.7,575.3,410.1,575.1,410.2,574.7z M395,588.8c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C394.5,589.4,394.8,589.2,395,588.8z M399.4,586.4c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C398.9,587,399.3,586.8,399.4,586.4z M403.8,584c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C403.3,584.6,403.7,584.3,403.8,584z M408.2,581.5c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C407.7,582.1,408.1,581.9,408.2,581.5z M397.4,593.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C396.9,593.9,397.3,593.6,397.4,593.3z M401.8,590.8c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C401.3,591.4,401.7,591.2,401.8,590.8z M399.8,597.7c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C399.3,598.3,399.7,598,399.8,597.7z M404.2,595.2c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C403.7,595.8,404.1,595.6,404.2,595.2z M406.2,588.4c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C405.7,589,406.1,588.8,406.2,588.4z M410.7,586c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C410.1,586.6,410.5,586.4,410.7,586z M412.6,579.1c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C412.1,579.7,412.5,579.5,412.6,579.1z M414.6,572.3c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C414.1,572.9,414.5,572.6,414.6,572.3z M416.6,565.4c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
                C416.1,566,416.5,565.8,416.6,565.4z M374.7,564.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C375.2,563.6,374.8,563.9,374.7,564.2z M370.3,566.7c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C370.8,566.1,370.4,566.3,370.3,566.7z M365.9,569.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C366.4,568.5,366,568.7,365.9,569.1z M361.5,571.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C362,570.9,361.6,571.1,361.5,571.5z M357.1,573.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C357.6,573.3,357.2,573.6,357.1,573.9z M352.6,576.4c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C353.1,575.8,352.7,576,352.6,576.4z M348.2,578.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C348.7,578.2,348.3,578.4,348.2,578.8z M343.8,581.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C344.3,580.6,343.9,580.8,343.8,581.2z M377.2,568.7c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C377.7,568.1,377.3,568.3,377.2,568.7z M372.7,571.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C373.2,570.5,372.9,570.7,372.7,571.1z M368.3,573.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C368.8,572.9,368.4,573.1,368.3,573.5z M363.9,575.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C364.4,575.3,364,575.6,363.9,575.9z M359.5,578.4c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C360,577.8,359.6,578,359.5,578.4z M355.1,580.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C355.6,580.2,355.2,580.4,355.1,580.8z M350.6,583.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C351.1,582.6,350.7,582.8,350.6,583.2z M346.2,585.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C346.7,585,346.3,585.3,346.2,585.6z M379.6,573.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C380.1,572.5,379.7,572.7,379.6,573.1z M375.2,575.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C375.7,574.9,375.3,575.1,375.2,575.5z M370.7,577.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C371.3,577.3,370.9,577.6,370.7,577.9z M366.3,580.4c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C366.8,579.8,366.4,580,366.3,580.4z M361.9,582.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C362.4,582.2,362,582.4,361.9,582.8z M357.5,585.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C358,584.6,357.6,584.8,357.5,585.2z M353.1,587.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C353.6,587,353.2,587.3,353.1,587.6z M348.6,590.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C349.1,589.5,348.8,589.7,348.6,590.1z M382,577.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C382.5,576.9,382.1,577.1,382,577.5z M377.6,579.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C378.1,579.3,377.7,579.5,377.6,579.9z M373.2,582.3c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C373.7,581.8,373.3,582,373.2,582.3z M368.8,584.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C369.3,584.2,368.9,584.4,368.8,584.8z M364.3,587.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C364.8,586.6,364.4,586.8,364.3,587.2z M359.9,589.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C360.4,589,360,589.3,359.9,589.6z M355.5,592.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C356,591.5,355.6,591.7,355.5,592.1z M351.1,594.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C351.6,593.9,351.2,594.1,351.1,594.5z M384.4,581.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C384.9,581.3,384.6,581.5,384.4,581.9z M380,584.3c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C380.5,583.7,380.1,584,380,584.3z M375.6,586.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C376.1,586.2,375.7,586.4,375.6,586.8z M371.2,589.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C371.7,588.6,371.3,588.8,371.2,589.2z M366.8,591.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C367.3,591,366.9,591.2,366.8,591.6z M362.3,594.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C362.8,593.5,362.4,593.7,362.3,594.1z M357.9,596.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C358.4,595.9,358,596.1,357.9,596.5z M386.9,586.3c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C387.4,585.7,387,586,386.9,586.3z M382.5,588.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C383,588.2,382.6,588.4,382.5,588.8z M378,591.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C378.5,590.6,378.1,590.8,378,591.2z M373.6,593.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C374.1,593,373.7,593.2,373.6,593.6z M369.2,596c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C369.7,595.5,369.3,595.7,369.2,596z M364.8,598.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C365.3,597.9,364.9,598.1,364.8,598.5z M389.3,590.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C389.8,590.2,389.4,590.4,389.3,590.8z M384.9,593.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C385.4,592.6,385,592.8,384.9,593.2z M380.5,595.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C381,595,380.6,595.2,380.5,595.6z M376,598c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C376.5,597.4,376.1,597.7,376,598z M371.6,600.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C372.1,599.9,371.7,600.1,371.6,600.5z M391.7,595.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C392.2,594.6,391.8,594.8,391.7,595.2z M387.3,597.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C387.8,597,387.4,597.2,387.3,597.6z M382.9,600c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C383.4,599.4,383,599.7,382.9,600z M394.2,599.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C394.7,599,394.3,599.2,394.2,599.6z M389.7,602c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C390.2,601.4,389.8,601.7,389.7,602z M385.3,604.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C385.8,603.9,385.4,604.1,385.3,604.5z M378.5,602.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C379,601.9,378.6,602.1,378.5,602.5z M374,604.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C374.5,604.3,374.2,604.5,374,604.9z M367.2,602.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C367.7,602.3,367.3,602.5,367.2,602.9z M360.3,600.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C360.9,600.3,360.5,600.5,360.3,600.9z M353.5,598.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C354,598.3,353.6,598.5,353.5,598.9z M372.3,559.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C372.8,559.2,372.4,559.4,372.3,559.8z M367.9,562.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C368.4,561.6,368,561.9,367.9,562.2z M363.5,564.7c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C364,564.1,363.6,564.3,363.5,564.7z M359,567.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C359.6,566.5,359.2,566.7,359,567.1z M354.6,569.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C355.1,568.9,354.7,569.1,354.6,569.5z M350.2,571.9c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C350.7,571.4,350.3,571.6,350.2,571.9z M345.8,574.4c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C346.3,573.8,345.9,574,345.8,574.4z M341.4,576.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C341.9,576.2,341.5,576.4,341.4,576.8z M369.9,555.4c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C370.4,554.8,370,555,369.9,555.4z M365.5,557.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C366,557.2,365.6,557.4,365.5,557.8z M361,560.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C361.5,559.7,361.2,559.9,361,560.2z M356.6,562.7c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C357.1,562.1,356.7,562.3,356.6,562.7z M352.2,565.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C352.7,564.5,352.3,564.7,352.2,565.1z M347.8,567.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C348.3,566.9,347.9,567.1,347.8,567.5z M343.4,570c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C343.9,569.4,343.5,569.6,343.4,570z M367.5,551c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C368,550.4,367.6,550.6,367.5,551z M363,553.4c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C363.5,552.8,363.1,553,363,553.4z M358.6,555.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C359.1,555.2,358.7,555.4,358.6,555.8z M354.2,558.3c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C354.7,557.7,354.3,557.9,354.2,558.3z M349.8,560.7c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C350.3,560.1,349.9,560.3,349.8,560.7z M345.4,563.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C345.9,562.5,345.5,562.7,345.4,563.1z M365,546.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C365.5,546,365.1,546.2,365,546.6z M360.6,549c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C361.1,548.4,360.7,548.6,360.6,549z M356.2,551.4c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C356.7,550.8,356.3,551,356.2,551.4z M351.8,553.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C352.3,553.2,351.9,553.5,351.8,553.8z M347.3,556.3c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C347.9,555.7,347.5,555.9,347.3,556.3z M362.6,542.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C363.1,541.5,362.7,541.7,362.6,542.1z M358.2,544.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C358.7,543.9,358.3,544.2,358.2,544.5z M353.8,547c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C354.3,546.4,353.9,546.6,353.8,547z M360.2,537.7c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C360.7,537.1,360.3,537.3,360.2,537.7z M355.8,540.1c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C356.3,539.5,355.9,539.8,355.8,540.1z M351.3,542.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C351.8,542,351.4,542.2,351.3,542.6z M349.3,549.4c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C349.8,548.8,349.4,549,349.3,549.4z M344.9,551.8c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C345.4,551.2,345,551.4,344.9,551.8z M342.9,558.7c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C343.4,558.1,343,558.3,342.9,558.7z M340.9,565.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C341.4,564.9,341,565.2,340.9,565.5z M338.9,572.4c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
                C339.4,571.8,339,572,338.9,572.4z"
              />
            </g>
            <circle
              class="st3 an1"
              cx="-8.5"
              cy="-0.5"
              r="80.5"
            />
            <g class="a1">
              <path
                class="st2"
                d="M8.3,511.2c0,0,73.1,7.1,73.1,75.3v-75.3H8.3z"
              />
              <path
                class="st2"
                d="M316.5,533.5h-75.3c0,0-7.1,73.1-75.3,73.1h75.3C241.2,606.5,248.4,533.5,316.5,533.5z"
              />
              <path
                class="st0 an4"
                d="M151.8,533.3c0.4-0.4,1.2-0.4,1.6,0c0.4,0.4,0.4,1.2,0,1.6c-0.4,0.4-1.2,0.4-1.6,0
                C151.3,534.5,151.3,533.7,151.8,533.3z M151.8,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C151.3,541.7,151.3,542.4,151.8,542.9z M151.8,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C151.3,549.7,151.3,550.4,151.8,550.9z M151.8,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C151.3,557.7,151.3,558.4,151.8,558.9z M151.8,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C151.3,565.7,151.3,566.4,151.8,566.8z M151.8,574.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C151.3,573.7,151.3,574.4,151.8,574.8z M151.8,582.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C151.3,581.6,151.3,582.4,151.8,582.8z M159.7,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C159.3,533.7,159.3,534.5,159.7,534.9z M159.7,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C159.3,541.7,159.3,542.4,159.7,542.9z M159.7,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C159.3,549.7,159.3,550.4,159.7,550.9z M159.7,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C159.3,557.7,159.3,558.4,159.7,558.9z M159.7,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C159.3,565.7,159.3,566.4,159.7,566.8z M159.7,574.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C159.3,573.7,159.3,574.4,159.7,574.8z M159.7,582.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C159.3,581.6,159.3,582.4,159.7,582.8z M167.7,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C167.3,533.7,167.3,534.5,167.7,534.9z M167.7,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C167.3,541.7,167.3,542.4,167.7,542.9z M167.7,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C167.3,549.7,167.3,550.4,167.7,550.9z M167.7,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C167.3,557.7,167.3,558.4,167.7,558.9z M167.7,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C167.3,565.7,167.3,566.4,167.7,566.8z M167.7,574.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C167.3,573.7,167.3,574.4,167.7,574.8z M167.7,582.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C167.3,581.6,167.3,582.4,167.7,582.8z M175.7,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C175.3,533.7,175.3,534.5,175.7,534.9z M175.7,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C175.3,541.7,175.3,542.4,175.7,542.9z M175.7,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C175.3,549.7,175.3,550.4,175.7,550.9z M175.7,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C175.3,557.7,175.3,558.4,175.7,558.9z M175.7,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C175.3,565.7,175.3,566.4,175.7,566.8z M175.7,574.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C175.3,573.7,175.3,574.4,175.7,574.8z M175.7,582.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C175.3,581.6,175.3,582.4,175.7,582.8z M183.7,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C183.2,533.7,183.2,534.5,183.7,534.9z M183.7,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C183.2,541.7,183.2,542.4,183.7,542.9z M183.7,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C183.2,549.7,183.2,550.4,183.7,550.9z M183.7,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C183.2,557.7,183.2,558.4,183.7,558.9z M183.7,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C183.2,565.7,183.2,566.4,183.7,566.8z M183.7,574.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C183.2,573.7,183.2,574.4,183.7,574.8z M191.7,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C191.2,533.7,191.2,534.5,191.7,534.9z M191.7,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C191.2,541.7,191.2,542.4,191.7,542.9z M191.7,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C191.2,549.7,191.2,550.4,191.7,550.9z M191.7,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C191.2,557.7,191.2,558.4,191.7,558.9z M191.7,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C191.2,565.7,191.2,566.4,191.7,566.8z M199.7,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C199.2,533.7,199.2,534.5,199.7,534.9z M199.7,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C199.2,541.7,199.2,542.4,199.7,542.9z M199.7,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C199.2,549.7,199.2,550.4,199.7,550.9z M199.7,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C199.2,557.7,199.2,558.4,199.7,558.9z M207.6,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C207.2,533.7,207.2,534.5,207.6,534.9z M207.6,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C207.2,541.7,207.2,542.4,207.6,542.9z M215.7,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C215.2,533.7,215.2,534.5,215.7,534.9z M215.7,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C215.2,541.7,215.2,542.4,215.7,542.9z M207.6,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C207.2,549.7,207.2,550.4,207.6,550.9z M207.6,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C207.2,557.7,207.2,558.4,207.6,558.9z M199.7,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C199.2,565.7,199.2,566.4,199.7,566.8z M191.7,574.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C191.2,573.7,191.2,574.4,191.7,574.8z M183.7,582.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C183.2,581.6,183.2,582.4,183.7,582.8z M143.8,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C143.3,533.7,143.3,534.5,143.8,534.9z M143.8,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C143.3,541.7,143.3,542.4,143.8,542.9z M143.8,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C143.3,549.7,143.3,550.4,143.8,550.9z M143.8,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C143.3,557.7,143.3,558.4,143.8,558.9z M143.8,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C143.3,565.7,143.3,566.4,143.8,566.8z M143.8,574.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C143.3,573.7,143.3,574.4,143.8,574.8z M143.8,582.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C143.3,581.6,143.3,582.4,143.8,582.8z M135.8,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C135.4,533.7,135.4,534.5,135.8,534.9z M135.8,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C135.4,541.7,135.4,542.4,135.8,542.9z M135.8,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C135.4,549.7,135.4,550.4,135.8,550.9z M135.8,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C135.4,557.7,135.4,558.4,135.8,558.9z M135.8,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C135.4,565.7,135.4,566.4,135.8,566.8z M135.8,574.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C135.4,573.7,135.4,574.4,135.8,574.8z M127.8,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C127.4,533.7,127.4,534.5,127.8,534.9z M127.8,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C127.4,541.7,127.4,542.4,127.8,542.9z M127.8,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C127.4,549.7,127.4,550.4,127.8,550.9z M127.8,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C127.4,557.7,127.4,558.4,127.8,558.9z M127.8,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C127.4,565.7,127.4,566.4,127.8,566.8z M119.8,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C119.4,533.7,119.4,534.5,119.8,534.9z M119.8,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C119.4,541.7,119.4,542.4,119.8,542.9z M119.8,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C119.4,549.7,119.4,550.4,119.8,550.9z M119.8,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C119.4,557.7,119.4,558.4,119.8,558.9z M111.8,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C111.4,533.7,111.4,534.5,111.8,534.9z M111.8,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C111.4,541.7,111.4,542.4,111.8,542.9z M103.9,534.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C103.4,533.7,103.4,534.5,103.9,534.9z M103.9,542.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C103.4,541.7,103.4,542.4,103.9,542.9z M111.8,550.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C111.4,549.7,111.4,550.4,111.8,550.9z M111.8,558.9c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C111.4,557.7,111.4,558.4,111.8,558.9z M119.8,566.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C119.4,565.7,119.4,566.4,119.8,566.8z M127.8,574.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C127.4,573.7,127.4,574.4,127.8,574.8z M135.8,582.8c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0
                C135.4,581.6,135.4,582.4,135.8,582.8z M169.4,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C169.8,526.7,169.8,526,169.4,525.6z M169.4,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C169.8,518.7,169.8,518,169.4,517.6z M169.4,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C169.8,510.8,169.8,510,169.4,509.6z M169.4,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C169.8,502.8,169.8,502.1,169.4,501.6z M169.4,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C169.8,494.8,169.8,494.1,169.4,493.6z M169.4,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C169.8,486.8,169.8,486.1,169.4,485.7z M169.4,477.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C169.8,478.8,169.8,478.1,169.4,477.7z M169.4,469.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C169.8,470.8,169.8,470.1,169.4,469.7z M161.4,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C161.8,526.7,161.8,526,161.4,525.6z M161.4,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C161.8,518.7,161.8,518,161.4,517.6z M161.4,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C161.8,510.8,161.8,510,161.4,509.6z M161.4,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C161.8,502.8,161.8,502.1,161.4,501.6z M161.4,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C161.8,494.8,161.8,494.1,161.4,493.6z M161.4,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C161.8,486.8,161.8,486.1,161.4,485.7z M161.4,477.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C161.8,478.8,161.8,478.1,161.4,477.7z M161.4,469.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C161.8,470.8,161.8,470.1,161.4,469.7z M153.4,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C153.8,526.7,153.8,526,153.4,525.6z M153.4,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C153.8,518.7,153.8,518,153.4,517.6z M153.4,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C153.8,510.8,153.8,510,153.4,509.6z M153.4,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C153.8,502.8,153.8,502.1,153.4,501.6z M153.4,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C153.8,494.8,153.8,494.1,153.4,493.6z M153.4,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C153.8,486.8,153.8,486.1,153.4,485.7z M153.4,477.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C153.8,478.8,153.8,478.1,153.4,477.7z M153.4,469.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C153.8,470.8,153.8,470.1,153.4,469.7z M145.4,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C145.8,526.7,145.8,526,145.4,525.6z M145.4,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C145.8,518.7,145.8,518,145.4,517.6z M145.4,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C145.8,510.8,145.8,510,145.4,509.6z M145.4,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C145.8,502.8,145.8,502.1,145.4,501.6z M145.4,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C145.8,494.8,145.8,494.1,145.4,493.6z M145.4,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C145.8,486.8,145.8,486.1,145.4,485.7z M145.4,477.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C145.8,478.8,145.8,478.1,145.4,477.7z M145.4,469.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C145.8,470.8,145.8,470.1,145.4,469.7z M137.4,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C137.9,526.7,137.9,526,137.4,525.6z M137.4,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C137.9,518.7,137.9,518,137.4,517.6z M137.4,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C137.9,510.8,137.9,510,137.4,509.6z M137.4,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C137.9,502.8,137.9,502.1,137.4,501.6z M137.4,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C137.9,494.8,137.9,494.1,137.4,493.6z M137.4,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C137.9,486.8,137.9,486.1,137.4,485.7z M137.4,477.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C137.9,478.8,137.9,478.1,137.4,477.7z M129.4,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C129.9,526.7,129.9,526,129.4,525.6z M129.4,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C129.9,518.7,129.9,518,129.4,517.6z M129.4,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C129.9,510.8,129.9,510,129.4,509.6z M129.4,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C129.9,502.8,129.9,502.1,129.4,501.6z M129.4,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C129.9,494.8,129.9,494.1,129.4,493.6z M129.4,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C129.9,486.8,129.9,486.1,129.4,485.7z M121.4,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C121.9,526.7,121.9,526,121.4,525.6z M121.4,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C121.9,518.7,121.9,518,121.4,517.6z M121.4,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C121.9,510.8,121.9,510,121.4,509.6z M121.4,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C121.9,502.8,121.9,502.1,121.4,501.6z M121.4,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C121.9,494.8,121.9,494.1,121.4,493.6z M113.5,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C113.9,526.7,113.9,526,113.5,525.6z M113.5,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C113.9,518.7,113.9,518,113.5,517.6z M113.5,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C113.9,510.8,113.9,510,113.5,509.6z M105.5,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C105.9,526.7,105.9,526,105.5,525.6z M105.5,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C105.9,518.7,105.9,518,105.5,517.6z M105.5,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C105.9,510.8,105.9,510,105.5,509.6z M113.5,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C113.9,502.8,113.9,502.1,113.5,501.6z M113.5,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C113.9,494.8,113.9,494.1,113.5,493.6z M121.4,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C121.9,486.8,121.9,486.1,121.4,485.7z M129.4,477.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C129.9,478.8,129.9,478.1,129.4,477.7z M137.4,469.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C137.9,470.8,137.9,470.1,137.4,469.7z M177.3,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C177.8,526.7,177.8,526,177.3,525.6z M177.3,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C177.8,518.7,177.8,518,177.3,517.6z M177.3,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C177.8,510.8,177.8,510,177.3,509.6z M177.3,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C177.8,502.8,177.8,502.1,177.3,501.6z M177.3,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C177.8,494.8,177.8,494.1,177.3,493.6z M177.3,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C177.8,486.8,177.8,486.1,177.3,485.7z M177.3,477.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C177.8,478.8,177.8,478.1,177.3,477.7z M177.3,469.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C177.8,470.8,177.8,470.1,177.3,469.7z M185.3,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C185.8,526.7,185.8,526,185.3,525.6z M185.3,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C185.8,518.7,185.8,518,185.3,517.6z M185.3,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C185.8,510.8,185.8,510,185.3,509.6z M185.3,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C185.8,502.8,185.8,502.1,185.3,501.6z M185.3,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C185.8,494.8,185.8,494.1,185.3,493.6z M185.3,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C185.8,486.8,185.8,486.1,185.3,485.7z M185.3,477.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C185.8,478.8,185.8,478.1,185.3,477.7z M193.3,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C193.7,526.7,193.7,526,193.3,525.6z M193.3,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C193.7,518.7,193.7,518,193.3,517.6z M193.3,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C193.7,510.8,193.7,510,193.3,509.6z M193.3,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C193.7,502.8,193.7,502.1,193.3,501.6z M193.3,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C193.7,494.8,193.7,494.1,193.3,493.6z M193.3,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C193.7,486.8,193.7,486.1,193.3,485.7z M201.3,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C201.7,526.7,201.7,526,201.3,525.6z M201.3,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C201.7,518.7,201.7,518,201.3,517.6z M201.3,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C201.7,510.8,201.7,510,201.3,509.6z M201.3,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C201.7,502.8,201.7,502.1,201.3,501.6z M201.3,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C201.7,494.8,201.7,494.1,201.3,493.6z M209.3,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C209.7,526.7,209.7,526,209.3,525.6z M209.3,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C209.7,518.7,209.7,518,209.3,517.6z M209.3,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C209.7,510.8,209.7,510,209.3,509.6z M217.3,525.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C217.7,526.7,217.7,526,217.3,525.6z M217.3,517.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C217.7,518.7,217.7,518,217.3,517.6z M217.3,509.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C217.7,510.8,217.7,510,217.3,509.6z M209.3,501.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C209.7,502.8,209.7,502.1,209.3,501.6z M209.3,493.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C209.7,494.8,209.7,494.1,209.3,493.6z M201.3,485.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C201.7,486.8,201.7,486.1,201.3,485.7z M193.3,477.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C193.7,478.8,193.7,478.1,193.3,477.7z M185.3,469.7c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0
                C185.8,470.8,185.8,470.1,185.3,469.7z"
              />
              <path
                class="st2 an5"
                d="M58.1,565.8v71.8c-0.6,0.9-1.1,1.8-1.7,2.7v-77.3C57,564,57.6,564.9,58.1,565.8z M65.1,622v-40.5
                c-0.5-1.7-1.1-3.4-1.7-5V627C64,625.3,64.5,623.7,65.1,622z M49.4,554.3v94.9c0.6-0.6,1.2-1.3,1.7-1.9v-91
                C50.6,555.5,50,554.9,49.4,554.3z M42.5,547.7v107.9c0.6-0.5,1.2-1,1.7-1.5v-105C43.7,548.7,43.1,548.2,42.5,547.7z M35.6,542.7
                v118c0.6-0.4,1.2-0.7,1.7-1.1V543.8C36.7,543.5,36.2,543.1,35.6,542.7z M28.6,538.9v125.7c0.6-0.3,1.2-0.6,1.7-0.9V539.7
                C29.8,539.4,29.2,539.1,28.6,538.9z M21.7,536v131.5c0.6-0.2,1.2-0.4,1.7-0.6V536.6C22.9,536.4,22.3,536.2,21.7,536z M14.8,533.9
                v135.6c0.6-0.1,1.2-0.3,1.7-0.4V534.3C15.9,534.2,15.3,534,14.8,533.9z M7.8,532.6v138.3c0.6-0.1,1.2-0.2,1.7-0.3V532.8
                C9,532.7,8.4,532.7,7.8,532.6z M0.9,532v139.5c0.6,0,1.2,0,1.7-0.1V532.1C2,532,1.5,532,0.9,532z M-6.1,532.1v139.3
                c0.6,0,1.2,0.1,1.7,0.1V532C-4.9,532-5.5,532-6.1,532.1z M-13,532.8v137.7c0.6,0.1,1.2,0.2,1.7,0.3V532.6
                C-11.8,532.7-12.4,532.7-13,532.8z M-19.9,534.3v134.7c0.6,0.2,1.2,0.3,1.7,0.4V533.9C-18.8,534-19.4,534.2-19.9,534.3z
                M-26.9,536.6v130.2c0.6,0.2,1.2,0.4,1.7,0.6V536C-25.7,536.2-26.3,536.4-26.9,536.6z M-33.8,539.7v123.9c0.6,0.3,1.2,0.6,1.7,0.9
                V538.9C-32.7,539.1-33.2,539.4-33.8,539.7z M-34.2,543.8v115.7c0.6,0.4,1.2,0.8,1.7,1.1v-118C-33.1,543.1-33.7,543.5-34.2,543.8z
                M-41.2,549.2v105c0.6,0.5,1.1,1,1.7,1.5V547.7C-40,548.2-40.6,548.7-41.2,549.2z M-48.1,556.2v91c0.6,0.7,1.1,1.3,1.7,1.9v-94.9
                C-47,554.9-47.6,555.5-48.1,556.2z M-55.1,565.8v71.8c0.6,0.9,1.1,1.8,1.7,2.7v-77.3C-53.9,564-54.5,564.9-55.1,565.8z M-62,581.4
                V622c0.5,1.7,1.1,3.4,1.7,5v-50.6C-60.9,578.1-61.5,579.7-62,581.4z"
              />
            </g>
            <g>
              <g>
                <polygon
                  class="st4"
                  points="237.2,198.6 218.7,198.6 218.7,186.5 225,186.5 225,148.1 303.8,148.1 303.8,142 316,142 316,160.3 
              237.2,160.3 		"
                />
              </g>
              <g>
                <polygon
                  class="st4"
                  points="263.5,198.4 251.4,198.4 251.4,183.6 231.1,183.6 231.1,171.5 263.5,171.5 		"
                />
              </g>
              <g>
                <rect
                  x="218.7"
                  y="227.5"
                  class="st4"
                  width="97.1"
                  height="12.1"
                />
              </g>
              <g>
                <polygon
                  class="st4"
                  points="316,239.3 303.8,239.3 303.8,217.5 237.2,217.5 237.2,233.6 225,233.6 225,205.3 316,205.3 		"
                />
              </g>
              <g>
                <rect
                  x="251.4"
                  y="209.4"
                  class="st4"
                  width="12.1"
                  height="22.8"
                />
              </g>
              <g>
                <rect
                  x="277.6"
                  y="209.4"
                  class="st4"
                  width="12.1"
                  height="24.1"
                />
              </g>
              <g>
                <rect
                  x="109.9"
                  y="213"
                  class="st4"
                  width="94.6"
                  height="12.1"
                />
              </g>
              <g>
                <rect
                  x="109.9"
                  y="186.5"
                  class="st4"
                  width="94.6"
                  height="12.1"
                />
              </g>
              <g>
                <rect
                  x="151.1"
                  y="142"
                  class="st4"
                  width="12.1"
                  height="97.3"
                />
              </g>
              <g>
                <polygon
                  class="st4"
                  points="316,198.7 277.6,198.7 277.6,142 289.7,142 289.7,186.5 303.8,186.5 303.8,172.7 316,172.7 		"
                />
              </g>
              <polygon
                class="st1"
                points="109,159 109,171 205.3,160.4 205.3,148.2 	"
              />
              <g>
                <path
                  class="st4"
                  d="M59.9,223.6c0.3-0.1,0.8-0.1,1.3-0.1c0.7,0,1.2,0.1,1.5,0.4c0.3,0.2,0.5,0.6,0.5,1c0,0.5-0.4,1-1,1.2v0
              c0.5,0.1,1.2,0.6,1.2,1.4c0,0.5-0.2,0.9-0.5,1.1c-0.4,0.4-1,0.5-2,0.5c-0.5,0-0.9,0-1.1-0.1V223.6z M60.6,225.9h0.7
              c0.8,0,1.2-0.4,1.2-0.9c0-0.7-0.5-0.9-1.2-0.9c-0.3,0-0.5,0-0.6,0V225.9z M60.6,228.6c0.1,0,0.4,0,0.6,0c0.8,0,1.4-0.3,1.4-1.1
              c0-0.8-0.7-1.1-1.5-1.1h-0.6V228.6z"
                />
                <path
                  class="st4"
                  d="M69.2,226.3c0,1.9-1.2,3-2.6,3c-1.5,0-2.5-1.2-2.5-2.9c0-1.8,1.1-3,2.6-3C68.2,223.4,69.2,224.6,69.2,226.3z
              M64.9,226.4c0,1.2,0.7,2.3,1.8,2.3c1.2,0,1.8-1.1,1.8-2.3c0-1.1-0.6-2.3-1.8-2.3C65.5,224,64.9,225.1,64.9,226.4z"
                />
                <path
                  class="st4"
                  d="M70.9,223.5v3.3c0,1.3,0.6,1.8,1.3,1.8c0.8,0,1.4-0.6,1.4-1.8v-3.3h0.7v3.3c0,1.7-0.9,2.4-2.1,2.4
              c-1.2,0-2-0.7-2-2.4v-3.3H70.9z"
                />
                <path
                  class="st4"
                  d="M76.7,224.1H75v-0.6h4.2v0.6h-1.7v5h-0.7V224.1z"
                />
                <path
                  class="st4"
                  d="M80.5,223.5v5.6h-0.7v-5.6H80.5z"
                />
                <path
                  class="st4"
                  d="M86.4,230c-0.8-0.2-1.5-0.4-2.2-0.7c-0.1,0-0.2-0.1-0.3-0.1c-1.3,0-2.5-1-2.5-2.9c0-1.8,1.1-3,2.6-3
              c1.5,0,2.5,1.2,2.5,2.9c0,1.4-0.7,2.4-1.6,2.7v0c0.6,0.1,1.2,0.3,1.7,0.4L86.4,230z M85.8,226.3c0-1.1-0.6-2.3-1.8-2.3
              c-1.2,0-1.8,1.1-1.8,2.4c0,1.2,0.7,2.3,1.8,2.3C85.2,228.7,85.8,227.6,85.8,226.3z"
                />
                <path
                  class="st4"
                  d="M88.3,223.5v3.3c0,1.3,0.6,1.8,1.3,1.8c0.8,0,1.4-0.6,1.4-1.8v-3.3h0.7v3.3c0,1.7-0.9,2.4-2.1,2.4
              c-1.2,0-2-0.7-2-2.4v-3.3H88.3z"
                />
                <path
                  class="st4"
                  d="M95.9,226.5h-2.2v2h2.4v0.6h-3.2v-5.6h3v0.6h-2.3v1.8h2.2V226.5z"
                />
                <path
                  class="st4"
                  d="M69.8,231.9c0.4-0.1,0.9-0.1,1.4-0.1c0.8,0,1.3,0.1,1.6,0.5c0.3,0.3,0.4,0.6,0.4,1.1c0,0.7-0.5,1.2-1.1,1.4v0
              c0.4,0.2,0.7,0.6,0.8,1.1c0.2,0.8,0.3,1.3,0.4,1.5h-0.8c-0.1-0.2-0.2-0.6-0.4-1.3c-0.2-0.8-0.5-1.1-1.1-1.1h-0.7v2.4h-0.7V231.9z
              M70.5,234.5h0.7c0.8,0,1.3-0.4,1.3-1.1c0-0.7-0.5-1-1.3-1.1c-0.4,0-0.6,0-0.7,0.1V234.5z"
                />
                <path
                  class="st4"
                  d="M77.2,234.9H75v2h2.4v0.6h-3.2v-5.6h3v0.6H75v1.8h2.2V234.9z"
                />
                <path
                  class="st4"
                  d="M78.3,236.6c0.3,0.2,0.8,0.4,1.3,0.4c0.7,0,1.2-0.4,1.2-1c0-0.5-0.3-0.8-1.1-1.1c-0.9-0.3-1.5-0.8-1.5-1.6
              c0-0.9,0.7-1.5,1.8-1.5c0.6,0,1,0.1,1.2,0.3l-0.2,0.6c-0.2-0.1-0.6-0.3-1.1-0.3c-0.8,0-1.1,0.5-1.1,0.8c0,0.5,0.3,0.8,1.1,1.1
              c1,0.4,1.4,0.8,1.4,1.7c0,0.9-0.6,1.6-2,1.6c-0.5,0-1.1-0.2-1.4-0.4L78.3,236.6z"
                />
                <path
                  class="st4"
                  d="M87.4,234.6c0,1.9-1.2,3-2.6,3c-1.5,0-2.5-1.2-2.5-2.9c0-1.8,1.1-3,2.6-3C86.4,231.8,87.4,233,87.4,234.6z
              M83,234.7c0,1.2,0.7,2.3,1.8,2.3c1.2,0,1.8-1.1,1.8-2.3c0-1.1-0.6-2.3-1.8-2.3C83.6,232.4,83,233.5,83,234.7z"
                />
                <path
                  class="st4"
                  d="M88.3,231.9c0.4-0.1,0.9-0.1,1.4-0.1c0.8,0,1.3,0.1,1.6,0.5c0.3,0.3,0.4,0.6,0.4,1.1c0,0.7-0.5,1.2-1.1,1.4v0
              c0.4,0.2,0.7,0.6,0.8,1.1c0.2,0.8,0.3,1.3,0.4,1.5h-0.8c-0.1-0.2-0.2-0.6-0.4-1.3c-0.2-0.8-0.5-1.1-1.1-1.1h-0.7v2.4h-0.7V231.9z
              M89.1,234.5h0.7c0.8,0,1.3-0.4,1.3-1.1c0-0.7-0.5-1-1.3-1.1c-0.4,0-0.6,0-0.7,0.1V234.5z"
                />
                <path
                  class="st4"
                  d="M94,232.5h-1.7v-0.6h4.2v0.6h-1.7v5H94V232.5z"
                />
              </g>
              <g>
                <rect
                  x="96.2"
                  y="146.5"
                  class="st4"
                  width="0.6"
                  height="67.5"
                />
              </g>
              <g>
                <rect
                  x="58.8"
                  y="146.5"
                  class="st4"
                  width="0.6"
                  height="67.5"
                />
              </g>
              <path
                class="st4"
                d="M77.6,150.8c-0.1,0.1-0.2,0.1-0.3,0.1h-2.8l1.5,2.4c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.1-0.2,0.1h-3.9
            c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2l-1.6-2.5h-1.4l-1.7,2.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1h-3.8
            c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3l4.2-6.3c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1h3.8c0.1,0,0.2,0.1,0.3,0.2
            c0.1,0.1,0,0.2-0.1,0.3l-0.6,0.8h7.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v1.8C77.8,150.6,77.7,150.7,77.6,150.8z
            M67.5,176.8c-0.1,0.2-0.4,0.3-0.7,0.3h-4.5c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0-0.3,0.1-0.3l6-5.6c0.3-0.3,0.6-0.4,0.9-0.4h4.3
            c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0,0.3-0.1,0.4L67.5,176.8z M75.9,164c-0.1,0.1-0.2,0.1-0.3,0.1l-12.8,1c-0.1,0-0.2,0-0.3-0.1
            c-0.1-0.1-0.1-0.2-0.1-0.2v-1.9c0-0.3,0.2-0.4,0.5-0.5l4.4-0.3v-4.6h-3.8c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.2v-1.9
            c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.1h11.8c0.3,0,0.5,0.1,0.5,0.4v1.8c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.3,0.1h-3.6
            v4.2l4-0.3c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v1.8C76.1,163.8,76,163.9,75.9,164z M92.8,169.5H80v7.6
            c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.2H76c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3v-7.6H62.8
            c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.2v-2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h12.7v-1c0-0.1,0-0.2,0.1-0.3
            c0.1-0.1,0.2-0.1,0.2-0.1h3.7c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v1h12.8c0.3,0,0.5,0.1,0.5,0.4v1.9
            C93.3,169.3,93.1,169.5,92.8,169.5z M92.6,165.3c-0.1,0.2-0.2,0.2-0.3,0.2H89c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.4-0.7-0.8-0.8-1.3
            h-3.2c-0.2,0-0.4,0-0.6-0.1l-0.7-0.4l-1.2,1c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1h-3.8c-0.1,0-0.2-0.1-0.3-0.2
            c-0.1-0.1,0-0.2,0.1-0.3l3.1-2.8l-4.2-2.4c-0.1-0.1-0.2-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.2h3.6c0.2,0,0.3,0,0.5,0.1l1.4,0.8v-1.9
            h-3.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.2v-1.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h10.9
            c1.1,0,1.9,0.3,2.4,0.8c0.4,0.4,0.6,1,0.6,1.8v5.8h2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.1,0,0.2L92.6,165.3z M93.1,150.8
            c-0.1,0.1-0.2,0.1-0.3,0.1H90l1.5,2.4c0,0.1,0.1,0.2,0,0.3c0,0.1-0.1,0.1-0.2,0.1h-3.9c-0.1,0-0.2,0-0.3-0.1
            c-0.1,0-0.2-0.1-0.2-0.2l-1.5-2.5h-1.9l-1.6,2.5c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1h-3.9c-0.1,0-0.2-0.1-0.2-0.2
            c0-0.1,0-0.2,0-0.3l3.7-6c0.2-0.3,0.5-0.5,0.7-0.5h3.8c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0,0.2,0,0.3l-0.5,0.8h7.6c0.1,0,0.2,0,0.3,0.1
            c0.1,0.1,0.1,0.2,0.1,0.3v1.8C93.2,150.6,93.2,150.7,93.1,150.8z M93.4,176.9c-0.1,0.1-0.2,0.2-0.3,0.2h-4.6
            c-0.3,0-0.6-0.1-0.7-0.3l-5.9-5.7c-0.1-0.1-0.2-0.2-0.1-0.4c0-0.1,0.2-0.2,0.3-0.2h4.2c0.4,0,0.7,0.1,1,0.4l6.1,5.6
            C93.4,176.6,93.4,176.7,93.4,176.9z M86.9,157.5h-2.3v2.7c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.4,0.7l2.8,1.7V157.5z"
              />
              <path
                class="st4"
                d="M66.6,194h1.4c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v18.3c0,0.1,0,0.2-0.1,0.3
            c-0.1,0.1-0.2,0.2-0.3,0.2h-3.6c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3v-17.9h-1.7c-0.1,0-0.2,0-0.3-0.1
            c-0.1-0.1-0.1-0.2-0.1-0.3l4-11.5c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1H70c0.1,0,0.2,0.1,0.3,0.2
            c0.1,0.1,0.1,0.2,0.1,0.3L66.6,194z M73.5,212.7c0,0.2-0.2,0.3-0.4,0.3h-3.7c-0.1,0-0.2,0-0.2-0.1c0-0.1,0-0.2,0-0.3l1.6-5.4
            c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0h3.5c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.2L73.5,212.7z M93.2,192.8
            c-0.1,0.1-0.2,0.1-0.3,0.1h-23c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.1-0.1-0.3v-1.7c0-0.2,0.1-0.3,0.4-0.3h5.4l-1.3-3.3l-2.8,0
            c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.2v-1.6c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h8.3l-1-1.8c0-0.1,0-0.2,0-0.3
            c0.1-0.1,0.2-0.2,0.2-0.2h4.1c0.2,0,0.4,0.1,0.5,0.3l1.2,2h7.3c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.3v1.5
            c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.1l-2.4,0l-1.2,3.3h4.6c0.3,0,0.5,0.1,0.5,0.4v1.7C93.3,192.6,93.3,192.7,93.2,192.8
            z M91.2,204.6c-0.3,0.3-0.8,0.5-1.3,0.5H72.8c-0.4,0-0.8-0.1-1.1-0.3c-0.4-0.3-0.7-0.8-0.7-1.4v-7c0-0.3,0.1-0.5,0.2-0.7
            c0.1-0.2,0.2-0.4,0.4-0.6c0.4-0.4,0.9-0.6,1.4-0.6h16.7c0.6,0,1.1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4v6.7
            C91.7,203.7,91.6,204.2,91.2,204.6z M87.5,196.9H75.5v1.9h12.1V196.9z M87.5,201H75.5v1.9h12.1V201z M86.6,213c0,0-0.1,0.1-0.2,0.2
            c-0.1,0.1-0.1,0.1-0.2,0.1h-7.8c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.4-0.5-0.7-1.1-0.7-1.7v-4.2
            c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.1h3.7c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v4.4h6.5c0.1,0,0.2,0,0.2,0.1
            c0,0.1,0.1,0.2,0,0.2L86.6,213z M78.4,187.1l1.3,3.3h4.1l1.1-3.3H78.4z M86.7,209.9c0,0.1-0.1,0.1-0.2,0.1h-2.9c-0.1,0-0.2,0-0.3,0
            c-0.1,0-0.1-0.1-0.2-0.1l-1-3.5c0,0,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.2h2.8c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.2,0.2l1,3.4
            C86.7,209.7,86.7,209.8,86.7,209.9z M93.5,212.9c0,0.1-0.1,0.1-0.2,0.1h-3.7c-0.2,0-0.4-0.1-0.4-0.2l-1.6-5.8c0-0.1,0-0.2,0.1-0.3
            c0.1-0.1,0.1-0.1,0.3-0.1h3.6c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.2l1.6,5.8C93.5,212.7,93.5,212.8,93.5,212.9z"
              />
            </g>
            <g class="t1">
              <rect x="59" y="376.3" class="st0" width="256.3" height="44.7"/>
	<path class="st6" d="M101,403.1h-2.1v4.6h-3.3v-4.6h-7.6v-2.4l6.7-10.8h4.3v10.5h2.1V403.1z M95.6,400.4v-3.6c0-1,0.1-2.7,0.1-3.7
		h-0.1c-0.4,0.9-0.9,1.9-1.4,2.9l-2.8,4.4H95.6z M111.5,401.1c-1.5,3.3-4.1,6.6-6.7,8.6c-0.5-0.7-1.5-1.8-2.1-2.3
		c2.5-1.6,5-4.4,6.3-7.2L111.5,401.1z M115.3,399.1v10.7h-3.1v-10.7H103v-2.9h9.3v-9h3.1v9h9.3v2.9H115.3z M107.1,395.2
		c-0.4-1.5-1.7-3.9-3-5.6l2.6-1.2c1.3,1.7,2.7,4,3.2,5.5L107.1,395.2z M118.3,400c2.6,2.4,5.5,5.7,6.7,8.1l-2.5,1.6
		c-1.1-2.4-3.9-5.9-6.5-8.4L118.3,400z M123.8,389.5c-1.3,2.1-2.7,4.4-3.9,5.8l-2.5-1.1c1.1-1.6,2.5-4.1,3.2-6L123.8,389.5z
		 M126.9,405.6c4.9-4.4,7.7-7.7,7.7-10.5c0-1.8-1-2.9-2.7-2.9c-1.3,0-2.4,0.8-3.3,1.8l-1.9-1.9c1.6-1.7,3.2-2.7,5.7-2.7
		c3.4,0,5.6,2.2,5.6,5.5c0,3.2-2.7,6.7-5.9,9.9c0.9-0.1,2.1-0.2,2.9-0.2h3.9v3h-12V405.6z M163.1,389.9v2.7h-17.3v3.4
		c0,3.9-0.4,10-2.6,13.8c-0.6-0.5-1.8-1.4-2.5-1.7c2-3.5,2.2-8.6,2.2-12.1v-6.1h9c-0.2-0.7-0.5-1.5-0.8-2.1l2.9-0.6
		c0.5,0.8,0.9,1.8,1.2,2.7H163.1z M155.1,395.6h7.4v2.6h-7.4v2.2h6.3v9.1h-2.9v-0.9H150v0.9h-2.8v-9.1h5v-7.1h3V395.6z M158.5,403.1
		H150v2.9h8.5V403.1z M177.6,391.3c-0.2,0.8-0.5,1.5-0.7,2.2h9.2v16.3h-2.9v-1.2h-13.9v1.2h-2.8v-16.3h7.4c0.1-0.7,0.3-1.5,0.4-2.2
		h-8.8v-2.7h21.8v2.7H177.6z M169.3,405.9h2.5v-9.8h-2.5V405.9z M178,396.1h-3.7v1.7h3.7V396.1z M178,400.1h-3.7v1.8h3.7V400.1z
		 M174.3,405.9h3.7v-1.8h-3.7V405.9z M183.2,396.1h-2.6v9.8h2.6V396.1z M217.2,404.2h-7c2.4,1.2,5,2.6,6.5,3.6l-2.7,1.9
		c-1.5-1.1-4.1-2.7-6.5-3.9l1.7-1.6h-7.2l2.3,1.5c-1.9,1.5-5,3.1-7.4,4.1c-0.6-0.6-1.5-1.5-2.3-2.1c2.4-0.8,5.2-2.3,6.8-3.5h-6.8
		v-2.7h2.4V390h5v-2.9h2.7v2.9h2.3v-2.9h2.7v2.9h5.2v11.5h2.2V404.2z M199.9,392.7v3h2.2v-3H199.9z M199.9,401.5h2.2v-3.2h-2.2
		V401.5z M204.8,392.7v3h2.3v-3H204.8z M207.1,398.3h-2.3v3.2h2.3V398.3z M209.8,392.7v3h2.3v-3H209.8z M209.8,401.5h2.3v-3.2h-2.3
		V401.5z M240.4,396.8c-0.7,3.1-1.6,5.8-2.9,8.1c0.5,1.6,1,2.4,1.5,2.4c0.4,0,0.7-0.7,0.8-3c0.5,0.5,1.3,1,1.9,1.3
		c-0.3,3.6-1.1,4.3-2.8,4.3c-1.2,0-2.1-0.9-2.9-2.5c-0.7,1-1.6,1.8-2.6,2.6c-0.4-0.5-1.5-1.4-2.1-1.7c0.4-0.3,0.8-0.6,1.2-0.9h-4.1
		v0h-2v-10.8h7v1.8h-1.8v1.2h1.9v4.8h-1.9v1.3h2v0.7c0.5-0.6,0.9-1.2,1.4-1.8c-0.6-2.3-1-5.3-1.2-8.8h-8.1v4.8c0,3-0.2,7-1.6,9.5
		c-0.5-0.4-1.5-0.9-2.2-1.2c0.9-1.6,1.2-3.6,1.3-5.6H222v0.6c0,1.6-0.2,4.4-1.8,6.1c-0.4-0.4-1.2-0.9-1.6-1.2c1.2-1.5,1.4-3.6,1.4-5
		v-0.6h-1.2v-2.3h4.4v-0.5v-1h-3.4v-6h2.1v3.7h1.3v-3.9h10.4l0-1.4h0.1V391h-3.1v-2.4h3.1v-1.4h2.9v1.4h4.4v2.4h-2
		c0.7,0.5,1.4,1.1,1.8,1.5l-0.7,0.5h1.1v2.4h-4.9c0.1,2,0.3,3.7,0.5,5.3c0.5-1.4,0.9-2.8,1.2-4.4L240.4,396.8z M223.9,391h-4.5v-2.4
		h4.5v-1.4h2.9v1.4h2.9v2.4h-2.9v1.2h-2.9V391z M228.4,398.3v1.2h1.3v-1.2H228.4z M228.4,401.1v1.4h3.1v-1.4H228.4z M228.4,405.4
		h1.3v-1.3h-1.3V405.4z M236.1,393.1h2.1c-0.4-0.4-0.8-0.7-1.2-1l1.4-1.1h-1.9v1h-0.4V393.1z M265.6,399.9h-3l1,0.4
		c-0.4,0.6-0.7,1.2-1.1,1.7h2v2.1h-4v1.1h4.7v2.1h-4.7v2.6h-2.7v-2.6h-4.7v-2.1h4.7v-1.1h-3.9V402h2c-0.2-0.5-0.5-1.2-0.8-1.7l1-0.4
		h-3.5v-1.5h-3.2v0.5c0.8,0.7,2.9,2.6,3.4,3.1l-1.5,2c-0.4-0.6-1.2-1.5-1.9-2.4v8.2h-2.4v-7.6c-0.8,2-1.8,3.8-2.8,5.1
		c-0.2-0.7-0.9-1.9-1.3-2.6c1.3-1.5,2.7-4.1,3.5-6.4H243V396h3.9v-5.6c-1.1,0.1-2.1,0.3-3.1,0.4c-0.1-0.5-0.4-1.4-0.7-1.9
		c2.8-0.3,6.1-0.9,7.9-1.7l1.8,1.8c-1,0.4-2.2,0.7-3.5,1v6h3.5v1.8h5v-0.9h-3.8v-2h3.8V394h-4.6v-5.9h11.7v5.9h-4.4v0.9h3.6v2h-3.6
		v0.9h5.2V399.9z M245.3,391.1c0.5,1.2,1,2.8,1.1,3.9l-2,0.7c-0.1-1.1-0.5-2.7-1-3.9L245.3,391.1z M249.5,395c0.5-1.2,1-3,1.3-4.3
		l2.2,0.9c-0.7,1.4-1.4,3.1-1.9,4.2L249.5,395z M256.5,390h-1.1v2.1h1.1V390z M260.2,402c0.4-0.7,0.7-1.5,1-2.1h-4.2
		c0.4,0.6,0.7,1.3,0.8,1.8l-0.7,0.4H260.2z M258.3,392h1.1V390h-1.1V392z M261.3,392h1.2V390h-1.2V392z M288.3,399.3v10.5h-3.2v-1.3
		h-16.6v-9.2h3.2v6.3h5.1V398h-7v-8.8h3v6h4v-8h3.2v8h4.2v-6h3.2v8.8H280v7.6h5.1v-6.3H288.3z"/>

              
              <path
                class="st4"
                d="M64.8,305.5c-0.4,2.7-1.3,5.8-2,7.7c-0.8-0.4-2.3-0.9-3.2-1.1c0.9-1.8,1.6-4.7,2-7.2L64.8,305.5z M70.4,303.5
            c-0.1-0.5-0.2-1-0.3-1.6c-7.1,1.1-8.4,1.5-9.2,1.9c-0.2-0.7-0.8-2.5-1.2-3.5c0.8-0.2,1.6-0.8,2.7-2c0.5-0.4,1.3-1.4,2.3-2.7
            c-2.8,0.4-3.5,0.5-4,0.8c-0.2-0.7-0.7-2.4-1.1-3.3c0.6-0.2,1.2-0.8,1.9-1.7c0.7-0.9,3-4.4,4.3-7.7l3.4,1.5c-1.3,2.6-3,5.3-4.8,7.5
            l2.6-0.2c0.8-1.2,1.7-2.7,2.4-4l3.1,2.1c-1.9,3.1-4.4,6.2-6.9,8.9l3.5-0.5c-0.2-0.8-0.5-1.6-0.8-2.4l2.9-0.8
            c0.8,2.2,1.8,5.1,2.1,6.8L70.4,303.5z M68.4,304.5c0.5,2.3,0.9,5.2,1.1,7.1l-3.1,0.8c-0.1-2-0.5-4.9-0.9-7.2L68.4,304.5z M72,303.7
            c0.8,1.9,1.6,4.3,2,5.9l-2.8,1.1c-0.3-1.6-1.1-4.2-1.8-6L72,303.7z M88.1,289.5c0,0,0,1.3,0,1.8c-0.3,13.8-0.7,18.7-1.9,20.4
            c-0.9,1.2-1.8,1.6-3.1,1.8c-1.1,0.2-3.1,0.2-4.9,0.1c-0.1-1.1-0.6-2.7-1.2-3.8c1.9,0.2,3.6,0.2,4.5,0.2c0.6,0,1-0.1,1.3-0.5
            c0.9-1,1.2-5.5,1.5-16.2h-7.4c-0.8,1.6-1.5,3.1-2.4,4.3c-0.7-0.6-2.3-1.7-3.2-2.1c2.2-2.7,3.8-7.1,4.7-11.4l3.7,0.7
            c-0.4,1.7-0.8,3.3-1.4,4.9H88.1z M79.9,305.5c-0.9-2-3.3-4.9-5.4-6.9l2.5-2c2.1,2,4.6,4.7,5.6,6.7L79.9,305.5z M92.9,308.8l2.7-3.8
            c1.6,1.6,3.7,3,6.4,3c3,0,5.1-2,5.1-5.4c0-3.4-2-5.4-4.9-5.4c-1.7,0-2.7,0.4-4.4,1.6l-2.6-1.8l0.8-14.4h15.5v4.9h-10.5l-0.5,6.3
            c1.1-0.6,2.2-0.8,3.5-0.8c4.9,0,8.9,3,8.9,9.4c0,6.6-4.8,10.4-10.2,10.4C98.1,312.9,95,311,92.9,308.8z M139,284.3
            c1.4,4.7,4.6,9.2,9,11.5c-0.8,0.8-2,2.4-2.6,3.5c-0.8-0.5-1.6-1.2-2.4-1.9c-0.3,9.4-0.7,13.1-1.8,14.4c-0.8,1.1-1.6,1.4-2.9,1.5
            c-1.1,0.2-2.9,0.2-4.7,0.1c-0.1-1.2-0.6-2.8-1.2-3.8c1.7,0.1,3.4,0.2,4.1,0.2c0.6,0,0.9-0.1,1.3-0.4c0.6-0.7,1-3.6,1.3-10.2h-6.2
            c-0.8,6.1-2.8,11.6-10.9,14.7c-0.5-1.1-1.6-2.7-2.5-3.5c7.1-2.3,8.7-6.6,9.3-11.2h-5.3v-2.1c-0.8,0.8-1.5,1.6-2.4,2.3
            c-0.7-0.8-2.3-2.2-3.1-2.8c3.5-2.6,6.3-6.9,7.8-11.7l3.9,1c-1.2,3.6-2.9,6.9-4.9,9.6H141c-2-2.3-3.6-5-4.7-7.6h-5v-3.6H139z
            M162.5,307.3l0.2,3c-3.6,0.9-7.3,1.7-10.2,2.4l-0.7-3.4c1.2-0.2,2.7-0.5,4.3-0.8v-8h-3.9v-3.2h3.9V295h-2.2v-1.4
            c-0.5,0.5-0.9,0.9-1.4,1.3c-0.2-0.8-0.8-3-1.2-3.9c2.1-1.7,4.5-4.5,5.8-7.4l2.5,1.3c-0.2,0.4-0.3,0.8-0.5,1.2
            c1.8,1.2,3.5,2.5,4.5,3.6l-1.4,3.1c-0.2-0.2-0.4-0.5-0.7-0.7v3h-2.6v2.3h3.5v3.2h-3.5v7.5L162.5,307.3z M154.6,301.7
            c0.5,1.8,0.9,4,1.1,5.5l-2.2,0.7c-0.1-1.5-0.6-3.8-1-5.5L154.6,301.7z M161.3,291.8c-1.1-1-2.4-2.1-3.8-3c-0.7,1.1-1.4,2.1-2.1,3
            H161.3z M159.2,306.8c0.4-1.4,0.8-3.7,1-5.4l2.4,0.7c-0.6,1.9-1.1,4-1.5,5.3L159.2,306.8z M173.7,310.2h7.2v2.7H163v-2.7h7.2v-1.4
            h-6v-2.6h6V305h-5.6v-9.3h15v9.3h-5.8v1.2h6.2v2.6h-6.2V310.2z M181.1,291.8v2.9h-17.9v-2.9h3.8c-0.2-0.7-0.4-1.4-0.7-2.1l2-0.4
            h-4.4v-2.8h6.4c-0.2-0.7-0.5-1.4-0.9-2l3.4-0.8c0.6,0.8,1.1,1.9,1.3,2.8h6.1v2.8h-4.5l2.3,0.4c-0.3,0.7-0.7,1.4-1,2H181.1z
            M167.7,299.3h2.7v-1.4h-2.7V299.3z M167.7,302.7h2.7v-1.4h-2.7V302.7z M169.4,289.3c0.4,0.8,0.7,1.8,0.8,2.5h3.4
            c0.3-0.8,0.6-1.7,0.8-2.5H169.4z M176.3,297.9h-2.8v1.4h2.8V297.9z M176.3,301.3h-2.8v1.4h2.8V301.3z M187.6,292.8
            c-0.4,0.4-0.8,0.8-1.1,1.2c-0.3-0.7-1.2-2.1-1.8-2.7c1.9-1.8,3.6-4.6,4.5-7.4l2.9,0.8c-0.3,0.8-0.6,1.6-1,2.5h2.4
            c-0.3-0.8-0.6-1.6-0.9-2.3l3.1-0.9c0.5,1,1.1,2.2,1.4,3.2h2.3v2.4h-3.3v1.2h2.6v0.1c1.6-1.9,3.1-4.4,3.9-6.9l2.9,0.8
            c-0.3,0.8-0.6,1.6-1,2.5h2.4c-0.3-0.8-0.6-1.6-0.9-2.3l3.1-0.9c0.5,1,1,2.2,1.4,3.1h2.8v2.4h-3.9v1.2h3.1v2.2h-3.1v1.2h3.1v2.2
            h-3.1v1.2h4.3v2.4H201v-7.2c-0.4,0.5-0.8,0.9-1.1,1.3c-0.3-0.4-0.8-1.2-1.3-1.8v0.6h-2.6v1.2h2.6v2.2h-2.6v1.2h3.5v2.4h-11.9V292.8
            z M210.6,302.7c-1.5,2.4-3.6,4.4-6.1,5.9c2.8,0.6,5.9,1,9.1,1.2c-0.8,0.9-1.8,2.6-2.3,3.6c-4.3-0.4-8.3-1.2-11.8-2.5
            c-3.9,1.4-8.3,2.3-12.9,2.8c-0.3-1-0.9-2.5-1.5-3.4c3.4-0.3,6.7-0.7,9.8-1.5c-1.8-1.1-3.3-2.3-4.7-3.7l1.4-0.7h-4.8v-3.1h20.5
            l0.7-0.2L210.6,302.7z M193,289.5h-2.3v1.2h2.3V289.5z M193,293h-2.3v1.2h2.3V293z M193,296.4h-2.3v1.2h2.3V296.4z M194.6,304.4
            c1.4,1.1,3.1,2,5.1,2.8c1.8-0.8,3.3-1.7,4.6-2.8H194.6z M206.3,289.5h-2.2v1.2h2.2V289.5z M206.3,293h-2.2v1.2h2.2V293z
            M206.3,296.4h-2.2v1.2h2.2V296.4z M228,300.6c-1,0.3-2,0.6-3,0.9v8.1c0,1.8-0.3,2.7-1.2,3.3c-1,0.6-2.3,0.7-4.4,0.7
            c-0.1-0.9-0.5-2.5-0.9-3.5c1.1,0.1,2.2,0.1,2.6,0c0.4,0,0.5-0.1,0.5-0.5v-7.1c-1,0.3-2.1,0.6-3,0.9l-0.8-3.6
            c1.1-0.2,2.4-0.6,3.8-0.9v-5.5h-3.3v-3.5h3.3v-6h3.4v6h2.9v3.5h-2.9v4.6l2.5-0.7L228,300.6z M238.9,310c0.8,0.1,1.7,0.1,2.6,0.1
            c1,0,4.7,0,6-0.1c-0.5,0.8-0.9,2.3-1,3.3h-5.2c-4.3,0-7.7-0.8-10.1-3.8c-0.7,1.7-1.6,3.1-2.8,4.3c-0.6-0.6-2-1.6-2.8-2.1
            c2.3-2,3.4-5.3,3.9-9.1l3.4,0.4c-0.2,1-0.3,2-0.6,3c0.8,1.4,1.9,2.4,3.2,3v-6.8h-5.9v-2.8h5.9v-1.6h-7.7v-2.9h7.7v-1.5h-5.9v-2.8
            h5.9v-1.3h-7.2v-3h7.2v-2.4h3.4v2.4h7.9v3h-7.9v1.3h6.6v4.3h1.9v2.9h-1.9v4.4h-6.6v1.9h6.9v3h-6.9V310z M238.9,294.8h3.2v-1.5h-3.2
            V294.8z M238.9,299.4h3.2v-1.6h-3.2V299.4z M260,299.3c-0.5,2.6-1.2,5.5-2.2,7.9c0.5,0.3,1.1,0.7,1.9,1.2c2.1,1.3,5.1,1.5,8.7,1.5
            c3.6,0,9.2-0.3,12.7-0.8c-0.4,1-1.1,2.9-1.1,3.9c-2.6,0.2-8.1,0.4-11.8,0.4c-4.2,0-6.8-0.4-9.1-1.7c-1.3-0.7-2.4-1.8-3.1-1.8
            c-0.7,0-1.8,2.2-2.6,4l-2.5-3.6c1.1-1.4,2.3-2.5,3.4-3.1c0.8-1.6,1.3-3.6,1.8-5.5h-4c0.8-1.9,1.6-4.4,2.3-6.9h-2.9v-3.4h7.4
            c-0.7,2.3-1.5,4.8-2.2,7h0.5l0.5-0.1L260,299.3z M254.9,290.4c-0.3-1.5-1.2-3.8-1.7-5.5l3.3-1c0.6,1.7,1.4,3.9,1.8,5.3L254.9,290.4
            z M262.4,291.2h-2.6v-6.4h20v6.4h-3.3v1.2h-4.8v1.2h6.5v9.4h-6.5v1.2h8.1v2.8h-8.1v2.1h-3.6v-2.1h-8V304h8v-1.2h-6.5v-9.4h6.5v-1.2
            h-5.6V291.2z M263.2,287.6v2.1h4.8v-1.2h3.6v1.2h4.5v-2.1H263.2z M264.7,297.1h3.3v-1.3h-3.3V297.1z M268.1,300.6v-1.3h-3.3v1.3
            H268.1z M271.7,295.8v1.3h3.2v-1.3H271.7z M274.9,299.3h-3.2v1.3h3.2V299.3z M296.9,304.5l0.4,3.5c-4,1-8.4,2-11.7,2.8l-0.8-3.8
            c1.8-0.3,4-0.8,6.5-1.3c0.7-3.3,1.4-8.3,1.8-12l3.4,0.6c-0.6,3.5-1.4,7.6-2.2,10.7L296.9,304.5z M291.3,289.4h6.3v3.5h-12.5v-3.5
            h5.3c-0.3-1.3-1.1-3.1-1.8-4.5l3.2-0.9c0.9,1.4,1.7,3.3,2.1,4.6L291.3,289.4z M289,294c0.7,3.4,1.2,7.6,1.4,10.5l-3,0.6
            c-0.1-2.9-0.7-7.3-1.2-10.7L289,294z M306.5,293.3v5.5h6.2v14.8h-3.8v-1.4h-7.4v1.5h-3.6v-14.9h4.9v-15h3.8v5.9h7.3v3.6H306.5z
            M308.9,302.4h-7.4v6.2h7.4V302.4z M65.7,340.9c-1.6,3.8-4.3,7.7-6.7,10.1c-0.5-0.6-1.7-1.8-2.4-2.3c2.4-2,4.8-5.4,6.1-8.7
            L65.7,340.9z M72,351.5c1.8,2.5,3.9,5.8,5,7.9l-2.8,1.5c-0.3-0.7-0.7-1.4-1.2-2.3c-10.9,0.9-12.4,1-13.5,1.4
            c-0.2-0.6-0.7-2.1-1.1-2.9c0.7-0.2,1.4-0.8,2.3-1.8c0.9-1,3.8-4.9,5.5-8.7l3.1,1.3c-1.7,3.1-3.9,6.2-6.1,8.7l8.3-0.6
            c-0.7-1.1-1.5-2.3-2.2-3.4L72,351.5z M72.4,340c1,3.2,3,6.5,5.8,8.2c-0.6,0.6-1.6,1.9-2,2.8c-2.6-2-4.6-5.1-5.8-8.1h-3.6V340H72.4z
            M100.8,340.3v20.6h-2.6v-0.8H84.1v0.8h-2.5v-20.6H100.8z M98.1,357.6v-14.9H84.1v14.9H98.1z M92.1,354.5c-0.1,0.5,0,1.3,0,1.7
            c-3.8,1-4.5,1.2-4.9,1.4c-0.1-0.4-0.4-1.1-0.6-1.4c0.3-0.2,0.7-0.4,0.7-1.3v-0.8c-0.6,0.2-1.2,0.4-1.8,0.6
            c-0.3-0.4-0.9-1.2-1.3-1.5c1.5-0.3,3-0.8,4.3-1.4H86v-3.3h10.4v3.3h-4.8c-0.6,0.5-1.4,1-2.3,1.4v2L92.1,354.5z M97.4,347.9H84.8
            v-1.5h5v-0.9h-4V344h4v-1h2.4v1h4.1v1.5h-4.1v0.9h5.1V347.9z M88.2,350.7H94v-0.9h-5.8V350.7z M97.6,353.1
            c-0.6,0.5-1.2,0.9-1.9,1.2c0.8,0.6,1.5,1.1,2,1.6l-1.7,1c-1.1-1.1-3.5-2.8-5.4-4l1.6-0.9c0.6,0.4,1.4,0.9,2.1,1.4
            c0.6-0.4,1.3-0.9,1.7-1.3L97.6,353.1z M113.5,358.4c0,1-0.2,1.6-0.9,2c-0.7,0.3-1.6,0.4-2.9,0.4c-0.1-0.6-0.4-1.5-0.7-2
            c0.8,0,1.7,0,1.9,0c0.3,0,0.4-0.1,0.4-0.3v-1.2h-3.5c-0.2,1.4-0.7,2.8-1.5,3.8c-0.3-0.5-1.2-1.4-1.7-1.7c1-1.4,1.1-3.3,1.1-5v-4.7
            h7.8V358.4z M114.4,348.8h-9.7v-2h3.8v-0.9h-2.9v-1.8h2.9v-0.9H105v-1.9h3.4v-1.9h2.5v1.9h3.2v1.9H111v0.9h2.6v1.8H111v0.9h3.4
            V348.8z M111.2,355.4v-1.1h-3.3c0,0.3,0,0.7,0,1.1H111.2z M107.9,351.5v1.1h3.3v-1.1H107.9z M125.5,352.2h-1.2v3.5h-3.8v2.5
            c0,1.2-0.2,1.8-1,2.2c-0.7,0.4-1.8,0.4-3.3,0.4c-0.1-0.7-0.4-1.7-0.7-2.3c0.9,0,1.8,0,2.1,0s0.4-0.1,0.4-0.3v-2.4h-3v-2.2h3v-1.3
            h-3.9v-2.3h3.9v-1.2h-2.8v-2.2h0.7c-0.2-0.8-0.7-1.8-1.2-2.5l1.9-0.8c0.6,0.9,1.1,2,1.4,2.8l-1.2,0.5h7.5v3.4h1.2V352.2z
            M125.1,341.4c-2.8,0.9-6.8,1.4-10.2,1.7c-0.1-0.5-0.4-1.4-0.6-1.9c3.2-0.3,6.9-0.9,9-1.6L125.1,341.4z M118.7,346.4
            c-0.1-0.8-0.4-2.1-0.8-3.1l2-0.4c0.4,1,0.7,2.2,0.9,3.1L118.7,346.4z M120.5,349.9h1.6v-1.2h-1.6V349.9z M122.1,352.2h-1.6v1.3h1.6
            V352.2z M121.2,345.9c0.5-0.9,1-2.4,1.4-3.4l2.2,0.6c-0.5,1.3-1.1,2.5-1.6,3.4L121.2,345.9z M144.1,348.9c1.4,1.6,3.4,3.1,5.7,3.8
            c-0.6,0.6-1.5,1.7-1.9,2.4c-1.4-0.6-2.6-1.4-3.8-2.5v3.8h-8.2v0.7c0,0.9,0.3,1.1,2.1,1.1h5.5c1.4,0,1.7-0.4,1.9-2.4
            c0.6,0.4,1.7,0.8,2.5,1c-0.4,3.1-1.2,3.9-4.1,3.9h-5.8c-3.6,0-4.6-0.7-4.6-3.5v-3.9c-1,0.9-2.1,1.7-3.2,2.3c-0.4-0.5-1.4-1.6-2-2.1
            c2.2-1,4.1-2.5,5.4-4.4h-4.8v-2.5h5.4v-2.3h-4.1v-2.4h4.1v-2.4h2.7v2.4h4.1v-2.4h2.7v2.4h4.3v2.4h-4.3v2.3h5.6v2.5H144.1z
            M136.7,348.9c-0.3,0.5-0.6,0.9-0.9,1.4h6.3c-0.3-0.5-0.6-0.9-0.9-1.4H136.7z M141.6,352.6h-5.7v1.6h5.7V352.6z M136.8,346.5h4.1
            v-2.3h-4.1V346.5z M163.3,338.7v23h-1v-23H163.3z M188.8,348.4c1.5,4.5,4.2,8.1,8.4,9.8c-0.7,0.6-1.6,1.8-2,2.6
            c-4.1-2-6.7-5.3-8.5-9.8c-1.2,3.8-3.7,7.4-8.4,9.8c-0.4-0.8-1.2-1.7-2-2.3c5.2-2.3,7.3-6.2,8.2-10.1h-7.8v-2.8h8.2
            c0.2-2.2,0.2-4.3,0.2-6.1h2.9c0,1.9,0,4-0.3,6.1h8.9v2.8H188.8z M220.2,357.8c0,1.4-0.3,2.2-1.1,2.6c-0.9,0.5-2.1,0.5-4,0.5
            c-0.1-0.8-0.5-2-0.9-2.7c1.2,0.1,2.5,0.1,2.9,0c0.4,0,0.5-0.2,0.5-0.5v-14.6h-13.9v17.8h-2.6v-20.4h19.1V357.8z M216.2,347H205
            v-2.3h11.2V347z M208.5,356.5v1.5H206v-9.2h9.2v7.7H208.5z M208.5,351v3.2h4.2V351H208.5z M240.3,357.6c1.4,0,3.2-0.1,4.1-0.3
            c0.1,0.7,0.2,1.9,0.4,2.6c-0.9,0.2-2.9,0.3-4.7,0.3h-8.9c-4,0-5.8-1.2-5.8-5.5v-12h-0.9v-2.4h19.2v2.4h-15.6v12.1
            c0,2,0.7,2.8,3,2.8H240.3z M229.1,350.2h6.2v6.2h-6.2V350.2z M231.3,354.5h1.8V352h-1.8V354.5z M240.3,349.2h-8.9v-5.5h8.9V349.2z
            M237.6,345.5h-3.7v1.9h3.7V345.5z M242.7,356.4h-6.4v-6.2h6.4V356.4z M240.3,352h-1.8v2.5h1.8V352z M268.7,357.4v2.7h-20.5v-2.7
            h2.8v-10.2h2.8v10.2h3.9v-13.8H249v-2.7H268v2.7h-7.5v5.1h6.4v2.7h-6.4v6H268.7z M278.4,352.9c-0.2-0.6-0.6-1.5-1.1-2.4v10.4h-2.6
            v-8.5c-0.6,1.7-1.2,3.3-2,4.3c-0.2-0.8-0.8-2-1.3-2.8c1.4-1.8,2.6-5,3.2-7.8H272v-2.5h2.7v-4.3h2.6v4.3h2.3v2.6h-2.3v0.7
            c0.6,0.9,2.2,3.4,2.6,4.1L278.4,352.9z M292.7,351.3c-0.9,1.5-2.1,3-3.4,4.3c1.4,1.1,2.9,2.4,3.7,3.3l-2.1,1.8
            c-0.7-0.9-2.2-2.3-3.6-3.5c-2.1,1.6-4.5,2.9-7.3,3.7c-0.3-0.6-1.1-1.7-1.6-2.3c5.3-1.5,9.5-4.6,11.4-8.3L292.7,351.3z M291.1,346.8
            c-2.1,3.7-5.7,7.1-9.9,9c-0.3-0.6-1-1.6-1.5-2.1c2.1-0.9,4-2.1,5.5-3.5c-3.4,0.3-4.1,0.4-4.6,0.6c-0.1-0.6-0.5-1.9-0.7-2.5
            c0.5-0.1,1.4-0.3,1.8-0.8c0.4-0.4,1.2-1.8,1.9-2.9h-3.7v-2.5h5.3c-0.2-0.7-0.5-1.5-0.8-2.2l2.5-0.8c0.5,0.9,1,2.1,1.2,2.9h4.6v2.5
            h-6.2c-0.7,1.1-1.4,2.3-2.1,3.4l3-0.1c0.5-0.6,0.9-1.3,1.2-2L291.1,346.8z M300.1,347.8c-0.3,3-0.9,6.9-1.6,9.5l-2.7-1.1
            c0.8-2.4,1.4-5.9,1.7-8.9L300.1,347.8z M304.4,346v10.7c0,1.1,0.2,1.3,1.3,1.3h3.2c1.1,0,1.3-0.7,1.5-4.3c0.6,0.5,1.8,1,2.5,1.1
            c-0.3,4.4-1,5.7-3.8,5.7h-3.7c-2.9,0-3.7-0.9-3.7-3.8V346H304.4z M304.2,339.8c2,1.3,4.9,3.3,6.2,4.8l-2,2.1c-1.2-1.4-4-3.6-6.1-5
            L304.2,339.8z M314,346.6c1.3,2.7,2.4,6.2,2.9,8.6l-2.7,1.1c-0.3-2.3-1.5-6-2.7-8.7L314,346.6z"
              />
              <g>
                <line
                  class="st5"
                  x1="315.8"
                  y1="325.3"
                  x2="59"
                  y2="325.3"
                />
              </g>
            </g>
            <g class="t2">
              <path
                class="st4"
                d="M62.2,258.5c-0.3,0.3-0.6,0.7-0.9,1c-0.3-0.5-1-1.7-1.5-2.2c1.5-1.4,2.9-3.7,3.7-5.9l2.3,0.6
            c-0.2,0.7-0.5,1.3-0.8,2h1.9c-0.2-0.6-0.5-1.3-0.8-1.9l2.5-0.7c0.4,0.8,0.9,1.8,1.1,2.6h1.9v1.9H69v1h2.1v0.1
            c1.3-1.5,2.5-3.5,3.2-5.5l2.4,0.7c-0.2,0.7-0.5,1.3-0.8,2h2c-0.2-0.6-0.5-1.3-0.7-1.8l2.5-0.7c0.4,0.8,0.8,1.7,1.1,2.5H83v1.9h-3.2
            v1h2.5v1.8h-2.5v1h2.5v1.8h-2.5v1h3.5v1.9H73.1v-5.7c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2-0.3-0.6-0.9-1-1.4v0.5H69v1h2.1v1.8H69v1h2.9
            v1.9h-9.7V258.5z M80.9,266.4c-1.2,1.9-2.9,3.5-5,4.7c2.3,0.5,4.8,0.8,7.4,1c-0.7,0.7-1.5,2-1.9,2.9c-3.5-0.3-6.8-1-9.6-2
            c-3.2,1.1-6.8,1.8-10.5,2.2c-0.2-0.8-0.7-2-1.2-2.7c2.8-0.2,5.5-0.6,8-1.2c-1.4-0.8-2.7-1.9-3.8-3l1.2-0.5h-3.9v-2.5h16.7l0.5-0.1
            L80.9,266.4z M66.6,255.9h-1.8v1h1.8V255.9z M66.6,258.6h-1.8v1h1.8V258.6z M66.6,261.4h-1.8v1h1.8V261.4z M67.9,267.7
            c1.1,0.9,2.5,1.6,4.2,2.2c1.5-0.6,2.7-1.3,3.8-2.2H67.9z M77.4,255.9h-1.8v1h1.8V255.9z M77.4,258.6h-1.8v1h1.8V258.6z M77.4,261.4
            h-1.8v1h1.8V261.4z M98.6,264.7c-0.8,0.3-1.6,0.5-2.5,0.7v6.5c0,1.4-0.2,2.2-1,2.7c-0.8,0.5-1.9,0.6-3.5,0.6
            c-0.1-0.7-0.4-2-0.7-2.8c0.9,0.1,1.8,0.1,2.1,0c0.3,0,0.4-0.1,0.4-0.4v-5.6c-0.8,0.3-1.7,0.5-2.4,0.7l-0.7-2.9
            c0.9-0.2,1.9-0.5,3.1-0.7v-4.4h-2.7v-2.8h2.7v-4.8h2.8v4.8h2.3v2.8h-2.3v3.7l2.1-0.6L98.6,264.7z M107.5,272.2
            c0.7,0.1,1.4,0.1,2.1,0.1c0.8,0,3.8,0,4.9-0.1c-0.4,0.6-0.7,1.9-0.8,2.6h-4.2c-3.5,0-6.3-0.6-8.2-3c-0.6,1.4-1.3,2.5-2.2,3.4
            c-0.5-0.5-1.7-1.3-2.3-1.7c1.8-1.6,2.8-4.2,3.1-7.2l2.8,0.4c-0.1,0.8-0.3,1.6-0.5,2.4c0.7,1.1,1.5,1.9,2.6,2.4V266H100v-2.2h4.8
            v-1.3h-6.3v-2.3h6.3v-1.2H100v-2.3h4.8v-1.1h-5.8v-2.4h5.8v-1.9h2.8v1.9h6.4v2.4h-6.4v1.1h5.4v3.4h1.6v2.3H113v3.5h-5.4v1.5h5.6
            v2.4h-5.6V272.2z M107.5,260.1h2.6v-1.2h-2.6V260.1z M107.5,263.8h2.6v-1.3h-2.6V263.8z M131.3,272.4c0,1.1-0.2,1.8-1,2.2
            c-0.7,0.4-1.8,0.4-3.3,0.4c-0.1-0.7-0.5-1.7-0.8-2.3c0.9,0.1,1.9,0,2.2,0c0.3,0,0.4-0.1,0.4-0.4V271h-4c-0.3,1.5-0.8,3.1-1.7,4.2
            c-0.4-0.5-1.4-1.6-1.9-1.9c1.2-1.6,1.3-3.7,1.3-5.5v-5.2h8.9V272.4z M132.4,261.7h-11v-2.2h4.3v-1h-3.2v-2h3.2v-0.9h-3.9v-2.1h3.9
            v-2.1h2.8v2.1h3.6v2.1h-3.6v0.9h2.9v2h-2.9v1h3.9V261.7z M128.8,269v-1.2h-3.8c0,0.4,0,0.8,0,1.2H128.8z M125.1,264.7v1.2h3.8v-1.2
            H125.1z M145,265.5h-1.4v3.9h-4.3v2.7c0,1.4-0.3,2-1.1,2.5c-0.8,0.4-2.1,0.4-3.7,0.4c-0.1-0.8-0.4-1.8-0.8-2.6c1,0,2.1,0,2.4,0
            c0.3,0,0.4-0.1,0.4-0.4v-2.7h-3.4V267h3.4v-1.5h-4.4v-2.6h4.4v-1.3h-3.2v-2.4h0.8c-0.3-0.9-0.8-2-1.4-2.8l2.1-0.8
            c0.7,0.9,1.3,2.2,1.6,3.1l-1.3,0.6h8.5v3.7h1.4V265.5z M144.5,253.5c-3.2,1-7.7,1.6-11.6,1.9c-0.1-0.6-0.4-1.5-0.7-2.1
            c3.6-0.3,7.8-1,10.2-1.8L144.5,253.5z M137.3,259.1c-0.1-0.9-0.5-2.4-0.9-3.5l2.3-0.5c0.4,1.1,0.8,2.5,1,3.4L137.3,259.1z
            M139.3,262.9h1.8v-1.3h-1.8V262.9z M141.1,265.5h-1.8v1.5h1.8V265.5z M140.1,258.4c0.5-1,1.2-2.6,1.5-3.8l2.5,0.6
            c-0.6,1.4-1.3,2.8-1.8,3.8L140.1,258.4z M169.6,261.9c1.6,1.8,3.9,3.4,6.4,4.2c-0.7,0.6-1.7,1.8-2.1,2.6c-1.5-0.7-3-1.6-4.3-2.8
            v4.2h-9.3v0.7c0,1,0.4,1.2,2.4,1.2h6.2c1.6,0,1.9-0.4,2.1-2.7c0.7,0.5,1.9,0.9,2.8,1.1c-0.4,3.4-1.4,4.3-4.7,4.3h-6.5
            c-4,0-5.2-0.8-5.2-3.9v-4.3c-1.1,1-2.3,1.8-3.6,2.5c-0.5-0.6-1.6-1.8-2.2-2.3c2.5-1.1,4.7-2.8,6.2-4.9h-5.5v-2.7h6.2v-2.5h-4.6
            v-2.7h4.6v-2.7h3v2.7h4.6v-2.7h3.1v2.7h4.8v2.7h-4.8v2.5h6.3v2.7H169.6z M161.3,261.9c-0.3,0.5-0.7,1-1,1.6h7.1
            c-0.4-0.5-0.7-1-1-1.6H161.3z M166.7,265.9h-6.4v1.8h6.4V265.9z M161.4,259.1h4.6v-2.5h-4.6V259.1z"
              />
              <path
                class="st1"
                d="M182,263.6c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.7,3.7,3.7s-1.6,3.7-3.7,3.7C183.7,267.3,182,265.7,182,263.6z"
              />
              <path
                class="st4"
                d="M210.2,253c-1.9,4.2-4.9,8.5-7.6,11.2c-0.6-0.7-1.9-2-2.7-2.6c2.7-2.3,5.4-6,6.9-9.7L210.2,253z M217.3,264.7
            c2.1,2.8,4.4,6.4,5.7,8.8l-3.1,1.7c-0.3-0.7-0.8-1.6-1.3-2.5c-12.3,1-14.1,1.1-15.3,1.6c-0.2-0.6-0.8-2.3-1.3-3.2
            c0.8-0.2,1.5-0.9,2.6-2c1.1-1.1,4.3-5.4,6.3-9.7l3.5,1.5c-2,3.4-4.4,6.9-6.9,9.6l9.4-0.6c-0.8-1.3-1.7-2.6-2.5-3.7L217.3,264.7z
            M217.8,251.9c1.1,3.6,3.4,7.2,6.6,9.1c-0.7,0.7-1.8,2.1-2.3,3.1c-2.9-2.2-5.2-5.6-6.6-9h-4.1v-3.1H217.8z M253.5,252.3v22.9h-3
            v-0.9h-16v0.9h-2.9v-22.9H253.5z M250.5,271.5V255h-16v16.5H250.5z M243.6,268.1c-0.1,0.6-0.1,1.4,0,1.8c-4.3,1.1-5.1,1.3-5.5,1.6
            c-0.1-0.5-0.4-1.3-0.7-1.6c0.4-0.2,0.8-0.5,0.8-1.4v-0.9c-0.7,0.3-1.4,0.5-2,0.7c-0.3-0.4-1-1.3-1.5-1.7c1.7-0.3,3.4-0.9,4.8-1.5
            h-2.9v-3.7h11.8v3.7H243c-0.7,0.6-1.6,1.1-2.6,1.6v2.2L243.6,268.1z M249.6,260.7h-14.2V259h5.7v-1h-4.5v-1.6h4.5v-1.1h2.8v1.1h4.7
            v1.6h-4.7v1h5.7V260.7z M239.3,263.8h6.6v-1h-6.6V263.8z M249.9,266.5c-0.7,0.5-1.4,0.9-2.1,1.4c0.9,0.6,1.7,1.2,2.2,1.7l-1.9,1.1
            c-1.3-1.2-4-3.1-6.1-4.4l1.8-1c0.7,0.4,1.5,1,2.4,1.5c0.7-0.5,1.5-1,1.9-1.5L249.9,266.5z M275.1,257.5c-0.2,0.6-0.5,1.1-0.7,1.6
            h7.7v16.1h-3.2V274h-11.6v1.2h-3v-16.1h6.7c0.2-0.5,0.3-1.1,0.4-1.6h-9.7v-2.8h5.8c-0.5-0.8-1.2-1.7-1.8-2.4l2.7-1.2
            c0.9,0.9,2,2.2,2.5,3l-1.1,0.6h5.8c0.7-1,1.5-2.4,2-3.5l3.5,0.8c-0.7,1-1.4,1.9-2,2.7h5.7v2.8H275.1z M278.9,261.7h-11.6v1.6h11.6
            V261.7z M278.9,265.6h-11.6v1.7h11.6V265.6z M278.9,271.3v-1.7h-11.6v1.7H278.9z M315.5,253.7v2.7h-18.6v4.4
            c0,4.1-0.4,10.4-2.7,14.3c-0.5-0.5-1.8-1.4-2.5-1.7c2.1-3.6,2.3-8.9,2.3-12.7v-7.1h9.4c-0.2-0.6-0.5-1.3-0.8-1.9l3-0.8
            c0.5,0.8,1,1.8,1.3,2.7H315.5z M311.5,258.8h3.8v2.5h-3.8v3.9h-3.9v1.4h6.5v4.7c0,1.3-0.2,2-1.1,2.5c-0.8,0.4-2.1,0.4-3.7,0.4
            c-0.1-0.8-0.5-1.9-0.8-2.6c0.9,0.1,2,0.1,2.2,0c0.3,0,0.4-0.1,0.4-0.4v-2.1h-3.5v6.1h-3v-6.1h-3.3v4.8h-2.9v-7.4h6.3v-1.4h-3.9
            v-3.9h-3.2v-2.5h3.2v-1.9h2.8v1.9h5v-1.9h2.9V258.8z M308.6,261.2h-5v1.5h5V261.2z"
              />
              <rect
                x="59.3"
                y="280.2"
                class="st4"
                width="256.3"
                height="22.6"
              />
              <path
                class="st6"
                d="M72.1,284.9h1.6v6.5c0,3-0.4,5.6-3.3,7.2c-0.3-0.4-0.9-1-1.3-1.2c1.8-0.9,2.5-2.2,2.8-3.7
            c-0.6,0.5-1.2,1-1.7,1.4l-0.9-1.5c0.7-0.4,1.8-1.1,2.8-1.8l0,0.1v-0.5v-1.5l-0.9,0.5c-0.2-0.7-0.8-1.9-1.3-2.8v1.6h-1.2v1.9
            l1.1-0.4l0.3,1.6c-0.5,0.2-1,0.4-1.5,0.6v4c0,0.8-0.1,1.3-0.6,1.6c-0.4,0.3-1.1,0.3-2.1,0.3c0-0.4-0.2-1.2-0.5-1.6
            c0.6,0,1.1,0,1.2,0c0.2,0,0.3-0.1,0.3-0.3v-3.3l-1.4,0.5l-0.4-1.7c0.5-0.2,1.1-0.4,1.8-0.6v-2.5h-1.6v-1.6H67v-2.8h1.6v2.8h1.2
            l-0.1-0.2l1.3-0.7c0.4,0.5,0.7,1.2,1,1.8V284.9z M77.5,296.9c0.3,0,0.4-0.4,0.4-1.9c0.4,0.3,1,0.6,1.5,0.7c-0.2,2-0.6,2.8-1.7,2.8
            h-1.3c-1.3,0-1.7-0.5-1.7-2.4v-11.3h1.7v4.4c0.4-0.9,0.9-2,1.2-2.8l1.6,0.8c-0.6,1.1-1.3,2.4-1.8,3.2l-1-0.5v1.7l0.4-0.4
            c1,0.8,2.1,1.9,2.7,2.6l-1.1,1.3c-0.4-0.6-1.2-1.5-2-2.2v3.4c0,0.7,0,0.8,0.4,0.8H77.5z M95.3,286.1v1.5H82.1v-1.5h5.8
            c-0.1-0.4-0.2-0.7-0.4-1l1.8-0.4c0.2,0.5,0.4,1,0.6,1.5H95.3z M95,297c0,0.8-0.2,1.1-0.8,1.3c-0.5,0.2-1.3,0.2-2.4,0.2
            c-0.1-0.4-0.3-0.9-0.5-1.3h-4.3v0.6h-1.6V294h6.5v3.3h-0.2c0.5,0,1,0,1.2,0c0.2,0,0.3-0.1,0.3-0.3v-3.6h-8.9v5.2h-1.7V292H95V297z
            M93.6,291.4h-9.4v-3.2h9.4V291.4z M91.7,289.4h-5.8v0.8h5.8V289.4z M90.3,295.2h-3.3v0.9h3.3V295.2z M98.2,296.8l1.3-1.7
            c0.8,0.8,1.8,1.4,3.1,1.4c1.4,0,2.4-0.7,2.4-1.9c0-1.4-0.8-2.2-3.8-2.2v-2c2.5,0,3.4-0.9,3.4-2.1c0-1.1-0.7-1.7-1.9-1.7
            c-1,0-1.8,0.5-2.6,1.2l-1.4-1.7c1.2-1,2.5-1.7,4.1-1.7c2.7,0,4.5,1.3,4.5,3.7c0,1.5-0.8,2.6-2.3,3.2v0.1c1.6,0.4,2.8,1.6,2.8,3.4
            c0,2.5-2.2,4-4.9,4C100.7,298.7,99.2,297.9,98.2,296.8z M116.7,293.3c-1,2.1-2.5,4.1-4.2,5.3c-0.3-0.4-0.9-1.1-1.3-1.5
            c1.5-1,3.1-2.8,3.9-4.4L116.7,293.3z M119.1,292.1v6.6h-1.9v-6.6h-5.7v-1.8h5.7v-5.5h1.9v5.5h5.7v1.8H119.1z M114,289.6
            c-0.3-0.9-1.1-2.4-1.9-3.5l1.6-0.7c0.8,1,1.7,2.5,2,3.4L114,289.6z M120.9,292.6c1.6,1.5,3.4,3.5,4.2,5l-1.6,1
            c-0.7-1.5-2.4-3.6-4-5.2L120.9,292.6z M124.3,286.1c-0.8,1.3-1.7,2.7-2.4,3.6l-1.6-0.7c0.7-1,1.5-2.5,2-3.7L124.3,286.1z
            M135.7,287.6c-0.5-0.6-1.3-1-2.1-1c-1.6,0-2.9,1.2-3,4.7c0.7-0.9,1.9-1.5,2.8-1.5c2.3,0,4,1.4,4,4.4c0,2.8-2,4.6-4.3,4.6
            c-2.6,0-5-2.1-5-7c0-5.2,2.6-7.4,5.3-7.4c1.7,0,2.9,0.7,3.8,1.6L135.7,287.6z M134.8,294.1c0-1.6-0.8-2.4-2-2.4
            c-0.7,0-1.6,0.4-2.3,1.5c0.3,2.5,1.2,3.5,2.4,3.5C134,296.6,134.8,295.8,134.8,294.1z M145.1,288.6c-0.3,0.7-0.7,1.4-1.2,2.1h0v8
            h-1.7V293c-0.4,0.5-0.7,0.9-1.1,1.2c-0.2-0.4-0.7-1.3-1-1.7c1.2-1.1,2.5-2.9,3.3-4.5L145.1,288.6z M144.9,285.4
            c-1,1.4-2.4,3.1-3.7,4.2c-0.2-0.4-0.6-1.1-0.8-1.5c1-0.9,2.3-2.3,2.9-3.3L144.9,285.4z M144.1,297.4c0.5-0.7,0.9-1.8,1.2-2.8
            l1.3,0.5c-0.3,1-0.6,2.2-1.2,3L144.1,297.4z M154.4,294h-4.5c0.3,0.5,0.7,1.2,0.9,1.6l-1.4,0.5c-0.2-0.5-0.6-1.2-0.9-1.8l0.8-0.3
            h-4.5v-1.4h9.6V294z M148.5,287.9c0-0.2,0.1-0.5,0.1-0.8H145v-1.4h3.8c0-0.4,0-0.8,0.1-1.1l1.9,0.1l-0.1,1h3.8v1.4h-4
            c0,0.3-0.1,0.5-0.1,0.8h3.7v4h-8.4v-4H148.5z M150.4,297.2c0.4,0,0.5-0.2,0.6-1.2c0.3,0.2,0.9,0.4,1.4,0.5c-0.2,1.6-0.6,2-1.7,2
            h-1.9c-1.5,0-1.9-0.4-1.9-1.7v-1.8h1.6v1.8c0,0.4,0.1,0.4,0.6,0.4H150.4z M147.9,289.2h-1v1.5h1V289.2z M150.2,289.2h-1v1.5h1
            V289.2z M151.4,290.6h1.1v-1.5h-1.1V290.6z M153,294.5c0.7,0.9,1.3,2,1.6,2.8l-1.4,0.6c-0.2-0.8-0.8-2-1.5-2.9L153,294.5z
            M170.3,285.3v13.4h-1.8v-0.6h-9.3v0.6h-1.7v-13.4H170.3z M168.5,296.5v-9.6h-1.9c0.5,0.2,1,0.6,1.3,0.9l-0.6,0.6h0.7v1.3h-2.7
            c0.1,0.8,0.2,1.6,0.3,2.3c0.3-0.6,0.5-1.2,0.7-1.9l1.4,0.3c-0.4,1.3-0.9,2.4-1.6,3.4c0.2,0.4,0.4,0.7,0.7,0.8
            c0.2,0.1,0.3-0.5,0.3-1.4c0.3,0.3,0.8,0.7,1.1,0.8c-0.2,1.8-1,2.4-2,2c-0.4-0.2-0.8-0.5-1.1-1c-0.5,0.5-1,0.9-1.6,1.3
            c-0.2-0.3-0.7-0.8-1-1.1c0.2-0.1,0.3-0.2,0.5-0.3c-1.2,0.2-2.4,0.3-3.3,0.5l-0.2-1.3c1.1-0.1,2.6-0.3,4.2-0.5l0,0.7
            c0.3-0.2,0.5-0.5,0.7-0.7c-0.3-1.1-0.5-2.4-0.6-3.9h-4.2v-1.3h4.1c0-0.4,0-0.8-0.1-1.3h1.5c0,0.4,0,0.9,0.1,1.3h1.6
            c-0.3-0.3-0.8-0.6-1.2-0.8l0.7-0.7h-7.2v9.6H168.5z M163.5,293.2h-3.3v-2.8h3.3V293.2z M162.3,291.3h-1v1h1V291.3z M187.3,289.5
            v1.3h-8.5v-1.3h3.3v-0.7h-2.7v-1.2h2.7v-0.6H179v-0.6c-0.4,1-0.8,2-1.1,2.8l-1-0.4v0.8h1.6v1.6h-1.6v1l0.6-0.4
            c0.3,0.3,1.3,1.8,1.6,2.2l-1.1,1.3c-0.2-0.6-0.7-1.6-1.1-2.3v5.6h-1.6v-4.9c-0.3,1.3-0.8,2.5-1.4,3.3c-0.1-0.5-0.5-1.3-0.7-1.8
            c0.6-0.8,1.2-2.1,1.4-3.3l0.8,0.3v-0.9h-1.9v-1.6h1.9v-4.9h1.6v3.8c0.3-0.8,0.6-2,0.8-2.8l1.3,0.4v-0.3h3.1v-1.1h1.7v1.1h3.3v1.3
            h-3.3v0.6h3v1.2h-3v0.7H187.3z M174.1,289.2c-0.1-0.9-0.4-2.2-0.6-3.2l1.2-0.3c0.3,1,0.6,2.3,0.7,3.2L174.1,289.2z M186.6,297.1
            c0,0.7-0.1,1.1-0.7,1.4c-0.5,0.2-1.2,0.3-2.1,0.2c-0.1-0.4-0.3-1-0.5-1.4c0.6,0,1.2,0,1.4,0c0.2,0,0.3-0.1,0.3-0.2v-0.6h-4.1
            c-0.2,0.9-0.5,1.7-1.1,2.4c-0.3-0.3-0.9-0.9-1.2-1.1c0.9-1,1-2.5,1-3.6v-2.7h7.1V297.1z M184.9,295.3v-0.7h-3.8c0,0.2,0,0.5,0,0.7
            H184.9z M181.1,292.7v0.7h3.8v-0.7H181.1z M190.4,291.9h5.7v6.7h-1.8v-0.7h-2.2v0.7h-1.7V291.9z M192.1,293.7v2.6h2.2v-2.6H192.1z
            M201.2,290.7h-8.8v-5.4h8.8V290.7z M199.4,287h-5.2v2h5.2V287z M203.2,291.9v6.7h-1.8v-0.7h-2.4v0.7h-1.7v-6.7H203.2z
            M201.4,296.2v-2.6h-2.4v2.6H201.4z M218.8,291.2c-0.6,0.5-1.3,0.9-1.9,1.4c0.6,1.8,1.8,3.2,3.5,3.9c-0.4,0.3-0.9,1-1.2,1.5
            c-1.8-0.9-3-2.5-3.7-4.5c-0.1,0.1-0.3,0.2-0.5,0.3c0.4,1.8,0.1,3.5-0.7,4.1c-0.5,0.5-1,0.7-1.8,0.7c-0.3,0-0.8,0-1.2,0
            c0-0.5-0.2-1.2-0.5-1.7c0.5,0.1,1,0.1,1.3,0.1c0.4,0,0.6,0,0.8-0.4c0.3-0.2,0.4-0.8,0.4-1.5c-1.7,1.2-4.1,2.3-6,2.9
            c-0.2-0.4-0.6-1.1-0.9-1.5c2.4-0.5,5.2-1.8,6.7-3.1c-0.1-0.2-0.1-0.4-0.2-0.6c-1.5,0.9-3.7,1.9-5.4,2.4c-0.2-0.4-0.6-1-0.9-1.4
            c1.8-0.4,4.3-1.3,5.6-2.2c-0.1-0.2-0.3-0.3-0.4-0.5c-1.3,0.7-2.8,1.2-4.2,1.6c-0.2-0.3-0.6-1-0.9-1.4c1.7-0.3,3.5-0.9,4.8-1.6h-2.6
            v-1.5h8.4v1.5h-3.3c-0.3,0.2-0.6,0.5-0.9,0.7c0.6,0.5,1,1.2,1.3,1.8c1-0.7,2.1-1.5,2.8-2.3L218.8,291.2z M208.6,289.3h-1.8v-3.4
            h5.4c-0.1-0.3-0.2-0.6-0.4-0.8l1.9-0.5c0.2,0.4,0.5,0.9,0.6,1.3h5.3v3.4h-1.8v-1.8h-9.3V289.3z M241.6,289.5v1.3h-8.5v-1.3h3.3
            v-0.7h-2.7v-1.2h2.7v-0.6h-3.1v-0.6c-0.4,1-0.8,2-1.1,2.8l-1-0.4v0.8h1.6v1.6h-1.6v1l0.6-0.4c0.3,0.3,1.3,1.8,1.6,2.2l-1.1,1.3
            c-0.2-0.6-0.7-1.6-1.1-2.3v5.6h-1.6v-4.9c-0.3,1.3-0.8,2.5-1.4,3.3c-0.1-0.5-0.5-1.3-0.7-1.8c0.6-0.8,1.2-2.1,1.4-3.3l0.8,0.3v-0.9
            h-1.9v-1.6h1.9v-4.9h1.6v3.8c0.3-0.8,0.6-2,0.8-2.8l1.3,0.4v-0.3h3.1v-1.1h1.7v1.1h3.3v1.3h-3.3v0.6h3v1.2h-3v0.7H241.6z
            M228.3,289.2c-0.1-0.9-0.4-2.2-0.6-3.2l1.2-0.3c0.3,1,0.6,2.3,0.7,3.2L228.3,289.2z M240.9,297.1c0,0.7-0.1,1.1-0.7,1.4
            c-0.5,0.2-1.2,0.3-2.1,0.2c-0.1-0.4-0.3-1-0.5-1.4c0.6,0,1.2,0,1.4,0c0.2,0,0.3-0.1,0.3-0.2v-0.6h-4.1c-0.2,0.9-0.5,1.7-1.1,2.4
            c-0.3-0.3-0.9-0.9-1.2-1.1c0.9-1,1-2.5,1-3.6v-2.7h7.1V297.1z M239.2,295.3v-0.7h-3.8c0,0.2,0,0.5,0,0.7H239.2z M235.4,292.7v0.7
            h3.8v-0.7H235.4z M245.7,294.7c-0.1,1.3-0.3,2.7-0.6,3.6c-0.3-0.1-0.9-0.3-1.2-0.4c0.3-0.9,0.5-2.2,0.6-3.3L245.7,294.7z
            M253.4,296.5c-1.6,0.5-3.3,1-4.7,1.4l-0.4-1.6c0.5-0.1,1.2-0.3,2-0.4v-2h-1.6v-0.3l-0.9,0.3c0-0.2-0.1-0.4-0.1-0.6
            c-2.4,0.5-2.8,0.7-3.1,0.8c-0.1-0.3-0.3-1-0.5-1.4c0.3-0.1,0.6-0.4,1-1c0.2-0.2,0.5-0.7,0.8-1.3c-0.9,0.1-1.2,0.2-1.4,0.3
            c-0.1-0.3-0.3-1-0.5-1.4c0.2-0.1,0.4-0.4,0.7-0.8c0.2-0.4,0.9-2.1,1.2-3.6l1.5,0.6c-0.4,1.3-0.9,2.6-1.6,3.7l0.8-0.1
            c0.3-0.6,0.5-1.3,0.8-1.9l1.4,0.7c-0.5,1.1-1,2.2-1.7,3.2l1-0.3c0.2,0.6,0.5,1.4,0.6,2.1v-0.3h1.6v-1.3h1.4v1.3h1.4v1.5h-1.4v1.6
            l1.5-0.4L253.4,296.5z M247,294.4c0.2,0.9,0.3,2,0.3,2.8l-1.2,0.3c0-0.8-0.1-1.9-0.3-2.8L247,294.4z M247.3,292
            c-0.1-0.4-0.2-0.7-0.3-1.1c-0.3,0.4-0.6,0.9-0.9,1.3L247.3,292z M248.3,294c0.2,0.6,0.5,1.4,0.6,1.9l-1.1,0.4
            c-0.1-0.5-0.3-1.3-0.5-1.9L248.3,294z M252,291.1c0-0.2-0.1-0.4-0.1-0.6c-2.2,0.4-2.6,0.5-2.9,0.7c-0.1-0.3-0.3-1.1-0.5-1.5
            c0.2-0.1,0.4-0.3,0.6-0.7c0.1-0.3,0.5-1,0.7-1.9h-1.2v-1.5h5v1.5h-2.1c-0.3,0.8-0.8,1.7-1.3,2.5l1.3-0.2c-0.1-0.4-0.3-0.7-0.4-1.1
            l1.2-0.4c0.4,0.9,0.8,2,1,2.8L252,291.1z M257.8,287.7c-0.2,2.9-0.8,5.3-1.6,7.2c0.6,1,1.2,1.9,1.9,2.5c-0.3,0.3-0.9,0.8-1.1,1.2
            c-0.6-0.6-1.1-1.3-1.6-2.2c-0.6,0.9-1.2,1.7-2,2.3c-0.2-0.4-0.7-1-1-1.3c0.9-0.6,1.6-1.6,2.2-2.8c-0.2-0.6-0.4-1.1-0.6-1.7
            c-0.1,0.3-0.3,0.5-0.4,0.8c-0.1-0.4-0.4-1.3-0.6-1.7c0.2-0.3,0.3-0.7,0.5-1.1c-0.2-1-0.4-2.1-0.6-3.2l1-0.2c0,0.4,0.1,0.8,0.2,1.2
            c0.3-1.2,0.4-2.6,0.6-3.9l1.3,0.2c-0.1,0.8-0.2,1.6-0.3,2.4h1l0.2-0.1L257.8,287.7z M255.4,288.9c-0.2,0.9-0.4,1.7-0.7,2.5
            c0.2,0.6,0.4,1.2,0.6,1.7c0.4-1.3,0.8-2.7,1-4.2H255.4z M263.2,290.6c0,2.4-0.2,5.9-1.5,8.2c-0.3-0.3-1-0.9-1.3-1.1
            c1.1-2,1.2-5,1.2-7.1v-4.8h5.5c-0.1-0.3-0.2-0.5-0.3-0.7l1.9-0.4c0.1,0.4,0.3,0.8,0.4,1.2h5.2v1.5h-11.1V290.6z M273.1,295.7
            c0.2,0.4,0.4,0.8,0.5,1.2c0.3,0.1,0.7,0.3,1.1,0.3c-0.1,1.1-0.5,1.4-1.6,1.4H271c-1.5,0-1.9-0.3-1.9-1.5v-1.6h-0.9
            c-0.4,1.6-1.4,2.7-4.3,3.3c-0.1-0.4-0.5-1-0.8-1.3c2.3-0.4,3.1-1,3.4-2h-2.3v-3.8h2.3c0.1-0.2,0.2-0.4,0.3-0.6h-1V289h-1.8v-1h1.8
            v-0.5h1.2v0.5h1.7v1h-1.7v1.9l1.7,0.2c-0.1,0.2-0.3,0.4-0.4,0.6h5.4v3.8H272l0.4,0.1c-0.1,0.3-0.3,0.5-0.4,0.7l0.6-0.1
            c-0.1-0.1-0.1-0.3-0.2-0.4L273.1,295.7z M265.1,289.8c0,0.4-0.1,1.2-1,1.6c-0.2-0.2-0.5-0.5-0.7-0.7c0.7-0.3,0.8-0.8,0.8-1v-0.5h1
            V289.8z M265.6,293.1h2.4v-0.6h-2.4V293.1z M265.6,294.5h2.4v-0.6h-2.4V294.5z M270,289.8c0,0.4-0.1,1.1-0.6,1.5
            c-0.2-0.2-0.5-0.5-0.7-0.7l0,0.2c-0.1,0-0.3,0.1-0.5,0.1h0h0h0c-0.7,0-0.8-0.4-0.8-1v-0.6h1v0.6c0,0.2,0,0.2,0.1,0.3
            c0.1,0,0.2,0,0.3,0c0,0.1,0,0.3,0,0.5c0.3-0.3,0.4-0.7,0.4-0.9v-0.5h1V289.8z M270.4,291.3V289h-1.6v-1h1.6v-0.5h1.3v0.5h2.1v1
            h-2.1v2.3H270.4z M271.8,292.6h-2.3v0.6h2.3V292.6z M271.8,294.5v-0.6h-2.3v0.6H271.8z M272.9,297.5c0.3,0,0.5-0.1,0.5-0.6
            l-0.5,0.2l-0.1-0.3c-1.4,0.3-1.7,0.4-1.8,0.5c0-0.2-0.2-0.6-0.3-0.8c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.3-0.5,0.5-0.9h-1v1.6
            c0,0.4,0.1,0.4,0.6,0.4H272.9z M273,291.1c-0.8,0-0.9-0.3-0.9-1v-0.8h1v0.8c0,0.2,0,0.3,0.2,0.3h0.3c0.1,0,0.4,0,0.6-0.1
            c0,0.2,0,0.5,0,0.7c-0.1,0.1-0.4,0.1-0.6,0.1H273z M290.9,291h-1.2v5.6c0,0.8-0.1,1.3-0.7,1.6c-0.5,0.3-1.2,0.3-2.2,0.3
            c0-0.5-0.3-1.2-0.5-1.7c0.6,0,1.2,0,1.4,0c0.2,0,0.3,0,0.3-0.2V291h-1.3v-1.2h-2.2v8.8h-1.6v-8.8h-2v-1c-0.2,0.5-0.5,1.1-0.9,1.6
            v8.2h-1.6v-6c-0.3,0.3-0.6,0.6-0.8,0.9c-0.2-0.4-0.7-1.3-1-1.6c1.1-1,2.3-2.6,2.9-4.1l1.4,0.5v-0.1h2v-3.5h1.6v3.5h2.2v1.2h4.1
            L290.9,291z M281.1,285.3c-0.8,1.3-2,2.8-3.1,3.7c-0.2-0.4-0.6-1-0.9-1.3c0.9-0.7,1.9-1.9,2.4-2.9L281.1,285.3z M282.6,290.6
            c0,2.8-0.2,4.7-1.3,5.9c-0.2-0.3-0.5-0.8-0.8-1c0.8-0.9,0.9-2.4,0.9-4.8H282.6z M286,287.9c-0.2-0.5-0.7-1.3-1.1-1.9l1.1-0.6
            c0.5,0.6,1,1.4,1.2,1.9L286,287.9z M286.5,296.1c-1.2,0-1.4-0.4-1.4-1.7v-3.7h1.3v3.7c0,0.4,0,0.5,0.1,0.5h0.3c0.1,0,0.3,0,0.5-0.1
            c0,0.3,0.1,0.9,0.1,1.2c-0.2,0.1-0.4,0.1-0.6,0.1H286.5z M290.7,287.4h-3.6v-1.6h3.6V287.4z M295.9,289.4H294V286h5.5
            c-0.1-0.3-0.3-0.6-0.4-0.9l1.8-0.5c0.3,0.4,0.5,0.9,0.7,1.4h5.2v3.3h-1.9v-1.6h-9.1V289.4z M304.4,296.7c0.8,0,0.9-0.3,1-1.8
            c0.4,0.3,1.2,0.6,1.8,0.7c-0.2,2.2-0.8,2.9-2.6,2.9h-2.9c-2.2,0-2.8-0.6-2.8-2.5v-1.8h-4.5v-1.8h4.5v-1.5c-1.1,0.1-2.2,0.3-3.3,0.4
            c-0.1-0.4-0.3-1.2-0.5-1.6c3-0.3,6.3-0.8,8.3-1.6l1.4,1.5c-1.2,0.4-2.5,0.8-3.9,1v1.8h5.4v1.8h-5.4v1.8c0,0.7,0.2,0.7,1,0.7H304.4z
            "
              />
            </g>
            <g class="bottom2">
              <path
                class="st2"
                d="M12.3,561.6c-0.4-0.4-0.4-1.2,0-1.6c0.4-0.4,1.2-0.4,1.6,0c0.4,0.4,0.4,1.2,0,1.6
            C13.5,562.1,12.7,562.1,12.3,561.6z M21.9,561.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,562.1,21.4,562.1,21.9,561.6z M29.8,561.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,562.1,29.4,562.1,29.8,561.6z M37.8,561.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,562.1,37.3,562.1,37.8,561.6z M45.7,561.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,562.1,45.3,562.1,45.7,561.6z M53.7,561.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C52.5,562.1,53.3,562.1,53.7,561.6z M61.7,561.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C60.5,562.1,61.2,562.1,61.7,561.6z M13.9,553.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,554.1,13.5,554.1,13.9,553.7z M21.9,553.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,554.1,21.4,554.1,21.9,553.7z M29.8,553.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,554.1,29.4,554.1,29.8,553.7z M37.8,553.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,554.1,37.3,554.1,37.8,553.7z M45.7,553.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,554.1,45.3,554.1,45.7,553.7z M53.7,553.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C52.5,554.1,53.3,554.1,53.7,553.7z M61.7,553.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C60.5,554.1,61.2,554.1,61.7,553.7z M13.9,545.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,546.2,13.5,546.2,13.9,545.7z M21.9,545.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,546.2,21.4,546.2,21.9,545.7z M29.8,545.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,546.2,29.4,546.2,29.8,545.7z M37.8,545.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,546.2,37.3,546.2,37.8,545.7z M45.7,545.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,546.2,45.3,546.2,45.7,545.7z M53.7,545.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C52.5,546.2,53.3,546.2,53.7,545.7z M61.7,545.7c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C60.5,546.2,61.2,546.2,61.7,545.7z M13.9,537.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,538.2,13.5,538.2,13.9,537.8z M21.9,537.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,538.2,21.4,538.2,21.9,537.8z M29.8,537.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,538.2,29.4,538.2,29.8,537.8z M37.8,537.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,538.2,37.3,538.2,37.8,537.8z M45.7,537.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,538.2,45.3,538.2,45.7,537.8z M53.7,537.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C52.5,538.2,53.3,538.2,53.7,537.8z M61.7,537.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C60.5,538.2,61.2,538.2,61.7,537.8z M13.9,529.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,530.2,13.5,530.2,13.9,529.8z M21.9,529.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,530.2,21.4,530.2,21.9,529.8z M29.8,529.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,530.2,29.4,530.2,29.8,529.8z M37.8,529.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,530.2,37.3,530.2,37.8,529.8z M45.7,529.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,530.2,45.3,530.2,45.7,529.8z M53.7,529.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C52.5,530.2,53.3,530.2,53.7,529.8z M13.9,521.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,522.3,13.5,522.3,13.9,521.8z M21.9,521.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,522.3,21.4,522.3,21.9,521.8z M29.8,521.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,522.3,29.4,522.3,29.8,521.8z M37.8,521.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,522.3,37.3,522.3,37.8,521.8z M45.7,521.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,522.3,45.3,522.3,45.7,521.8z M13.9,513.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,514.3,13.5,514.3,13.9,513.9z M21.9,513.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,514.3,21.4,514.3,21.9,513.9z M29.8,513.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,514.3,29.4,514.3,29.8,513.9z M37.8,513.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,514.3,37.3,514.3,37.8,513.9z M13.9,505.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,506.4,13.5,506.4,13.9,505.9z M21.9,505.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,506.4,21.4,506.4,21.9,505.9z M13.9,497.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,498.4,13.5,498.4,13.9,497.9z M21.9,497.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,498.4,21.4,498.4,21.9,497.9z M29.8,505.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,506.4,29.4,506.4,29.8,505.9z M37.8,505.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,506.4,37.3,506.4,37.8,505.9z M45.7,513.9c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,514.3,45.3,514.3,45.7,513.9z M53.7,521.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C52.5,522.3,53.3,522.3,53.7,521.8z M61.7,529.8c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C60.5,530.2,61.2,530.2,61.7,529.8z M13.9,569.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,570,13.5,570,13.9,569.6z M21.9,569.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,570,21.4,570,21.9,569.6z M29.8,569.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,570,29.4,570,29.8,569.6z M37.8,569.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,570,37.3,570,37.8,569.6z M45.7,569.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,570,45.3,570,45.7,569.6z M53.7,569.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C52.5,570,53.3,570,53.7,569.6z M61.7,569.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C60.5,570,61.2,570,61.7,569.6z M13.9,577.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,578,13.5,578,13.9,577.6z M21.9,577.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,578,21.4,578,21.9,577.6z M29.8,577.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,578,29.4,578,29.8,577.6z M37.8,577.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,578,37.3,578,37.8,577.6z M45.7,577.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,578,45.3,578,45.7,577.6z M53.7,577.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C52.5,578,53.3,578,53.7,577.6z M13.9,585.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,586,13.5,586,13.9,585.5z M21.9,585.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,586,21.4,586,21.9,585.5z M29.8,585.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,586,29.4,586,29.8,585.5z M37.8,585.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,586,37.3,586,37.8,585.5z M45.7,585.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,586,45.3,586,45.7,585.5z M13.9,593.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,593.9,13.5,593.9,13.9,593.5z M21.9,593.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,593.9,21.4,593.9,21.9,593.5z M29.8,593.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,593.9,29.4,593.9,29.8,593.5z M37.8,593.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,593.9,37.3,593.9,37.8,593.5z M13.9,601.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,601.9,13.5,601.9,13.9,601.5z M21.9,601.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,601.9,21.4,601.9,21.9,601.5z M13.9,609.4c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C12.7,609.9,13.5,609.8,13.9,609.4z M21.9,609.4c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C20.7,609.9,21.4,609.8,21.9,609.4z M29.8,601.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C28.7,601.9,29.4,601.9,29.8,601.5z M37.8,601.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C36.6,601.9,37.3,601.9,37.8,601.5z M45.7,593.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C44.6,593.9,45.3,593.9,45.7,593.5z M53.7,585.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C52.5,586,53.3,586,53.7,585.5z M61.7,577.6c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.2,0,1.6
            C60.5,578,61.2,578,61.7,577.6z M4.6,544.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,543.7,5,543.7,4.6,544.1z M-3.4,544.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,543.7-2.9,543.7-3.4,544.1z M-11.3,544.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,543.7-10.9,543.7-11.3,544.1z M-19.3,544.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,543.7-18.9,543.7-19.3,544.1z M-27.3,544.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,543.7-26.8,543.7-27.3,544.1z M-35.2,544.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,543.7-34.8,543.7-35.2,544.1z M-43.2,544.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-42,543.7-42.7,543.7-43.2,544.1z M-51.1,544.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-50,543.7-50.7,543.7-51.1,544.1z M4.6,552.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,551.6,5,551.6,4.6,552.1z M-3.4,552.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,551.6-2.9,551.6-3.4,552.1z M-11.3,552.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,551.6-10.9,551.6-11.3,552.1z M-19.3,552.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,551.6-18.9,551.6-19.3,552.1z M-27.3,552.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,551.6-26.8,551.6-27.3,552.1z M-35.2,552.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,551.6-34.8,551.6-35.2,552.1z M-43.2,552.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-42,551.6-42.7,551.6-43.2,552.1z M-51.1,552.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-50,551.6-50.7,551.6-51.1,552.1z M4.6,560c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,559.6,5,559.6,4.6,560z M-3.4,560c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,559.6-2.9,559.6-3.4,560z M-11.3,560c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,559.6-10.9,559.6-11.3,560z M-19.3,560c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,559.6-18.9,559.6-19.3,560z M-27.3,560c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,559.6-26.8,559.6-27.3,560z M-35.2,560c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,559.6-34.8,559.6-35.2,560z M-43.2,560c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-42,559.6-42.7,559.6-43.2,560z M-51.1,560c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-50,559.6-50.7,559.6-51.1,560z M4.6,568c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,567.5,5,567.5,4.6,568z M-3.4,568c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,567.5-2.9,567.5-3.4,568z M-11.3,568c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,567.5-10.9,567.5-11.3,568z M-19.3,568c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,567.5-18.9,567.5-19.3,568z M-27.3,568c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,567.5-26.8,567.5-27.3,568z M-35.2,568c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,567.5-34.8,567.5-35.2,568z M-43.2,568c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-42,567.5-42.7,567.5-43.2,568z M-51.1,568c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-50,567.5-50.7,567.5-51.1,568z M4.6,575.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,575.5,5,575.5,4.6,575.9z M-3.4,575.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,575.5-2.9,575.5-3.4,575.9z M-11.3,575.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,575.5-10.9,575.5-11.3,575.9z M-19.3,575.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,575.5-18.9,575.5-19.3,575.9z M-27.3,575.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,575.5-26.8,575.5-27.3,575.9z M-35.2,575.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,575.5-34.8,575.5-35.2,575.9z M-43.2,575.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-42,575.5-42.7,575.5-43.2,575.9z M4.6,583.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,583.5,5,583.5,4.6,583.9z M-3.4,583.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,583.5-2.9,583.5-3.4,583.9z M-11.3,583.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,583.5-10.9,583.5-11.3,583.9z M-19.3,583.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,583.5-18.9,583.5-19.3,583.9z M-27.3,583.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,583.5-26.8,583.5-27.3,583.9z M-35.2,583.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,583.5-34.8,583.5-35.2,583.9z M4.6,591.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,591.4,5,591.4,4.6,591.9z M-3.4,591.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,591.4-2.9,591.4-3.4,591.9z M-11.3,591.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,591.4-10.9,591.4-11.3,591.9z M-19.3,591.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,591.4-18.9,591.4-19.3,591.9z M-27.3,591.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,591.4-26.8,591.4-27.3,591.9z M4.6,599.8c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,599.4,5,599.4,4.6,599.8z M-3.4,599.8c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,599.4-2.9,599.4-3.4,599.8z M-11.3,599.8c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,599.4-10.9,599.4-11.3,599.8z M4.6,607.8c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,607.4,5,607.4,4.6,607.8z M-3.4,607.8c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,607.4-2.9,607.4-3.4,607.8z M-11.3,607.8c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,607.4-10.9,607.4-11.3,607.8z M-19.3,599.8c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,599.4-18.9,599.4-19.3,599.8z M-27.3,599.8c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,599.4-26.8,599.4-27.3,599.8z M-35.2,591.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,591.4-34.8,591.4-35.2,591.9z M-43.2,583.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-42,583.5-42.7,583.5-43.2,583.9z M-51.1,575.9c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-50,575.5-50.7,575.5-51.1,575.9z M4.6,536.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,535.7,5,535.7,4.6,536.1z M-3.4,536.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,535.7-2.9,535.7-3.4,536.1z M-11.3,536.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,535.7-10.9,535.7-11.3,536.1z M-19.3,536.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,535.7-18.9,535.7-19.3,536.1z M-27.3,536.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,535.7-26.8,535.7-27.3,536.1z M-35.2,536.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,535.7-34.8,535.7-35.2,536.1z M-43.2,536.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-42,535.7-42.7,535.7-43.2,536.1z M-51.1,536.1c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-50,535.7-50.7,535.7-51.1,536.1z M4.6,528.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,527.7,5,527.7,4.6,528.2z M-3.4,528.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,527.7-2.9,527.7-3.4,528.2z M-11.3,528.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,527.7-10.9,527.7-11.3,528.2z M-19.3,528.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,527.7-18.9,527.7-19.3,528.2z M-27.3,528.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,527.7-26.8,527.7-27.3,528.2z M-35.2,528.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,527.7-34.8,527.7-35.2,528.2z M-43.2,528.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-42,527.7-42.7,527.7-43.2,528.2z M4.6,520.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,519.8,5,519.8,4.6,520.2z M-3.4,520.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,519.8-2.9,519.8-3.4,520.2z M-11.3,520.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,519.8-10.9,519.8-11.3,520.2z M-19.3,520.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,519.8-18.9,519.8-19.3,520.2z M-27.3,520.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,519.8-26.8,519.8-27.3,520.2z M-35.2,520.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,519.8-34.8,519.8-35.2,520.2z M4.6,512.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,511.8,5,511.8,4.6,512.3z M-3.4,512.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,511.8-2.9,511.8-3.4,512.3z M-11.3,512.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,511.8-10.9,511.8-11.3,512.3z M-19.3,512.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,511.8-18.9,511.8-19.3,512.3z M-27.3,512.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,511.8-26.8,511.8-27.3,512.3z M4.6,504.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,503.8,5,503.8,4.6,504.3z M-3.4,504.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,503.8-2.9,503.8-3.4,504.3z M-11.3,504.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,503.8-10.9,503.8-11.3,504.3z M4.6,496.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C5.7,495.9,5,495.9,4.6,496.3z M-3.4,496.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-2.2,495.9-2.9,495.9-3.4,496.3z M-11.3,496.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-10.2,495.9-10.9,495.9-11.3,496.3z M-19.3,504.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-18.1,503.8-18.9,503.8-19.3,504.3z M-27.3,504.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-26.1,503.8-26.8,503.8-27.3,504.3z M-35.2,512.3c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-34.1,511.8-34.8,511.8-35.2,512.3z M-43.2,520.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-42,519.8-42.7,519.8-43.2,520.2z M-51.1,528.2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6
            C-50,527.7-50.7,527.7-51.1,528.2z"
              />
              <path
                class="st3"
                d="M-23.6,566.3c-27.1-16.7-59.4,0-52.5,24.4c22,77.9,137.8,77.9,159.9,0c6.9-24.4-25.4-41.1-52.5-24.4
            C15,549.6-7.2,549.6-23.6,566.3z"
              />
            </g>
          </svg>

          <div class="img">
            <img
              src="./s1/c1.png"
              alt=""
              class="bg-img cloud1"
              data-aos="fade"
              data-aos-delay="800"
            >
            <img
              src="./s1/c2.png"
              alt=""
              class="bg-img cloud2"
              data-aos="fade"
              data-aos-delay="1000"
            >
            <img
              src="./s1/1.png"
              alt=""
              class="bg-img img1"
              data-aos="fade-up"
              data-aos-delay="0"
            >
            <img
              src="./s1/3.png"
              alt=""
              class="bg-img img3"
              data-aos="fade-right"
              data-aos-delay="300"
            >
            <img
              src="./s1/4.png"
              alt=""
              class="bg-img img4"
              data-aos="fade"
              data-aos-delay="400"
            >
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<style lang="scss" >
#s1 {
  z-index: 2;
  position: relative;
  .st0 {
    fill: #be2b27;
  }
  .st1 {
    fill: #cd0012;
  }
  .st2 {
    fill: #717071;
  }
  .st3 {
    opacity: 0.6;
  }
  .st4 {
    fill: #3e3a39;
  }
  .st5 {
    fill: none;
    stroke: #3e3a39;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #ffffff;
  }
  .logo {
    opacity: 0;
    animation: op 0.5s 1s forwards;
  }
  .t1 {
    animation: op 0.3s 4.5s reverse backwards;
    opacity: 0;
    .ans1 {
      opacity: 0;
      animation: op 0.5s 1.5s forwards;
    }
    .ans2 {
      opacity: 0;
      animation: op 0.5s 2s forwards;
    }
  }
  .t2 {
    opacity: 0;
    animation: op 0.5s 6s forwards;
  }
  .logoall {
    animation: tr 1s 5s reverse backwards;
    transform: translate(23.5%, -12.5%);
  }
  .right_1 {
    transform: translate(45%, 0%) scale(0.4);
    animation: tr 1s 5s reverse backwards;
  }
  .right_2 {
    transform: translate(65%, 15%);
    animation: tr 1s 5s reverse backwards;
  }
  .right_3 {
    transform: translate(17.5%, -10%) scale(0.9);
    animation: tr 1s 5s reverse backwards;
  }
  .a1 {
    transform: translate(-37%, -23%) scale(1.5);
    animation: tr 1s 5s reverse backwards;
  }
  .bottom1 {
    transform: translate(-10%, 0%) scale(1.2);
    animation: tr 1s 5s reverse backwards;
  }
  .an1 {
    animation: an_y 5s 0s linear infinite alternate-reverse;
    transform: translateY(-4vw);
  }
  .an2 {
    animation: an_y 3s -2s linear infinite alternate-reverse;
    transform: translateY(-5vw);
  }
  .an3 {
    animation: an_y 4s -1s linear infinite alternate-reverse;
    transform: translateY(-4vw);
  }
  .an4 {
    animation: an_y 4s -1s linear infinite alternate-reverse;
    transform: translateY(2vw);
  }
  .an5 {
    animation: an_y 4s -1s linear infinite alternate-reverse;
    transform: translateY(1vw);
  }
  .no {
    opacity: 0;
  }
}

// begin
.swipe-fade-leave-to {
  opacity: 0;
  z-index: 0;
}
// end
.swipe-fade-enter {
  opacity: 0;
  z-index: 1;
}

.swipe-fade-enter-active {
  transition: all 0.5s ease;
}

.swipe-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

@media screen and (max-width: 767px) {
  #s1 {
    .right_2 {
      transform: translate(36%, 37%) scale(0.6);
    }
    .a1 {
      transform: translate(-18%, 0) scale(1.5);
    }
    .bottom2 {
      transform: translate(-24%, 0%);
      animation: tr 1s 5s forwards;
    }
  }
}
@keyframes cloud {
  to {
    margin-left: 150px;
  }
}
@keyframes an_y {
  to {
    transform: translateY(0);
  }
}
@keyframes op {
  to {
    opacity: 1;
  }
}
@keyframes tr {
  to {
    transform: translate(0%, 0%) scale(1);
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section1 {
  width: size(1920);
  height: calc(100vh + 6.6145833333vw - 15.625vw);
  min-height: size(727);
  max-height: size(907);
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

.bg-img {
  width: size(1557);
  height: size(727);
  position: absolute;
  top: 0;
  left: size((1920 - 2200) / 2);
  height: auto;
  display: block;
  object-fit: cover;
  margin-top: 0;
  /*
  &:nth-child(1) {
    position: relative;
  }*/
}
#s1 {
  width: 100%;
  height: 100%;z-index: 2;
}
.img {
  animation: op 0.5s 6s forwards;
  opacity: 0;top: -5%;position: absolute;
}
.all {
  opacity: 0;
  animation: op 0.5s 10s reverse backwards;
}
.cloud1 {
  z-index: 1;
  animation: cloud 5s 0s linear infinite alternate-reverse;
}

.cloud2 {
  z-index: 3;
  animation: cloud 8s 1s linear infinite alternate-reverse;
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section1 {
    width: 100vw;
    min-height: sizem(604);
    max-height: sizem(604);
    height: sizem(604);
    // background-image: url('./mo/1/bg.png');
    background-size: cover;
    background-attachment: scroll;
  }
  .img1 {
    width: sizem(820);
    top: auto;
    left: sizem(-100);
    bottom: 0;
  }
  .img3 {
    width: sizem(820);
    top: auto;
    left: sizem(-50);
    bottom: 0;
  }
  .img4 {
    width: sizem(820);
    top: auto;
    left: sizem(-130);
    bottom: 0;
  }
  .cloud1 {
    width: sizem(1100);
    top: auto;
    left: sizem(-600);
    bottom: sizem(100);
    // animation: cloud 5s 0s linear infinite alternate-reverse;
  }

  .cloud2 {
    width: sizem(1100);
    top: auto;
    z-index: 1;
    left: sizem(-500);
    bottom: sizem(-50);
    // animation: cloud 8s 1s linear infinite alternate-reverse;
  }

  .img {
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'

export default {
  name: 'section1',

  data() {
    return {
      isPC,
      isMobile,
      isTablet,
      isShow: true,
    }
  },

  methods: {},

  created() {
    setInterval(() => {
      this.isShow = false
      setTimeout(() => {
        this.isShow = true
      }, 500)
    }, 11000)
  },

  mounted() {},

  computed: {},
}
</script>
